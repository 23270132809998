import React from "react";
import { MyInfoCircle } from "./Entities";

export default function Infobox(props) {
  return (
    <div className="d-inline-flex infobox-container align-items-center">
      <div className="infobox-icon">
        <MyInfoCircle />
      </div>
      <div className="infobox-content">{props.children}</div>
    </div>
  );
}
