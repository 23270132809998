import React, { useState, useEffect } from "react";
import {
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  Button,
  Label,
} from "reactstrap";
import API from "../api/api";
import { toast } from "react-toastify";
import BidList from "./BidList";
import Loader from "../common/Loader";
import MetaTags from "react-meta-tags";
import * as CONSTANTS from "../common/Constants";

export default function BidListPage(props) {
  const title =
    CONSTANTS.TITLES.MY_BIDS +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;
  const [bids, setBids] = useState([]);
  const [bidLoading, setBidLoading] = useState(true);
  const [auction, setAuction] = useState();
  const [paginationData, setPaginationData] = useState();
  const PER_PAGE_LIMIT = 10;
  const [auctionStatusFilter, setAuctionStatusFilter] = useState(null);
  const [bidStatusFilter, setBidStatusFilter] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      setBidLoading(true);
      if (props.match.params.auctionId) {
        API.get(`auctions/${props.match.params.auctionId}/`)
          .then((result) => {
            setAuction(result.data);
          })
          .catch((err) => {
            toast.error("Error loading auction data", {});
            console.log("ERROR", err);
          })
          .finally(() => {});
      }
      getBids(1);
    };
    fetchData(props.match.params.auctionId);
  }, [props.match.params.auctionId]);

  const getBids = (page) => {
    let params = {
      page,
      ordering: "-created_at",
    };

    if (props.match.params.auctionId) {
      params = { ...params, auction: props.match.params.auctionId };
    }

    if (auctionStatusFilter && auctionStatusFilter !== "none") {
      params = { ...params, auction__state: auctionStatusFilter };
    }

    if (bidStatusFilter && bidStatusFilter !== "none") {
      params = { ...params, state: bidStatusFilter };
    }

    API.get(`bids/`, { params })
      .then((result) => {
        setBids(result.data.results);
        setPaginationData((data) => ({
          ...data,
          count: result.data.count,
          next: result.data.next,
          previous: result.data.previous,
          activePage: page,
        }));
      })
      .catch((err) => {
        toast.error("Error loading bid data", {});
        console.log("ERROR", err);
      })
      .finally(() => {
        setBidLoading(false);
      });
  };

  const renderPagination = () => {
    const pages = Array.from(
      Array(Math.ceil(paginationData.count / 10)),
      (_, i) => i + 1
    );
    return (
      <Pagination aria-label="Synthagora pagination">
        {/* <PaginationItem>
          <PaginationLink first href="#" />
        </PaginationItem> */}
        <PaginationItem disabled={paginationData.activePage === 1}>
          <PaginationLink
            previous
            onClick={() => {
              getBids(paginationData.activePage - 1);
            }}
          />
        </PaginationItem>
        {pages.map((item, index) => (
          <PaginationItem
            key={item}
            active={item === paginationData.activePage}
          >
            <PaginationLink
              onClick={() => {
                getBids(item);
              }}
            >
              {item}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={paginationData.activePage === pages.length}>
          <PaginationLink
            next
            onClick={() => {
              getBids(paginationData.activePage + 1);
            }}
          />
        </PaginationItem>
        {/* <PaginationItem>
          <PaginationLink last href="#" />
        </PaginationItem> */}
      </Pagination>
    );
  };

  return (
    <div className="grey-bg">
      <Container className="pt-4">
        <MetaTags>
          <title>{title}</title>
        </MetaTags>
        <h1 className="mb-4 subheadline orange-text">
          My bids
          {auction && auction.id && (
            <span> for auction: {auction.auction_name_full}</span>
          )}
        </h1>
        <div className="mb-4 filter-row">
          <Label for="auction-filter">Status of auction</Label>
          <Input
            type="select"
            name="auction-filter"
            id="auction-filter"
            onChange={(event) => setAuctionStatusFilter(event.target.value)}
          >
            <option value={"none"}>All</option>
            <option value={CONSTANTS.AUCTION_STATE.APPROVED}>
              {CONSTANTS.REQUEST_STATE_LABEL.APPROVED}
            </option>
            <option value={CONSTANTS.AUCTION_STATE.CLOSED}>
              {CONSTANTS.REQUEST_STATE_LABEL.CLOSED}
            </option>
            <option value={CONSTANTS.AUCTION_STATE.ACCEPTED}>
              {CONSTANTS.REQUEST_STATE_LABEL.ACCEPTED}
            </option>
            <option value={CONSTANTS.AUCTION_STATE.REQUESTED}>
              {CONSTANTS.REQUEST_STATE_LABEL.REQUESTED}
            </option>
            <option value={CONSTANTS.AUCTION_STATE.EXPIRED}>
              {CONSTANTS.REQUEST_STATE_LABEL.EXPIRED}
            </option>
            <option value={CONSTANTS.AUCTION_STATE.CANCELLED}>
              {CONSTANTS.REQUEST_STATE_LABEL.CANCELLED}
            </option>
          </Input>
          <Label for="auction-filter">Status of bid</Label>
          <Input
            type="select"
            name="bid-filter"
            id="bid-filter"
            onChange={(event) => setBidStatusFilter(event.target.value)}
            className="ml-1"
          >
            <option value={"none"}>All</option>
            <option value={CONSTANTS.BID_STATE.DRAFT}>
              {CONSTANTS.BID_STATE_LABEL.DRAFT}
            </option>
            <option value={CONSTANTS.BID_STATE.SUBMITTED}>
              {CONSTANTS.BID_STATE_LABEL.SUBMITTED}
            </option>
            <option value={CONSTANTS.BID_STATE.WITHDRAWN}>
              {CONSTANTS.BID_STATE_LABEL.WITHDRAWN}
            </option>
            <option value={CONSTANTS.BID_STATE.ACCEPTED}>
              {CONSTANTS.BID_STATE_LABEL.ACCEPTED}
            </option>
            <option value={CONSTANTS.BID_STATE.NOT_SELECTED}>
              {CONSTANTS.BID_STATE_LABEL.NOT_SELECTED}
            </option>
          </Input>
          <Button className="btn btn-orange ml-2" onClick={() => getBids(1)}>
            Filter
          </Button>
        </div>
        <div className="box-container orange">
          <div className="box">
            {bidLoading ? (
              <Loader overlay text="Loading bids" />
            ) : (
              <React.Fragment>
                <BidList bids={bids} />
                {paginationData.count > PER_PAGE_LIMIT && renderPagination()}
              </React.Fragment>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
