import axios from "axios"

let axiosConf = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
}

if (process.env.REACT_APP_AUTH_USERNAME) {
  axiosConf["headers"] = {
    "Auth-username": process.env.REACT_APP_AUTH_USERNAME,
  }
}

export default axios.create(axiosConf)
