import React, { useState, useEffect } from "react";
import {
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import API from "../api/api";
import { toast } from "react-toastify";
import Loader from "../common/Loader";
import OrderList from "./OrderList";
import MetaTags from "react-meta-tags";
import * as CONSTANTS from "../common/Constants";

export default function OrderListPage(props) {
  const title =
    CONSTANTS.TITLES.MY_ORDERS +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;
  const [orders, setOrders] = useState([]);
  const [orderLoading, setOrderLoading] = useState(true);
  const [paginationData, setPaginationData] = useState();
  const PER_PAGE_LIMIT = 10;

  useEffect(() => {
    const fetchData = () => {
      setOrderLoading(true);
      getOrders(1);
    };
    fetchData();
  }, []);

  const getOrders = (page) => {
    let params = {
      page,
      ordering: "-created_at",
    };
    API.get(`customer-orders-for-supplier/`, { params })
      .then((result) => {
        setOrders(result.data.results);
        setPaginationData((data) => ({
          ...data,
          count: result.data.count,
          next: result.data.next,
          previous: result.data.previous,
          activePage: page,
        }));
      })
      .catch((err) => {
        toast.error("Error loading auction data", {});
        console.log("ERROR", err);
      })
      .finally(() => {
        setOrderLoading(false);
      });
  };

  const renderPagination = () => {
    const pages = Array.from(
      Array(Math.ceil(paginationData.count / 10)),
      (_, i) => i + 1
    );
    return (
      <Pagination aria-label="Synthagora pagination">
        {/* <PaginationItem>
          <PaginationLink first href="#" />
        </PaginationItem> */}
        <PaginationItem disabled={paginationData.activePage === 1}>
          <PaginationLink
            previous
            onClick={() => {
              getOrders(paginationData.activePage - 1);
            }}
          />
        </PaginationItem>
        {pages.map((item, index) => (
          <PaginationItem
            key={item}
            active={item === paginationData.activePage}
          >
            <PaginationLink
              onClick={() => {
                getOrders(item);
              }}
            >
              {item}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={paginationData.activePage === pages.length}>
          <PaginationLink
            next
            onClick={() => {
              getOrders(paginationData.activePage + 1);
            }}
          />
        </PaginationItem>
        {/* <PaginationItem>
          <PaginationLink last href="#" />
        </PaginationItem> */}
      </Pagination>
    );
  };

  return (
    <div className="grey-bg">
      <Container className="pt-4">
        <MetaTags>
          <title>{title}</title>
        </MetaTags>
        <h1 className="subheadline left">My orders</h1>
        <div className="box-container blue">
          <div className="box">
            {orderLoading ? (
              <Loader overlay text="Loading order" />
            ) : (
              <React.Fragment>
                <OrderList orders={orders} />
                {paginationData.count > PER_PAGE_LIMIT && renderPagination()}
              </React.Fragment>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
