import { useState, useEffect } from "react";
import * as moment from "moment";
import * as CONSTANTS from "../common/Constants";

function setInputValue(target) {
  switch (target.type) {
    case "number":
    case "text":
      return target.value;
    case "select-one":
      return Number.parseInt(target.value);
    case "checkbox":
      return target.checked;
    default:
      return target.value;
  }
}

export const useAuctionForm = (initialValues, callback) => {
  const initValues = {
    auction_name: "",
    description: "",
    close_immediately: false,
    valid_until: moment().add(15, "days").toDate(),
    target_price: "",
    delivery_deadline: "",
    state: CONSTANTS.AUCTION_STATE.DRAFT,
    preferred_suppliers: [],
    structures: [],
    target_purity: 90,
    purity_own_analysis: false,
    exclusivity_value: 0,
    exclusivity_unit: CONSTANTS.EXLUSIVITY_UNIT.MONTH,
    amount: 1,
    unit: CONSTANTS.AMOUNT_UNIT.MG,
    partial_offer_type: CONSTANTS.PARTIAL_OFFER_TYPE.MINIMUM,
    partial_offer: 1,
    exact_stereo_match: false,
    reporting: CONSTANTS.REPORTING.NOT_REQUIRED,
    known_synthesis_route: false,
    files: [],
    comment: "",
  };
  const [inputs, setInputs] = useState(initValues);

  useEffect(() => {
    if (initialValues) {
      setInputs(initialValues);
    }
  }, [initialValues]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
      callback();
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === "files") {
      setInputs((inputs) => ({
        ...inputs,
        files: event.target.files[0],
      }));
      setInputs((inputs) => ({
        ...inputs,
        files: [],
      }));
    } else {
      setInputs((inputs) => ({
        ...inputs,
        [event.target.name]: setInputValue(event.target),
      }));
    }
  };

  const handleDateTimePickerChange = (_moment, name) => {
    let tempMoment = null;
    if (moment.isMoment(_moment)) {
      tempMoment = _moment.toDate();
    }
    setInputs((inputs) => ({
      ...inputs,
      [name]: tempMoment,
    }));
  };

  const handleSupplierChange = (supplier, action, multiSupplier = false) => {
    if (action === CONSTANTS.PREFERRED_SUPPLIER_REMOVE) {
      let filteredArray = inputs.preferred_suppliers.filter(
        (e) => e !== supplier.id
      );
      if (multiSupplier) {
        filteredArray = [];
      }
      setInputs((inputs) => ({
        ...inputs,
        preferred_suppliers: filteredArray,
      }));
    } else if (action === CONSTANTS.PREFERRED_SUPPLIER_ADD) {
      if (multiSupplier) {
        for (let sup of supplier) {
          if (!inputs.preferred_suppliers.includes(sup.id)) {
            inputs.preferred_suppliers.push(sup.id);
          }
        }
        setInputs((inputs) => ({
          ...inputs,
          preferred_suppliers: inputs.preferred_suppliers,
        }));
      } else {
        if (!inputs.preferred_suppliers.includes(supplier.id)) {
          inputs.preferred_suppliers.push(supplier.id);
          setInputs((inputs) => ({
            ...inputs,
            preferred_suppliers: inputs.preferred_suppliers,
          }));
        }
      }
    }
  };

  const handleStructureChange = (compounds) => {
    setInputs((inputs) => ({
      ...inputs,
      structures: compounds,
    }));
  };

  const handlePartialOfferChange = (num) => {
    const partialOfferType =
      num === 1
        ? CONSTANTS.PARTIAL_OFFER_TYPE.EXACT
        : inputs.partial_offer_type;

    setInputs((inputs) => ({
      ...inputs,
      partial_offer: num,
      partial_offer_type: partialOfferType,
    }));
  };

  const setInitAuctionValues = () => {
    setInputs(initValues);
  };

  return {
    handleSubmit,
    handleInputChange,
    handleDateTimePickerChange,
    handleSupplierChange,
    handleStructureChange,
    handlePartialOfferChange,
    setInitAuctionValues,
    inputs,
    setInputs,
  };
};
