import React, { useEffect } from "react";
import * as CONSTANTS from "../common/Constants";
import { useAuth } from "../context/auth";
import {
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import MetaTags from "react-meta-tags";
import { useNotification } from "../context/notification";
import Loader from "../common/Loader";
import API from "../api/api";
import DOMPurify from "dompurify";

export default function NotificationList(props) {
  const title =
    CONSTANTS.TITLES.NOTIFICATIONS +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;
  const { userInfo } = useAuth();
  const { notifications, updateNotifications } = useNotification();
  const PER_PAGE_LIMIT = 10;

  const isCustomer = () => {
    return userInfo.user_type === CONSTANTS.USER_TYPE.CUSTOMER;
  };

  useEffect(() => {
    markSeenNotification(notifications?.results.map((a) => a.id));
  }, [notifications]);

  const getNotifications = (page) => {
    updateNotifications(page);
  };

  const markSeenNotification = (seenNotifications) => {
    API.post(`/notifications/notices/mark-seen/`, {
      notices: seenNotifications,
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        toast.error("Error setting seen notifications", {});
        console.log("ERROR", err);
      });
  };

  /* Supplier handling START */
  function renderNotificationRows() {
    if (notifications && notifications.count > 0) {
      const sanitizer = DOMPurify.sanitize;
      return notifications.results.map((item, index) => {
        return (
          <div
            key={item.id}
            className={"notification-row " + (item.unseen ? "unseen" : "seen")}
            dangerouslySetInnerHTML={{
              __html: sanitizer(item.message),
            }}
          ></div>
        );
      });
    }
  }

  const renderPagination = () => {
    const pages = Array.from(
      Array(Math.ceil(notifications.count / PER_PAGE_LIMIT)),
      (_, i) => i + 1
    );
    return (
      <Pagination aria-label="Synthagora pagination">
        {/* <PaginationItem>
          <PaginationLink first href="#" />
        </PaginationItem> */}
        <PaginationItem disabled={notifications.activePage === 1}>
          <PaginationLink
            previous
            onClick={() => {
              getNotifications(notifications.activePage - 1);
            }}
          />
        </PaginationItem>
        {pages.map((item, index) => (
          <PaginationItem key={item} active={item === notifications.activePage}>
            <PaginationLink
              onClick={() => {
                getNotifications(item);
              }}
            >
              {item}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={notifications.activePage === pages.length}>
          <PaginationLink
            next
            onClick={() => {
              getNotifications(notifications.activePage + 1);
            }}
          />
        </PaginationItem>
        {/* <PaginationItem>
          <PaginationLink last href="#" />
        </PaginationItem> */}
      </Pagination>
    );
  };

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="pt-4">
        <div className="text-right">
          <Link to={`/notification-settings`}>
            Manage notification settings
          </Link>
        </div>
        <h1 className="mb-4 subheadline text-center">Notifications</h1>
        {!notifications ? (
          <Loader overlay text="Loading notifications" />
        ) : (
          <div
            className={"box-container " + (isCustomer() ? "orange" : "blue")}
          >
            <div className="box notification-list-container">
              {renderNotificationRows()}
              {notifications.count > PER_PAGE_LIMIT && renderPagination()}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}
