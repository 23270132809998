function getDefaultServicesPrefix() {
  var servername = `${process.env.REACT_APP_MARVINJS_API_DOMAIN}`
  var webapp = ''
  return servername + webapp
}

function getDefaultServices() {
  var base = getDefaultServicesPrefix()
  var services = {
    clean2dws:
      base + `${process.env.REACT_APP_MARVINJS_API_BASE_URL}convert/clean`,
    clean3dws:
      base + `${process.env.REACT_APP_MARVINJS_API_BASE_URL}convert/clean`,
    molconvertws:
      base +
      `${process.env.REACT_APP_MARVINJS_API_BASE_URL}calculate/molExport`,
    stereoinfows:
      base +
      `${process.env.REACT_APP_MARVINJS_API_BASE_URL}calculate/cipStereoInfo`,
    reactionconvertws:
      base +
      `${process.env.REACT_APP_MARVINJS_API_BASE_URL}calculate/reactionExport`,
    hydrogenizews:
      base +
      `${process.env.REACT_APP_MARVINJS_API_BASE_URL}convert/hydrogenizer`,
    automapperws:
      base +
      `${process.env.REACT_APP_MARVINJS_API_BASE_URL}convert/reactionConverter`,
    aromatizews:
      base +
      `${process.env.REACT_APP_MARVINJS_API_BASE_URL}calculate/molExport`,
  }
  return services
}
export { getDefaultServices }
