import React from "react";
import { Link } from "react-router-dom";

export default function Td({ children, to, datalabel }) {
  // Conditionally wrapping content into a link
  const ContentTag = to ? Link : "div";

  return (
    <td data-label={datalabel} className="link-td-container">
      <ContentTag to={to} className="link-td">
        {children}
      </ContentTag>
    </td>
  );
}
