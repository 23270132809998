import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Col,
  Container,
} from "reactstrap";
import { useAuth } from "../context/auth";
import { useModal } from "../context/modal";
import API from "../api/api";
import { toast } from "react-toastify";
import * as CONSTANTS from "../common/Constants";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";

export default function LoginPage(props) {
  const title =
    CONSTANTS.TITLES.LOGIN +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;
  const { setUserInfo } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loginModal, setLoginModal } = useModal();
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    API.post("users/signin/", {
      email,
      password: password,
    })
      .then(() => {
        setUserInfo();
        history.push("/");
      })
      .catch((err) => {
        toast.error("Please correct the form errors.", {});

        if (err.response) {
          setErrors(err.response.data);
        }
      });
  };

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="pt-4">
        <h1 className="mb-4 subheadline text-center">Login</h1>
        <div className="box-container mw-500">
          <Form className="box" autoComplete="off" onSubmit={handleSubmit}>
            <FormGroup
              row
              className="justify-content-center allign-items-center"
            >
              <Label sm={12} htmlFor="email">
                Email
              </Label>
              <Col sm={12}>
                <Input
                  required
                  name="email"
                  type="text"
                  id="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup
              row
              className="justify-content-center allign-items-center"
            >
              <Label sm={12} htmlFor="password">
                Password
              </Label>
              <Col sm={12}>
                <Input
                  required
                  name="password"
                  type="password"
                  id="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  invalid={errors.non_field_errors}
                />
                {errors && errors.non_field_errors && (
                  <FormFeedback>{errors.non_field_errors}</FormFeedback>
                )}
              </Col>
            </FormGroup>
            <div className="text-right">
              <Link to={`/reset-password`}>Forgot password?</Link>
            </div>
            <div className="d-flex justify-content-around">
              {/* <Button color="secondary" onClick={setLoginModal}>
                Cancel
              </Button> */}
              <Button color="primary" type="submit">
                Login
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
}
