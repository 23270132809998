import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import API from "../api/api";
import * as CONSTANTS from "../common/Constants";
import { toast } from "react-toastify";
import Loader from "../common/Loader";

import AuctionList from "./AuctionList";
import OrderList from "./OrderList";
import MetaTags from "react-meta-tags";

export default function CustomerHome() {
  const title =
    CONSTANTS.TITLES.DASHBOARD +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;
  const [auctions, setAuctions] = useState([]);
  const [auctionLoading, setAuctionLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderLoading, setOrderLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setAuctionLoading(true);
      let params = {
        ordering: "-created_at",
      };

      API.get(`auctions/`, { params })
        .then((auctionResult) => {
          setAuctions(auctionResult.data.results);
        })
        .catch((err) => {
          toast.error("Error loading auction data", {});
          console.log("ERROR", err);
        })
        .finally(() => {
          setAuctionLoading(false);
        });
      setOrderLoading(true);
      API.get("customer-orders/", { params })
        .then((orderResult) => {
          setOrders(orderResult.data.results);
        })
        .catch((err) => {
          toast.error("Error loading auction data", {});
          console.log("ERROR", err);
        })
        .finally(() => {
          setOrderLoading(false);
        });
    };
    fetchData();
  }, []);

  return (
    <div className="grey-bg pt-4 dashboard">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="pt-4">
        <Row>
          <Col lg={6}>
            <Row>
              <Col sm={4}>
                <h2 className="subheadline">My auctions</h2>
              </Col>
              <Col sm={4}>
                <div className="text-center header-right mb-1">
                  <Link
                    to="create-auction"
                    className="btn btn-orange d-inline-block"
                  >
                    <i className="fas fa-plus"></i> Create an auction
                  </Link>
                </div>
              </Col>
              <Col sm={4}>
                <div className="text-right header-right mb-1">
                  <Link to="my-auctions" className="btn btn-orange">
                    Go to my auctions
                  </Link>
                </div>
              </Col>
            </Row>
            <div className="box-container orange mt-2">
              <div className="box">
                {auctionLoading ? (
                  <Loader overlay text="Loading auctions" />
                ) : (
                  <AuctionList summary auctions={auctions} />
                )}
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <Row>
              <Col sm={6}>
                <h2 className="subheadline blue">My orders</h2>
              </Col>
              <Col sm={6}>
                <div className="text-right header-right mb-1">
                  <Link to="my-orders" className="btn btn-blue">
                    Go to my orders
                  </Link>
                </div>
              </Col>
            </Row>

            <div className="box-container blue">
              <div className="box">
                {orderLoading ? (
                  <Loader overlay text="Loading orders" />
                ) : (
                  <OrderList summary orders={orders} />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
