import React, { useState, useEffect } from "react";
import placeholderSrc from "../assets/no_image_b_300px.png";
import { convertStructureToImage } from "../Marvin";
import Loader from "./Loader";
import FsLightbox from "fslightbox-react";

export default function StructureImage(props) {
  const [src, setSrc] = useState(placeholderSrc);
  const [loading, setLoading] = useState(false);
  const [toggler, setToggler] = useState(false);

  useEffect(() => {
    const getCompoundImage = async (chemical_id) => {
      setLoading(true);
      try {
        await convertStructureToImage(chemical_id)
          .then((result) => {
            setSrc(result);
            setLoading(false);
          })
          .catch((err) => {
            console.log("ERROR", err);
            setSrc(placeholderSrc);
            setLoading(false);
          });
      } catch (e) {
        console.log("error convert error", e);
      }
    };
    if (props.src) {
      getCompoundImage(props.src);
    }
  }, [props.src]);

  return (
    <>
      <div className="structure-image" onClick={() => setToggler(!toggler)}>
        <img src={src} alt={props.alt || "Structure image"} />
        {loading ? (
          <Loader overlay />
        ) : (
          <FsLightbox toggler={toggler} sources={[src]} />
        )}
      </div>
    </>
  );
}
