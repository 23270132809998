import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Table,
  Input,
  InputGroupText,
  Button,
  Badge,
  ButtonGroup,
} from "reactstrap";
import API from "../api/api";
import { toast } from "react-toastify";
import LinkButton from "../common/LinkButton";
import * as CONSTANTS from "../common/Constants";
import moment from "moment";
import { Link } from "react-router-dom";
import StructureImage from "../common/StructureImage";
import { formatPrice } from "../common/Utilities";
import InputField from "../common/InputField";
import SimpleTooltip from "../common/SimpleTooltip";
import { useAuth } from "../context/auth";

export default function BidDetail(props) {
  const APIDomain = process.env.REACT_APP_API_DOMAIN;
  const APIBase = process.env.REACT_APP_API_BASE_URL;
  const [auction, setAuction] = useState({});
  const [bid, setBid] = useState([]);
  const [noData, setNoData] = useState(false);
  const { userInfo } = useAuth();
  const [profile, setProfile] = useState(false);

  const getProfile = () => {
    API.get(`customers/${userInfo.customer}/`)
      .then((result) => {
        setProfile(result.data);
      })
      .catch((err) => {
        toast.error("Error at request.", {});
        console.log("ERROR", err);
      });
  };

  const hasDiscountedPrice = (bid) => {
    return bid.discounted_public_total_price !== bid.public_total_price;
  };

  function getOffers(auctionResult, bidId) {
    API.get(`bids/?auction=${auctionResult.id}`)
      .then((result) => {
        const bidResult = result.data.results.filter(
          (b) => b.id === parseInt(bidId)
        )[0];
        if (bidResult) {
          let tempStructures = auctionResult.structures;
          tempStructures.map((item, index) => {
            if (bidResult.structures.includes(item.unique_id)) {
              item.checked = true;
            }
            return true;
          });
          setBid(bidResult);
          setBid((inputs) => ({
            ...inputs,
            structures: tempStructures,
          }));
        } else {
          setNoData(true);
        }
      })
      .catch((err) => {
        toast.error("Error loading suppliers data", {});
        console.log("ERROR", err);
      })
      .finally(() => {
        // this.setState({loading: false})
      });
  }

  const acceptBid = (bidId) => {
    // ToDo: Confirmation
    API.patch(`bids/${bidId}/`, {
      state: CONSTANTS.BID_STATE.ACCEPTED,
    })
      .then((result) => {
        console.log(result);
        toast.success("Bid accepted", {});
        getInfo(auction.id, bid.id);
      })
      .catch((err) => {
        toast.error("Error accepting bid", {});
        console.log("ERROR", err);
      });
  };

  const getInfo = (auctionId, bidId) => {
    API.get(`auctions/${auctionId}/`)
      .then((result) => {
        setAuction(result.data);
        if (result.data.bids && result.data.bids.length > 0) {
          getOffers(result.data, bidId);
        } else {
          setBid(null);
        }
      })
      .catch((err) => {
        toast.error("Error loading auction data", {});
        console.log("ERROR", err);
        setNoData(true);
      })
      .finally(() => {
        // this.setState({loading: false})
      });
  };

  function getDownloadQuoteURL(bidId) {
    return `${APIDomain}${APIBase}bids/${bidId}/get-quote-xlsx/`;
  }

  useEffect(() => {
    getProfile();
    setNoData(false);
    if (props.match.params.auctionId && props.match.params.bidId) {
      getInfo(props.match.params.auctionId, props.match.params.bidId);
    }
  }, [props.match.params.auctionId, props.match.params.bidId]);

  return (
    <div className="grey-bg">
      <Container className="mt-4">
        {!noData ? (
          <React.Fragment>
            <Row>
              <Col sm={8}>
                <ul className="breadcrumb">
                  <li>
                    <Link to={`/my-auctions`}>My auctions</Link>
                  </li>
                  <li>
                    <Link to={`/track-auction/${auction.id}`}>
                      Track auction {auction.auction_name_full}
                    </Link>
                  </li>
                  <li>{bid.bid_cqr_name}</li>
                </ul>
                <h1 className="subheadline">Bid {bid.bid_cqr_name}</h1>
              </Col>
              <Col sm={4} className="text-right">
                <h3 className="subheadline">{auction.auction_name_full}</h3>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col sm={8}>
                <>
                  <div className="subheader border-left-orange mb-4">
                    {auction && (
                      <div>
                        <span className="subheader-label">
                          Auction closing date:
                        </span>
                        <span className="subheader-value">
                          {moment(auction.valid_until).format(
                            CONSTANTS.DATETIME_FORMAT
                          )}
                        </span>
                      </div>
                    )}
                    {bid && (
                      <React.Fragment>
                        <div>
                          <span className="subheader-label">Supplier:</span>
                          <span className="subheader-value">
                            {bid.supplier_name}
                            {/* (win rate:{" "}
                            {bid.supplier_statistics && (
                              <span>{bid.supplier_statistics.win_percent}</span>
                            )}
                            %) */}
                          </span>
                        </div>
                        <div>
                          <span className="subheader-label">Date of bid:</span>
                          <span className="subheader-value">
                            {moment(bid.created_at).format(
                              CONSTANTS.DATETIME_FORMAT
                            )}
                          </span>
                        </div>
                        <div>
                          <span className="subheader-label">
                            Bid expiry date:
                            <SimpleTooltip name="expiry_date">
                              Bid acceptance and order confirmation only
                              possible before the bid expiry date. After the bid
                              expiry date, these bids are no longer valid, so
                              you have to contact us at{" "}
                              <a href="mailto:support@synthagora.com">
                                support@synthagora.com
                              </a>
                              to require a new quote. Please indicate which
                              expired offer you wish to order. The bids expire
                              automatically 45 days from the auction submission.
                            </SimpleTooltip>
                          </span>
                          <span className="subheader-value">
                            {moment(bid.valid_until).format(
                              CONSTANTS.DATETIME_FORMAT
                            )}
                          </span>
                        </div>
                        <small>
                          (THE BID EXPIRY DATE IS AUTOMATICALLY GENERATED 45
                          DAYS FROM THE AUCTION SUBMISSION DATE. )
                        </small>
                      </React.Fragment>
                    )}
                  </div>
                  {profile?.discount > 0 && (
                    <div className="discount-box subheader">
                      Your permanent discount: {profile.discount} %
                    </div>
                  )}
                </>
              </Col>
              <Col sm={4} className="text-right">
                {(auction.state === CONSTANTS.AUCTION_STATE.CLOSED ||
                  auction.state === CONSTANTS.AUCTION_STATE.SUBMITTED) && (
                  <div>
                    <Button
                      className="mb-2 btn-orange"
                      onClick={(event) => acceptBid(bid.id)}
                    >
                      Accept bid
                    </Button>
                  </div>
                )}
                {auction.state === CONSTANTS.AUCTION_STATE.ACCEPTED &&
                  bid.state === CONSTANTS.BID_STATE.ACCEPTED && (
                    <React.Fragment>
                      {/* style as button */}
                      <a
                        className="d-block mb-1 ml-auto"
                        href={getDownloadQuoteURL(bid.id)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download quote
                      </a>
                      <LinkButton
                        to={`/confirm-order/${auction.id}`}
                        className="d-block btn btn-secondary mb-1 ml-auto"
                      >
                        Confirm order
                      </LinkButton>
                    </React.Fragment>
                  )}
              </Col>
            </Row>

            <h2 className="subheadline">General compound details:</h2>
            <div className="box-container orange">
              <div className="box">
                <Row>
                  <Col sm={12} md={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Requested</div>
                      <div className="label-line"></div>
                    </div>
                    <div className="bid-container">
                      <div className="bid-margin-bottom">
                        <InputField
                          name="target_price"
                          displayName="Target budget"
                          type="price"
                          value={auction.target_price}
                          disabled
                          append="USD"
                        />
                      </div>
                      <FormGroup>
                        <Label for="delivery_deadline">
                          Target delivery date
                        </Label>
                        <InputGroup>
                          <Input
                            type="text"
                            name="delivery_deadline"
                            id="delivery_deadline"
                            value={auction.delivery_deadline || "Not set"}
                            disabled
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label for="request_amount">Requested amount</Label>
                        <InputGroup>
                          <Input
                            type="number"
                            name="amount"
                            id="request_amount"
                            value={auction.amount || 0}
                            disabled
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              {auction.unit_display}
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label for="target_purity">Requested purity</Label>
                        <InputGroup>
                          <Input
                            type="number"
                            name="target_purity"
                            id="target_purity"
                            value={auction.target_purity || 0}
                            disabled
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>%</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label for="request_coverage">
                          Number of compounds
                        </Label>
                        <InputGroup>
                          {auction.structures && (
                            <Input
                              type="text"
                              name="coverage"
                              id="request_coverage"
                              value={
                                auction.partial_offer_type_display +
                                " " +
                                auction.partial_offer
                              }
                              disabled
                            />
                          )}
                        </InputGroup>
                      </FormGroup>
                      <Table>
                        <thead>
                          <tr>
                            <th className="column-id">#</th>
                            <th className="column-structure">Structure</th>
                            <th>Chemical ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {auction.structures &&
                            auction.structures.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="structure-container">
                                  <StructureImage src={item.chemical_id} />
                                  <div>{item.unique_id}</div>
                                </td>
                                <td>{item.chemical_id}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  <Col sm={12} md={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Quoted</div>
                      <div className="label-line"></div>
                    </div>
                    <div className="bid-container bid-request">
                      <FormGroup>
                        <Label for="price">Total price</Label>
                        <SimpleTooltip name="price">
                          The total price includes total compound price,
                          shipping, and handling fees, and additional fees for
                          extra services (exclusivity, reporting).
                        </SimpleTooltip>
                        {hasDiscountedPrice(bid) ? (
                          <div className="form-control disabled">
                            <span className="old-price">
                              {`${bid.public_total_price} USD`}
                            </span>
                            <span className="discounted-price">
                              {`${bid.discounted_public_total_price} USD`}
                            </span>
                          </div>
                        ) : (
                          <InputGroup>
                            {formatPrice(
                              bid.public_total_price,
                              false,
                              "form-control disabled"
                            )}
                            <InputGroupAddon addonType="append">
                              <InputGroupText>USD</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label for="compound_price">Total compound price</Label>
                        {hasDiscountedPrice(bid) ? (
                          <div className="form-control disabled">
                            <span className="old-price">
                              {`${bid.public_product_price} USD`}
                            </span>
                            <span className="discounted-price">
                              {`${bid.discounted_public_product_price} USD`}
                            </span>
                          </div>
                        ) : (
                          <InputGroup>
                            {formatPrice(
                              bid.public_product_price,
                              false,
                              "form-control disabled"
                            )}
                            <InputGroupAddon addonType="append">
                              <InputGroupText>USD</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label for="shipping_and_handling_fee">
                          Shipping and handling fee
                        </Label>
                        {hasDiscountedPrice(bid) ? (
                          <div className="form-control disabled">
                            <span className="old-price">
                              {`${bid.public_delivery_cost} USD`}
                            </span>
                            <span className="discounted-price">
                              {`${
                                Math.round(
                                  (bid.discounted_public_total_price -
                                    bid.discounted_public_product_price) *
                                    100
                                ) / 100
                              } USD`}
                            </span>
                          </div>
                        ) : (
                          <InputGroup>
                            {formatPrice(
                              bid.public_delivery_cost,
                              false,
                              "form-control disabled"
                            )}
                            <InputGroupAddon addonType="append">
                              <InputGroupText>USD</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label for="average_price">Average product price</Label>
                        <SimpleTooltip name="average_price">
                          The average compound price is the total compound price
                          per number of compounds.
                        </SimpleTooltip>
                        {hasDiscountedPrice(bid) ? (
                          <div className="form-control disabled">
                            <span className="old-price">
                              {`${bid.avg_public_product_price} USD`}
                            </span>
                            <span className="discounted-price">
                              {`${bid.discounted_avg_public_product_price} USD`}
                            </span>
                          </div>
                        ) : (
                          <InputGroup>
                            {formatPrice(
                              bid.avg_public_product_price,
                              false,
                              "form-control disabled"
                            )}
                            <InputGroupAddon addonType="append">
                              <InputGroupText>USD</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label for="max_delivery_time">Quoted lead time</Label>
                        <SimpleTooltip name="max_delivery_time">
                          The counting of lead time starts after the order
                          confirmation by Mcule.
                        </SimpleTooltip>
                        <InputGroup>
                          <Input
                            type="number"
                            name="max_delivery_time"
                            id="max_delivery_time"
                            value={bid.public_delivery_time || 0}
                            disabled
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>weeks</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label for="amount">Quoted amount</Label>
                        <SimpleTooltip name="amount">
                          The quoted amount indicated here applies to all quoted
                          compounds.
                        </SimpleTooltip>
                        <InputGroup>
                          <Input
                            type="number"
                            name="amount"
                            id="amount"
                            value={bid.amount || 0}
                            disabled
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText className="no-padding">
                              <Input
                                type="select"
                                name="unit"
                                id="unit"
                                className="no-padding"
                                value={bid.unit}
                                disabled
                              >
                                <option value={CONSTANTS.AMOUNT_UNIT.MG}>
                                  mg
                                </option>
                                <option value={CONSTANTS.AMOUNT_UNIT.G}>
                                  g
                                </option>
                                <option value={CONSTANTS.AMOUNT_UNIT.KG}>
                                  kg
                                </option>
                              </Input>
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label for="purity">Purity</Label>
                        <SimpleTooltip name="purity">
                          The purity indicated here applies to all quoted
                          compounds.
                        </SimpleTooltip>
                        <InputGroup>
                          <Input
                            type="number"
                            name="purity"
                            id="purity"
                            value={bid.purity || 0}
                            disabled
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>%</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label for="num_compounds">Number of compounds</Label>
                        <SimpleTooltip name="num_compounds">
                          Suppliers can provide the number of compounds they
                          will ship and/or select the compounds from the table
                          below. "If suppliers did not select the provided
                          compound(s) in their bid, but only determined the
                          number of compound(s) they want to synthesize, then
                          any of the compounds could be shipped.
                        </SimpleTooltip>
                        <Input
                          type="number"
                          name="num_compounds"
                          id="num_compounds"
                          value={
                            auction.partial_offer_type ===
                            CONSTANTS.PARTIAL_OFFER_TYPE.EXACT
                              ? auction.partial_offer
                              : bid.num_compounds || 0
                          }
                          disabled
                        />
                      </FormGroup>
                      <Table>
                        <thead>
                          <tr>
                            <th className="column-id">#</th>
                            <th className="column-structure">Structure</th>
                            <th>Chemical ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {auction.structures &&
                            auction.structures.map((item, index) => (
                              <tr key={index}>
                                <td data-label="#">
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        name={item.unique_id}
                                        id={item.unique_id}
                                        checked={item.checked || false}
                                        disabled={true}
                                      />
                                    </Label>
                                  </FormGroup>
                                </td>
                                <td
                                  className="structure-container"
                                  data-label="Structure"
                                >
                                  <StructureImage src={item.chemical_id} />
                                  <div>{item.unique_id}</div>
                                </td>
                                <td data-label="Chemical ID">
                                  {item.chemical_id}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <h2 className="subheadline">Other details</h2>
            <div className="box-container orange mb-4">
              <div className="box">
                <Row className="mb-4">
                  <Col sm={12} md={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Requested</div>
                      <div className="label-line"></div>
                    </div>
                    <div className="bid-container">
                      <Row className="align-items-center mb-2 block-end">
                        <Col sm={6}>
                          <Label for="exclusivity">Exclusivity</Label>
                        </Col>
                        <Col sm={6} className="text-right">
                          <Badge
                            color={
                              "" +
                              (auction.exclusivity_value
                                ? "success"
                                : "secondary")
                            }
                            pill
                          >
                            {auction.exclusivity_value ? (
                              <span>
                                Required for{" "}
                                {auction.exclusivity_value +
                                  " " +
                                  auction.exclusivity_unit_display}
                              </span>
                            ) : (
                              <span>Not required</span>
                            )}
                          </Badge>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2 block-end">
                        <Col sm={6}>
                          <Label for="reporting">
                            Reporting about the project
                          </Label>
                        </Col>
                        <Col sm={6} className="text-right">
                          <Badge
                            color={
                              "" +
                              (auction.reporting !== 500
                                ? "success"
                                : "secondary")
                            }
                            pill
                          >
                            {auction.reporting_display}
                          </Badge>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2 block-end">
                        <Col sm={6}>
                          <Label for="exact-stereo-match">
                            Exact stereo match
                          </Label>
                        </Col>
                        <Col sm={6} className="text-right">
                          <Badge
                            color={
                              "" +
                              (auction.exact_stereo_match
                                ? "success"
                                : "secondary")
                            }
                            pill
                          >
                            {auction.exact_stereo_match
                              ? "Required"
                              : "Not required"}
                          </Badge>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2 block-end">
                        <Col sm={6}>
                          <Label for="syntesis-route">Syntesis route is</Label>
                        </Col>
                        <Col sm={6} className="text-right">
                          <Badge
                            color={
                              "" +
                              (auction.known_synthesis_route
                                ? "success"
                                : "secondary")
                            }
                            pill
                          >
                            {auction.known_synthesis_route
                              ? "Known"
                              : "Not set"}
                          </Badge>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col sm={6}>
                          <Label for="syntesis-route">
                            Quality control (QC) requirements by customer
                          </Label>
                        </Col>
                        <Col sm={6} className="text-right">
                          <Badge
                            color={
                              "" +
                              (auction.purity_own_analysis
                                ? "success"
                                : "secondary")
                            }
                            pill
                          >
                            {auction.purity_own_analysis
                              ? "Provided"
                              : "Not provided"}
                          </Badge>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col sm={12} md={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Quoted</div>
                      <div className="label-line"></div>
                    </div>
                    <div className="bid-container bid-request">
                      <Row className="align-items-center mb-2 block-end">
                        <Col sm={6}>
                          <Label for="exclusivity">
                            Exclusivity requirement
                          </Label>
                        </Col>
                        <Col sm={6} className="text-right">
                          <ButtonGroup>
                            <Button active={bid.exclusivity_accepted} disabled>
                              Accepted
                            </Button>
                            <Button active={!bid.exclusivity_accepted} disabled>
                              Not accepted
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2 block-end">
                        <Col sm={6}>
                          <Label for="reporting">
                            Reporting about the project
                          </Label>
                        </Col>
                        <Col sm={6} className="text-right">
                          <ButtonGroup>
                            <Button active={bid.reporting_accepted} disabled>
                              Accepted
                            </Button>
                            <Button active={!bid.reporting_accepted} disabled>
                              Not accepted
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2 block-end">
                        <Col sm={6}>
                          <Label for="exact-stereo-match">
                            Exact stereo match
                          </Label>
                        </Col>
                        <Col sm={6} className="text-right">
                          <ButtonGroup>
                            <Button
                              active={bid.exact_stereo_match_accepted}
                              disabled
                            >
                              Accepted
                            </Button>
                            <Button
                              active={!bid.exact_stereo_match_accepted}
                              disabled
                            >
                              Not accepted
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2 block-end">
                        <Col sm={6}>
                          <Label for="syntesis-route">Syntesis route is</Label>
                        </Col>
                        <Col sm={6} className="text-right">
                          <ButtonGroup>
                            <Button
                              active={bid.synthesis_route_accepted}
                              disabled
                            >
                              Accepted
                            </Button>
                            <Button
                              active={!bid.synthesis_route_accepted}
                              disabled
                            >
                              Not accepted
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2 block-end">
                        <Col sm={6}>
                          <Label for="syntesis-route">
                            Quality control (QC) requirements by customer is
                          </Label>
                        </Col>
                        <Col sm={6} className="text-right">
                          <ButtonGroup>
                            <Button
                              active={bid.analytical_method_accepted}
                              disabled
                            >
                              Accepted
                            </Button>
                            <Button
                              active={!bid.analytical_method_accepted}
                              disabled
                            >
                              Not accepted
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                      <div>
                        <Label>Available analytical equipment(s)</Label>
                      </div>
                      {bid.analytical_methods && (
                        <ButtonGroup>
                          <Button
                            active={bid.analytical_methods.includes(
                              CONSTANTS.ANALYTICAL_METHOD.LCMS_UV
                            )}
                            disabled
                          >
                            LC-MS (DAD)
                          </Button>
                          <Button
                            active={bid.analytical_methods.includes(
                              CONSTANTS.ANALYTICAL_METHOD.LCMS_ELSD
                            )}
                            disabled
                          >
                            LC-MS (ELSD)
                          </Button>
                          <Button
                            active={bid.analytical_methods.includes(
                              CONSTANTS.ANALYTICAL_METHOD.GCMS
                            )}
                            disabled
                          >
                            GC-MS
                          </Button>
                          <Button
                            active={bid.analytical_methods.includes(
                              CONSTANTS.ANALYTICAL_METHOD.NMR
                            )}
                            disabled
                          >
                            <sup>1</sup>H.NMR
                          </Button>
                        </ButtonGroup>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={12} md={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">
                        File uploaded by customer
                      </div>
                      <div className="label-line"></div>
                    </div>
                    {auction.files?.length > 1 && (
                      <a
                        href={`${APIDomain}${APIBase}auctions/${auction.id}/download-files/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mb-1 d-block"
                      >
                        <span>Download all files</span>
                      </a>
                    )}
                    <ul className="file-list">
                      {auction.files?.map((file, index) => (
                        <li key={file.id}>
                          <a
                            href={`${APIDomain}${APIBase}files/auction/${file.id}/download/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="far fa-file"></i>{" "}
                            <span>{file.filename}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Col>
                  <Col sm={12} md={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">
                        File uploaded by supplier
                      </div>
                      <div className="label-line"></div>
                    </div>
                    {bid.files?.length > 1 && (
                      <a
                        href={`${APIDomain}${APIBase}bids/${bid.id}/download-files/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mb-1 d-block"
                      >
                        <span>Download all files</span>
                      </a>
                    )}
                    <ul className="file-list">
                      {bid.files?.map((file, index) => (
                        <li key={file.id}>
                          <a
                            href={`${APIDomain}${APIBase}files/bid/${file.id}/download/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="far fa-file"></i>{" "}
                            <span>{file.filename}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Customer's comment</div>
                      <div className="label-line"></div>
                    </div>
                    <textarea
                      rows="4"
                      cols="50"
                      className="form-control"
                      disabled
                      value={auction.comment}
                    ></textarea>
                  </Col>
                  <Col sm={12} md={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Supplier's comment</div>
                      <div className="label-line"></div>
                    </div>
                    <textarea
                      rows="4"
                      cols="50"
                      className="form-control"
                      disabled
                      value={bid.comment}
                    ></textarea>
                  </Col>
                </Row>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div>No bid found to this auction</div>
        )}
      </Container>
    </div>
  );
}
