import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datetime/css/react-datetime.css";
import * as Sentry from "@sentry/react";
import App from "./App";
import "./index.scss";
import TagManager from "react-gtm-module";
import browserUpdate from "browser-update";

browserUpdate({
  required: {
    i: 11,
    e: -4,
    f: -3,
    o: -3,
    s: -1,
    c: 0,
    samsung: 7.0,
    vivaldi: 1.2,
  },
  insecure: true,
});

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  });
}

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(<App />, document.getElementById("root"));
