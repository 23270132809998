import React, { useState, useEffect } from "react";
import { Table, UncontrolledTooltip as Tooltip } from "reactstrap";
import { toast } from "react-toastify";
import API from "../api/api";
import LinkButton from "../common/LinkButton";
import * as CONSTANTS from "../common/Constants";
import moment from "moment";
import StructureImage from "../common/StructureImage";
import { useAuth } from "../context/auth";
import { formatPrice } from "../common/Utilities";
import Td from "../common/ContentTag";

export default function AuctionList(props) {
  const { userInfo } = useAuth();
  const [auctions, setAuctions] = useState([]);
  const [supplier, setSupplier] = useState();

  const getLastBid = (auction) => {
    const lastBid = supplier.last_bids[auction.id];
    if (lastBid) {
      return (
        <React.Fragment>
          <div
            className="detail-icons price-icon"
            id={`auction-my-${auction.id}-price`}
          >
            {formatPrice(lastBid.total_price)}
          </div>
          <Tooltip placement="left" target={`auction-my-${auction.id}-price`}>
            Total price
          </Tooltip>
          <div
            className="detail-icons delivery-icon"
            id={`auction-my-${auction.id}-time`}
          >
            {lastBid.max_delivery_time
              ? `${lastBid.max_delivery_time} week(s)`
              : "N/A"}
          </div>
          <Tooltip placement="left" target={`auction-my-${auction.id}-time`}>
            Lead time
          </Tooltip>
          <div
            className="detail-icons num-compounds-icon"
            id={`auction-my-${auction.id}-num`}
          >
            {lastBid.num_compounds
              ? `${lastBid.num_compounds} compound(s)`
              : "N/A"}
          </div>
          <Tooltip placement="left" target={`auction-my-${auction.id}-num`}>
            Number of compounds
          </Tooltip>
          <div
            className="detail-icons purity-icon"
            id={`auction-my-${auction.id}-purity`}
          >
            {lastBid.purity}%
          </div>
          <Tooltip placement="left" target={`auction-my-${auction.id}-purity`}>
            Purity
          </Tooltip>
        </React.Fragment>
      );
    }
    return false;
  };

  useEffect(() => {
    let _isMounted = true;
    if (props.auctions) {
      setAuctions(props.auctions);

      API.get(`suppliers/${userInfo.supplier}/`)
        .then((result) => {
          if (_isMounted) {
            setSupplier(result.data);
          }
        })
        .catch((error) => {
          toast.error("Error loading user data", {});
          console.log("ERROR", error);
        });
      return () => {
        _isMounted = false;
      };
    }
  }, [props.auctions]);

  const renderResult = () => {
    if (auctions.length <= 0) {
      return (
        <div className="text-center subheadline blue">No auction found</div>
      );
    } else {
      return (
        <React.Fragment>
          <Table>
            <thead>
              <tr>
                <th>Auction name</th>
                <th className="column-structure">Structure</th>
                <th>Status</th>
                <th>Auction details</th>
                <th>Best bids</th>
                <th>My latest bid</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {auctions.map((auction, index) => (
                <tr key={index}>
                  <Td to={`/make-bid/${auction.id}`} datalabel="Auction name">
                    {auction.cqr_name}
                  </Td>
                  <td data-label="Structure" className="structure-container">
                    <StructureImage src={auction.structures[0].chemical_id} />
                    {auction.structures.length > 1 && (
                      <div className="structure-counter">
                        + {auction.structures.length - 1} other(s)
                      </div>
                    )}
                  </td>
                  <Td to={`/make-bid/${auction.id}`} datalabel="Status">
                    <div>
                      <div
                        className={
                          "status " + auction.state_display.toLowerCase()
                        }
                      >
                        {auction.state_display}
                      </div>
                      <div>
                        {`Closes: ${moment(auction.valid_until).format(
                          CONSTANTS.DATE_FORMAT
                        )}`}
                      </div>
                    </div>
                  </Td>
                  <Td
                    to={`/make-bid/${auction.id}`}
                    datalabel="Auction details"
                  >
                    <div>
                      <div
                        className="detail-icons price-icon"
                        id={`auction-detail-${auction.id}-price`}
                      >
                        {auction.target_price_for_supplier
                          ? formatPrice(auction.target_price_for_supplier)
                          : "N/A"}
                      </div>
                      <Tooltip
                        placement="left"
                        target={`auction-detail-${auction.id}-price`}
                      >
                        Target budget
                      </Tooltip>
                      <div
                        className="detail-icons delivery-icon"
                        id={`auction-detail-${auction.id}-time`}
                      >
                        {auction.delivery_deadline_for_supplier
                          ? moment(
                              auction.delivery_deadline_for_supplier
                            ).format(CONSTANTS.INPUT_DATE_FORMAT)
                          : "Not set"}
                      </div>
                      <Tooltip
                        placement="left"
                        target={`auction-detail-${auction.id}-time`}
                      >
                        Target delivery date
                      </Tooltip>
                      <div
                        className="detail-icons purity-icon"
                        id={`auction-detail-${auction.id}-purity`}
                      >
                        {auction.target_purity}%
                      </div>
                      <Tooltip
                        placement="left"
                        target={`auction-detail-${auction.id}-purity`}
                      >
                        Requested purity
                      </Tooltip>
                      <div
                        className="detail-icons amount-icon"
                        id={`auction-detail-${auction.id}-amount`}
                      >
                        {auction.amount + " " + auction.unit_display}
                      </div>
                      <Tooltip
                        placement="left"
                        target={`auction-detail-${auction.id}-amount`}
                      >
                        Requested amount
                      </Tooltip>
                    </div>
                  </Td>
                  <Td datalabel="Best bid">
                    <div>
                      {auction.best_price_bid && (
                        <React.Fragment>
                          <div className="best-bid-title">Best price bid</div>
                          <div
                            className="detail-icons price-icon"
                            id={`auction-best-price-${auction.id}-price`}
                          >
                            {auction.best_price_bid
                              ? formatPrice(auction.best_price_bid.total_price)
                              : "N/A"}
                          </div>
                          <Tooltip
                            placement="left"
                            target={`auction-best-price-${auction.id}-price`}
                          >
                            Total price
                          </Tooltip>
                          <div
                            className="detail-icons delivery-icon"
                            id={`auction-best-price-${auction.id}-time`}
                          >
                            {auction.best_price_bid
                              ? auction.best_price_bid.max_delivery_time +
                                " week(s)"
                              : "N/A"}
                          </div>
                          <Tooltip
                            placement="left"
                            target={`auction-best-price-${auction.id}-time`}
                          >
                            Lead time
                          </Tooltip>
                          <div
                            className="detail-icons num-compounds-icon"
                            id={`auction-best-price-${auction.id}-num`}
                          >
                            {auction.best_price_bid
                              ? auction.best_price_bid.num_compounds +
                                " compound(s)"
                              : "N/A"}
                          </div>
                          <Tooltip
                            placement="left"
                            target={`auction-best-price-${auction.id}-num`}
                          >
                            Number of compounds
                          </Tooltip>
                          <div
                            className="detail-icons purity-icon"
                            id={`auction-best-price-${auction.id}-purity`}
                          >
                            {auction.best_price_bid.purity}%
                          </div>
                          <Tooltip
                            placement="left"
                            target={`auction-best-price-${auction.id}-purity`}
                          >
                            Purity
                          </Tooltip>
                        </React.Fragment>
                      )}
                      {auction.best_delivery_time_bid && (
                        <React.Fragment>
                          <div className="best-bid-title">
                            Best lead time bid
                          </div>
                          <div
                            className="detail-icons price-icon"
                            id={`auction-best-delivery-${auction.id}-price`}
                          >
                            {auction.best_delivery_time_bid
                              ? formatPrice(
                                  auction.best_delivery_time_bid.total_price
                                )
                              : "N/A"}
                          </div>
                          <Tooltip
                            placement="left"
                            target={`auction-best-delivery-${auction.id}-price`}
                          >
                            Total price
                          </Tooltip>
                          <div
                            className="detail-icons delivery-icon"
                            id={`auction-best-delivery-${auction.id}-time`}
                          >
                            {auction.best_delivery_time_bid
                              ? auction.best_delivery_time_bid
                                  .max_delivery_time + " week(s)"
                              : "N/A"}
                          </div>
                          <Tooltip
                            placement="left"
                            target={`auction-best-delivery-${auction.id}-time`}
                          >
                            Lead time
                          </Tooltip>
                          <div
                            className="detail-icons num-compounds-icon"
                            id={`auction-best-delivery-${auction.id}-num`}
                          >
                            {auction.best_delivery_time_bid
                              ? auction.best_delivery_time_bid.num_compounds +
                                " compound(s)"
                              : "N/A"}
                          </div>
                          <Tooltip
                            placement="left"
                            target={`auction-best-delivery-${auction.id}-num`}
                          >
                            Number of compounds
                          </Tooltip>
                          <div
                            className="detail-icons purity-icon"
                            id={`auction-best-delivery-${auction.id}-purity`}
                          >
                            {auction.best_delivery_time_bid.purity}%
                          </div>
                          <Tooltip
                            placement="left"
                            target={`auction-best-delivery-${auction.id}-purity`}
                          >
                            Purity
                          </Tooltip>
                        </React.Fragment>
                      )}
                      {!auction.best_price_bid &&
                        !auction.best_delivery_time_bid && (
                          <div>No bid yet</div>
                        )}
                    </div>
                  </Td>
                  <Td to={`/make-bid/${auction.id}`} datalabel="My latest bid">
                    <div>
                      {supplier && getLastBid(auction)
                        ? getLastBid(auction)
                        : "No bid yet"}
                    </div>
                  </Td>
                  <td className="text-center">
                    <LinkButton
                      to={`/make-bid/${auction.id}`}
                      className="d-block btn btn-orange mb-1 mx-auto"
                    >
                      Bid now
                    </LinkButton>
                    <LinkButton
                      to={`/my-bids/${auction.id}`}
                      className="d-block btn btn-orange mb-1 mx-auto"
                    >
                      My previous bids
                    </LinkButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </React.Fragment>
      );
    }
  };

  return renderResult();
}
