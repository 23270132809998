import React from "react";
import PropTypes from "prop-types";
import {
  FormGroup,
  FormFeedback,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledPopover,
  PopoverBody,
  Row,
  Col,
} from "reactstrap";
import { MyInfoCircle, MyCrossMark, MyCheckMark } from "../common/Entities";
import { ToolTip } from "./ToolTip";
import { stringToSlug } from "./Utilities";
import NumberFormat from "react-number-format";

const renderComponent = (props) => {
  const {
    name,
    displayName,
    type,
    value,
    onChangeHandler,
    invalidCondition,
    append,
    tooltip,
    required,
    errorField,
    className,
    formGroupClass,
    ...rest
  } = props;
  switch (type) {
    case "email":
      return (
        <React.Fragment>
          <Input
            type={type}
            name={name}
            id={stringToSlug(displayName)}
            onChange={onChangeHandler}
            value={value || ""}
            invalid={invalidCondition}
            {...rest}
          />
          <UncontrolledPopover
            trigger="focus"
            placement="bottom"
            target={`${name}Popover`}
            modifiers={{
              computeStyle: {
                gpuAcceleration: false,
              },
            }}
          >
            <PopoverBody>
              <Row noGutters={true}>
                <Col xs="auto">
                  <MyCheckMark />
                </Col>
                <Col>
                  Only business email is allowed:{" "}
                  <span className="green-text">
                    me@company.com
                  </span>
                </Col>
              </Row>
              <Row noGutters={true}>
                <Col xs="auto">
                  <MyCrossMark />
                </Col>
                <Col>
                  Personal email is not allowed:{" "}
                  <span className="red-text">
                    me@gmail.com
                  </span>
                </Col>
              </Row>
            </PopoverBody>
          </UncontrolledPopover>
        </React.Fragment>
      );
    case "price":
      return (
        <NumberFormat
          className={
            "form-control " +
            className +
            (invalidCondition ? " is-invalid" : "")
          }
          thousandSeparator={true}
          min="0"
          name={name}
          id={
            displayName
              ? stringToSlug(displayName)
              : stringToSlug(name)
          }
          onChange={onChangeHandler}
          value={value || value === 0 ? value : ""}
          invalid={invalidCondition}
          {...rest}
        />
      );
    case "text":
    default:
      return (
        <Input
          type={type}
          name={name}
          id={stringToSlug(displayName)}
          onChange={onChangeHandler}
          value={value || ""}
          invalid={invalidCondition}
          {...rest}
        />
      );
  }
};

const InputField = (props) => {
  const {
    name,
    displayName,
    invalidCondition,
    tooltip,
    required,
    append,
    errorField,
    formGroupClass,
  } = props;
  return (
    <FormGroup className={formGroupClass}>
      {displayName && (
        <Label for={stringToSlug(displayName)}>
          {displayName}
          {required && "*"}
        </Label>
      )}
      {tooltip && (
        <React.Fragment>
          <div id={"info-" + name} className="info-circle">
            <MyInfoCircle />
          </div>
          <ToolTip target={"info-" + name}>{tooltip}</ToolTip>
        </React.Fragment>
      )}
      <InputGroup
        className={"" + (invalidCondition ? " is-invalid" : "")}
      >
        {renderComponent(props)}
        {append && (
          <InputGroupAddon addonType="append">
            <InputGroupText>{append}</InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>
      {invalidCondition && <FormFeedback>{errorField}</FormFeedback>}
    </FormGroup>
  );
};

InputField.propTypes = {
  className: PropTypes.string,
};

export default InputField;
