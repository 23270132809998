export const DATETIME_FORMAT = "MMM DD YYYY HH:mm:ss";
export const DATE_FORMAT = "MMM DD YYYY";
export const INPUT_DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm:ss";

export const MODE_CREATE = 10;
export const MODE_EDIT = 20;

export const PREFERRED_SUPPLIER_ADD = 10;
export const PREFERRED_SUPPLIER_REMOVE = 20;

export const ACTION_MODE = {
  DISCARD: "discard",
  CANCEL: "cancel",
};

export const AUCTION_STATE = {
  DRAFT: 5,
  APPROVED: 10,
  SUBMITTED: 20,
  ACCEPTED: 30,
  CANCELLED: 40,
  EXPIRED: 50,
  CLOSED: 60,
  REQUESTED: 70,
  REJECT: 80,
};

export const REQUEST_STATE_LABEL = {
  DRAFT: "Draft",
  APPROVED: "Open",
  SUBMITTED: "Waiting for Approval",
  ACCEPTED: "Bid accepted",
  CANCELLED: "Cancelled",
  EXPIRED: "Expired",
  CLOSED: "Closed",
  REQUESTED: "Order requested by customer",
  REJECT: "Rejected by Mcule",
};

export const AMOUNT_UNIT = {
  MG: 1,
  G: 2,
  KG: 3,
};

export const EXLUSIVITY_UNIT = {
  MONTH: 1,
  YEAR: 12,
};

export const REPORTING = {
  WEEKLY: 1,
  TWO_WEEKS: 2,
  ONE_MONTH: 4,
  END_OF_THE_PROJECT: 100,
  NOT_REQUIRED: 500,
};

export const STRUCTURE_INPUT = {
  TEXTAREA: 1,
  MARVIN: 2,
};

export const BID_STATE = {
  DRAFT: 5,
  SUBMITTED: 10,
  WITHDRAWN: 15,
  ACCEPTED: 20,
  NOT_SELECTED: 25,
};

export const BID_STATE_LABEL = {
  DRAFT: "Draft",
  SUBMITTED: "Submitted",
  WITHDRAWN: "Withdrawn",
  ACCEPTED: "Accepted",
  NOT_SELECTED: "Not selected",
};

export const USER_TYPE = {
  MCULE: 10,
  CUSTOMER: 20,
  SUPPLIER: 30,
};

export const COMPANY_TYPE = {
  INDUSTRIAL: 10,
  UNIVERSITY: 20,
  SUPPLIER: 30,
};

export const COMPANY_TYPES = {
  INDUSTRIAL: "Industrial",
  UNIVERSITY: "Academic/University/Non-profit",
  SUPPLIER: "Chemical Supplier",
};

export const ADDRESS_TYPE = {
  GENERAL: 5,
  SHIPPING: 10,
  MANUFACTURING: 20,
  PO: 30,
  BILLING: 40,
};

export const PARTIAL_OFFER_TYPE = {
  MINIMUM: 10,
  EXACT: 20,
};

export const ANALYTICAL_METHOD = {
  LCMS_UV: 10,
  LCMS_ELSD: 20,
  GCMS: 30,
  NMR: 40,
};

export const ORDER_STATE = {
  CONFIRMED_BY_CUSTOMER: 1,
  CONFIRMED_OR_TERMS_ACCEPTED: 2,
  PAYMENT_RECEIVED_OR_ACCOUNT_SET: 3,
  CONFIRMED_BY_SUPPLIER: 4,
  REAGENTS_ARRRIVED: 5,
  VALIDATION: 6,
  PRODUCTION: 7,
  PURIFICATION: 8,
  READY_FOR_SHIPPING: 9,
  SHIPPING_TO_MCULE: 10,
  SHIPPING_TO_CUSTOMER: 11,
};

export const PAYMENT_TYPE = {
  IN_ADVANCE: 10,
  WITH_ACCOUNT: 20,
};

export const PAYMENT_IN_ADVANCE_TYPE = {
  CREDIT_CARD: 10,
  BANK_TRANSFER: 20,
};

export const CONTACT_TYPE = {
  GENERAL: 5,
  SHIPPING: 10,
  BILLING: 40,
};

export const PAYMENT_ACCOUNT_STATE = {
  NOT_REQUESTED: 10,
  REQUESTED: 15,
  CONFIRMED: 20,
  DECLINED: 30,
  SUSPENDED: 40,
};

export const SUPPLIER_CATALOG_TYPE = {
  ONLINE_UPDATE: 10,
  ONLINE_NO_UPDATE: 20,
  OFFLINE_UPDATE: 30,
  OFFLINE_NO_UPDATE: 40,
  NOT_EXIST: 50,
};

export const SUPPLIER_CATALOG_TYPES = {
  ONLINE_UPDATE: "I own regularly updated online catalog(s).",
  ONLINE_NO_UPDATE: "I own online catalogs, but do not update them regularly.",
  OFFLINE_UPDATE: "I own regularly updated not online catalogs.",
  OFFLINE_NO_UPDATE:
    "I own not online catalogs, and do not update them regularly.",
  NOT_EXIST: "I do not own catalog and do not sell in-stock compounds.",
};

export const TITLES = {
  DELIMITER: " | ",
  POSTFIX: "SynthAgora",
  MAKE_BID: "Make bid",
  EDIT_BID: "Edit bid",
  PROFILE: "Profile",
  DASHBOARD: "Dashboard",
  MY_BIDS: "My bids",
  MY_ORDERS: "My orders",
  OPEN_AUCTIONS: "Open auctions",
  ORDER_TRACKING: "Order tracking",
  MAKE_AUCTION: "Create auction",
  AUCTION_DETAILS: "Auction details",
  MY_AUCTIONS: "My auctions",
  TRACK_AUCTION: "Track auction",
  VIEW_AUCTION: "View auction",
  CONFIRM_ORDER: "Confirm order",
  ORDER_DETAILS: "Order details",
  NOTIFICATIONS: "Notifications",
  NOTIFICATION_SETTINGS: "Notification settings",
  LOGIN: "Login",
  REGISTER: "Registration",
  RESET_PASS: "Reset password",
  RESET_PASS_CONFIRM: "Setting new password",
  EMAIL_CHANGE_VERIFY: "Verify email change",
  PASSWORD_CHANGE: "Password change",
};
