import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Container,
  Table,
  FormFeedback,
  Label,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import API from "../api/api";
import { toast } from "react-toastify";
import * as CONSTANTS from "../common/Constants";
import LinkButton from "../common/LinkButton";
import moment from "moment";
import StructureImage from "../common/StructureImage";
import Datetime from "react-datetime";
import { getTimeLeft, formatPrice } from "../common/Utilities";
import InputField from "../common/InputField";
import Loader from "../common/Loader";
import ConfirmModal from "../common/ConfirmModal";
import SimpleTooltip from "../common/SimpleTooltip";
import MetaTags from "react-meta-tags";
import { useAuth } from "../context/auth";

export default function TrackAuction(props) {
  const title =
    CONSTANTS.TITLES.TRACK_AUCTION +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;
  const APIDomain = process.env.REACT_APP_API_DOMAIN;
  const APIBase = process.env.REACT_APP_API_BASE_URL;
  const [auction, setAuction] = useState();
  const [bids, setBids] = useState([]);
  const [editEndDateMode, setEditEndDateMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [validUntil, setValidUntil] = useState();
  const [auctionLoading, setAuctionLoading] = useState(true);
  const [confirmChangeEndDateModal, setConfirmChangeEndDateModal] =
    useState(false);
  const [confirmCloseImmediatelyModal, setConfirmCloseImmediatelyModal] =
    useState(false);
  const [confirmAcceptBidModal, setConfirmAcceptBidModal] = useState(false);
  const [selectedBid, setSelectedBid] = useState(null);

  const [auctionClosed, setAuctionClosed] = useState(false);
  const [bidClosed, setBidClosed] = useState(false);
  const [paginationData, setPaginationData] = useState([]);
  const PER_PAGE_LIMIT = 10;
  const { userInfo } = useAuth();
  const [profile, setProfile] = useState(false);

  function getOffers(page) {
    const endPoint = props.match.params.auctionId
      ? `?auction=${props.match.params.auctionId}&`
      : `?`;
    API.get(`bids/${endPoint}page=${page}`)
      .then((result) => {
        let filteredArray = result.data.results.filter(
          (b) => b.state !== CONSTANTS.BID_STATE.DRAFT
        );
        setBids(filteredArray);
        setBidClosed(
          getTimeLeft(result.data.valid_until, "Bid ended", false, true)
        );
        setPaginationData((data) => ({
          ...data,
          count: result.data.count,
          next: result.data.next,
          previous: result.data.previous,
          activePage: page,
        }));
      })
      .catch((err) => {
        toast.error("Error loading suppliers data", {});
        console.log("ERROR", err);
      })
      .finally(() => {
        // this.setState({loading: false})
      });
  }

  function acceptBid() {
    if (selectedBid) {
      API.patch(`bids/${selectedBid.id}/`, {
        state: CONSTANTS.BID_STATE.ACCEPTED,
      })
        .then((result) => {
          toast.success("Bid accepted", {});
          getAuction(props.match.params.auctionId);
        })
        .catch((err) => {
          toast.error("Error accepting bid", {});
          console.log("ERROR", err);
        });
    }
  }

  function getAuction(auctionId) {
    setAuctionLoading(true);
    API.get(`auctions/${auctionId}/`)
      .then((result) => {
        setAuction(result.data);
        if (result.data.bids.length > 0) {
          getOffers(1);
        }
        setAuctionClosed(
          !getTimeLeft(
            result.data.expiration_date,
            "Auction ended",
            false,
            true
          )
        );
      })
      .catch((err) => {
        toast.error("Error loading auction data", {});
        console.log("ERROR", err);
      })
      .finally(() => {
        setAuctionLoading(false);
      });
  }

  function getDownloadAuctionReportURL(auction) {
    return `${APIDomain}${APIBase}auctions/${auction.id}/auction-summary/`;
  }

  function getDownloadQuoteURL(bidId) {
    return `${APIDomain}${APIBase}bids/${bidId}/get-quote-xlsx/`;
  }

  function closeAuction() {
    const obj = {
      state: CONSTANTS.AUCTION_STATE.CLOSED,
      delivery_deadline: moment(auction.delivery_deadline).format(
        CONSTANTS.INPUT_DATE_FORMAT
      ),
    };
    API.patch(`auctions/${auction.id}/`, obj)
      .then((res) => {
        toast.success("Auction updated successfully!", {});
        getAuction(props.match.params.auctionId);
      })
      .catch((err) => {
        toast.error("Please correct the form errors.", {});
        console.log("ERROR", err);
        // if (err.response) {
        // 	setErrors(err.response.data);
        // }
      });
  }

  const endDateInput = (auction) => {
    setValidUntil(auction.valid_until);
    setEditEndDateMode(true);
  };

  const handleDateTimePickerChange = (_moment) => {
    let tempMoment = null;
    if (moment.isMoment(_moment)) {
      tempMoment = _moment.toDate();
    }
    setValidUntil(tempMoment);
  };

  const openConfirmEditEndDate = () => {
    if (validUntil) {
      if (
        moment(validUntil).format("YYYY-MM-DD") !==
        moment(auction.valid_until).format("YYYY-MM-DD")
      ) {
        toggleModal();
      } else {
        setEditEndDateMode(false);
      }
    } else {
      setValidUntil(auction.valid_until);
      setEditEndDateMode(false);
    }
  };

  const toggleModal = () => {
    setConfirmChangeEndDateModal(!confirmChangeEndDateModal);
  };

  const toggleCloseImmediatelyModal = () => {
    setConfirmCloseImmediatelyModal(!confirmCloseImmediatelyModal);
  };

  const toggleAcceptBidModal = () => {
    setConfirmAcceptBidModal(!confirmAcceptBidModal);
  };

  const saveEndDateInput = () => {
    const obj = { valid_until: validUntil };
    API.patch(`auctions/${auction.id}/`, obj)
      .then((res) => {
        toast.success("Auction updated successfully!", {});
        setEditEndDateMode(false);
        getAuction(props.match.params.auctionId);
      })
      .catch((err) => {
        toast.error("Please correct the form errors.", {});
        // console.log("ERROR", err);
        if (err.response) {
          setErrors(err.response.data);
        }
      });
  };

  const renderPagination = () => {
    const pages = Array.from(
      Array(Math.ceil(paginationData.count / 10)),
      (_, i) => i + 1
    );
    return (
      <Pagination aria-label="Synthagora pagination">
        <PaginationItem disabled={paginationData.activePage === 1}>
          <PaginationLink
            previous
            onClick={() => {
              getOffers(paginationData.activePage - 1);
            }}
          />
        </PaginationItem>
        {pages.map((item, index) => (
          <PaginationItem
            key={item}
            active={item === paginationData.activePage}
          >
            <PaginationLink
              onClick={() => {
                getOffers(item);
              }}
            >
              {item}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={paginationData.activePage === pages.length}>
          <PaginationLink
            next
            onClick={() => {
              getOffers(paginationData.activePage + 1);
            }}
          />
        </PaginationItem>
      </Pagination>
    );
  };

  const getProfile = () => {
    API.get(`customers/${userInfo.customer}/`)
      .then((result) => {
        setProfile(result.data);
      })
      .catch((err) => {
        toast.error("Error at request.", {});
        console.log("ERROR", err);
      });
  };

  const hasDiscountedPrice = (bid) => {
    return bid.discounted_public_total_price !== bid.public_total_price;
  };

  useEffect(() => {
    getProfile();
    if (props.match.params.auctionId) {
      getAuction(props.match.params.auctionId);
    }
    setSelectedBid(null);
  }, [props.match.params.auctionId]);

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="mt-4">
        {auctionLoading ? (
          <Loader text="Loading auction" />
        ) : (
          <React.Fragment>
            <Row>
              <Col sm={8}>
                <ul className="breadcrumb">
                  <li>
                    <Link to={`/my-auctions`}>My auctions</Link>
                  </li>
                  <li>{auction?.auction_name} auction</li>
                </ul>
                <h1 className="mb-4 subheadline">
                  {auction?.auction_name} auction tracking
                </h1>
              </Col>
              <Col sm={4} className="text-right header-right">
                <div className="subheader">
                  <span className="subheader-label">Status:</span>
                  <span
                    className={"status " + auction?.state_display.toLowerCase()}
                  >
                    {auction?.state_display}
                  </span>
                </div>
                <h3 className="subheadline">{auction?.auction_name_full}</h3>
              </Col>
            </Row>
            {/* <Alert color="danger">
              The bids have been expired. Please, ask our colleagues if you
              would like to place an order (custom.synthesis@mcule.com).
            </Alert> */}
            <div className="auction-details">
              <Row className="mb-4">
                <Col sm={6}>
                  <>
                    <div className="subheader border-left-orange mb-2">
                      <div>
                        <span className="subheader-label">
                          Auction closing date:
                        </span>
                        {auctionClosed ? (
                          <span className="subheader-value">
                            Auction closed
                          </span>
                        ) : (
                          <span className="subheader-value">
                            <SimpleTooltip name="closing_date">
                              In case of modifying the closing date of the
                              auction keep in consideration the following
                              aspects: <br />- The auction has to remain active
                              for 3 more days. <br />- You can only shorten the
                              auction. <br />- The extension of auction closing
                              is not possible. <br />- You cannot reset the
                              original closing date of the auction or extend the
                              bidding period after saving the modifications.
                            </SimpleTooltip>{" "}
                            {editEndDateMode ? (
                              <Datetime
                                name="valid_until"
                                id="valid_until"
                                className={
                                  "end-date-container " +
                                  (errors.valid_until ? "is-invalid" : "")
                                }
                                onChange={(moment) =>
                                  handleDateTimePickerChange(moment)
                                }
                                value={
                                  validUntil
                                    ? moment(validUntil).toDate()
                                    : null
                                }
                                inputProps={{
                                  className:
                                    "form-control " +
                                    (errors.valid_until ? "is-invalid" : ""),
                                }}
                                readonly
                              />
                            ) : (
                              moment(auction.valid_until).format(
                                CONSTANTS.DATETIME_FORMAT
                              )
                            )}
                            {auction.state ===
                              CONSTANTS.AUCTION_STATE.APPROVED && (
                              <span>
                                {editEndDateMode ? (
                                  <Button
                                    className="ml-2 btn-orange"
                                    onClick={() => openConfirmEditEndDate()}
                                  >
                                    <i className="far fa-check-circle"></i>
                                  </Button>
                                ) : (
                                  <Button
                                    className="ml-2 btn-orange"
                                    onClick={(event) => endDateInput(auction)}
                                  >
                                    <i className="far fa-edit"></i>
                                  </Button>
                                )}
                              </span>
                            )}
                            <div>
                              {errors.valid_until && (
                                <FormFeedback
                                  className={
                                    errors.valid_until ? "d-block" : ""
                                  }
                                >
                                  {errors.valid_until}
                                </FormFeedback>
                              )}
                            </div>
                          </span>
                        )}
                      </div>
                      {!auctionClosed && (
                        <div>
                          <span className="subheader-label pl-2">
                            Time remaining:
                          </span>
                          <span className="subheader-value">
                            {getTimeLeft(
                              auction.valid_until,
                              "Auction ended",
                              true
                            )}
                          </span>
                        </div>
                      )}
                      {bids && (
                        <div>
                          <span className="subheader-label">
                            Bids received:
                          </span>
                          <span className="subheader-value">{bids.length}</span>
                        </div>
                      )}
                      {bids && bids.length > 0 && (
                        <div>
                          <span className="subheader-label">
                            Bid expiry date:{" "}
                            <SimpleTooltip name="bid_expiry_date">
                              Bid acceptance and order confirmation only
                              possible before the bid expiry date. After the bid
                              expiry date, these bids are no longer valid, so
                              you have to contact us at{" "}
                              <a href="mailto:support@synthagora.com">
                                support@synthagora.com
                              </a>{" "}
                              to require a new quote. Please indicate which
                              expired offer you wish to order. The bids expire
                              automatically 45 days from the auction submission.
                            </SimpleTooltip>
                          </span>
                          <span className="subheader-value">
                            {bidClosed
                              ? "Bid expired"
                              : moment(auction.expiration_date).format(
                                  CONSTANTS.DATETIME_FORMAT
                                )}
                          </span>
                        </div>
                      )}
                      {bids && bids.length > 0 && !bidClosed && (
                        <div>
                          <span className="subheader-label pl-2">
                            Time remaining:
                          </span>
                          <span className="subheader-value">
                            {getTimeLeft(
                              moment(auction.expiration_date).format(
                                CONSTANTS.DATETIME_FORMAT
                              ),
                              "Auction ended",
                              true
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                    {profile?.discount > 0 && (
                      <div className="discount-box subheader">
                        Your permanent discount: {profile.discount} %
                      </div>
                    )}
                  </>
                </Col>
                <Col sm={6} className="text-right header-right">
                  <div>
                    <Link
                      to={`/auction/${auction.id}`}
                      className="mb-2 btn btn-orange"
                    >
                      Auction details
                    </Link>
                  </div>

                  {(auction.state === CONSTANTS.AUCTION_STATE.APPROVED ||
                    auction.state === CONSTANTS.AUCTION_STATE.SUBMITTED) &&
                    auction.close_immediately && (
                      <Button
                        className="mb-2 btn-orange"
                        onClick={() => {
                          toggleCloseImmediatelyModal();
                        }}
                      >
                        Close auction immediately
                      </Button>
                    )}
                  {(auction.state === CONSTANTS.AUCTION_STATE.ACCEPTED ||
                    auction.state === CONSTANTS.AUCTION_STATE.CANCELLED ||
                    auction.state === CONSTANTS.AUCTION_STATE.CLOSED ||
                    auction.state === CONSTANTS.AUCTION_STATE.EXPIRED) && (
                    <a
                      className="btn btn-orange mx-auto"
                      href={getDownloadAuctionReportURL(auction)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download auction report
                    </a>
                  )}
                </Col>
              </Row>
            </div>
            <h2 className="mb-2 subheadline">
              Bids{" "}
              <SimpleTooltip name="bids">
                Please note that suppliers can withdraw their offers anytime
                until the auction closing date. After the closing date, no bids
                can be withdrawn. You can accept a bid only after the auction is
                closed.
              </SimpleTooltip>
            </h2>
            <div className="box-container orange">
              <div className="box">
                {bids && bids.length > 0 ? (
                  <React.Fragment>
                    <Table className="table table-hover bg-white table-responsive-sm">
                      <thead>
                        <tr>
                          <th>Rank</th>
                          <th>Bid ID</th>
                          <th>Supplier</th>
                          <th>Date of bid</th>
                          <th>
                            Total price
                            <SimpleTooltip name="total_price">
                              The total price includes total compound price,
                              shipping, and handling fees, and additional fees
                              for extra services (exclusivity, reporting). Under
                              the total price, the average compound price (total
                              compound price per number of compounds) is shown.
                            </SimpleTooltip>
                          </th>
                          <th>
                            Lead time
                            <SimpleTooltip name="lead_time">
                              The counting of lead time starts after the order
                              confirmation by Mcule.
                            </SimpleTooltip>
                          </th>
                          <th>
                            Quoted amount
                            <SimpleTooltip name="quoted_amount">
                              The quoted amount indicated here applies to all
                              quoted compounds.
                            </SimpleTooltip>
                          </th>
                          <th>
                            Purity
                            <SimpleTooltip name="purity">
                              The purity indicated here applies to all quoted
                              compounds.
                            </SimpleTooltip>
                          </th>
                          <th>
                            Number of compounds
                            <SimpleTooltip name="number_of_compounds">
                              The bids could include a different number of
                              compounds if you allowed the partial offer.
                            </SimpleTooltip>
                          </th>
                          <th>
                            Actions
                            <SimpleTooltip name="actions">
                              This section is used for accepting an offer for
                              the auction.This is inactive for open auctions as
                              bids can only be accepted after the auction is
                              closed. If you want to accept a bid earlier,
                              shorten the auction. Only one bid can be accepted.
                              Please contact us at{" "}
                              <a href="mailto:support@synthagora.com">
                                support@synthagora.com
                              </a>{" "}
                              if you want to accept more bids in parallel.
                            </SimpleTooltip>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {bids.map((bid, index) => (
                          <tr
                            key={index}
                            className={
                              "" +
                              (auction.winner_bid &&
                              auction.winner_bid.id === bid.id
                                ? "winner"
                                : "")
                            }
                          >
                            <td data-label="Rank">
                              {(paginationData.activePage - 1) * 10 + index + 1}
                            </td>
                            <td data-label="Bid ID">
                              <Link
                                to={`/track-auction/${props.match.params.auctionId}/bid/${bid.id}`}
                              >
                                {bid.bid_cqr_name}
                              </Link>
                              <LinkButton
                                to={`/track-auction/${props.match.params.auctionId}/bid/${bid.id}`}
                                className="d-block btn-orange btn btn-secondary"
                              >
                                Bid details
                              </LinkButton>
                            </td>
                            <td data-label="Supplier">
                              {bid.supplier_name}{" "}
                              {/* <small>
                                {`(win-rate: ${bid.supplier_statistics.win_percent} %)`}
                              </small> */}
                            </td>
                            <td data-label="Date of bid">
                              <div className="detail-icons date-icon">
                                {moment(bid.created_at).format(
                                  CONSTANTS.DATE_FORMAT
                                )}
                              </div>
                              <div className="detail-icons time-icon">
                                {moment(bid.created_at).format(
                                  CONSTANTS.TIME_FORMAT
                                )}
                              </div>
                            </td>
                            <td data-label="Total price">
                              <div
                                className={
                                  "detail-icons price-icon " +
                                  (hasDiscountedPrice(bid) ? "old-price" : "")
                                }
                              >
                                {formatPrice(bid.public_total_price)}
                              </div>
                              {hasDiscountedPrice(bid) ? (
                                <>
                                  <div className="detail-icons price-icon discounted-price">
                                    {formatPrice(
                                      bid.discounted_public_total_price
                                    )}
                                  </div>
                                  <small>
                                    {formatPrice(
                                      bid.discounted_avg_public_product_price
                                    )}
                                    /compound
                                  </small>
                                </>
                              ) : (
                                <small>
                                  {formatPrice(bid.avg_public_product_price)}
                                  /compound
                                </small>
                              )}
                            </td>
                            <td data-label="Lead time">
                              <div className="detail-icons delivery-icon">
                                {bid.public_delivery_time + " week(s)"}
                              </div>
                            </td>
                            <td data-label="Quoted amount">
                              <div className="detail-icons amount-icon">
                                {bid.amount} {bid.unit_display}
                              </div>
                            </td>
                            <td data-label="Purity">
                              <div className="detail-icons purity-icon">
                                {bid.purity}%
                              </div>
                            </td>
                            <td data-label="Number of compounds">
                              <div className="detail-icons num-compounds-icon">
                                {bid.num_compounds}
                              </div>
                            </td>
                            <td className="text-center">
                              {/* <LinkButton
                              to={`/track-auction/${props.match.params.auctionId}/bid/${bid.id}`}
                              className="d-block btn btn-secondary mb-1 mx-auto"
                            >
                              Bid details
                            </LinkButton> */}
                              {auction.state ===
                                CONSTANTS.AUCTION_STATE.CLOSED &&
                                bid.state === CONSTANTS.BID_STATE.SUBMITTED && (
                                  <Button
                                    className="d-block btn btn-secondary mb-1 mx-auto"
                                    onClick={() => {
                                      setSelectedBid(bid);
                                      toggleAcceptBidModal();
                                    }}
                                  >
                                    Accept bid
                                  </Button>
                                )}
                              {auction.state ===
                                CONSTANTS.AUCTION_STATE.ACCEPTED &&
                                bid.state === CONSTANTS.BID_STATE.ACCEPTED && (
                                  <React.Fragment>
                                    <a
                                      className="d-block btn btn-secondary mb-1 mx-auto"
                                      href={getDownloadQuoteURL(bid.id)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Download quote
                                    </a>
                                    <LinkButton
                                      to={`/confirm-order/${auction.id}`}
                                      className="d-block btn btn-secondary mb-1 mx-auto"
                                    >
                                      Confirm order
                                    </LinkButton>
                                  </React.Fragment>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {paginationData.count > PER_PAGE_LIMIT &&
                      renderPagination()}
                  </React.Fragment>
                ) : (
                  <div className="no-data">
                    No bid available for this auction
                  </div>
                )}
              </div>
            </div>
            <h2 className="mb-2 subheadline">
              Compound details
              <SimpleTooltip name="compound_details">
                Here you can see a short summary of the auction details. Click
                to the “Auction details” button at the top of the page for
                further details.
              </SimpleTooltip>
            </h2>
            <div className="box-container orange">
              <div className="box">
                <Row>
                  <Col sm="6">
                    <Row className="align-items-center mb-2">
                      <Col sm={6}>
                        <Label for="amount">Requested amount:</Label>
                      </Col>
                      <Col sm={6}>
                        <Input
                          type="text"
                          name="amount"
                          id="amount"
                          value={auction.amount + " " + auction.unit_display}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <Row className="align-items-center mb-2">
                      <Col sm={6}>
                        <Label for="purity">Requested purity:</Label>
                      </Col>
                      <Col sm={6}>
                        <Input
                          type="text"
                          name="purity"
                          id="purity"
                          value={auction.target_purity + " %"}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row className="align-items-center mb-2">
                      <Col sm={6}>
                        <Label for="price">Target budget:</Label>
                      </Col>
                      <Col sm={6}>
                        <InputField
                          name="target_price"
                          type="price"
                          value={auction.target_price}
                          min={0}
                          disabled
                          append="USD"
                          formGroupClass="no-margin"
                        />
                      </Col>
                    </Row>

                    <Row className="align-items-center mb-2">
                      <Col sm={6}>
                        <Label for="deadline">Target delivery deadline:</Label>
                      </Col>
                      <Col sm={6}>
                        <Input
                          type="text"
                          name="deadline"
                          id="deadline"
                          value={
                            auction.delivery_deadline
                              ? auction.delivery_deadline
                              : "N/A"
                          }
                          disabled
                        />
                      </Col>
                    </Row>
                    {auction.partial_offer > 1 && (
                      <Row className="align-items-center mb-2">
                        <Col>
                          {`Offers must include ${auction.partial_offer_type_display} ${auction.partial_offer} requested compound(s).`}
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col sm="6">
                    <Table>
                      <thead>
                        <tr>
                          <th className="column-id">#</th>
                          <th className="column-structure">Structure</th>
                          <th>Chemical Id</th>
                        </tr>
                      </thead>
                      <tbody>
                        {auction.structures &&
                          auction.structures.map((item, index) => (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td className="structure-container">
                                <StructureImage
                                  src={item.chemical_id}
                                  alt={index}
                                  className="compound-img"
                                />
                                <div>{item.customer_id}</div>
                              </td>
                              <td>{item.chemical_id}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </div>
            <ConfirmModal
              open={confirmChangeEndDateModal}
              toggle={toggleModal}
              confirmed={saveEndDateInput}
              title="Confirmation needed"
              bodyText={`<p>You are about to change the end of the auction from <b>${moment(
                auction.valid_until
              ).format(CONSTANTS.DATETIME_FORMAT)}</b> to <b>${moment(
                validUntil
              ).format(
                CONSTANTS.DATETIME_FORMAT
              )}</b>.</p> <p>Please note, that <ul> <li>You can only shorten the closing date of the auction, extension is not possible.</li><li>You cannot reset the original closing date of the auction or extend the bidding period after saving the new date.</li></ul></p>`}
              cancelBtn="Cancel"
              cancelBtnClass="btn-danger"
              okBtn="Save"
              okBtnClass="btn-success"
            />
            <ConfirmModal
              open={confirmCloseImmediatelyModal}
              toggle={toggleCloseImmediatelyModal}
              confirmed={closeAuction}
              title="Confirmation needed"
              bodyText={`<p>You are about to close this auction right now.</p> <p>Please note, that after this confirmation you cannot reset the original closing date of the auction or open it again.</p>`}
              cancelBtn="Cancel"
              cancelBtnClass="btn-danger"
              okBtn="Confirm"
              okBtnClass="btn-success"
            />
            <ConfirmModal
              open={confirmAcceptBidModal}
              toggle={toggleAcceptBidModal}
              confirmed={acceptBid}
              title="Confirmation needed"
              bodyText={`<p>Are you sure you want to accept Bid <b>${selectedBid?.bid_cqr_name}</b>?</p> <p>Please note, that you cannot withdraw this acceptance.</p><p>Please note that accepting a Bid does not constitute a legal obligation yet. Bid acceptance has to be followed by an Order confirmation. We start to process your Order only afterwards.</p>`}
              cancelBtn="Cancel"
              cancelBtnClass="btn-danger"
              okBtn="Confirm"
              okBtnClass="btn-success"
            />
          </React.Fragment>
        )}
      </Container>
    </div>
  );
}
