import React, { useState, useEffect } from "react";
import {
  Row,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Col,
  Container,
  Alert,
} from "reactstrap";
import API from "../api/api";
import { toast } from "react-toastify";
import * as CONSTANTS from "../common/Constants";
import MetaTags from "react-meta-tags";
import * as countryFile from "../assets/countries.json";
import * as publicDomains from "../assets/popularDomains.json";
import InputField from "../common/InputField";
import { Link } from "react-router-dom";

export default function RegisterPage(props) {
  const title =
    CONSTANTS.TITLES.REGISTER +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;

  const [inputs, setInputs] = useState({
    company_name: "",
    contact_person: "",
    email: "",
    phone: "",
    description: "",
    user_type: null,
    field: 0,
    eula: false,
    is_checked_terms_and_conf: false,
  });
  const [selectDisabled, setSelectDisabled] = useState();
  const [errors, setErrors] = useState({});
  const [address, setAddress] = useState({
    name: "",
    country: "",
    city: "",
    address_line: "",
    zip_code: "",
    state: "",
  });
  const [countries, setCountries] = useState([]);
  const [popularDomains, setPopularDomains] = useState([]);
  const [regSuccess, setRegSuccess] = useState(false);

  useEffect(() => {
    setInputs({});
    setErrors({});
    setAddress({});
    setCountries(countryFile.default);
    setPopularDomains(publicDomains.default);

    if (props.match.params.userType) {
      setInputs((inputs) => ({
        ...inputs,
        customerType: props.match.params.userType === "customer" ? true : false,
        supplierType: props.match.params.userType === "supplier" ? true : false,
        user_type:
          props.match.params.userType === "customer"
            ? CONSTANTS.USER_TYPE.CUSTOMER
            : CONSTANTS.USER_TYPE.SUPPLIER,
      }));
      let userType = null;
      if (props.match.params.userType === "customer") {
        userType = "customerType";
      } else if (props.match.params.userType === "supplier") {
        userType = "supplierType";
      }
      configureFieldsPerType(userType);
      handleInviteToken();
    }
  }, [props.match.params.userType]);

  useEffect(() => {
    handleInviteToken();
  }, [props.location]);

  const handleInviteToken = () => {
    let token = new URLSearchParams(props.location.search).get("it");
    if (token) {
      localStorage.setItem("synthagora_invite_token", token);
    }
  };

  const onChangeHandler = (event) => {
    event.persist();
    configureFieldsPerType(event.target.name, event);
  };

  const configureFieldsPerType = (paramName, event = null) => {
    if (paramName === "customerType") {
      setInputs((inputs) => ({
        ...inputs,
        user_type: CONSTANTS.USER_TYPE.CUSTOMER,
        customerType: true,
        supplierType: false,
        field: 0,
      }));
      setSelectDisabled(false);
    } else if (paramName === "supplierType") {
      setInputs((inputs) => ({
        ...inputs,
        user_type: CONSTANTS.USER_TYPE.SUPPLIER,
        customerType: false,
        supplierType: true,
        field: CONSTANTS.COMPANY_TYPE.SUPPLIER,
      }));
      setSelectDisabled(true);
    } else if (event.target.type === "checkbox") {
      setInputs((inputs) => ({
        ...inputs,
        [paramName]: event.target.checked,
      }));
    } else {
      setInputs((inputs) => ({
        ...inputs,
        [paramName]: event.target.value,
      }));
    }
  };

  const onAddressChangeHandler = (event) => {
    event.persist();
    setAddress((address) => ({
      ...address,
      [event.target.name]: event.target.value,
    }));
  };

  const isAllowedEmail = (email) => {
    let response = true;
    if (email && email.length) {
      var parts = email.split("@");
      if (parts.length === 2) {
        if (popularDomains.some((v) => parts[1].includes(v))) {
          response = false;
        }
      }
    }
    return response;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isAllowedEmail(inputs.email)) {
      let payLoad = {
        company_name: inputs.company_name,
        description: inputs.description,
        user_type: inputs.user_type,
        address: address,
        is_checked_terms_and_conf: inputs.is_checked_terms_and_conf,
        contact: {
          contact_person: inputs.contact_person,
          phone: inputs.phone,
          email: inputs.email,
        },
        ...(inputs.field && { field: inputs.field }),
      };
      const inviteToken = localStorage.getItem("synthagora_invite_token");
      if (inviteToken) {
        payLoad.invite_token = inviteToken;
      }
      API.post("users/register/", payLoad)
        .then((res) => {
          toast.success(
            "Successful registration! Thank you for your registration. You will receive an email with instructions shortly.",
            { autoClose: 3000 }
          );
          setRegSuccess(true);
          localStorage.removeItem("synthagora_invite_token");
        })
        .catch((err) => {
          toast.error("Error at registration.", {});
          // console.log("ERROR", err);
          setErrors(err.response.data);
        });
    } else {
      setErrors({
        contact: {
          email: "Only company or university domains are allowed to register",
        },
      });
    }
  };

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>

      <Container className="pt-4">
        <h1 className="mb-4 subheadline text-center">Registration</h1>
        <div
          className={
            "box-container mw-500 " + (inputs.customerType ? "orange" : "blue")
          }
        >
          {regSuccess ? (
            <div className="box">
              Successful registration.
              <br />
              <br />
              Thank you for your registration! Your request will be reviewed and
              after approval, a confirmation email will be sent to you with
              instructions. Please visit the link in the email and finish your
              registration by setting the password of your account.
            </div>
          ) : (
            <Form className="box" autoComplete="off" onSubmit={handleSubmit}>
              <FormGroup tag="fieldset" className="type-container">
                <legend>I would like to register as</legend>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="customerType"
                      onChange={onChangeHandler}
                      value={inputs.customerType || false}
                      checked={inputs.customerType || false}
                      disabled={props.match.params.userType}
                    />
                    Customer
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="supplierType"
                      onChange={onChangeHandler}
                      value={inputs.supplierType || false}
                      checked={inputs.supplierType || false}
                      disabled={props.match.params.userType}
                    />
                    Supplier
                  </Label>
                </FormGroup>
              </FormGroup>
              {(inputs.customerType || inputs.supplierType) && (
                <React.Fragment>
                  <InputField
                    name="contact_person"
                    displayName="Contact name"
                    type="text"
                    value={inputs.contact_person}
                    onChangeHandler={onChangeHandler}
                    invalidCondition={errors && errors.contact_person}
                    errorField={
                      errors.contact_person ? errors.contact_person : ""
                    }
                    required
                    placeholder="Your name"
                  />
                  <Row>
                    <Col sm={6}>
                      <InputField
                        id="emailPopover"
                        name="email"
                        displayName="Contact email"
                        type="email"
                        value={inputs.email}
                        onChangeHandler={onChangeHandler}
                        invalidCondition={
                          errors && errors.contact && errors.contact.email
                        }
                        errorField={
                          errors && errors.contact ? errors.contact.email : ""
                        }
                        required
                        placeholder="Your email"
                        autoComplete="off"
                      />
                    </Col>
                    <Col sm={6}>
                      <InputField
                        name="phone"
                        displayName="Contact phone"
                        type="tel"
                        value={inputs.phone}
                        onChangeHandler={onChangeHandler}
                        invalidCondition={
                          errors && errors.contact && errors.contact.phone
                        }
                        errorField={
                          errors && errors.contact ? errors.contact.phone : ""
                        }
                        required
                        placeholder="Your phone number"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <InputField
                        name="company_name"
                        displayName="Organization name"
                        type="text"
                        value={inputs.company_name}
                        onChangeHandler={onChangeHandler}
                        invalidCondition={errors && errors.company_name}
                        errorField={
                          errors.company_name ? errors.company_name : ""
                        }
                        required
                        placeholder="Your company's name"
                      />
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="field">Organization type*</Label>
                        <Input
                          type="select"
                          name="field"
                          id="field"
                          onChange={onChangeHandler}
                          value={inputs.field}
                          disabled={selectDisabled}
                          required
                          invalid={errors.field}
                        >
                          <option value={0}>Not set</option>
                          <option value={CONSTANTS.COMPANY_TYPE.INDUSTRIAL}>
                            {CONSTANTS.COMPANY_TYPES.INDUSTRIAL}
                          </option>
                          <option value={CONSTANTS.COMPANY_TYPE.UNIVERSITY}>
                            {CONSTANTS.COMPANY_TYPES.UNIVERSITY}
                          </option>
                          {inputs.field === CONSTANTS.COMPANY_TYPE.SUPPLIER && (
                            <option value={CONSTANTS.COMPANY_TYPE.SUPPLIER}>
                              {CONSTANTS.COMPANY_TYPES.SUPPLIER}
                            </option>
                          )}
                        </Input>
                        {errors.field && (
                          <FormFeedback>{errors.field}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="description">Organization description</Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      onChange={onChangeHandler}
                      value={inputs.description}
                    />
                  </FormGroup>
                  <InputField
                    name="name"
                    displayName="Address line 1"
                    type="text"
                    value={address.name}
                    onChangeHandler={onAddressChangeHandler}
                    invalidCondition={
                      errors && errors.address && errors.address.name
                    }
                    errorField={
                      errors && errors.address && errors.address.name
                        ? errors.address.name
                        : ""
                    }
                    required
                  />
                  <InputField
                    name="address_line"
                    displayName="Address line 2"
                    type="text"
                    value={address.address_line}
                    onChangeHandler={onAddressChangeHandler}
                    invalidCondition={
                      errors && errors.address && errors.address.address_line
                    }
                    errorField={
                      errors && errors.address && errors.address.address_line
                        ? errors.address.address_line
                        : ""
                    }
                  />
                  <InputField
                    name="city"
                    displayName="City/Region"
                    type="text"
                    value={address.city}
                    onChangeHandler={onAddressChangeHandler}
                    invalidCondition={
                      errors && errors.address && errors.address.city
                    }
                    errorField={
                      errors && errors.address && errors.address.city
                        ? errors.address.city
                        : ""
                    }
                    required
                  />
                  <Row>
                    <Col sm={6}>
                      <InputField
                        name="state"
                        displayName="State"
                        type="text"
                        value={address.state}
                        onChangeHandler={onAddressChangeHandler}
                        invalidCondition={
                          errors && errors.address && errors.address.state
                        }
                        errorField={
                          errors && errors.address && errors.address.state
                            ? errors.address.state
                            : ""
                        }
                      />
                    </Col>
                    <Col sm={6}>
                      <InputField
                        name="zip_code"
                        displayName="ZIP code"
                        type="text"
                        value={address.zip_code}
                        onChangeHandler={onAddressChangeHandler}
                        invalidCondition={
                          errors && errors.address && errors.address.zip_code
                        }
                        errorField={
                          errors && errors.address && errors.address.zip_code
                            ? errors.address.zip_code
                            : ""
                        }
                        required
                      />
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="shipping_country">Country*</Label>
                    <Input
                      type="select"
                      name="country"
                      id="shipping_country"
                      onChange={onAddressChangeHandler}
                      value={address.country || ""}
                      required
                      invalid={
                        errors && errors.address && errors.address.country
                      }
                    >
                      {countries.map((country, index) => (
                        <option key={country.iso} value={country.iso}>
                          {country.name}
                        </option>
                      ))}
                    </Input>
                    {errors && errors.address && errors.address.country && (
                      <FormFeedback>{errors.address.country}</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup check>
                    <Label check className="eula-container">
                      <Input
                        type="checkbox"
                        onChange={onChangeHandler}
                        value={inputs.eula}
                        required
                      />
                      I have read and accept the{" "}
                      <Link to="/terms">Terms and Conditions</Link> and{" "}
                      <Link to="/privacy-policy">Privacy policy</Link>*
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check className="eula-container">
                      <Input
                        type="checkbox"
                        onChange={onChangeHandler}
                        value={inputs.is_checked_terms_and_conf}
                        required
                        name="is_checked_terms_and_conf"
                      />
                      I have read and accept the Confidentiality requirements of
                      the <Link to="/terms">Terms and Conditions</Link>*
                    </Label>
                  </FormGroup>
                  <div className="d-flex justify-content-around mb-4">
                    <Button
                      className={
                        inputs.customerType ? "customer-btn" : "supplier-btn"
                      }
                      type="submit"
                    >
                      Register
                    </Button>
                  </div>

                  <Alert color="info">
                    <i className="fas fa-exclamation"></i>
                    After the submission of your registration request, your
                    request will be reviewed and a confirmation email will be
                    sent to you. Click on the link in the email and set the
                    password of your account.
                  </Alert>
                </React.Fragment>
              )}
            </Form>
          )}
        </div>
      </Container>
    </div>
  );
}
