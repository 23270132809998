import React from "react";
import { Switch, Route } from "react-router-dom";
import { useAuth } from "./context/auth";

// General components
import * as CONSTANTS from "./common/Constants";
import PrivateRoute from "./PrivateRoute";
import Landing from "./landing/Landing";
import RegistrationConfirmPage from "./views/RegistrationConfirmPage";
import NoPageFound from "./views/NoPageFound";
import Profile from "./views/Profile";
import Footer from "./views/Footer";
import Privacy from "./views/Privacy";
import TermsAndConditions from "./views/TermsAndConditions";
import NotificationList from "./views/NotificationList";
import NotificationSettingsPage from "./views/NotificationSettingsPage";
import LoginPage from "./views/LoginPage";
import RegisterPage from "./views/RegisterPage";
import ResetPasswordPage from "./views/ResetPasswordPage";
import ResetPasswordConfirmPage from "./views/ResetPasswordConfirmPage";
import EmailChangeConfirmPage from "./views/EmailChangeConfirmPage";
import PasswordChangePage from "./views/PasswordChangePage";

// Customer components
import CustomerHome from "./customer/Home";
import Auction from "./customer/Auction";
import AuctionListPage from "./customer/AuctionListPage";
import TrackAuction from "./customer/TrackAuction";
import BidDetail from "./customer/Bid";
import ConfirmOrder from "./customer/ConfirmOrder";
import OrderListCustomer from "./customer/OrderListPage";
import OrderCustomer from "./customer/Order";

// Supplier components
import OpenAuctions from "./supplier/AuctionListPage";
import Bid from "./supplier/Bid";
import SupplierHome from "./supplier/Home";
import BidListPage from "./supplier/BidListPage";
import OrderListSupplier from "./supplier/OrderListPage";
import OrderSupplier from "./supplier/Order";

// Marvin structure reader
import { loadConverter } from "./Marvin";
import { createResource } from "./api/cache";

const generalRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/about" component={Landing} />
      <Route exact path="/customers" component={Landing} />
      <Route exact path="/suppliers" component={Landing} />
      <Route exact path="/contact" component={Landing} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/registration" component={RegisterPage} />
      <Route exact path="/registration/:userType" component={RegisterPage} />
      <Route exact path="/confirm/:token" component={RegistrationConfirmPage} />
      <Route exact path="/privacy-policy" component={Privacy} />
      <Route exact path="/terms" component={TermsAndConditions} />
      <Route exact path="/reset-password" component={ResetPasswordPage} />
      <Route
        exact
        path="/reset-password/confirm/:uid/:token"
        component={ResetPasswordConfirmPage}
      />
      <Route component={NoPageFound} />
    </Switch>
  );
};

const customerRoutes = () => {
  return (
    <React.Fragment>
      <Switch>
        <PrivateRoute exact path="/" component={CustomerHome} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/create-auction" component={Auction} />
        <PrivateRoute exact path="/auction/:auctionId" component={Auction} />
        <PrivateRoute exact path="/my-auctions" component={AuctionListPage} />
        <PrivateRoute
          exact
          path="/track-auction/:auctionId"
          component={TrackAuction}
        />
        <PrivateRoute
          exact
          path="/track-auction/:auctionId/bid/:bidId"
          component={BidDetail}
        />
        <PrivateRoute
          exact
          path="/confirm-order/:auctionId"
          component={ConfirmOrder}
        />
        <PrivateRoute exact path="/my-orders" component={OrderListCustomer} />
        <PrivateRoute exact path="/order/:orderId" component={OrderCustomer} />
        <PrivateRoute
          exact
          path="/notification-settings"
          component={NotificationSettingsPage}
        />
        <PrivateRoute
          exact
          path="/password-change"
          component={PasswordChangePage}
        />
        <Route exact path="/privacy-policy" component={Privacy} />
        <Route exact path="/terms" component={TermsAndConditions} />
        <PrivateRoute
          exact
          path="/notifications"
          component={NotificationList}
        />
        <Route
          exact
          path="/email-change/verify/:token"
          component={EmailChangeConfirmPage}
        />
        <Route component={NoPageFound} />
      </Switch>
      <Footer />
    </React.Fragment>
  );
};

const supplierRoutes = () => {
  return (
    <React.Fragment>
      <Switch>
        <PrivateRoute exact path="/" component={SupplierHome} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute
          exact
          path="/my-bids/:auctionId"
          component={BidListPage}
        />
        <PrivateRoute exact path="/my-bids" component={BidListPage} />
        <PrivateRoute exact path="/open-auctions" component={OpenAuctions} />
        <PrivateRoute exact path="/make-bid/:auctionId" component={Bid} />
        <PrivateRoute
          exact
          path="/make-bid/:auctionId/:bidId"
          component={Bid}
        />
        <PrivateRoute exact path="/my-orders" component={OrderListSupplier} />
        <PrivateRoute exact path="/order/:orderId" component={OrderSupplier} />
        <PrivateRoute
          exact
          path="/notification-settings"
          component={NotificationSettingsPage}
        />
        <PrivateRoute
          exact
          path="/password-change"
          component={PasswordChangePage}
        />
        <Route exact path="/privacy-policy" component={Privacy} />
        <Route exact path="/terms" component={TermsAndConditions} />
        <PrivateRoute
          exact
          path="/notifications"
          component={NotificationList}
        />
        <Route
          exact
          path="/email-change/verify/:token"
          component={EmailChangeConfirmPage}
        />
        <Route component={NoPageFound} />
      </Switch>
      <Footer />
    </React.Fragment>
  );
};

const Routing = () => {
  const { userInfo } = useAuth();

  // MARVINJS
  const converterResource = createResource(loadConverter);
  converterResource.read("converter");

  if (userInfo) {
    if (userInfo.user_type === CONSTANTS.USER_TYPE.CUSTOMER) {
      return customerRoutes();
    } else {
      return supplierRoutes();
    }
  } else {
    return generalRoutes();
  }
};

export default Routing;
