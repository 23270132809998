import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap";
import { toast } from "react-toastify";
import API from "../api/api";
import * as CONSTANTS from "../common/Constants";
import { useHistory } from "react-router-dom";
import InputField from "../common/InputField";
import { MyCrossMark, MyCheckMark } from "../common/Entities";

export default function RegistrationConfirmPage(props) {
  const history = useHistory();
  const [inputs, setInputs] = useState();
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState();

  const onChangeHandler = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setToken(props.match.params.token);
      API.post("users/confirm/", {
        token: props.match.params.token,
      })
        .then((result) => {
          if (result.data.reg_data.user_type === CONSTANTS.USER_TYPE.SUPPLIER) {
            result.data.reg_data.field = CONSTANTS.COMPANY_TYPE.SUPPLIER;
          }
          setInputs(result.data.reg_data);
        })
        .catch((error) => {
          toast.error("Error at confirmation", {});
          if (error.response) {
            setErrors(error.response.data);
          }
        });
    };
    if (props.match.params.token) {
      fetchData();
    }
  }, [props.match.params.token]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors({});
    inputs.token = token;

    API.post("users/confirm/", inputs)
      .then((res) => {
        toast.success("Successful email confirmation!", {
          autoClose: 5000,
        });
        history.push("/");
        window.location.reload();
      })
      .catch((err) => {
        toast.error("Error at confirmation.", {});
        // console.log("ERROR", err);
        setErrors(err.response.data);
      });
  };

  const confirmPassword = () => {
    let result = false;
    if (
      ((inputs.password && inputs.password.length > 0) ||
        (inputs.confirm_password && inputs.confirm_password.length > 0)) &&
      inputs.password !== inputs.confirm_password
    ) {
      result = true;
    }

    return result;
  };

  const isCustomer = () => {
    return inputs.user_type === CONSTANTS.USER_TYPE.CUSTOMER;
  };

  return (
    <div className="grey-bg">
      <Container className="pt-4">
        <h1 className="mb-4 subheadline text-center">Email confirmation</h1>
        {errors.non_field_errors && (
          <div className="text-center mb-2">
            <Alert color="danger" className="d-inline">
              {errors.non_field_errors}
            </Alert>
          </div>
        )}
        {inputs && (
          <div
            className={"box-container " + (isCustomer() ? "orange" : "blue")}
          >
            <div className="box">
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Row>
                  <Col sm={12} md={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">User/contact information</div>
                      <div className="label-line"></div>
                    </div>
                    <InputField
                      name="contact_person"
                      displayName="Contact name"
                      type="text"
                      value={inputs.contact.contact_person}
                      disabled
                    />
                    <InputField
                      name="email"
                      displayName="Contact email"
                      type="text"
                      value={inputs.contact.email}
                      disabled
                    />
                    <InputField
                      name="phone"
                      displayName="Contact phone"
                      type="text"
                      value={inputs.contact.phone}
                      disabled
                    />
                    <FormGroup>
                      <Label for="userPassword">Password</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Your password"
                        autoComplete="new-password"
                        onChange={onChangeHandler}
                        value={inputs.password || ""}
                        invalid={
                          confirmPassword() ||
                          (errors &&
                            errors.password &&
                            errors.password.length > 0)
                        }
                        required
                      />
                      <small>
                        (Minimum 8 characters - at least one number, one
                        lowercase and one uppercase letter)
                      </small>
                      {confirmPassword() && (
                        <FormFeedback>
                          The password fields are not the same!
                        </FormFeedback>
                      )}
                      {errors &&
                        (errors.CommonPasswordValidator ||
                          errors.MinimumLengthValidator ||
                          errors.NumericPasswordValidator) && (
                          <div className="password-validation">
                            <div>Password requirements:</div>
                            <Row noGutters={true}>
                              <Col xs="auto" className="validation-icon">
                                {errors.MinimumLengthValidator ? (
                                  <MyCrossMark />
                                ) : (
                                  <MyCheckMark />
                                )}
                              </Col>
                              <Col>
                                This password is too short. It must contain at
                                least 8 characters.
                              </Col>
                            </Row>
                            <Row noGutters={true}>
                              <Col xs="auto" className="validation-icon">
                                {errors.CommonPasswordValidator ? (
                                  <MyCrossMark />
                                ) : (
                                  <MyCheckMark />
                                )}
                              </Col>
                              <Col>Password is too common.</Col>
                            </Row>
                            <Row noGutters={true}>
                              <Col xs="auto" className="validation-icon">
                                {errors.NumericPasswordValidator ? (
                                  <MyCrossMark />
                                ) : (
                                  <MyCheckMark />
                                )}
                              </Col>
                              <Col>This password is entirely numeric.</Col>
                            </Row>
                          </div>
                        )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="userPassword">Confirm password</Label>
                      <Input
                        type="password"
                        name="confirm_password"
                        id="confirm_password"
                        placeholder="Confirm password"
                        autoComplete="new-password"
                        onChange={onChangeHandler}
                        value={inputs.confirm_password || ""}
                        invalid={confirmPassword()}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Organisation information</div>
                      <div className="label-line"></div>
                    </div>
                    <InputField
                      name="company_name"
                      displayName="Organization name"
                      type="text"
                      value={inputs.company_name}
                      disabled
                    />
                    <FormGroup>
                      <Label for="field">Organization type</Label>
                      <Input
                        type="select"
                        name="field"
                        id="field"
                        onChange={onChangeHandler}
                        value={inputs.field}
                        disabled={true}
                      >
                        <option value={CONSTANTS.COMPANY_TYPE.INDUSTRIAL}>
                          {CONSTANTS.COMPANY_TYPES.INDUSTRIAL}
                        </option>
                        <option value={CONSTANTS.COMPANY_TYPE.UNIVERSITY}>
                          {CONSTANTS.COMPANY_TYPES.UNIVERSITY}
                        </option>
                        {!isCustomer() && (
                          <option value={CONSTANTS.COMPANY_TYPE.SUPPLIER}>
                            {CONSTANTS.COMPANY_TYPES.SUPPLIER}
                          </option>
                        )}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="description">Organization description</Label>
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        onChange={onChangeHandler}
                        value={inputs.description}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button
                    className={
                      "" +
                      (inputs.user_type === CONSTANTS.USER_TYPE.CUSTOMER
                        ? "customer-btn"
                        : "supplier-btn")
                    }
                    type="submit"
                    disabled={
                      (inputs.password && inputs.password.length === 0) ||
                      confirmPassword()
                    }
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}
