import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import API from "../api/api";
import { toast } from "react-toastify";
import * as CONSTANTS from "../common/Constants";
import moment from "moment";
import StructureImage from "../common/StructureImage";
import { formatPrice } from "../common/Utilities";
import Loader from "../common/Loader";
import SimpleTooltip from "../common/SimpleTooltip";
import MetaTags from "react-meta-tags";

export default function OrderCustomer(props) {
  const [title, setTitle] = useState(
    CONSTANTS.TITLES.CONFIRM_ORDER +
      CONSTANTS.TITLES.DELIMITER +
      CONSTANTS.TITLES.POSTFIX
  );
  const APIDomain = process.env.REACT_APP_API_DOMAIN;
  const APIBase = process.env.REACT_APP_API_BASE_URL;
  const [order, setOrder] = useState(null);
  // const [message, setMessage] = useState("");
  const [orderLoading, setOrderLoading] = useState(true);

  const [shippingAddress, setShippingAddress] = useState({
    name: "",
    country: "",
    country_display: "",
    city: "",
    address_line: "",
    zip_code: "",
    state: "",
    concatenatedText: "",
  });

  const orderState = [
    {
      id: CONSTANTS.ORDER_STATE.CONFIRMED_BY_CUSTOMER,
      title: "Confirmed by customer",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.CONFIRMED_OR_TERMS_ACCEPTED,
      title: "Confirmed or terms accepted",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.PAYMENT_RECEIVED_OR_ACCOUNT_SET,
      title: "Payment received or credit account set",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.CONFIRMED_BY_SUPPLIER,
      title: "Confirmed by supplier",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.REAGENTS_ARRRIVED,
      title: "Reagents have arrived",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.VALIDATION,
      title: "Validation",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.PRODUCTION,
      title: "Production",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.PURIFICATION,
      title: "Purification",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.READY_FOR_SHIPPING,
      title: "Ready for shipping",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.SHIPPING_TO_MCULE,
      title: "Shipping to mcule",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.SHIPPING_TO_CUSTOMER,
      title: "Shipping to customer",
      date: null,
    },
  ];

  const hasDiscountedPrice = (bid) => {
    return bid.discounted_public_total_price !== bid.public_total_price;
  };

  useEffect(() => {
    if (props.match.params.orderId) {
      setTitle(
        CONSTANTS.TITLES.ORDER_DETAILS +
          CONSTANTS.TITLES.DELIMITER +
          CONSTANTS.TITLES.POSTFIX
      );
      setOrderLoading(true);
      API.get(`customer-orders/${props.match.params.orderId}/`)
        .then((res) => {
          setOrder(res.data);
          setShippingAddress(
            res.data.addresses.find(
              (address) => address.type_display === "shipping"
            )
          );
        })
        .catch((err) => {
          toast.error("Error loading order data", {});
          console.log("ERROR", err);
        })
        .finally(() => {
          setOrderLoading(false);
        });
    }
  }, [props.match.params.orderId]);

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="mt-4">
        {orderLoading ? (
          <Loader text="Loading order" />
        ) : (
          <React.Fragment>
            <Row>
              <Col sm={8}>
                <ul className="breadcrumb">
                  <li>
                    <Link to={`/my-orders`}>My orders</Link>
                  </li>
                  <li>{order?.order_name}</li>
                </ul>
                <h1 className="mb-4 subheadline">Order {order?.order_name}</h1>
              </Col>
              <Col sm={4} className="text-right header-right">
                <div className="subheader">
                  <span className="subheader-label">Status:</span>
                  <span
                    className={"status " + order?.state_display.toLowerCase()}
                  >
                    {order?.state_display}
                  </span>
                </div>
                <h3 className="subheadline">{order?.auction.auction_name}</h3>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={6}>
                <div className="subheader border-left-blue">
                  <div>
                    <span className="subheader-label">Date of order:</span>
                    <span className="subheader-value">
                      {moment(order.created_at).format(
                        CONSTANTS.DATETIME_FORMAT
                      )}
                    </span>
                  </div>
                  {order.end_date && (
                    <>
                      <div>
                        <span className="subheader-label">
                          Expected delivery date:
                        </span>
                        <span className="subheader-value">
                          {moment(order.end_date).format(CONSTANTS.DATE_FORMAT)}
                        </span>
                      </div>

                      <div>
                        <span className="subheader-label pl-2">
                          Time remaining:
                        </span>
                        <span className="subheader-value">
                          {(order.time_remaining || 0) + " day(s)"}
                        </span>
                      </div>
                    </>
                  )}

                  {order.payment_deadline && (
                    <div>
                      <span className="subheader-label">Payment deadline:</span>
                      <span className="subheader-value">
                        {moment(order.payment_deadline).format(
                          CONSTANTS.DATE_FORMAT
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </Col>
              <Col sm={6} className="text-right"></Col>
            </Row>

            <h2 className="mb-2 subheadline blue">Order details</h2>
            <div className="box-container blue">
              <div className="box">
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label for="description">Description</Label>
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        placeholder="description"
                        rows="5"
                        value={order.auction.description}
                        disabled
                      />
                    </FormGroup>
                    <Row className="align-items-center mb-2">
                      <Col sm={6}>
                        <Label for="price">Total price</Label>
                        <SimpleTooltip name="price">
                          Total price includes compound price, shipping, and
                          handling fees, and additional fees for extra services
                          (exclusivity, reporting).
                        </SimpleTooltip>
                      </Col>
                      <Col sm={6}>
                        <InputGroup>
                          {formatPrice(
                            hasDiscountedPrice(order.auction.winner_bid)
                              ? order.auction.winner_bid
                                  .discounted_public_total_price
                              : order.auction.winner_bid.public_total_price,
                            false,
                            "form-control disabled"
                          )}
                          <InputGroupAddon addonType="append">
                            <InputGroupText>USD</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="align-items-center mb-2">
                      <Col sm={6}>
                        <Label for="amount">Ordered amount</Label>
                      </Col>
                      <Col sm={6}>
                        <InputGroup>
                          <Input
                            type="number"
                            name="amount"
                            id="amount"
                            value={order.auction.winner_bid.amount}
                            disabled
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>mg</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="align-items-center mb-2">
                      <Col sm={6}>
                        <Label for="purity">Ordered purity</Label>
                      </Col>
                      <Col sm={6}>
                        <InputGroup>
                          <Input
                            type="number"
                            name="purity"
                            id="purity"
                            value={order.auction.winner_bid.purity}
                            disabled
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>%</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="align-items-center mb-2">
                      <Col sm={6}>
                        <Label for="num_compounds">Number of compounds</Label>
                      </Col>
                      <Col sm={6}>
                        <Input
                          type="text"
                          name="num_compounds"
                          id="num_compounds"
                          value={order.auction.winner_bid.num_compounds}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row className="align-items-center mb-2">
                      <Col sm={6}>
                        <Label for="country">Shipping country</Label>
                        <SimpleTooltip name="country">
                          Please contact{" "}
                          <a href="mailto:support@synthagora.com">
                            support@synthagora.com
                          </a>
                          if you want to modify the Shipping country.
                        </SimpleTooltip>
                      </Col>
                      <Col sm={6}>
                        <Input
                          type="text"
                          name="country"
                          id="country"
                          value={shippingAddress.country_display}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row className="align-items-center mb-2">
                      <Col sm={6}>
                        <Label for="address">Shipping address</Label>
                        <SimpleTooltip name="address">
                          Please contact{" "}
                          <a href="mailto:support@synthagora.com">
                            support@synthagora.com
                          </a>{" "}
                          if you want to modify the Shipping address.
                        </SimpleTooltip>
                      </Col>
                      <Col sm={6}>
                        <Input
                          type="text"
                          name="address"
                          id="address"
                          value={
                            shippingAddress.name +
                            ", " +
                            shippingAddress.address_line
                          }
                          disabled
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} md={6}>
                    <div>
                      {`${
                        order.auction.winner_bid.num_compounds ===
                        order.auction.winner_bid.auction_structures_filtered
                          .length
                          ? "All"
                          : order.auction.winner_bid.num_compounds
                      } of these compounds will be
                      shipped.`}
                    </div>
                    <Table>
                      <thead>
                        <tr>
                          <th className="column-id">#</th>
                          <th className="column-structure">Structure</th>
                          <th>Chemical ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.auction.winner_bid.auction_structures_filtered &&
                          order.auction.winner_bid.auction_structures_filtered.map(
                            (item, index) => (
                              <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td className="structure-container">
                                  <StructureImage src={item.chemical_id} />
                                  <div>{item.customer_id}</div>
                                </td>
                                <td>{item.chemical_id}</td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </div>

            <h2 className="mb-2 subheadline blue">
              Order status
              <SimpleTooltip name="status">
                Here you can track the status of your order. The dates under the
                completed steps belong to the status modification, and may not
                indicate the actual date the status reached.
              </SimpleTooltip>
            </h2>
            <div className="box-container blue">
              <div className="box">
                <ul className="customer-timeline timeline" id="timeline">
                  {orderState.map((item, index) => {
                    const foundState = order.state_updates.find(
                      (x) => Number.parseInt(x.id) === item.id
                    );
                    item.date = foundState
                      ? moment(foundState.date).format(
                          CONSTANTS.DATETIME_FORMAT
                        )
                      : null;

                    return (
                      <li
                        key={item.title}
                        className={`li ${item.date ? "complete" : ""}`}
                      >
                        <div className="timestamp text-center">
                          {item.title}
                        </div>
                        <div className="order-status">{item.date}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <h2 className="mb-2 subheadline blue">Uploaded files</h2>
            <div className="box-container blue">
              <div className="box">
                <div className="label-line-wrapper blue">
                  <div className="label-text">Customer files</div>
                  <div className="label-line"></div>
                </div>
                {order.auction?.files?.length > 1 && (
                  <a
                    href={`${APIDomain}${APIBase}auctions/${order.auction.id}/download-files/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mb-1 d-block"
                  >
                    <span>Download all files</span>
                  </a>
                )}
                <ul className="file-list">
                  {order.auction.files?.map((file) => (
                    <li key={file.id}>
                      <a
                        href={`${APIDomain}${APIBase}files/auction/${order.auction.id}/download/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="far fa-file"></i>{" "}
                        <span>{file.filename}</span>
                      </a>
                    </li>
                  ))}
                </ul>

                <div className="label-line-wrapper blue">
                  <div className="label-text">Received files</div>
                  <div className="label-line"></div>
                </div>
                {order.bid?.files?.length > 1 && (
                  <a
                    href={`${APIDomain}${APIBase}bids/${order.bid.id}/download-files/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mb-1 d-block"
                  >
                    <span>Download all files</span>
                  </a>
                )}
                <ul className="file-list">
                  {order.bid.files?.map((file) => (
                    <li key={file.id}>
                      <a
                        href={`${APIDomain}${APIBase}files/bid/${file.id}/download/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="far fa-file"></i>{" "}
                        <span>{file.filename}</span>
                      </a>
                    </li>
                  ))}
                </ul>
                {order.po_file && (
                  <React.Fragment>
                    <div className="label-line-wrapper blue">
                      <div className="label-text">PO</div>
                      <div className="label-line"></div>
                    </div>
                    <div className="file-list">
                      <a
                        href={`${APIDomain}${APIBase}files/po/${order.po_file.id}/download/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="far fa-file"></i>{" "}
                        <span>{order.po_file.filename}</span>
                      </a>
                    </div>
                  </React.Fragment>
                )}

                {/* <div className="label-line-wrapper blue">
                  <div className="label-text">
                    Upload new file
                  </div>
                  <div className="label-line"></div>
                </div>
                <div>
                  <FormGroup>
                    <Label for="files">File</Label>
                    <Input
                      type="file"
                      name="files"
                      id="files"
                      // onChange={handleFileChange}
                      // disabled={isDisabledField()}
                      // invalid={errors.files[0]}
                    />
                    <FormText color="muted">
                      This is some placeholder block-level
                      help text for the above input. It's
                      a bit lighter and easily wraps to a
                      new line.
                    </FormText>
                  </FormGroup>
                </div> */}
              </div>
            </div>

            <h2 className="mb-2 subheadline blue">Reports</h2>
            <div className="box-container blue">
              <div className="box">
                {order.reporting_files?.length > 1 && (
                  <a
                    href={`${APIDomain}${APIBase}customer-orders/${order.id}/download-reporting-files/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mb-1 d-block"
                  >
                    <span>Download all files</span>
                  </a>
                )}
                <ul className="file-list">
                  {order.reporting_files.map((file) => (
                    <li key={file.id}>
                      <a
                        href={`${APIDomain}${APIBase}files/reporting/${file.id}/download/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="far fa-file"></i>{" "}
                        {`${file.filename} (${moment(file.created_at).format(
                          CONSTANTS.DATETIME_FORMAT
                        )})`}
                        {/* <span>{file.name}</span> */}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* <h2 className="mb-2 subheadline blue">Messages</h2>
            <div className="box-container blue">
              <div className="box">
                <FormGroup>
                  <Label for="message">
                    Do you need further information about
                    the project?
                  </Label>
                  <FormText color="muted">
                    This is a provate communication channel
                    where you can discuss further details of
                    the project.
                  </FormText>
                  <Input
                    type="textarea"
                    name="message"
                    id="message"
                    placeholder="Message to your vendor! (Note: you cannot share information that violate the anonymity of the website users.)"
                    rows="5"
                    onChange={event => {
                      setMessage(event);
                    }}
                    value={message}
                    // disabled={isDisabledField()}
                    // invalid={errors.description}
                  />
                </FormGroup>
                <div className="text-right">
                  <Button
                    className="btn-blue"
                    onClick={() => console.log("send")}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div> */}
          </React.Fragment>
        )}
      </Container>
    </div>
  );
}
