import React, { useState, useEffect } from "react";
import { Table, UncontrolledTooltip as Tooltip } from "reactstrap";
import LinkButton from "../common/LinkButton";
import { Link } from "react-router-dom";
import * as CONSTANTS from "../common/Constants";
import StructureImage from "../common/StructureImage";
import moment from "moment";
import { formatPrice } from "../common/Utilities";
import Td from "../common/ContentTag";

export default function OrderListCustomer(props) {
  const [orders, setOrders] = useState([]);

  const hasDiscountedPrice = (bid) => {
    return bid.discounted_public_total_price !== bid.public_total_price;
  };

  useEffect(() => {
    setOrders(props.orders);
  }, [props.orders]);

  const renderResult = () => {
    if (orders.length <= 0) {
      return <div className="text-center subheadline blue">No order found</div>;
    } else {
      return (
        <Table>
          <thead>
            <tr>
              <th className="text-center">Structure</th>
              {!props.summary && <th>Order name</th>}
              <th>Details</th>
              <th>Status</th>
              {!props.summary && <th>Delivery deadline</th>}
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={index}>
                <td data-label="Structure" className="structure-container">
                  <StructureImage
                    src={
                      order.auction.winner_bid.auction_structures_filtered
                        .length > 0
                        ? order.auction.winner_bid
                            .auction_structures_filtered[0].chemical_id
                        : null
                    }
                  />

                  {order.auction.winner_bid.auction_structures_filtered.length >
                    1 && (
                    <div className="structure-counter">
                      +{" "}
                      {order.auction.winner_bid.auction_structures_filtered
                        .length - 1}{" "}
                      other(s)
                    </div>
                  )}
                </td>
                {!props.summary && (
                  <Td to={`/order/${order.id}`} datalabel="Order name">
                    {order.order_name}
                  </Td>
                )}
                <Td to={`/order/${order.id}`} datalabel="Detail">
                  <div>
                    {props.summary && <div>{order.order_name}</div>}
                    <div
                      className="detail-icons price-icon"
                      id={`order-${order.id}-price`}
                    >
                      {formatPrice(
                        hasDiscountedPrice(order.auction.winner_bid)
                          ? order.auction.winner_bid
                              .discounted_public_total_price
                          : order.auction.winner_bid.public_total_price
                      )}
                    </div>
                    <Tooltip
                      placement="left"
                      target={`order-${order.id}-price`}
                    >
                      Total price
                    </Tooltip>
                    <div
                      className="detail-icons delivery-icon"
                      id={`order-${order.id}-delivery`}
                    >
                      {order.auction.winner_bid.public_delivery_time +
                        " week(s)"}
                    </div>
                    <Tooltip
                      placement="left"
                      target={`order-${order.id}-delivery`}
                    >
                      Lead time
                    </Tooltip>
                    <div
                      className="detail-icons purity-icon"
                      id={`order-${order.id}-purity`}
                    >
                      {order.auction.winner_bid.purity + "%"}
                    </div>
                    <Tooltip
                      placement="left"
                      target={`order-${order.id}-purity`}
                    >
                      Purity
                    </Tooltip>
                    <div
                      className="detail-icons amount-icon"
                      id={`order-${order.id}-amount`}
                    >
                      {order.auction.winner_bid.amount +
                        " " +
                        order.auction.winner_bid.unit_display}
                    </div>
                    <Tooltip
                      placement="left"
                      target={`order-${order.id}-amount`}
                    >
                      Amount
                    </Tooltip>
                    <div
                      className="detail-icons num-compounds-icon"
                      id={`order-${order.id}-num`}
                    >
                      {order.auction.winner_bid.num_compounds}
                    </div>
                    <Tooltip placement="left" target={`order-${order.id}-num`}>
                      Number of compounds
                    </Tooltip>
                  </div>
                </Td>
                <Td to={`/order/${order.id}`} datalabel="Status">
                  <div className={"status " + order.state_display}>
                    {order.state_display}
                  </div>
                </Td>
                {!props.summary && (
                  <Td to={`/order/${order.id}`} datalabel="Delivery deadline">
                    {order.end_date
                      ? moment(order.end_date).format(CONSTANTS.DATE_FORMAT)
                      : "N/A"}
                  </Td>
                )}
                <td>
                  <LinkButton
                    to={`/order/${order.id}`}
                    className="d-block btn-orange btn btn-secondary mb-1 mx-auto"
                  >
                    Track Order
                  </LinkButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }
  };

  return renderResult();
}
