import React, { useEffect, useState } from "react";
import * as CONSTANTS from "../common/Constants";
import { useAuth } from "../context/auth";
import { toast } from "react-toastify";
import API from "../api/api";
import {
  Table,
  Input,
  Button,
  Alert
} from "reactstrap";

export default function NotificationSettings(props) {
  const { userInfo } = useAuth();
  const [notificationSettings, setNotificationSettings] = useState(null);
  const [notificationMeta, setNotificationMeta] = useState(null);

  const isCustomer = () => {
    return userInfo.user_type === CONSTANTS.USER_TYPE.CUSTOMER;
  };

  useEffect(() => {
    getNotificationSettings();
  }, []);

  const getNotificationSettings = () => {
    API.get(`/notifications/settings/`)
      .then((res) => {
        setNotificationSettings(res.data.settings);
        setNotificationMeta(res.data.meta);
      })
      .catch((err) => {
        toast.error("Error loading notification settings", {});
        console.log("ERROR", err);
      })
      .finally(() => {
        // this.setState({loading: false})
      });
  };

  const onNotificationChangeHandler = (event, key, notificationType) => {
    event.persist();
    if (notificationType === "email") {
      setNotificationSettings((inputs) => ({
        ...inputs,
        [key]: {
          email: !notificationSettings[key].email,
          inapp: notificationSettings[key].inapp,
        },
      }));
    }
    if (notificationType === "inapp") {
      setNotificationSettings((inputs) => ({
        ...inputs,
        [key]: {
          email: notificationSettings[key].email,
          inapp: !notificationSettings[key].inapp,
        },
      }));
    }
  };

  const saveNotificationSettings = () => {
    API.put(`/notifications/settings/`, notificationSettings)
      .then((res) => {
        setNotificationSettings(res.data);
        toast.success("Notification settings saved.", {});
      })
      .catch((err) => {
        toast.error("Error setting notifications.", {});
        console.log("ERROR", err);
      });
  };

  const renderNotificationSettings = () => {
    if (notificationSettings && notificationMeta) {
      return (
        <React.Fragment>
          <div
            className={"box-container " + (isCustomer() ? "orange" : "blue")}
          >
            <div className="box">
              <div className="label-line-wrapper">
                <div className="label-text">Notification settings</div>
                <div className="label-line"></div>
              </div>
              <Alert color="info">
            <i className="fas fa-exclamation"></i>
            Set your notification preferences by choosing the status changes you wish to receive notification via email and/or inside the application ("inapp").
          </Alert>
              <Table className="notification-settings-table" size="sm">
                <thead>
                  <tr>
                    <th>Notification name</th>
                    <th className="text-center">Email</th>
                    <th className="text-center">InApp</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(notificationSettings).map((key) => (
                    <tr key={key}>
                      <td>{notificationMeta.notice_types[key].display_name}</td>
                      <td className="text-center">
                        <Input
                          name={`${key}-email`}
                          type="checkbox"
                          checked={notificationSettings[key].email}
                          onChange={(event) =>
                            onNotificationChangeHandler(event, key, "email")
                          }
                          required
                        />
                      </td>
                      <td className="text-center">
                        <Input
                          name={`${key}-inapp`}
                          type="checkbox"
                          checked={notificationSettings[key].inapp}
                          onChange={(event) =>
                            onNotificationChangeHandler(event, key, "inapp")
                          }
                          required
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="text-center">
                <Button
                  className={
                    "" + (isCustomer() ? "customer-btn" : "supplier-btn")
                  }
                  onClick={() => saveNotificationSettings()}
                >
                  Save notification settings
                </Button>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return <div>Loading</div>;
    }
  };

  return (
    renderNotificationSettings()
  );
}
