import React, { useState, useEffect } from "react";
import { Table, UncontrolledTooltip as Tooltip } from "reactstrap";
import LinkButton from "../common/LinkButton";
import * as CONSTANTS from "../common/Constants";
import moment from "moment";
import StructureImage from "../common/StructureImage";
import { formatPrice } from "../common/Utilities";
import Td from "../common/ContentTag";

export default function BidList(props) {
  const [bids, setBids] = useState([]);

  useEffect(() => {
    setBids(props.bids);
  }, [props.bids]);

  const renderResult = () => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Bid name</th>
            <th className="column-structure">Structure</th>
            {!props.summary && <th>Status of auction</th>}
            <th>Bid details</th>
            {!props.summary && <th>Date of bid</th>}
            {!props.summary && <th>Status of bid</th>}
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {bids.length === 0 && (
            <tr>
              <td colSpan={props.summary ? 4 : 7}>No bid found</td>
            </tr>
          )}
          {bids.map((bid, index) => (
            <tr key={bid.id}>
              <Td
                to={`/make-bid/${bid.auction}/${bid.id}`}
                datalabel="Bid name"
              >
                {bid.bid_cqr_name}
              </Td>
              <td data-label="Structure" className="structure-container">
                <StructureImage
                  src={
                    bid.auction_structures.length > 0
                      ? bid.auction_structures[0].chemical_id
                      : null
                  }
                  alt={index}
                  className="compound-img"
                />
                {bid.auction_structures.length > 1 && (
                  <div className="structure-counter">
                    + {bid.auction_structures.length - 1} other(s)
                  </div>
                )}
              </td>
              {!props.summary && (
                <Td
                  to={`/make-bid/${bid.auction}/${bid.id}`}
                  datalabel="Auction status"
                >
                  <div>
                    <div
                      className={
                        "status " + bid.auction_state_display.toLowerCase()
                      }
                    >
                      {bid.auction_state_display}
                    </div>
                  </div>
                  {bid.auction_state_display.toLowerCase() === "approved" && (
                    <React.Fragment>
                      <div>
                        Open:{" "}
                        {moment(bid.auction_end_date).format(
                          CONSTANTS.DATE_FORMAT
                        )}
                      </div>
                      <div>
                        Close:{" "}
                        {moment(bid.auction_end_date).format(
                          CONSTANTS.DATE_FORMAT
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </Td>
              )}
              <Td
                to={`/make-bid/${bid.auction}/${bid.id}`}
                datalabel="Bid details"
              >
                <div>
                  {props.summary && (
                    <div
                      className={"status " + bid.state_display.toLowerCase()}
                    >
                      {bid.state_display}
                    </div>
                  )}
                  <div
                    className="detail-icons price-icon"
                    id={`bid-${bid.id}-price`}
                  >
                    {formatPrice(bid.price)}
                  </div>
                  <Tooltip placement="left" target={`bid-${bid.id}-price`}>
                    Total compound price
                  </Tooltip>
                  <div
                    className="detail-icons delivery-icon"
                    id={`bid-${bid.id}-time`}
                  >
                    {bid.max_delivery_time + " week(s)"}
                  </div>
                  <Tooltip placement="left" target={`bid-${bid.id}-time`}>
                    Lead time
                  </Tooltip>
                  <div
                    className="detail-icons purity-icon"
                    id={`bid-${bid.id}-purity`}
                  >
                    {bid.purity}%
                  </div>
                  <Tooltip placement="left" target={`bid-${bid.id}-purity`}>
                    Purity
                  </Tooltip>
                  <div
                    className="detail-icons amount-icon"
                    id={`bid-${bid.id}-amount`}
                  >
                    {bid.amount} {bid.unit_display}
                  </div>
                  <Tooltip placement="left" target={`bid-${bid.id}-amount`}>
                    Quote amount
                  </Tooltip>
                  <div
                    className="detail-icons num-compounds-icon"
                    id={`bid-${bid.id}-num`}
                  >
                    {bid.num_compounds}
                  </div>
                  <Tooltip placement="left" target={`bid-${bid.id}-num`}>
                    Number of compounds
                  </Tooltip>
                </div>
              </Td>
              {!props.summary && (
                <Td
                  to={`/make-bid/${bid.auction}/${bid.id}`}
                  datalabel="Date of bid"
                >
                  <div>
                    <div className="detail-icons date-icon">
                      {moment(bid.created_at).format(CONSTANTS.DATE_FORMAT)}
                    </div>
                    <div className="detail-icons time-icon">
                      {moment(bid.created_at).format(CONSTANTS.TIME_FORMAT)}
                    </div>
                  </div>
                </Td>
              )}
              {!props.summary && (
                <Td
                  to={`/make-bid/${bid.auction}/${bid.id}`}
                  datalabel="Bid status"
                >
                  <div>
                    <div
                      className={"status " + bid.state_display.toLowerCase()}
                    >
                      {bid.state_display}
                    </div>
                    {bid.extra_data.best_price && (
                      <div className="best-row-container">
                        <span className="best-offer-icon"></span>
                        <small>Best price bid</small>
                      </div>
                    )}
                    {bid.extra_data.best_time && (
                      <div className="best-row-container">
                        <span className="best-offer-icon"></span>
                        <small>Best lead time bid</small>
                      </div>
                    )}
                  </div>
                </Td>
              )}
              <td className="text-center">
                <LinkButton
                  to={`/make-bid/${bid.auction}/${bid.id}`}
                  className="d-block btn btn-orange mb-1 mx-auto"
                >
                  Bid details
                </LinkButton>
                {bid.auction_state_display.toLowerCase() === "approved" && (
                  <LinkButton
                    to={`/make-bid/${bid.auction}`}
                    className="d-block btn btn-orange mb-1 mx-auto"
                  >
                    Bid again
                  </LinkButton>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return renderResult();
}
