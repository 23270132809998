import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";

export class ToolTip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpened: !this.state.isOpened,
    });
  }

  render() {
    return (
      <Tooltip
        placement={this.props.placement}
        isOpen={this.state.isOpened}
        target={this.props.target}
        toggle={this.toggle}
        delay={this.props.delay}
        autohide={this.props.autohide}
        innerClassName={this.props.innerClassName}
        modifiers={{
          computeStyle: {
            gpuAcceleration: false,
          },
        }}
        className={"tooltip-container"}
        data-html="true"
      >
        {this.props.children}
      </Tooltip>
    );
  }
}

ToolTip.propTypes = {
  placement: PropTypes.string,
  target: PropTypes.string,
  delay: PropTypes.shape({ show: PropTypes.number, hide: PropTypes.number }),
  autohide: PropTypes.bool,
  innerClassName: PropTypes.string,
};

ToolTip.defaultProps = {
  placement: "right-start",
};

export default ToolTip;
