import React, { useState } from "react";
import * as CONSTANTS from "../common/Constants";
import MetaTags from "react-meta-tags";
import {
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import { useAuth } from "../context/auth";
import API from "../api/api";

export default function PasswordChangePage() {
  const { userInfo } = useAuth();
  const [errors, setErrors] = useState({});

  const isCustomer = () => {
    return userInfo.user_type === CONSTANTS.USER_TYPE.CUSTOMER;
  };

  const title =
    CONSTANTS.TITLES.PASSWORD_CHANGE +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;

  const confirmPassword = () => {
    let result = false;
    if (
      ((passwordChange.password && passwordChange.password.length > 0) ||
        (passwordChange.confirm_password &&
          passwordChange.confirm_password.length > 0)) &&
      passwordChange.password !== passwordChange.confirm_password
    ) {
      result = true;
    }

    return result;
  };

  const [passwordChange, setPasswordChange] = useState({
    current_password: "",
    password: "",
    confirm_password: "",
  });

  const onPasswordChangeHandler = (event) => {
    event.persist();
    setPasswordChange((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const savePassword = (event) => {
    event.preventDefault();

    API.post("users/password-change/", passwordChange)
      .then((result) => {
        toast.success(`Password successfully changed`, {});
        setPasswordChange({
          current_password: "",
          password: "",
          confirm_password: "",
        });
        setErrors({});
      })
      .catch((err) => {
        toast.error("Please correct the form errors.", {});
        if (err.response) {
          setErrors(err.response.data);
        }
      });
  };

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="pt-4">
        <h1 className="mb-4 subheadline text-center">Password change</h1>
        <div
          className={
            "box-container mw-600 " + (isCustomer() ? "orange" : "blue")
          }
        >
          <Form className="box" autoComplete="off" onSubmit={savePassword}>
            <FormGroup>
              <Label for="currentPassword">Current password</Label>
              <Input
                type="password"
                name="current_password"
                id="current_password"
                placeholder="Current password"
                autoComplete="new-password"
                onChange={onPasswordChangeHandler}
                value={passwordChange.current_password || ""}
                invalid={errors && errors.current_password}
              />
              {errors.current_password && (
                <FormFeedback>{errors.current_password}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="userPassword">Password</Label>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Your password"
                autoComplete="new-password"
                onChange={onPasswordChangeHandler}
                value={passwordChange.password || ""}
                invalid={
                  confirmPassword() ||
                  (errors && errors.password && errors.password.length > 0)
                }
              />
              <small>
                (Minimum 8 characters - can't be a commonly used password -
                can't be entirely numeric)
              </small>
              {confirmPassword() && (
                <FormFeedback>
                  The password fields are not the same!
                </FormFeedback>
              )}
              {errors.password && (
                <FormFeedback>{errors.password}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="userPassword">Confirm password</Label>
              <Input
                type="password"
                name="confirm_password"
                id="confirm_password"
                placeholder="Confirm password"
                autoComplete="new-password"
                onChange={onPasswordChangeHandler}
                value={passwordChange.confirm_password || ""}
                invalid={confirmPassword()}
              />
            </FormGroup>
            <Button
              className="btn btn-orange btn-sm mb-4"
              onClick={savePassword}
              type="submit"
            >
              Save password
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
}
