import React from "react";

import { Col, Row, Container } from "reactstrap";

export default function NoPageFound(props) {
  return (
    <Container className="mt-4 container-404">
      <Row className="justify-content-center align-items-center">
        <Col sm={6}>
          <h1>404</h1>
          <h2>Oops! Something went wrong</h2>
          <p>Sorry, we can't find the page you were looking for.</p>
        </Col>
        <Col sm={6}>
          <img src="./assets/img/404.png" alt="404" />
        </Col>
      </Row>
    </Container>
  );
}
