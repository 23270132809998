import React, { useState, useEffect } from "react";
import { Table, UncontrolledTooltip as Tooltip } from "reactstrap";
import LinkButton from "../common/LinkButton";
import { Link } from "react-router-dom";
import * as CONSTANTS from "../common/Constants";
import StructureImage from "../common/StructureImage";
import moment from "moment";
import { formatPrice } from "../common/Utilities";
import Td from "../common/ContentTag";

export default function OrderListSupplier(props) {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    setOrders(props.orders);
  }, [props.orders]);

  const renderResult = () => {
    if (orders.length <= 0) {
      return <div className="text-center subheadline blue">No order found</div>;
    } else {
      return (
        <React.Fragment>
          <Table>
            <thead>
              <tr>
                {!props.summary && <th className="text-center">Structure</th>}
                <th>Order name</th>
                <th>Status</th>
                {!props.summary && <th>Details</th>}
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={index}>
                  {!props.summary && (
                    <td data-label="Structures" className="structure-container">
                      {order.bid.auction_structures_filtered.length > 0 && (
                        <StructureImage
                          src={
                            order.bid.auction_structures_filtered[0].chemical_id
                          }
                        />
                      )}
                      {order.bid.auction_structures_filtered.length > 1 && (
                        <div className="structure-counter">
                          + {order.bid.auction_structures_filtered.length - 1}{" "}
                          other(s)
                        </div>
                      )}
                    </td>
                  )}
                  <Td to={`/order/${order.id}`} datalabel="Order name">
                    {order.order_cqr_name}
                  </Td>
                  <Td to={`/order/${order.id}`} datalabel="Status">
                    <div>
                      <div className={"status " + order.state_display}>
                        {order.state_display}
                      </div>
                      <div>
                        {"Updated: " +
                          moment(
                            order.state_updates.find(
                              (e) => (e.id = order.state)
                            ).date
                          ).format(CONSTANTS.DATE_FORMAT)}
                      </div>
                    </div>
                  </Td>
                  {!props.summary && (
                    <Td to={`/order/${order.id}`} datalabel="Details">
                      <div>
                        <div
                          className="detail-icons price-icon"
                          id={`bid-${order.bid.id}-price`}
                        >
                          {formatPrice(order.bid.total_price)}
                        </div>
                        <Tooltip
                          placement="left"
                          target={`bid-${order.bid.id}-price`}
                        >
                          Total price
                        </Tooltip>
                        <div
                          className="detail-icons delivery-icon"
                          id={`bid-${order.bid.id}-time`}
                        >
                          {order.bid.max_delivery_time + " week(s)"}
                        </div>
                        <Tooltip
                          placement="left"
                          target={`bid-${order.bid.id}-time`}
                        >
                          Lead time
                        </Tooltip>
                        <div
                          className="detail-icons purity-icon"
                          id={`bid-${order.bid.id}-purity`}
                        >
                          {order.bid.purity + "%"}
                        </div>
                        <Tooltip
                          placement="left"
                          target={`bid-${order.bid.id}-purity`}
                        >
                          Purity
                        </Tooltip>
                        <div
                          className="detail-icons num-compounds-icon"
                          id={`bid-${order.bid.id}-num`}
                        >
                          {order.bid.num_compounds}
                        </div>
                        <Tooltip
                          placement="left"
                          target={`bid-${order.bid.id}-num`}
                        >
                          Number of compounds
                        </Tooltip>
                        <div
                          className="detail-icons amount-icon"
                          id={`bid-${order.bid.id}-amount`}
                        >
                          {order.bid.amount + " " + order.bid.unit_display}
                        </div>
                        <Tooltip
                          placement="left"
                          target={`bid-${order.bid.id}-amount`}
                        >
                          Amount
                        </Tooltip>
                      </div>
                    </Td>
                  )}
                  <td>
                    <LinkButton
                      to={`/order/${order.id}`}
                      className="d-block btn-orange btn btn-secondary mb-1 mx-auto"
                    >
                      Update status
                    </LinkButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </React.Fragment>
      );
    }
  };

  return renderResult();
}
