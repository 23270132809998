import React, { useState, useEffect } from "react";
import { Container, Alert } from "reactstrap";
import API from "../api/api";
import * as CONSTANTS from "../common/Constants";
import MetaTags from "react-meta-tags";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export default function EmailChangeConfirmPage(props) {
  const [response, setResponse] = useState(null);
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const title =
    CONSTANTS.TITLES.EMAIL_CHANGE_VERIFY +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;

  useEffect(() => {
    const fetchData = async () => {
      API.post("users/email-change/verify/", {
        token: props.match.params.token,
      })
        .then((result) => {
          toast.success(`Your email address has been successfully changed.`, {});
          history.push("/profile");
        })
        .catch((error) => {
          if (error.response) {
            setErrors(error.response.data);
          }
        });
    };
    if (props.match.params.token) {
      fetchData();
    }
  }, [props.match.params.token]);

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="pt-4">
        <h1 className="mb-4 subheadline text-center">
          Email change verification
        </h1>
        <div className="box-container mw-500">
          {response && (
            <Alert className="mt-4" color="success">
              {response}
            </Alert>
          )}
          {errors && (errors.detail || errors.token) && (
            <Alert className="mt-4" color="danger">
              {errors.detail || errors.token}
            </Alert>
          )}
        </div>
      </Container>
    </div>
  );
}
