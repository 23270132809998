import React, { useState, useEffect } from "react";
import {
  Alert,
  Col,
  Row,
  Container,
  FormGroup,
  FormText,
  Input,
  CustomInput,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import API from "../api/api";
import { toast } from "react-toastify";
import LinkButton from "../common/LinkButton";
import * as moment from "moment";
import * as CONSTANTS from "../common/Constants";
import { useAuth } from "../context/auth";
import * as countryFile from "../assets/countries.json";
import { useHistory } from "react-router-dom";
import { getTimeLeft } from "../common/Utilities";
import InputField from "../common/InputField";
import { formatPrice } from "../common/Utilities";
import Loader from "../common/Loader";
import * as publicDomains from "../assets/popularDomains.json";
import SimpleTooltip from "../common/SimpleTooltip";
import ConfirmModal from "../common/ConfirmModal";

export default function ConfirmOrder(props) {
  const APIDomain = process.env.REACT_APP_API_DOMAIN;
  const APIBase = process.env.REACT_APP_API_BASE_URL;
  const history = useHistory();
  const { userInfo } = useAuth();
  const [errors, setErrors] = useState({});
  const [auction, setAuction] = useState();
  const [profile, setProfile] = useState();
  const [extraInfo, setExtraInfo] = useState({
    fedex_account_number: "",
    po_number: "",
  });
  // const [noData, setNoData] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({
    name: "",
    country: "",
    city: "",
    address_line: "",
    zip_code: "",
    state: "",
    type: CONSTANTS.ADDRESS_TYPE.SHIPPING,
  });
  const [billingAddress, setBillingAddress] = useState({
    name: "",
    country: "",
    city: "",
    address_line: "",
    zip_code: "",
    state: "",
    type: CONSTANTS.ADDRESS_TYPE.BILLING,
  });
  const [shippingContact, setShippingContact] = useState({
    contact_person: "",
    email: "",
    phone: "",
    type: CONSTANTS.COMPANY_TYPE.SHIPPING,
  });
  const [billingContact, setBillingContact] = useState({
    contact_person: "",
    email: "",
    phone: "",
    type: CONSTANTS.COMPANY_TYPE.BILLING,
  });
  const [countries, setCountries] = useState([]);
  const [paymentType, setPaymentType] = useState(
    CONSTANTS.PAYMENT_TYPE.IN_ADVANCE
  );
  const [paymentMode, setPaymentMode] = useState(
    CONSTANTS.PAYMENT_IN_ADVANCE_TYPE.CREDIT_CARD
  );
  const [termType, setTermType] = useState(1);
  const [file, setFile] = useState({});
  const [orderLoading, setOrderLoading] = useState(true);
  const [popularDomains, setPopularDomains] = useState([]);
  const [confirmOrderModal, setConfirmOrderModal] = useState(false);

  const hasDiscountedPrice = (bid) => {
    return bid.discounted_public_total_price !== bid.public_total_price;
  };

  function getDownloadQuoteURL(bidId) {
    return `${APIDomain}${APIBase}bids/${bidId}/get-quote-xlsx/`;
  }

  const isAllowedEmail = (email) => {
    let response = true;
    if (email && email.length) {
      var parts = email.split("@");
      if (parts.length === 2) {
        if (popularDomains.some((v) => parts[1].includes(v))) {
          response = false;
        }
      }
    }
    return response;
  };

  useEffect(() => {
    setPopularDomains(publicDomains.default);
    if (props.match.params.auctionId) {
      API.get(`auctions/${props.match.params.auctionId}/`)
        .then((res) => {
          setAuction(res.data);
        })
        .catch((err) => {
          // setNoData(true);
          toast.error("Error loading auction data", {});
          console.log("ERROR", err);
        })
        .finally(() => {
          setOrderLoading(false);
        });
      if (userInfo) {
        API.get(`customers/${userInfo.customer}/`)
          .then((result) => {
            setProfile(result.data);
            setExtraInfo((inputs) => ({
              ...inputs,
              fedex_account_number: result.data.fedex_account_number,
            }));
            const shipping = result.data.addresses.filter(
              (a) => a.type === CONSTANTS.ADDRESS_TYPE.SHIPPING
            );
            if (shipping.length > 0) {
              setShippingAddress(shipping[0]);
            }

            const billing = result.data.addresses.filter(
              (a) => a.type === CONSTANTS.ADDRESS_TYPE.BILLING
            );
            if (billing.length > 0) {
              setBillingAddress(billing[0]);
            }

            const shippingContact = result.data.contacts.filter(
              (a) => a.type === CONSTANTS.CONTACT_TYPE.SHIPPING
            );
            if (shippingContact.length > 0) {
              setShippingContact(shippingContact[0]);
            }

            const billingContact = result.data.contacts.filter(
              (a) => a.type === CONSTANTS.CONTACT_TYPE.BILLING
            );
            if (billingContact.length > 0) {
              setBillingContact(billingContact[0]);
            }
          })
          .catch((error) => {
            toast.error("Error loading user data", {});
            console.log("ERROR", error);
          })
          .finally(() => {
            // this.setState({loading: false})
          });
        setCountries(countryFile.default);
      }
    }
  }, [props.match.params.auctionId]);

  const onShippingAddressChangeHandler = (event) => {
    event.persist();
    setShippingAddress((address) => ({
      ...address,
      [event.target.name]: event.target.value,
    }));
  };

  const onBillingAddressChangeHandler = (event) => {
    event.persist();
    setBillingAddress((address) => ({
      ...address,
      [event.target.name]: event.target.value,
    }));
  };

  const onSetExtraInfoChangeHandler = (event) => {
    event.persist();
    setExtraInfo((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const onShippingContactChangeHandler = (event) => {
    event.persist();
    setShippingContact((contact) => ({
      ...contact,
      [event.target.name]: event.target.value,
    }));
  };

  const onBillingContactChangeHandler = (event) => {
    event.persist();
    setBillingContact((contact) => ({
      ...contact,
      [event.target.name]: event.target.value,
    }));
  };

  const requestPaymentAccount = () => {
    API.patch(`customers/${userInfo.customer}/`, {
      payment_account_state: CONSTANTS.PAYMENT_ACCOUNT_STATE.REQUESTED,
    })
      .then((res) => {
        toast.success("Credit Account request sent!", {
          autoClose: 5000,
        });
      })
      .catch((err) => {
        toast.error("Error at request.", {});
        // console.log("ERROR", err);
        if (err.response) {
          // console.log(err.response.data);
          setErrors(err.response.data);
        }
      });
  };

  const confirmOrder = () => {
    const canProceed =
      isAllowedEmail(billingContact.email) &&
      isAllowedEmail(shippingContact.email);
    if (canProceed) {
      const obj = {
        addresses: [shippingAddress, billingAddress],
        email: profile.email,
        phone: profile.phone,
        fedex_account_number: extraInfo.fedex_account_number,
        bid: auction.winner_bid?.id,
        contacts: [shippingContact, billingContact],
        payment_type: CONSTANTS.PAYMENT_TYPE.IN_ADVANCE,
        payment_in_advance_type:
          CONSTANTS.PAYMENT_IN_ADVANCE_TYPE.BANK_TRANSFER,
        po_number: extraInfo.po_number,
        po_file: file.id,
      };

      if (
        paymentType === CONSTANTS.PAYMENT_TYPE.WITH_ACCOUNT &&
        profile.payment_account_state ===
          CONSTANTS.PAYMENT_ACCOUNT_STATE.NOT_REQUESTED
      ) {
        requestPaymentAccount();
      }

      API.post(`customer-orders/`, obj)
        .then((res) => {
          toast.success(
            "Thank you for your SynthAgora Order. Shortly, the Mcule team will contact you with further informations.",
            {}
          );
          history.push("/my-orders");
        })
        .catch((err) => {
          toast.error("Error confirming order data", {});
          // console.log("ERROR", err);
          setErrors(err.response.data);
        });
    } else {
      if (!isAllowedEmail(billingContact.email)) {
        setErrors((error) => ({
          ...error,
          billingContact: {
            email: "Only company or university domains are allowed",
          },
        }));
      }
      if (!isAllowedEmail(shippingContact.email)) {
        setErrors((error) => ({
          ...error,
          shippingContact: {
            email: "Only company or university domains are allowed",
          },
        }));
      }
    }
  };

  const handlePOFileChange = (event) => {
    const formData = new FormData();
    formData.append("name", event.target.files[0].name);
    formData.append("file", event.target.files[0]);
    API.post(`files/po/`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((res) => {
        setFile(res.data);
      })
      .catch((err) => {
        console.log("ERROR", err);
        toast.error("File upload error.", {});
        if (err.response) {
          setErrors(err.response.data);
        }
      });
  };

  const toggleConfirmOrderModal = () => {
    setConfirmOrderModal(!confirmOrderModal);
  };

  return (
    <div className="grey-bg">
      <Container className="mt-4">
        {orderLoading || !profile ? (
          <Loader text="Loading offer" />
        ) : (
          <React.Fragment>
            <Row>
              <Col sm={6}>
                <ul className="breadcrumb">
                  <li>
                    <Link to={`/my-auctions`}>My auctions</Link>
                  </li>
                  <li>
                    <Link to={`/auction/${auction.id}`}>
                      Auction {auction.id}
                    </Link>
                  </li>
                  <li>Confirm order</li>
                </ul>
                <h1 className="subheadline">
                  Auction {auction.auction_name_full}
                </h1>
              </Col>
              <Col sm={6} className="text-right header-right">
                <div className="subheader">
                  <span className="subheader-label">
                    Time remaining until bid expiry:
                  </span>
                  <span className="subheader-value">
                    {getTimeLeft(
                      auction.winner_bid?.valid_until,
                      "Bid expired",
                      true
                    )}
                  </span>
                </div>
                <h3 className="subheadline">{auction.auction_name_full}</h3>
              </Col>
            </Row>
            <h2 className="subheadline">1. Accepted bid</h2>
            <div className="box-container orange">
              <div className="box">
                <Row>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for="bid_id">Bid ID</Label>
                      <Input
                        type="text"
                        name="bid_id"
                        id="bid_id"
                        value={auction.winner_bid?.bid_cqr_name}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for="price">Total price</Label>
                      <InputGroup>
                        {formatPrice(
                          hasDiscountedPrice(auction.winner_bid)
                            ? auction.winner_bid?.discounted_public_total_price
                            : auction.winner_bid?.public_total_price,
                          false,
                          "form-control disabled"
                        )}
                        <InputGroupAddon addonType="append">
                          <InputGroupText>USD</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for="purity">Quoted purity</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          name="purity"
                          id="purity"
                          value={auction.winner_bid?.purity}
                          disabled
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for="supplier">Supplier</Label>
                      <Input
                        type="text"
                        name="supplier"
                        id="supplier"
                        value={auction.winner_bid?.supplier_name}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for="lead_time">Lead time</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          name="lead_time"
                          id="lead_time"
                          value={auction.winner_bid?.public_delivery_time}
                          disabled
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>weeks</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for="num_compounds">Number of compounds</Label>
                      <Input
                        type="number"
                        name="num_compounds"
                        id="num_compounds"
                        value={auction.winner_bid?.num_compounds}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for="end_date">Expiry date of Bid</Label>
                      <Input
                        type="text"
                        name="end_date"
                        id="end_date"
                        value={moment(auction.winner_bid?.valid_until).format(
                          CONSTANTS.DATETIME_FORMAT
                        )}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for="amount">Quoted amount</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          name="amount"
                          id="amount"
                          value={auction.winner_bid?.amount}
                          disabled
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            {auction.winner_bid?.unit_display}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
            <Row className="mb-4 box-after-buttons">
              <Col sm={6} className="text-center">
                <a
                  className="btn btn-secondary mb-1 mx-auto"
                  href={getDownloadQuoteURL(auction.winner_bid?.id)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download quote
                </a>
              </Col>
              <Col sm={6} className="text-center">
                <LinkButton
                  to={`/track-auction/${props.match.params.auctionId}/bid/${auction.winner_bid?.id}`}
                  className="d-block btn btn-secondary mb-1 mx-auto"
                >
                  Go to bid detail
                </LinkButton>
              </Col>
            </Row>
            <h2 className="subheadline">
              2. Confirm billing & shipping details
            </h2>
            <div className="box-container orange">
              <div className="box">
                <Row>
                  <Col sm={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Billing address</div>
                      <div className="label-line"></div>
                    </div>
                    <FormGroup>
                      <Label for="billing_name">Address line 1</Label>
                      <Input
                        type="text"
                        name="name"
                        id="billing_name"
                        onChange={onBillingAddressChangeHandler}
                        value={billingAddress.name || ""}
                        invalid={
                          errors &&
                          errors.billingAddress &&
                          errors.billingAddress.name
                        }
                      />
                      {errors &&
                        errors.billingAddress &&
                        errors.billingAddress.name && (
                          <FormFeedback>
                            {errors.billingAddress.name}
                          </FormFeedback>
                        )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="billing_address_line">Address line 2</Label>
                      <Input
                        type="text"
                        name="address_line"
                        id="billing_address_line"
                        onChange={onBillingAddressChangeHandler}
                        value={billingAddress.address_line || ""}
                        invalid={
                          errors &&
                          errors.billingAddress &&
                          errors.billingAddress.address_line
                        }
                      />
                      {errors &&
                        errors.billingAddress &&
                        errors.billingAddress.address_line && (
                          <FormFeedback>
                            {errors.billingAddress.address_line}
                          </FormFeedback>
                        )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="billing_city">City/Region</Label>
                      <Input
                        type="text"
                        name="city"
                        id="billing_city"
                        onChange={onBillingAddressChangeHandler}
                        value={billingAddress.city || ""}
                        invalid={
                          errors &&
                          errors.billingAddress &&
                          errors.billingAddress.city
                        }
                      />
                      {errors &&
                        errors.billingAddress &&
                        errors.billingAddress.city && (
                          <FormFeedback>
                            {errors.billingAddress.city}
                          </FormFeedback>
                        )}
                    </FormGroup>
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="billing_state">State</Label>
                          <Input
                            type="text"
                            name="state"
                            id="billing_state"
                            onChange={onBillingAddressChangeHandler}
                            value={billingAddress.state || ""}
                            invalid={
                              errors &&
                              errors.billingAddress &&
                              errors.billingAddress.state
                            }
                          />
                          {errors &&
                            errors.billingAddress &&
                            errors.billingAddress.state && (
                              <FormFeedback>
                                {errors.billingAddress.state}
                              </FormFeedback>
                            )}
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="billing_zip_code">Zip code</Label>
                          <Input
                            type="text"
                            name="zip_code"
                            id="billing_zip_code"
                            onChange={onBillingAddressChangeHandler}
                            value={billingAddress.zip_code || ""}
                            invalid={
                              errors &&
                              errors.billingAddress &&
                              errors.billingAddress.zip_code
                            }
                          />
                          {errors &&
                            errors.billingAddress &&
                            errors.billingAddress.zip_code && (
                              <FormFeedback>
                                {errors.billingAddress.zip_code}
                              </FormFeedback>
                            )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="billing_country">Country</Label>
                      <Input
                        type="select"
                        name="country"
                        id="billing_country"
                        onChange={onBillingAddressChangeHandler}
                        value={billingAddress.country}
                        invalid={
                          errors &&
                          errors.billingAddress &&
                          errors.billingAddress.country
                        }
                      >
                        {countries.map((country, index) => (
                          <option key={country.iso} value={country.iso}>
                            {country.name}
                          </option>
                        ))}
                      </Input>
                      {errors &&
                        errors.billingAddress &&
                        errors.billingAddress.country && (
                          <FormFeedback>
                            {errors.billingAddress.country}
                          </FormFeedback>
                        )}
                    </FormGroup>
                    <div className="label-line-wrapper">
                      <div className="label-text">Billing contact</div>
                      <div className="label-line"></div>
                    </div>
                    <InputField
                      name="contact_person"
                      displayName="Billing contact person name"
                      type="text"
                      value={billingContact.contact_person}
                      onChangeHandler={onBillingContactChangeHandler}
                      required
                      invalidCondition={
                        errors.billingContact &&
                        errors.billingContact.contact_person
                      }
                      errorField={
                        errors.billingContact &&
                        errors.billingContact.contact_person
                      }
                    />
                    <InputField
                      name="email"
                      displayName="Billing contact email"
                      type="text"
                      value={billingContact.email}
                      onChangeHandler={onBillingContactChangeHandler}
                      required
                      invalidCondition={
                        errors.billingContact && errors.billingContact.email
                      }
                      errorField={
                        errors.billingContact && errors.billingContact.email
                      }
                    />
                    <InputField
                      name="phone"
                      displayName="Billing contact phone"
                      type="text"
                      value={billingContact.phone}
                      onChangeHandler={onBillingContactChangeHandler}
                      required
                      invalidCondition={
                        errors.billingContact && errors.billingContact.phone
                      }
                      errorField={
                        errors.billingContact && errors.billingContact.phone
                      }
                    />
                  </Col>
                  <Col sm={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Shipping address</div>
                      <div className="label-line"></div>
                      <span
                        className="label-line-button"
                        onClick={(event) =>
                          setShippingAddress((address) => ({
                            ...address,
                            ...billingAddress,
                            type: CONSTANTS.ADDRESS_TYPE.SHIPPING,
                          }))
                        }
                      >
                        Copy from billing address
                      </span>
                    </div>
                    <FormGroup>
                      <Label for="shipping_name">Address line 1</Label>
                      <Input
                        type="text"
                        name="name"
                        id="shipping_name"
                        onChange={onShippingAddressChangeHandler}
                        value={shippingAddress.name || ""}
                        invalid={
                          errors &&
                          errors.shippingAddress &&
                          errors.shippingAddress.name
                        }
                      />
                      {errors &&
                        errors.shippingAddress &&
                        errors.shippingAddress.name && (
                          <FormFeedback>
                            {errors.shippingAddress.name}
                          </FormFeedback>
                        )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="shipping_address_line">Address line 2</Label>
                      <Input
                        type="text"
                        name="address_line"
                        id="shipping_address_line"
                        onChange={onShippingAddressChangeHandler}
                        value={shippingAddress.address_line || ""}
                        invalid={
                          errors &&
                          errors.shippingAddress &&
                          errors.shippingAddress.address_line
                        }
                      />
                      {errors &&
                        errors.shippingAddress &&
                        errors.shippingAddress.address_line && (
                          <FormFeedback>
                            {errors.shippingAddress.address_line}
                          </FormFeedback>
                        )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="shipping_city">City/Region</Label>
                      <Input
                        type="text"
                        name="city"
                        id="shipping_city"
                        onChange={onShippingAddressChangeHandler}
                        value={shippingAddress.city || ""}
                        invalid={
                          errors &&
                          errors.shippingAddress &&
                          errors.shippingAddress.city
                        }
                      />
                      {errors &&
                        errors.shippingAddress &&
                        errors.shippingAddress.city && (
                          <FormFeedback>
                            {errors.shippingAddress.city}
                          </FormFeedback>
                        )}
                    </FormGroup>
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="shipping_state">State</Label>
                          <Input
                            type="text"
                            name="state"
                            id="shipping_state"
                            onChange={onShippingAddressChangeHandler}
                            value={shippingAddress.state || ""}
                            invalid={
                              errors &&
                              errors.shippingAddress &&
                              errors.shippingAddress.state
                            }
                          />
                          {errors &&
                            errors.shippingAddress &&
                            errors.shippingAddress.state && (
                              <FormFeedback>
                                {errors.shippingAddress.state}
                              </FormFeedback>
                            )}
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="shipping_zip_code">Zip code</Label>
                          <Input
                            type="text"
                            name="zip_code"
                            id="shipping_zip_code"
                            onChange={onShippingAddressChangeHandler}
                            value={shippingAddress.zip_code || ""}
                            invalid={
                              errors &&
                              errors.shippingAddress &&
                              errors.shippingAddress.zip_code
                            }
                          />
                          {errors &&
                            errors.shippingAddress &&
                            errors.shippingAddress.zip_code && (
                              <FormFeedback>
                                {errors.shippingAddress.zip_code}
                              </FormFeedback>
                            )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="shipping_country">Country</Label>
                      <Input
                        type="select"
                        name="country"
                        id="shipping_country"
                        onChange={onShippingAddressChangeHandler}
                        value={shippingAddress.country || ""}
                        invalid={
                          errors &&
                          errors.shippingAddress &&
                          errors.shippingAddress.country
                        }
                      >
                        {countries.map((country, index) => (
                          <option key={country.iso} value={country.iso}>
                            {country.name}
                          </option>
                        ))}
                      </Input>
                      {errors &&
                        errors.shippingAddress &&
                        errors.shippingAddress.country && (
                          <FormFeedback>
                            {errors.shippingAddress.country}
                          </FormFeedback>
                        )}
                    </FormGroup>
                    <div className="label-line-wrapper">
                      <div className="label-text">Shipping contact</div>
                      <div className="label-line"></div>
                      <span
                        className="label-line-button"
                        onClick={() =>
                          setShippingContact((contact) => ({
                            ...contact,
                            ...billingContact,
                            type: CONSTANTS.ADDRESS_TYPE.SHIPPING,
                          }))
                        }
                      >
                        Copy billing contact
                      </span>
                    </div>
                    <InputField
                      name="contact_person"
                      displayName="Shipping contact person name"
                      type="text"
                      value={shippingContact.contact_person}
                      onChangeHandler={onShippingContactChangeHandler}
                      required
                      invalidCondition={
                        errors.shippingContact &&
                        errors.shippingContact.contact_person
                      }
                      errorField={
                        errors.shippingContact &&
                        errors.shippingContact.contact_person
                      }
                    />
                    <InputField
                      name="email"
                      displayName="Shipping contact person email"
                      type="text"
                      value={shippingContact.email}
                      onChangeHandler={onShippingContactChangeHandler}
                      required
                      invalidCondition={
                        errors.shippingContact && errors.shippingContact.email
                      }
                      errorField={
                        errors.shippingContact && errors.shippingContact.email
                      }
                    />
                    <InputField
                      name="phone"
                      displayName="Shipping contact person phone"
                      type="text"
                      value={shippingContact.phone}
                      onChangeHandler={onShippingContactChangeHandler}
                      required
                      invalidCondition={
                        errors.shippingContact && errors.shippingContact.phone
                      }
                      errorField={
                        errors.shippingContact && errors.shippingContact.phone
                      }
                    />
                    <InputField
                      name="fedex_account_number"
                      displayName="FedEx account number"
                      type="text"
                      value={extraInfo.fedex_account_number}
                      onChangeHandler={onSetExtraInfoChangeHandler}
                      invalidCondition={errors.fedex_account_number}
                      errorField={errors.fedex_account_number}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <h2 className="subheadline">
              3. Payment{" "}
              <SimpleTooltip name="payment">
                Choose your preferred payment option. By default, you have to
                pay in advance. “Payment after fulfilment” is possible if you
                have a Credit account. Credit account can be requested here by
                selecting the “Payment after fulfilment” option or at your
                Profile. The selection of payment method (Credit card or Bank
                transfer) only required if you pay in advance
              </SimpleTooltip>
            </h2>
            <div className="box-container orange">
              <div className="box">
                <FormGroup>
                  <CustomInput
                    type="radio"
                    id="step1Radio1"
                    name="step1Radio"
                    label="Pay in advance"
                    checked={paymentType === CONSTANTS.PAYMENT_TYPE.IN_ADVANCE}
                    onChange={() =>
                      setPaymentType(CONSTANTS.PAYMENT_TYPE.IN_ADVANCE)
                    }
                  />
                  <CustomInput
                    type="radio"
                    id="step1Radio2"
                    name="step1Radio"
                    label={
                      profile && profile.payment_term
                        ? "Payment after fulfilment"
                        : "Request Credit account"
                    }
                    checked={
                      paymentType === CONSTANTS.PAYMENT_TYPE.WITH_ACCOUNT
                    }
                    onChange={() =>
                      setPaymentType(CONSTANTS.PAYMENT_TYPE.WITH_ACCOUNT)
                    }
                  />
                </FormGroup>
                {paymentType === CONSTANTS.PAYMENT_TYPE.IN_ADVANCE && (
                  <Row>
                    <Col sm={6}>
                      <div className="format-attribute">
                        <div className="attribute-label">Total price</div>
                        <div className="attribute-value">
                          {formatPrice(
                            hasDiscountedPrice(auction.winner_bid)
                              ? auction.winner_bid
                                  ?.discounted_public_total_price
                              : auction.winner_bid?.public_total_price
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <CustomInput
                          type="radio"
                          id="creditCard"
                          name="paymentMode"
                          label="Credit card"
                          checked={
                            paymentMode ===
                            CONSTANTS.PAYMENT_IN_ADVANCE_TYPE.CREDIT_CARD
                          }
                          onChange={() =>
                            setPaymentMode(
                              CONSTANTS.PAYMENT_IN_ADVANCE_TYPE.CREDIT_CARD
                            )
                          }
                        />
                        <CustomInput
                          type="radio"
                          id="BankTransfer"
                          name="paymentMode"
                          label="Bank transfer"
                          checked={
                            paymentMode ===
                            CONSTANTS.PAYMENT_IN_ADVANCE_TYPE.BANK_TRANSFER
                          }
                          onChange={() =>
                            setPaymentMode(
                              CONSTANTS.PAYMENT_IN_ADVANCE_TYPE.BANK_TRANSFER
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
            <h2 className="subheadline">4. Terms</h2>
            <div className="box-container orange">
              <div className="box">
                <div className="custom-radio custom-control">
                  <Input
                    type="radio"
                    id="termsRadio1"
                    name="termsRadio"
                    checked={termType === 1}
                    onChange={() => {
                      setTermType(1);
                    }}
                    className="custom-control-input"
                  />
                  <Label
                    check
                    className="custom-control-label"
                    for="termsRadio1"
                  >
                    Accept <Link to="/terms">SynthAgora's terms</Link>
                  </Label>
                </div>
                <div className="custom-radio custom-control">
                  <Input
                    type="radio"
                    id="termsRadio2"
                    name="termsRadi2"
                    checked={termType === 2}
                    onChange={() => {
                      setTermType(2);
                    }}
                    className="custom-control-input"
                  />
                  <Label
                    check
                    className="custom-control-label"
                    for="termsRadio2"
                  >
                    Upload your purchase order terms
                    <SimpleTooltip name="po_option">
                      If you upload a purchase order terms, it can lengthen the
                      duration of the order confirmation process. We have to
                      review and accept your PO.
                    </SimpleTooltip>
                  </Label>
                </div>

                <Col sm={6}>
                  <FormGroup>
                    <InputField
                      name="po_number"
                      displayName={termType === 2 ? "PO ID" : "Ref ID"}
                      type="text"
                      value={extraInfo.po_number}
                      onChangeHandler={onSetExtraInfoChangeHandler}
                      invalidCondition={errors && errors.po_number}
                      errorField={errors.po_number}
                      tooltip={
                        "Provide an ID serving as the primary identifier for your project besides the name, automatically generated by Mcule."
                      }
                    />
                  </FormGroup>
                  {termType === 2 && (
                    <>
                      <FormGroup>
                        <div className={errors?.file ? "is-invalid" : ""}>
                          <CustomInput
                            type="file"
                            label="Upload file"
                            name="files"
                            id="files"
                            onChange={handlePOFileChange}
                            invalid={errors?.file}
                          />
                        </div>
                        {errors?.file && (
                          <FormFeedback>{errors.file}</FormFeedback>
                        )}
                        <FormText color="muted">
                          Supported file formats: .gz, .zip, .pdf, .jpg, .png
                        </FormText>
                      </FormGroup>
                      {file.filename && (
                        <Alert color="success">{`Order is ready to be confirmed with ${file.filename} file`}</Alert>
                      )}
                    </>
                  )}
                </Col>
              </div>
            </div>

            <Button
              className="d-block ml-auto btn-orange"
              onClick={(event) => {
                toggleConfirmOrderModal();
              }}
              disabled={Object.keys(errors).length > 0}
            >
              Confirm order
            </Button>
            <ConfirmModal
              open={confirmOrderModal}
              toggle={toggleConfirmOrderModal}
              confirmed={confirmOrder}
              title="Confirmation needed"
              bodyText={`<p>You are about to order <b>Bid ${auction.winner_bid?.bid_cqr_name}</b><p>
              <p>If you confirm the order we will start to process your order and a payment obligation will arise.</p>
              `}
              cancelBtn="Cancel"
              cancelBtnClass="btn-danger"
              okBtn="Confirm"
              okBtnClass="btn-success"
            />
          </React.Fragment>
        )}
      </Container>
    </div>
  );
}
