import React, { useState } from "react";
import { Button, Form, Alert, Container } from "reactstrap";
import InputField from "../common/InputField";
import API from "../api/api";
import { toast } from "react-toastify";
import * as CONSTANTS from "../common/Constants";
import MetaTags from "react-meta-tags";
import Infobox from "../common/Infobox";

export default function ResetPasswordPage(props) {
  const title =
    CONSTANTS.TITLES.RESET_PASS +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState(null);
  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    API.post("/users/password-reset/", {
      email,
    })
      .then((res) => {
        setResponse(
          "We've emailed you instructions for setting your password. You should be receiving them shortly. If you don't receive an email, please make sure you've entered the email address you registered with, and check your spam folder."
        );
      })
      .catch((err) => {
        toast.error("Please correct the form errors.", {});

        if (err.response) {
          setErrors(err.response.data);
        }
      });
  };

  const onEmailChangeHandler = (event) => {
    event.persist();
    setEmail(event.target.value);
  };

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="pt-4">
        <h1 className="mb-4 subheadline text-center">Reset password</h1>
        <div className="box-container mw-500">
          <Form className="box" autoComplete="off" onSubmit={handleSubmit}>
            <Infobox>
              Enter your email address below and we'll email you instructions on
              how to reset your password.
            </Infobox>
            <InputField
              name="email"
              displayName="Email"
              type="text"
              value={email}
              onChangeHandler={onEmailChangeHandler}
              autoComplete="off"
              invalidCondition={(errors && errors.email) || errors.detail}
              errorField={(errors && errors.email) || errors.detail}
            />
            <div className="text-center">
              <Button color="primary" type="submit">
                Request new password
              </Button>
            </div>
            {response && (
              <Alert className="mt-4" color="success">
                {response}
              </Alert>
            )}
          </Form>
        </div>
      </Container>
    </div>
  );
}
