import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DOMPurify from "dompurify";

export default function ConfirmModal(props) {
  const sanitizer = DOMPurify.sanitize;
  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
      className="registration-form"
    >
      <div className="box-container orange">
        <div className="box">
          <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{ __html: sanitizer(props.bodyText) }}
            />
          </ModalBody>
          <ModalFooter>
            <Button className={props.cancelBtnClass} onClick={props.toggle}>
              {props.cancelBtn}
            </Button>
            <Button
              className={props.okBtnClass}
              onClick={() => {
                props.confirmed();
                props.toggle();
              }}
            >
              {props.okBtn}
            </Button>
          </ModalFooter>
        </div>
      </div>
    </Modal>
  );
}
