import React, { useState, useEffect, Suspense } from "react";
import {
  HashRouter as Router,
  useLocation,
  withRouter,
} from "react-router-dom";
import { AuthContext } from "./context/auth";
import { ModalContext } from "./context/modal";
import { ToastContainer } from "react-toastify";
import Routing from "./Routing";
import Navigation from "./views/Navigation";
import API from "./api/api";
import { NotificationContext } from "./context/notification";
import UsePageTracking from "./common/UsePageTracking";

function App(props) {
  const [initializing, setInitializing] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [loginModal, setLoginModal] = useState(false);
  const [registrationModal, setRegistrationModal] = useState(false);
  const [notifications, setNotifications] = useState(null);

  const setUser = () => {
    API.get("users/me/")
      .then((res) => {
        setUserInfo(res.data);
        getNotifications(1);
      })
      .catch((err) => {
        setUserInfo(null);
      })
      .finally(() => {
        setInitializing(false);
      });
  };

  const signOut = () => {
    return API.post("users/signout/")
      .then((res) => {
        setUserInfo(null);
      })
      .catch((err) => {
        window.location.reload(false);
      });
  };

  const setLoginModalClick = () => {
    setLoginModal(!loginModal);
  };

  const setRegistrationModalClick = () => {
    setRegistrationModal(!registrationModal);
  };

  const getNotifications = (page = 1) => {
    API.get(`notifications/notices/?page=${page}`)
      .then((res) => {
        setNotifications({ ...res.data, activePage: page });
      })
      .catch((err) => {
        setNotifications([]);
      })
      .finally(() => {
        setInitializing(false);
      });
  };

  useEffect(() => {
    setUser();
  }, []);

  function _ScrollToTop(props) {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return props.children;
  }

  const ScrollToTop = withRouter(_ScrollToTop);

  return initializing ? (
    <div>Loading...</div>
  ) : (
    <React.Fragment>
      <ToastContainer autoClose={5000} position="top-center" />
      <AuthContext.Provider value={{ userInfo, setUserInfo: setUser }}>
        <NotificationContext.Provider
          value={{
            notifications,
            updateNotifications: getNotifications,
          }}
        >
          <ModalContext.Provider
            value={{
              loginModal,
              setLoginModal: setLoginModalClick,
              registrationModal,
              setRegistrationModal: setRegistrationModalClick,
            }}
          >
            <Suspense fallback={<div></div>}>
              <Router>
                {process.env.REACT_APP_GTM_ID.length > 0 && <UsePageTracking />}
                <ScrollToTop>
                  <Navigation onSignOut={signOut} />
                  <Routing />
                </ScrollToTop>
              </Router>
            </Suspense>
          </ModalContext.Provider>
        </NotificationContext.Provider>
      </AuthContext.Provider>
    </React.Fragment>
  );
}

export default App;
