import React, { useState, useEffect } from "react";
import {
  Alert,
  Form,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  ButtonGroup,
  Badge,
  Table,
  FormFeedback,
  CustomInput,
  UncontrolledTooltip as Tooltip,
  Spinner,
} from "reactstrap";
import API from "../api/api";
import { useAuth } from "../context/auth";
import { toast } from "react-toastify";
import * as CONSTANTS from "../common/Constants";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import StructureImage from "../common/StructureImage";
import { round, getTimeLeft, isUndefined } from "../common/Utilities";
import LinkButton from "../common/LinkButton";
import { formatPrice, isValidFile } from "../common/Utilities";
import InputField from "../common/InputField";
import SimpleTooltip from "../common/SimpleTooltip";
import MetaTags from "react-meta-tags";
import CopyToClipboard from "../common/CopyToClipboard";
import ConfirmModal from "../common/ConfirmModal";
import { cloneDeep } from "lodash";

export default function BidDetail(props) {
  const [title, setTitle] = useState(
    CONSTANTS.TITLES.MAKE_BID +
      CONSTANTS.TITLES.DELIMITER +
      CONSTANTS.TITLES.POSTFIX
  );
  const APIDomain = process.env.REACT_APP_API_DOMAIN;
  const APIBase = process.env.REACT_APP_API_BASE_URL;
  const { userInfo } = useAuth();
  const history = useHistory();
  const [mode, setMode] = useState(CONSTANTS.MODE_CREATE);
  const [errors, setErrors] = useState([]);
  const [auction, setAuction] = useState({});
  const [bid, setBid] = useState({
    analytical_methods: [],
    files: [],
    state: 0,
  });
  const [tac, setTac] = useState(false);
  const [notPatentedCompounds, setNotPatentedCompounds] = useState(false);
  const [submitTry, setSubmitTry] = useState(false);

  const [allSelected, setAllSelected] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [logoFreeFile, setLogoFreeFile] = useState(false);

  const [files, setFiles] = useState([]);
  const [confirmDeleteFile, setConfirmDeleteFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const toggleDeleteFileModal = () => {
    setConfirmDeleteFile(!confirmDeleteFile);
  };

  const deleteBidFile = () => {
    API.delete(`files/bid/${selectedFile.id}/`)
      .then((res) => {
        toast.success("File deleted!", {});
        if (selectedFile.assigned_to) {
          const newFiles = bid.files.filter((f) => f.id !== selectedFile.id);
          setBid((inputs) => ({
            ...inputs,
            files: newFiles,
          }));
        } else {
          const newFiles = files.filter((f) => f.id !== selectedFile.id);
          setFiles(newFiles);
        }
      })
      .catch((err) => {
        toast.error("Error at deleting a file: " + err, {});
        console.log("ERROR", err);
      });
  };

  const handleFileChange = (event) => {
    if (!event.target.files[0]) {
      return
    }
    setIsFileUploading(true);
    const formData = new FormData();
    formData.append("name", event.target.files[0].name);
    formData.append("file", event.target.files[0]);
    API.post(`files/bid/`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((res) => {
        setFiles((inputs) => [...inputs, res.data]);
        setErrors((errors) => ({
          ...errors,
          files: undefined,
        }));
      })
      .catch((err) => {
        toast.error("Error at file upload.", {});
        if (err.response?.data) {
          setErrors((errors) => ({
            ...errors,
            files: err.response.data.file[0],
          }));
        }
      })
      .finally(() => {
        setIsFileUploading(false);
      });
  };

  const handleSubmit = (event) => {
    setSubmitTry(true);
    event.preventDefault();
    if (!isEditMode()) {
      addBid(bid, CONSTANTS.BID_STATE.SUBMITTED);
    }
    if (isEditMode()) {
      updateBid(bid, CONSTANTS.BID_STATE.SUBMITTED);
    }
  };

  const saveAsDraft = () => {
    addBid(bid, CONSTANTS.AUCTION_STATE.DRAFT);
  };

  /* Adding new bid */
  function addBid(bid, state) {
    setErrors([]);
    if (files.length > 0 && !logoFreeFile) {
      setErrors((errors) => ({
        ...errors,
        logoFreeFile: !logoFreeFile
          ? "Please check this box if you want to proceed"
          : null,
      }));
      toast.error("Please correct the form errors.", {});
    } else if (
      state !== CONSTANTS.BID_STATE.DRAFT &&
      (!tac || !notPatentedCompounds)
    ) {
      setErrors((errors) => ({
        ...errors,
        tac: !tac ? "Please check this box if you want to proceed" : null,
        notPatentedCompounds: !notPatentedCompounds
          ? "Please check this box if you want to proceed"
          : null,
      }));
    } else {
      // deep copy the bid for sending modified data to backend
      var inputs = cloneDeep(bid);
      inputs.state = state;
      inputs.auction = auction.id;
      console.log('INPUTS', inputs)
      let tempStructures = inputs.structures
        .filter((item) => item.checked)
        .map((a) => a.unique_id);
      inputs.structures = tempStructures;
      inputs.files = [...bid.files, ...files].map(function (file) {
        return file.id;
      });
      setWaiting(true);
      API.post("bids/", inputs)
        .then((res) => {
          history.push("/");
          const feedback =
            state === CONSTANTS.BID_STATE.DRAFT
              ? "Bid saved as draft"
              : "Bid created successfully!";
          toast.success(feedback, {});
        })
        .catch((err) => {
          toast.error("Please correct the form errors.", {});
          // console.log("ERROR", err);
          if (err.response) {
            setErrors(err.response.data);
          }
        })
        .finally(() => {
          setWaiting(false);
        });
    }
  }

  /* Updating existing bid */
  function updateBid(bid, state) {
    setErrors([]);
    if (files.length > 0 && !logoFreeFile) {
      setErrors((errors) => ({
        ...errors,
        logoFreeFile: !logoFreeFile
          ? "Please check this box if You want to proceed"
          : null,
      }));
      toast.error("Please correct the form errors.", {});
    } else {
      // deep copy the bid for sending modified data to backend
      var inputs = JSON.parse(JSON.stringify(bid));
      inputs.state = state;
      let tempStructures = inputs.structures
        .filter((item) => item.checked)
        .map((a) => a.unique_id);
      inputs.structures = tempStructures;
      inputs.files = [...bid.files, ...files].map(function (file) {
        return file.id;
      });
      setWaiting(true);
      API.put(`bids/${inputs.id}/`, inputs)
        .then((res) => {
          toast.success("Bid updated successfully!", {});
          history.push("/");
        })
        .catch((err) => {
          toast.error("Please correct the form errors.", {});
          // console.log("ERROR", err);
          if (err.response) {
            setErrors(err.response.data);
          }
        })
        .finally(() => {
          setWaiting(false);
        });
    }
  }

  /* Deleting existing bid */
  function deleteBid(bid) {
    if (bid.state === CONSTANTS.BID_STATE.SUBMITTED) {
      API.patch(`bids/${bid.id}/`, {
        state: CONSTANTS.BID_STATE.WITHDRAWN,
      })
        .then((res) => {
          toast.success("Bid cancelled successfully!", {});
          history.push("/");
        })
        .catch((err) => {
          toast.error("Error cancelling the bid.", {});
          console.log("ERROR", err);
        });
    }
    if (bid.state === CONSTANTS.BID_STATE.DRAFT) {
      API.delete(`bids/${bid.id}/`, bid)
        .then((res) => {
          toast.success("Bid deleted successfully!", {});
          history.push("/");
        })
        .catch((err) => {
          toast.error("Error deleting the bid.", {});
          console.log("ERROR", err);
        });
    }
  }

  function isDisabledField() {
    let response = false;
    if (
      mode !== CONSTANTS.MODE_CREATE &&
      bid &&
      bid.state !== CONSTANTS.BID_STATE.DRAFT
    ) {
      response = true;
    }
    return response;
  }

  const isEditMode = () => {
    return mode === CONSTANTS.MODE_EDIT;
  };

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.type === "checkbox") {
      if (event.target.name === "own_qc") {
        setBid((inputs) => ({
          ...inputs,
          own_qc: !bid.own_qc,
        }));
      } else {
        const tempStructures = bid.structures.map((s) =>
          s.unique_id === event.target.name ? { ...s, checked: !s.checked } : s
        );
        const numCompounds = tempStructures.filter((e) => e.checked).length;
        setBid((inputs) => ({
          ...inputs,
          num_compounds: numCompounds,
          structures: tempStructures,
        }));
        if (numCompounds === bid.structures.length) {
          setAllSelected(true);
        } else {
          setAllSelected(false);
        }
      }
    } else if (event.target.name === "price") {
      const compound = isNaN(event.target.value)
        ? parseFloat(event.target.value.split(",").join(""))
        : parseFloat(event.target.value);
      setBid((inputs) => ({
        ...inputs,
        [event.target.name]: compound || 0,
      }));
    } else if (event.target.name === "delivery_cost") {
      const delivery = isNaN(event.target.value)
        ? parseFloat(event.target.value.split(",").join(""))
        : parseFloat(event.target.value);
      setBid((inputs) => ({
        ...inputs,
        [event.target.name]: delivery || 0,
      }));
    } else {
      setBid((inputs) => ({
        ...inputs,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleRequirements = (name, value) => {
    setBid((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  const handleAnalyticalMethod = (value) => {
    let tempMethods = bid.analytical_methods;
    const index = tempMethods.indexOf(value);
    if (index < 0) {
      tempMethods.push(value);
    } else {
      tempMethods.splice(index, 1);
    }

    setBid((inputs) => ({
      ...inputs,
      analytical_methods: tempMethods,
    }));
  };

  const triggerAllSelected = () => {
    setAllSelected(!allSelected);
    const tempStructures = bid.structures.map((s) => ({
      ...s,
      checked: !allSelected,
    }));
    const numCompounds = tempStructures.filter((e) => e.checked).length;
    setBid((inputs) => ({
      ...inputs,
      num_compounds: numCompounds,
      structures: tempStructures,
    }));
  };

  function handleTacChange(event) {
    setSubmitTry(false);
    setTac(!tac);
  }

  function handleNotPatentedCompoundsChange(event) {
    setSubmitTry(false);
    setNotPatentedCompounds(!notPatentedCompounds);
  }

  const renderButtons = () => {
    return (
      <React.Fragment>
        {mode === CONSTANTS.MODE_CREATE && (
          <React.Fragment>
            <Col sm={4} className="text-center"></Col>
            <Col sm={4} className="text-center">
              <Button
                color="secondary"
                onClick={() => {
                  saveAsDraft();
                }}
                disabled={waiting}
              >
                Save as draft
              </Button>
            </Col>
            <Col sm={4} className="text-center">
              <Button disabled={waiting} color="primary">
                Submit bid
              </Button>
            </Col>
          </React.Fragment>
        )}
        {mode === CONSTANTS.MODE_EDIT && (
          <React.Fragment>
            <Col sm={4} className="text-center">
              {bid.state === CONSTANTS.BID_STATE.DRAFT && (
                <Button
                  color="danger"
                  onClick={() => {
                    deleteBid(bid);
                  }}
                >
                  <span>Discard braft</span>
                </Button>
              )}
              {bid.state === CONSTANTS.BID_STATE.SUBMITTED &&
                auction.state < CONSTANTS.AUCTION_STATE.ACCEPTED && (
                  <Button
                    color="danger"
                    onClick={() => {
                      deleteBid(bid);
                    }}
                  >
                    <span>Withdraw bid</span>
                  </Button>
                )}
            </Col>
            <Col sm={4} className="text-center">
              {bid.state === CONSTANTS.AUCTION_STATE.DRAFT && (
                <Button
                  color="secondary"
                  onClick={() => {
                    updateBid(bid, CONSTANTS.AUCTION_STATE.DRAFT);
                  }}
                  disabled={waiting}
                >
                  Save as draft
                </Button>
              )}
            </Col>
            <Col sm={4} className="text-center">
              {bid.state === CONSTANTS.AUCTION_STATE.DRAFT && (
                <Button disabled={waiting} color="primary">
                  Submit bid
                </Button>
              )}
            </Col>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const getProfile = () => {
    API.get(`suppliers/${userInfo.supplier}/`)
      .then((result) => {
        setBid((inputs) => ({
          ...inputs,
          delivery_cost: result.data.delivery_cost,
        }));
      })
      .catch((error) => {
        toast.error("Error loading user data", {});
        console.log("ERROR", error);
      });
  };

  useEffect(() => {
    if (props.match.params.auctionId) {
      if (props.match.params.bidId) {
        setMode(CONSTANTS.MODE_EDIT);
        setTitle(
          CONSTANTS.TITLES.EDIT_BID +
            CONSTANTS.TITLES.DELIMITER +
            CONSTANTS.TITLES.POSTFIX
        );
      } else {
        setMode(CONSTANTS.MODE_CREATE);
        setTitle(
          CONSTANTS.TITLES.MAKE_BID +
            CONSTANTS.TITLES.DELIMITER +
            CONSTANTS.TITLES.POSTFIX
        );
      }
      setNotFound(false);
      API.get(`auctions/${props.match.params.auctionId}/`)
        .then((auctionResult) => {
          if (props.match.params.bidId) {
            setAuction(auctionResult.data);
            API.get(`bids/${props.match.params.bidId}/`)
              .then((bidResult) => {
                let tempStructures = auctionResult.data.structures;

                tempStructures.map((item, index) => {
                  return (item.checked =
                    bidResult.data.auction_structures_filtered.some(
                      (bidItem) => bidItem.unique_id === item.unique_id
                    ));
                });
                setBid(bidResult.data);
                setBid((inputs) => ({
                  ...inputs,
                  structures: tempStructures,
                }));
                if (!bidResult.data.delivery_cost) {
                  getProfile();
                }
                if (bidResult.data.state >= CONSTANTS.BID_STATE.SUBMITTED) {
                  setTac(true);
                  setNotPatentedCompounds(true);
                }
              })
              .catch((err) => {
                toast.error("Error loading bid data.", {});
                console.log("ERROR", err);
              })
              .finally(() => {
                // this.setState({loading: false})
              });
          } else {
            auctionResult.data.structures.map((item, index) => {
              item.checked = false;
              return true;
            });
            setAuction(auctionResult.data);
            setBid((inputs) => ({
              ...inputs,
              structures: auctionResult.data.structures,
              total_price: 0,
              price: 0,
              amount: auctionResult.data.amount,
              unit: auctionResult.data.unit,
              purity: auctionResult.data.target_purity,
              max_delivery_time: 4,
            }));
            getProfile();
          }
        })
        .catch((err) => {
          toast.error("Error loading auction data", {});
          console.log("ERROR", err);
          if (err.response && err.response.status) {
            setNotFound(true);
          }
        })
        .finally(() => {
          // this.setState({loading: false})
        });
    }
  }, [props.match.params.auctionId, props.match.params.bidId]);

  const renderPage = () => {
    return (
      <React.Fragment>
        {bid ? (
          <React.Fragment>
            <Row>
              <Col sm={6}>
                {mode === CONSTANTS.MODE_EDIT && (
                  <React.Fragment>
                    <ul className="breadcrumb">
                      <li>
                        <Link to={`/my-bids`}>My bids</Link>
                      </li>
                      <li>{bid.bid_cqr_name}</li>
                    </ul>
                    <h1 className="subheadline">
                      {isDisabledField() ? (
                        <span>{bid.bid_cqr_name} details</span>
                      ) : (
                        <span>Editing {bid.bid_cqr_name}</span>
                      )}
                    </h1>
                  </React.Fragment>
                )}
                {mode === CONSTANTS.MODE_CREATE && (
                  <React.Fragment>
                    <ul className="breadcrumb">
                      <li>
                        <Link to={`/open-auctions`}>Open auctions</Link>
                      </li>
                      <li>Auction {auction.cqr_name}</li>
                    </ul>
                    <h1 className="subheadline">Make a bid</h1>
                  </React.Fragment>
                )}
              </Col>
              <Col sm={6} className="text-right">
                <h3 className="subheadline">{auction.auction_name_full}</h3>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="subheader border-left-orange">
                  {props.match.params.bidId && (
                    <div>
                      <span className="subheader-label">Bid status:</span>
                      {bid.state_display && (
                        <span
                          className={
                            "status " + bid.state_display.toLowerCase()
                          }
                        >
                          {bid.state_display}
                        </span>
                      )}
                    </div>
                  )}
                  <div>
                    <span className="subheader-label">
                      Auction closing date:
                    </span>
                    <span className="subheader-value">
                      {moment(auction.valid_until).format(
                        CONSTANTS.DATETIME_FORMAT
                      )}
                    </span>
                  </div>
                  <div>
                    <span className="subheader-label pl-2">
                      Time remaining:
                    </span>
                    <span className="subheader-value countdown">
                      {getTimeLeft(auction.valid_until, "Auction closed", true)}
                    </span>
                  </div>
                  <div>
                    {auction.close_immediately ? (
                      <small>
                        This auction could be closed anytime by the customer.
                      </small>
                    ) : (
                      <small>
                        This auction could be shortened anytime by the customer.
                      </small>
                    )}
                  </div>
                  {auction.structures && (
                    <div>
                      <span className="subheader-label">
                        Number of compound(s):
                      </span>
                      <span className="subheader-value">
                        {auction.structures.length}{" "}
                        <a
                          href={`${APIDomain}${APIBase}auctions/${auction.id}/structures-sdf/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mb-1"
                        >
                          <i className="far fa-file"></i>{" "}
                          <span>Download structures SDF</span>
                        </a>
                      </span>
                    </div>
                  )}
                  {auction.partial_offer > 1 && (
                    <div className="align-items-center mb-2">
                      {`Offers must include ${auction.partial_offer_type_display} ${auction.partial_offer} requested compound(s).`}
                    </div>
                  )}
                  {props.match.params.bidId && (
                    <div>
                      <span className="subheader-label">
                        Bid expiry date:
                        <SimpleTooltip name="expiry_date">
                          Your offer is valid until the bid expiry date. You can
                          receive the request for the order (under the
                          conditions specified in your offer) until this date.
                          In the case of order request you are obliged to
                          fulfill the order in accordance with the conditions
                          specified in your offer. If the customer wants to
                          order an expired bid, they have to require a new quote
                          from you. The bids expire automatically 50 days from
                          the auction submission.
                        </SimpleTooltip>
                      </span>
                      <span className="subheader-value">
                        {moment(bid.valid_until_for_supplier).format(
                          CONSTANTS.DATETIME_FORMAT
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </Col>
              <Col sm={6} className="text-right">
                <div className="mb-2">
                  <LinkButton
                    to={`/my-bids/${auction.id}`}
                    className="btn btn-orange"
                  >
                    Previous bids
                  </LinkButton>
                </div>
                <div>
                  {bid.state === CONSTANTS.AUCTION_STATE.DRAFT && (
                    <Button onClick={handleSubmit} className="btn btn-orange">
                      Submit bid
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col sm={12}>
                <div className="subheader border-left-orange">
                  {auction.bids && auction.bids.length > 0 && (
                    <div>
                      <span className="subheader-label">
                        You have {auction.bids.length} previous bid(s) for this
                        auction. Check them in{" "}
                        <Link to={`/my-bids`}>My bids</Link>.
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            {(auction.best_price_bid || auction.best_delivery_time_bid) && (
              <div className="mb-4">
                <h2 className="subheadline">
                  Current best offers{" "}
                  <SimpleTooltip name="best_offer">
                    Some details of the best offer regarding total price and
                    lead time are shown here.
                  </SimpleTooltip>
                </h2>
                <div className="box-container orange">
                  <div className="box">
                    <Table className="best-offer-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-center">Total price (USD)</th>
                          <th className="text-center">Lead time (weeks)</th>
                          <th className="text-center">Number of compounds</th>
                          <th className="text-center">Purity</th>
                          <th className="text-center">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {auction.best_price_bid && (
                          <tr key="best-price-offer">
                            <td className="text-center position-relative">
                              <div
                                className="detail-icons price-icon"
                                id={`best-bid-price`}
                              ></div>
                              <Tooltip
                                placement="left"
                                target={`best-bid-price`}
                              >
                                Best price bid
                              </Tooltip>
                              {auction.best_price_bid.id === bid.id && (
                                <div className="best-offer-icon">My bid</div>
                              )}
                            </td>
                            <td
                              className="text-center highlight-orange"
                              data-label="Total price (USD)"
                            >
                              {formatPrice(auction.best_price_bid.total_price)}
                            </td>
                            <td
                              className="text-center"
                              data-label="Lead time (weeks)"
                            >
                              {auction.best_price_bid.max_delivery_time}
                            </td>
                            <td
                              className="text-center"
                              data-label="Number of compounds"
                            >
                              {auction.best_price_bid.num_compounds}
                            </td>
                            <td className="text-center" data-label="Purity">
                              {`${auction.best_price_bid.purity}%`}
                            </td>
                            <td className="text-center" data-label="Amount">
                              {`${auction.best_price_bid.amount} ${auction.best_price_bid.unit_display}`}
                            </td>
                          </tr>
                        )}
                        {auction.best_delivery_time_bid && (
                          <tr key="best-delivery-time-offer">
                            <td className="text-center position-relative">
                              <div
                                className="detail-icons delivery-icon"
                                id={`best-bid-time`}
                              ></div>
                              <Tooltip
                                placement="left"
                                target={`best-bid-time`}
                              >
                                Best lead time bid
                              </Tooltip>
                              {auction.best_delivery_time_bid.id === bid.id && (
                                <div className="best-offer-icon">My bid</div>
                              )}
                            </td>
                            <td
                              className="text-center"
                              data-label="Total price (USD)"
                            >
                              {formatPrice(
                                auction.best_delivery_time_bid.total_price
                              )}
                            </td>
                            <td
                              className="text-center highlight-orange"
                              data-label="Lead time (weeks)"
                            >
                              {auction.best_delivery_time_bid.max_delivery_time}
                            </td>
                            <td
                              className="text-center"
                              data-label="Number of compounds"
                            >
                              {auction.best_delivery_time_bid.num_compounds}
                            </td>
                            <td className="text-center" data-label="Purity">
                              {`${auction.best_delivery_time_bid.purity}%`}
                            </td>
                            <td className="text-center" data-label="Amount">
                              {`${auction.best_delivery_time_bid.amount} ${auction.best_delivery_time_bid.unit_display}`}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            )}
            {errors && errors.non_field_errors && (
              <Alert color="danger" className="mb-2">
                <div>{errors.detail}</div>
                <div>{errors.name}</div>
                <div>{errors.non_field_errors}</div>
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <h2 className="subheadline">
                {!isDisabledField() && <span>1. </span>}General compound details
              </h2>
              <div className="box-container orange">
                <div className="box">
                  <Row>
                    <Col md={12} lg={6}>
                      <div className="label-line-wrapper">
                        <div className="label-text">Requested</div>
                        <div className="label-line"></div>
                      </div>
                      <div className="bid-container">
                        <FormGroup>
                          <Label for="request_coverage">
                            Number of compounds
                          </Label>
                          <InputGroup>
                            {auction.structures && (
                              <Input
                                type="text"
                                name="coverage"
                                id="request_coverage"
                                value={
                                  auction.partial_offer_type_display +
                                  " " +
                                  auction.partial_offer
                                }
                                disabled
                              />
                            )}
                          </InputGroup>
                        </FormGroup>
                        <Table>
                          <thead>
                            <tr>
                              <th className="column-id">#</th>
                              <th className="column-structure">Structure</th>
                              <th>Chemical Id</th>
                            </tr>
                          </thead>
                          <tbody>
                            {auction.structures &&
                              auction.structures.map((item, index) => (
                                <tr key={index}>
                                  <td className="text-center">{index + 1}</td>
                                  <td className="structure-container">
                                    <StructureImage src={item.chemical_id} />
                                    <div>{item.unique_id}</div>
                                  </td>
                                  <td>{item.chemical_id}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                        <FormGroup>
                          <Label for="delivery_deadline">
                            Target delivery date
                          </Label>
                          <InputGroup>
                            <Input
                              type="text"
                              name="delivery_deadline"
                              id="delivery_deadline"
                              value={
                                auction.delivery_deadline_for_supplier || ""
                              }
                              disabled
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="request_amount">Requested amount</Label>
                          <InputGroup>
                            <Input
                              type="number"
                              name="amount"
                              id="request_amount"
                              value={auction.amount || 0}
                              disabled
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                {auction.unit_display}
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="target_purity">Requested purity</Label>
                          <InputGroup>
                            <Input
                              type="number"
                              name="target_purity"
                              id="target_purity"
                              value={auction.target_purity || 0}
                              disabled
                              required
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                        <InputField
                          name="target_price"
                          displayName="Target budget"
                          type="price"
                          value={auction.target_price_for_supplier}
                          disabled
                          append="USD"
                        />
                      </div>
                    </Col>
                    <Col md={12} lg={6}>
                      <div className="label-line-wrapper">
                        <div className="label-text">Your offer</div>
                        <SimpleTooltip name="your_offer">
                          Select the compounds included in your offer or just
                          provide the number of them below at “Number of
                          compounds” section.
                        </SimpleTooltip>
                        <div className="label-line"></div>
                      </div>
                      <div className="bid-container bid-request">
                        <FormGroup>
                          <Label for="num_compounds">Number of compounds</Label>
                          <SimpleTooltip name="num_compounds">
                            If you did not specify the offered compounds by
                            selecting them from the table above, then provide
                            the number of compounds included in your offer
                          </SimpleTooltip>
                          {auction && auction.structures && (
                            <Input
                              type="number"
                              name="num_compounds"
                              id="num_compounds"
                              onChange={handleInputChange}
                              value={bid.num_compounds || 0}
                              disabled={
                                isDisabledField() ||
                                !auction.partial_offer ||
                                auction.partial_offer_type ===
                                  CONSTANTS.PARTIAL_OFFER_TYPE.EXACT
                              }
                              min={0}
                              max={auction.structures.length}
                              invalid={errors.num_compounds}
                            />
                          )}
                          {errors.num_compounds && (
                            <FormFeedback>{errors.num_compounds}</FormFeedback>
                          )}
                        </FormGroup>
                        <Table>
                          <thead>
                            <tr>
                              <th className="column-id">
                                {!isEditMode() && bid.structures ? (
                                  <Input
                                    type="checkbox"
                                    onChange={() => triggerAllSelected()}
                                    checked={allSelected || false}
                                    disabled={isDisabledField()}
                                  />
                                ) : (
                                  "#"
                                )}
                              </th>
                              <th className="column-structure">Structure</th>
                              <th>Chemical ID</th>
                            </tr>
                          </thead>
                          <tbody>
                            {bid.structures &&
                              bid.structures.map((item, index) => (
                                <tr key={index}>
                                  <td className="text-center">
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          name={item.unique_id}
                                          id={item.unique_id}
                                          onChange={handleInputChange}
                                          checked={item.checked || false}
                                          disabled={isDisabledField()}
                                        />
                                      </Label>
                                    </FormGroup>
                                  </td>
                                  <td className="structure-container">
                                    <StructureImage src={item.chemical_id} />
                                    <div>{item.unique_id}</div>
                                  </td>
                                  <td>
                                    {item.chemical_id}
                                    <CopyToClipboard text={item.chemical_id} />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                        {errors.structures && (
                          <Alert color="danger">Structures error: {JSON.stringify(errors.structures)}</Alert>
                        )}
                        <InputField
                          name="max_delivery_time"
                          displayName="Quoted lead time"
                          type="number"
                          value={bid.max_delivery_time}
                          min={0}
                          onChangeHandler={handleInputChange}
                          disabled={isDisabledField()}
                          invalidCondition={errors.max_delivery_time}
                          errorField={
                            errors && errors.max_delivery_time
                              ? errors.max_delivery_time
                              : ""
                          }
                          append="weeks"
                          required
                        />
                        <FormGroup>
                          <Label for="amount">Quoted amount*</Label>
                          <SimpleTooltip name="amount">
                            The quoted amount indicated here applies to all
                            quoted compounds.
                          </SimpleTooltip>
                          <InputGroup>
                            <Input
                              type="number"
                              name="amount"
                              id="amount"
                              onChange={handleInputChange}
                              value={bid.amount || 0}
                              disabled={isDisabledField()}
                              invalid={errors.amount}
                              required
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText className="no-padding">
                                <Input
                                  type="select"
                                  name="unit"
                                  id="unit"
                                  className="no-padding"
                                  onChange={handleInputChange}
                                  value={bid.unit}
                                  disabled={isDisabledField()}
                                >
                                  <option value={CONSTANTS.AMOUNT_UNIT.MG}>
                                    mg
                                  </option>
                                  <option value={CONSTANTS.AMOUNT_UNIT.G}>
                                    g
                                  </option>
                                  <option value={CONSTANTS.AMOUNT_UNIT.KG}>
                                    kg
                                  </option>
                                </Input>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {errors.amount && (
                            <FormFeedback>{errors.amount}</FormFeedback>
                          )}
                        </FormGroup>
                        <InputField
                          name="purity"
                          displayName="Purity"
                          type="number"
                          value={bid.purity}
                          min={0}
                          onChangeHandler={handleInputChange}
                          disabled={isDisabledField()}
                          invalidCondition={errors.purity}
                          errorField={
                            errors && errors.purity ? errors.purity : ""
                          }
                          append="%"
                          required
                          tooltip={
                            "The purity indicated here applies to all quoted compounds."
                          }
                        />
                        <FormGroup>
                          <div>
                            <Label for="total_price">Total price</Label>
                          </div>
                          <InputGroup>
                            {formatPrice(
                              bid.total_price ||
                                round(
                                  bid.price +
                                    //bid.customs_clearance +
                                    bid.delivery_cost,
                                  2
                                ),
                              false,
                              "form-control disabled"
                            )}
                            <InputGroupAddon addonType="append">
                              <InputGroupText>USD</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                        <InputField
                          name="price"
                          displayName="Total compound price"
                          type="price"
                          value={bid.price}
                          min={0}
                          onChangeHandler={handleInputChange}
                          disabled={isDisabledField()}
                          invalidCondition={errors.price}
                          errorField={
                            errors && errors.price ? errors.price : ""
                          }
                          append="USD"
                          required
                        />
                        <InputField
                          name="delivery_cost"
                          displayName="Shipping and handling fee [to Mcule, Hungary]"
                          type="price"
                          value={bid.delivery_cost}
                          min={0}
                          onChangeHandler={handleInputChange}
                          disabled={isDisabledField()}
                          invalidCondition={errors.delivery_cost}
                          errorField={
                            errors && errors.delivery_cost
                              ? errors.delivery_cost
                              : ""
                          }
                          append="USD"
                        />
                        {/* <InputField
                      name="custom_clearance"
                      displayName="Custom clearance"
                      type="price"
                      value={
                        bid.customs_clearance ||
                        0
                      }
                      readOnly
                      append="USD"
                    /> */}
                        <InputField
                          name="average_price"
                          displayName="Average product price"
                          type="price"
                          value={
                            bid.avg_product_price &&
                            bid.state !== CONSTANTS.BID_STATE.DRAFT
                              ? bid.avg_product_price
                              : round(bid.price / bid.num_compounds, 2) || 0
                          }
                          readOnly
                          append="USD"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <h2 className="subheadline">
                {!isDisabledField() && <span>2. </span>}Other details
              </h2>
              <div className="box-container orange">
                <div className="box">
                  <Row className="mb-4">
                    <Col sm={12} md={6}>
                      <div className="label-line-wrapper">
                        <div className="label-text">Requested</div>
                        <div className="label-line"></div>
                      </div>
                      <div className="bid-container">
                        <Row className="align-items-center mb-2 block-end">
                          <Col sm={6}>
                            <Label for="exclusivity">Exclusivity</Label>
                          </Col>
                          <Col sm={6} className="text-right">
                            <Badge
                              color={
                                "" +
                                (auction.exclusivity_value
                                  ? "success"
                                  : "secondary")
                              }
                              pill
                            >
                              {auction.exclusivity_value ? (
                                <span>
                                  Required for{" "}
                                  {auction.exclusivity_value +
                                    " " +
                                    auction.exclusivity_unit_display}
                                </span>
                              ) : (
                                <span>Not required</span>
                              )}
                            </Badge>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-2 block-end">
                          <Col sm={6}>
                            <Label for="reporting">
                              Reporting about the project
                            </Label>
                          </Col>
                          <Col sm={6} className="text-right">
                            <Badge
                              color={
                                "" +
                                (auction.reporting !== 500
                                  ? "success"
                                  : "secondary")
                              }
                              pill
                            >
                              {auction.reporting_display}
                            </Badge>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-2 block-end">
                          <Col sm={6}>
                            <Label for="exact-stereo-match">
                              Exact stereo match
                            </Label>
                          </Col>
                          <Col sm={6} className="text-right">
                            <Badge
                              color={
                                "" +
                                (auction.exact_stereo_match
                                  ? "success"
                                  : "secondary")
                              }
                              pill
                            >
                              {auction.exact_stereo_match
                                ? "Required"
                                : "Not required"}
                            </Badge>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-2 block-end">
                          <Col sm={6}>
                            <Label for="syntesis-route">
                              Synthesis route by customer is
                            </Label>
                          </Col>
                          <Col sm={6} className="text-right">
                            <Badge
                              color={
                                "" +
                                (auction.known_synthesis_route
                                  ? "success"
                                  : "secondary")
                              }
                              pill
                            >
                              {auction.known_synthesis_route
                                ? "Known"
                                : "Not provided"}
                            </Badge>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-2">
                          <Col sm={6}>
                            <Label for="syntesis-route">
                              Quality control (QC) requirements by customer
                            </Label>
                          </Col>
                          <Col sm={6} className="text-right">
                            <Badge
                              color={
                                "" +
                                (auction.purity_own_analysis
                                  ? "success"
                                  : "secondary")
                              }
                              pill
                            >
                              {auction.purity_own_analysis
                                ? "Provided"
                                : "Not provided"}
                            </Badge>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm={12} md={6}>
                      <div className="label-line-wrapper">
                        <div className="label-text">Your offer</div>
                        <div className="label-line"></div>
                      </div>
                      <div className="bid-container bid-request">
                        {errors.exclusivity_accepted && (
                          <Alert color="danger">
                            {errors.exclusivity_accepted}
                          </Alert>
                        )}
                        <Row className="align-items-center mb-2 block-end">
                          <Col lg={6}>
                            <Label for="exclusivity">
                              Exclusivity requirement
                              {auction.exclusivity_value ? (
                                <span>*</span>
                              ) : null}
                            </Label>
                            <SimpleTooltip name="exclusivity">
                              You must accept the exclusivity requirement. Your
                              offer is invalid without the acceptance of the
                              exclusivity requirement. The total compound price
                              has to include the fee of exclusivity if any
                            </SimpleTooltip>
                          </Col>
                          <Col lg={6} className="text-right">
                            <ButtonGroup>
                              <Button
                                onClick={() =>
                                  handleRequirements(
                                    "exclusivity_accepted",
                                    true
                                  )
                                }
                                active={
                                  !isUndefined(bid.exclusivity_accepted) &&
                                  bid.exclusivity_accepted
                                }
                                disabled={
                                  isDisabledField() ||
                                  !auction.exclusivity_value
                                }
                              >
                                Accepted
                              </Button>
                              <Button
                                onClick={() =>
                                  handleRequirements(
                                    "exclusivity_accepted",
                                    false
                                  )
                                }
                                disabled={
                                  isDisabledField() ||
                                  !auction.exclusivity_value
                                }
                                active={
                                  !isUndefined(bid.exclusivity_accepted) &&
                                  !bid.exclusivity_accepted
                                }
                              >
                                Not accepted
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                        {errors.reporting_accepted && (
                          <Alert color="danger">
                            {errors.reporting_accepted}
                          </Alert>
                        )}
                        <Row className="align-items-center mb-2 block-end">
                          <Col lg={6}>
                            <Label for="reporting">
                              Reporting about the project
                            </Label>
                            <SimpleTooltip name="reporting">
                              It is possible to upload a logo-free report
                              template below, to inform the customer about the
                              quality of your reporting. This template can help
                              the customer in choosing the winning offer. The
                              total compound price has to include the fee of
                              reporting if any.
                            </SimpleTooltip>
                          </Col>
                          <Col lg={6} className="text-right">
                            <ButtonGroup>
                              <Button
                                onClick={() =>
                                  handleRequirements("reporting_accepted", true)
                                }
                                active={
                                  !isUndefined(bid.reporting_accepted) &&
                                  bid.reporting_accepted
                                }
                                disabled={
                                  isDisabledField() ||
                                  auction.reporting ===
                                    CONSTANTS.REPORTING.NOT_REQUIRED
                                }
                              >
                                Accepted
                              </Button>
                              <Button
                                onClick={() =>
                                  handleRequirements(
                                    "reporting_accepted",
                                    false
                                  )
                                }
                                active={
                                  !isUndefined(bid.reporting_accepted) &&
                                  !bid.reporting_accepted
                                }
                                disabled={
                                  isDisabledField() ||
                                  auction.reporting ===
                                    CONSTANTS.REPORTING.NOT_REQUIRED
                                }
                              >
                                Not accepted
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                        {errors.exact_stereo_match_accepted && (
                          <Alert color="danger">
                            {errors.exact_stereo_match_accepted}
                          </Alert>
                        )}
                        <Row className="align-items-center mb-2 block-end">
                          <Col lg={6}>
                            <Label for="exact-stereo-match">
                              Exact stereo match
                            </Label>
                          </Col>
                          <Col lg={6} className="text-right">
                            <ButtonGroup>
                              <Button
                                onClick={() =>
                                  handleRequirements(
                                    "exact_stereo_match_accepted",
                                    true
                                  )
                                }
                                active={
                                  !isUndefined(
                                    bid.exact_stereo_match_accepted
                                  ) && bid.exact_stereo_match_accepted
                                }
                                disabled={
                                  isDisabledField() ||
                                  !auction.exact_stereo_match
                                }
                              >
                                Accepted
                              </Button>
                              <Button
                                onClick={() =>
                                  handleRequirements(
                                    "exact_stereo_match_accepted",
                                    false
                                  )
                                }
                                active={
                                  !isUndefined(
                                    bid.exact_stereo_match_accepted
                                  ) && !bid.exact_stereo_match_accepted
                                }
                                disabled={
                                  isDisabledField() ||
                                  !auction.exact_stereo_match
                                }
                              >
                                Not accepted
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                        {errors.synthesis_route_accepted && (
                          <Alert color="danger">
                            {errors.synthesis_route_accepted}
                          </Alert>
                        )}
                        <Row className="align-items-center mb-2 block-end">
                          <Col lg={6}>
                            <Label for="syntesis-route">
                              Synthesis route by customer is
                            </Label>
                          </Col>
                          <Col lg={6} className="text-right">
                            <ButtonGroup>
                              <Button
                                onClick={() =>
                                  handleRequirements(
                                    "synthesis_route_accepted",
                                    true
                                  )
                                }
                                active={
                                  !isUndefined(bid.synthesis_route_accepted) &&
                                  bid.synthesis_route_accepted
                                }
                                disabled={
                                  isDisabledField() ||
                                  !auction.known_synthesis_route
                                }
                              >
                                Accepted
                              </Button>
                              <Button
                                onClick={() =>
                                  handleRequirements(
                                    "synthesis_route_accepted",
                                    false
                                  )
                                }
                                active={
                                  !isUndefined(bid.synthesis_route_accepted) &&
                                  !bid.synthesis_route_accepted
                                }
                                disabled={
                                  isDisabledField() ||
                                  !auction.known_synthesis_route
                                }
                              >
                                Not accepted
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                        {errors.synthesis_route_accepted && (
                          <Alert color="danger">
                            {errors.synthesis_route_accepted}
                          </Alert>
                        )}
                        <Row className="align-items-center mb-2 block-end">
                          <Col lg={6}>
                            <Label for="analytical_method_accepted">
                              Quality control (QC) requirements by customer
                              <SimpleTooltip name="analytical_method_accepted">
                                If the customer requires QC requirements, it
                                will be the guidance in case of any quality
                                related customer claims. You must accept the QC
                                requirement if provided. If the customer did not
                                provide such requirements, the QC standards you
                                provided along with the bid will be the
                                guidance. In the absence of these, Mcule’s QC
                                standards will be applied.
                              </SimpleTooltip>
                            </Label>
                          </Col>
                          <Col lg={6} className="text-right">
                            <ButtonGroup>
                              <Button
                                onClick={() =>
                                  handleRequirements(
                                    "analytical_method_accepted",
                                    true
                                  )
                                }
                                active={
                                  !isUndefined(
                                    bid.analytical_method_accepted
                                  ) && bid.analytical_method_accepted
                                }
                                disabled={
                                  isDisabledField() ||
                                  !auction.purity_own_analysis
                                }
                              >
                                Accepted
                              </Button>
                              <Button
                                onClick={() =>
                                  handleRequirements(
                                    "analytical_method_accepted",
                                    false
                                  )
                                }
                                active={
                                  !isUndefined(
                                    bid.analytical_method_accepted
                                  ) && !bid.analytical_method_accepted
                                }
                                disabled={
                                  isDisabledField() ||
                                  !auction.purity_own_analysis
                                }
                              >
                                Not accepted
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                        {errors.analytical_method_accepted && (
                          <Alert color="danger">
                            {errors.analytical_method_accepted}
                          </Alert>
                        )}
                        {!auction.purity_own_analysis && (
                          <div className="row block-end mb-2">
                            <div className="col-12">
                              <FormGroup check className="mb-0">
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    name="own_qc"
                                    id="ownQC"
                                    onChange={handleInputChange}
                                    checked={bid.own_qc || false}
                                  />{" "}
                                  I provide my quality control (QC) standards
                                </Label>
                              </FormGroup>

                              {bid.own_qc && (
                                <Alert color="info" className="mb-0 mt-2">
                                  <i className="fas fa-exclamation"></i>
                                  Please indicate your QC standards in the
                                  General comment field or upload them as a file
                                  below.
                                </Alert>
                              )}
                            </div>
                          </div>
                        )}
                        <div>
                          <Label>Available analytical equipment(s)</Label>
                        </div>
                        {bid.analytical_methods && (
                          <React.Fragment>
                            <ButtonGroup className="available_analytical_methods">
                              <Button
                                onClick={() =>
                                  handleAnalyticalMethod(
                                    CONSTANTS.ANALYTICAL_METHOD.LCMS_UV
                                  )
                                }
                                active={bid.analytical_methods.includes(
                                  CONSTANTS.ANALYTICAL_METHOD.LCMS_UV
                                )}
                                disabled={isDisabledField()}
                              >
                                LC-MS (DAD)
                              </Button>
                              <Button
                                onClick={() =>
                                  handleAnalyticalMethod(
                                    CONSTANTS.ANALYTICAL_METHOD.LCMS_ELSD
                                  )
                                }
                                active={bid.analytical_methods.includes(
                                  CONSTANTS.ANALYTICAL_METHOD.LCMS_ELSD
                                )}
                                disabled={isDisabledField()}
                              >
                                LC-MS (ELSD)
                              </Button>
                              <Button
                                onClick={() =>
                                  handleAnalyticalMethod(
                                    CONSTANTS.ANALYTICAL_METHOD.GCMS
                                  )
                                }
                                active={bid.analytical_methods.includes(
                                  CONSTANTS.ANALYTICAL_METHOD.GCMS
                                )}
                                disabled={isDisabledField()}
                              >
                                GC-MS
                              </Button>
                              <Button
                                onClick={() =>
                                  handleAnalyticalMethod(
                                    CONSTANTS.ANALYTICAL_METHOD.NMR
                                  )
                                }
                                active={bid.analytical_methods.includes(
                                  CONSTANTS.ANALYTICAL_METHOD.NMR
                                )}
                                disabled={isDisabledField()}
                              >
                                <sup>1</sup>
                                H.NMR
                              </Button>
                            </ButtonGroup>
                            <div
                              className={
                                "invalid-feedback " +
                                (errors.analytical_methods && submitTry
                                  ? "d-block"
                                  : "")
                              }
                            >
                              {errors.analytical_methods}
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col sm={6}>
                      <div className="label-line-wrapper">
                        <div className="label-text">
                          Customer uploaded files
                        </div>
                        <div className="label-line"></div>
                      </div>
                      {auction.files?.length > 1 && (
                        <a
                          href={`${APIDomain}${APIBase}auctions/${auction.id}/download-files/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mb-1 d-block"
                        >
                          <span>Download all files</span>
                        </a>
                      )}
                      <ul className="file-list">
                        {auction.files?.map((file, index) => (
                          <li key={file.id}>
                            <a
                              href={`${APIDomain}${APIBase}files/auction/${file.id}/download/`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="far fa-file"></i>{" "}
                              <span>{file.filename}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </Col>
                    <Col sm={6}>
                      <div className="label-line-wrapper">
                        <div className="label-text">Your uploaded files</div>
                        <SimpleTooltip name="file">
                          The file(s) must be uploaded in one of .gz, .zip, .pdf, .jpg, .png, .doc,
                          .docx, .xls, .xlsx, .csv file formats.
                        </SimpleTooltip>
                        <div className="label-line"></div>
                      </div>
                      <FormGroup>
                        <div
                          className={
                            errors?.files
                              ? "is-invalid position-relative"
                              : "position-relative"
                          }
                        >
                          <CustomInput
                            type="file"
                            label="Upload file"
                            name="files"
                            id="files"
                            onChange={handleFileChange}
                            disabled={isDisabledField()}
                            invalid={errors && errors.files}
                          />
                          {isFileUploading && (
                            <div className="file-uploading-overlay">
                              <Spinner color="info" />
                              <span className="ml-2">Uploading file</span>
                            </div>
                          )}
                        </div>
                        {errors?.files && (
                          <FormFeedback>{errors.files}</FormFeedback>
                        )}
                        <FormText color="muted">
                          Please upload here any additional details related to
                          your offer, such as your provided quality control
                          standards. Please note that uploading a file that
                          contains any information related to your company is
                          banned. Furthermore, the shared file could not include
                          the details already presented in the filled bidding
                          form, such as lead time or compound prices. The
                          Administrator of SynthAgora can remove files that do
                          not correspond to the above regulations.
                          <br />
                          <br />
                          Supported file formats: .gz, .zip, .pdf, .jpg, .png, .doc, .docx, .xls, .xlsx, .csv
                        </FormText>
                      </FormGroup>

                      {bid.files?.length > 1 && (
                        <a
                          href={`${APIDomain}${APIBase}bids/${bid.id}/download-files/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mb-1 d-block"
                        >
                          <span>Download all files</span>
                        </a>
                      )}
                      <ul className="file-list">
                        {bid.files?.map((file) => (
                          <li key={file.id}>
                            <a
                              href={`${APIDomain}${APIBase}files/bid/${file.id}/download/`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="far fa-file"></i>{" "}
                              <span>{file.name || file.filename}</span>
                            </a>
                            {bid?.state === CONSTANTS.AUCTION_STATE.DRAFT && (
                              <span
                                className="del-btn"
                                onClick={() => {
                                  setSelectedFile(file);
                                  toggleDeleteFileModal();
                                }}
                              >
                                &#10006;
                              </span>
                            )}
                          </li>
                        ))}
                        {files.length > 0 && (
                          <>
                            <div className="mt-2">Newly added files</div>
                            {files.map((file, index) => (
                              <li key={file.id}>
                                <a
                                  href={`${APIDomain}${APIBase}files/auction/${file.id}/download/`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="far fa-file"></i>{" "}
                                  <span>{file.name || file.filename}</span>
                                </a>
                                {console.log(bid?.state)}
                                {bid?.state <=
                                  CONSTANTS.AUCTION_STATE.DRAFT && (
                                  <span
                                    className="del-btn"
                                    onClick={() => {
                                      setSelectedFile(file);
                                      toggleDeleteFileModal();
                                    }}
                                  >
                                    &#10006;
                                  </span>
                                )}
                              </li>
                            ))}
                          </>
                        )}
                      </ul>

                      {files.length > 0 && (
                        <FormGroup check>
                          <Label
                            check
                            className={errors.logoFreeFile ? "text-danger" : ""}
                          >
                            <Input
                              type="checkbox"
                              name="logo-free-file"
                              id="logo-free-file"
                              onChange={() => {
                                setLogoFreeFile(!logoFreeFile);
                              }}
                              checked={logoFreeFile}
                              invalid={errors.logoFreeFile}
                            />{" "}
                            I understand and acknowledge when uploading files,
                            that according to Mcule SynthAgora Terms and
                            Conditions it is not allowed to upload files
                            containing company identifier information or
                            pricing. I accept that if such a case would occur
                            the Administrator of SynthAgora can remove these
                            files in its sole decision.
                          </Label>
                          {errors.logoFreeFile && (
                            <FormFeedback>{errors.logoFreeFile}</FormFeedback>
                          )}
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <div className="label-line-wrapper">
                        <div className="label-text">Customer's comment</div>
                        <div className="label-line"></div>
                      </div>
                      <textarea
                        rows="4"
                        cols="50"
                        value={auction.comment}
                        className="form-control"
                        disabled
                      ></textarea>
                    </Col>
                    <Col sm={6}>
                      <div className="label-line-wrapper">
                        <div className="label-text">Supplier's comment</div>
                        <div className="label-line"></div>
                      </div>
                      <textarea
                        className="form-control"
                        type="textarea"
                        name="comment"
                        id="comment"
                        rows="4"
                        cols="50"
                        value={bid.comment}
                        onChange={handleInputChange}
                        disabled={isDisabledField()}
                        placeholder="Add further details about your offer here. Please note that this section is visible to the customers, thus do not share any information referring to your company. Please do not provide information in General Comment Section that is already presented in your bidding form (lead time, compound prices). The Administrator of SynthAgora can remove this information at its sole decision."
                      ></textarea>
                    </Col>
                  </Row>
                </div>
              </div>

              <React.Fragment>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="tac"
                      id="tac"
                      onChange={handleTacChange}
                      checked={tac}
                      required
                      disabled={isDisabledField()}
                    />{" "}
                    Circumvention of SynthAgora is not allowed. You cannot use
                    SynthAgora only to get information about custom synthesis
                    requesters, so you cannot sell the compounds of the
                    submitted bid to the customer outside of the Platform.*
                  </Label>
                  {submitTry === true && !tac && (
                    <div className={"invalid-feedback "}>
                      You have to accept this condition in order to proceed!
                    </div>
                  )}
                </FormGroup>

                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="notPatentedCompounds"
                      id="notPatentedCompounds"
                      onChange={handleNotPatentedCompoundsChange}
                      checked={notPatentedCompounds}
                      required
                      disabled={isDisabledField()}
                    />
                    The compounds in the submitted bid are not patented nor
                    under any kind of regulation in my country.*
                  </Label>
                  {submitTry === true && !notPatentedCompounds && (
                    <div className={"invalid-feedback"}>
                      You have to accept this condition in order to proceed!
                    </div>
                  )}
                </FormGroup>
              </React.Fragment>

              <Row className="mt-4">{renderButtons()}</Row>
            </Form>
            <ConfirmModal
              open={confirmDeleteFile}
              toggle={toggleDeleteFileModal}
              confirmed={deleteBidFile}
              title="Confirmation needed"
              bodyText={
                "Are you sure you want to delete this file? This step cannot be redone."
              }
              cancelBtn="No"
              okBtn="Yes"
            />
          </React.Fragment>
        ) : (
          <div>No bid found to this auction</div>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="mt-4">
        {notFound ? <div>No auction found</div> : <div>{renderPage()}</div>}
      </Container>
    </div>
  );
}
