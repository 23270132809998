import React, { useEffect } from "react";
import { Container } from "reactstrap";
import "./Terms.scss";
import pdfFile from "../assets/Synthagora_LCMS_Method.pdf";

export default function TermsAndConditions(props) {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div className="grey-bg">
        <Container className="pt-4">
          <h1 className="mb-4 subheadline text-center">Terms and Conditions</h1>
          <p class="c0">
            <span class="c5 c1 c4">I. Definitions</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">- &ldquo;</span>
            <span class="c1 c4">Terms and Conditions</span>
            <span class="c1">&rdquo; or</span>
            <span>&nbsp;</span>
            <span class="c1">&ldquo;</span>
            <span class="c1 c4">Terms</span>
            <span class="c1">
              &rdquo; govern the use of SynthAgora website, web service, and
              application operated by Mcule. These Terms apply to all visitors,
              users, and others who access SynthAgora.
            </span>
          </p>
          <p class="c0">
            <span>-</span>
            <span class="c1">&nbsp;</span>
            <span>&ldquo;</span>
            <span class="c1 c4">SynthAgora</span>
            <span>&rdquo;</span>
            <span class="c1 c4">&nbsp;</span>
            <span class="c1">or</span>
            <span class="c1 c4">&nbsp;</span>
            <span>&ldquo;</span>
            <span class="c1 c4">System</span>
            <span>&rdquo; is</span>
            <span class="c2 c1">
              &nbsp;an online accessible system operated by Mcule for the online
              distribution of compounds primarily produced by custom synthesis.
            </span>
          </p>
          <p class="c0">
            <span class="c1">- </span>
            <span>&ldquo;</span>
            <span class="c1 c4">Mcule</span>
            <span>&rdquo;</span>
            <span class="c1">&nbsp;or</span>
            <span class="c1 c4">&nbsp;</span>
            <span>&ldquo;</span>
            <span class="c4">Administrator</span>
            <span>&rdquo; is the </span>
            <span class="c1">
              operator of SynthAgora, who enters into a legal relationship with
              the Customer (as{" "}
            </span>
            <span>Contractor)</span>
            <span class="c1">
              &nbsp;and who enters into a legal relationship with the Supplier
              (as Client) in case of{" "}
            </span>
            <span>O</span>
            <span class="c1">rder.</span>
            <span>&nbsp;The definition of Mcule includes its Affiliates </span>
            <span class="c1">&ndash;</span>
            <span>
              &nbsp;any legal entity or unincorporated entity over which the
              Party exercises control or has management rights, or in which
              Party has ownership rights.
            </span>
          </p>
          <p class="c0">
            <span class="c1">- </span>
            <span>&ldquo;</span>
            <span class="c1 c4">Customer</span>
            <span>&rdquo; is the</span>
            <span class="c1">
              &nbsp;User of SynthAgora registered as Customer who is authorized
              to create{" "}
            </span>
            <span>A</span>
            <span class="c1">
              uctions or in case of an Order enters into a legal relationship
              with Mcule as Customer /
            </span>
            <span>&nbsp;</span>
            <span class="c2 c1">Ordering Party.</span>
          </p>
          <p class="c0">
            <span class="c1">- </span>
            <span>&ldquo;</span>
            <span class="c1 c4">Supplier</span>
            <span>&rdquo; is the</span>
            <span class="c1">
              &nbsp;User of SynthAgora registered as Supplier who is entitled to
              submit{" "}
            </span>
            <span>B</span>
            <span class="c1">ids </span>
            <span>for</span>
            <span class="c1">&nbsp;</span>
            <span>A</span>
            <span class="c2 c1">
              uctions or in case of an Order enters into a legal relationship
              with Mcule as Supplier / Service Provider.
            </span>
          </p>
          <p class="c0">
            <span class="c1">- </span>
            <span>&ldquo;</span>
            <span class="c1 c4">Party</span>
            <span>&rdquo;</span>
            <span class="c1">
              &nbsp;means any party involved in the use of the{" "}
            </span>
            <span>S</span>
            <span class="c1">ystem &ndash; Customer, Supplier, or Mcule, </span>
            <span>depending</span>
            <span class="c2 c1">&nbsp;on the context.</span>
          </p>
          <p class="c0">
            <span class="c1">- </span>
            <span>&ldquo;</span>
            <span class="c1 c4">Parties</span>
            <span>&rdquo; means</span>
            <span class="c2 c1">
              &nbsp;all parties involved in the use of the System, i.e.
              Customer, Supplier, and Mcule together.
            </span>
          </p>
          <p class="c0">
            <span>- &ldquo;</span>
            <span class="c4">User</span>
            <span class="c2 c1">
              &rdquo; means Customer or Supplier authorized to use the System or
              both, or the Representative who uses the System on behalf of the
              Customer or the Supplier, as the context requires.
            </span>
          </p>
          <p class="c0">
            <span>- </span>
            <span class="c4">&ldquo;Affiliate</span>
            <span class="c2 c1">
              &rdquo; (or subsidiary) of that Party includes in the definition
              of Mcule, Customer or Supplier, i.e. any legal entity or
              unincorporated entity over which the Party exercises control or
              has management rights, or in which Party has ownership rights.
            </span>
          </p>
          <p class="c0">
            <span>- &ldquo;</span>
            <span class="c4">Account</span>
            <span class="c2 c1">
              &rdquo; means the access right through which the User is entitled
              to use the System after registration, and through which the use of
              the System is technically possible.
            </span>
          </p>
          <p class="c0">
            <span class="c1">- </span>
            <span>&ldquo;</span>
            <span class="c1 c4">Auction</span>
            <span>&rdquo;</span>
            <span class="c1">
              &nbsp;is a request created by Customer in SynthAgora as a request
              for quotation (
            </span>
            <span>B</span>
            <span class="c1">
              ids) on the production and delivery of a specific{" "}
            </span>
            <span>C</span>
            <span class="c1">ompound(s) </span>
            <span class="c1">from</span>
            <span>&nbsp;Supplier to</span>
            <span class="c1">&nbsp;Mcule and from Mcule </span>
            <span>to Customer</span>
            <span class="c2 c1">.</span>
          </p>
          <p class="c0">
            <span class="c1">- </span>
            <span>&ldquo;</span>
            <span class="c1 c4">Bid</span>
            <span>&rdquo;</span>
            <span class="c1">&nbsp;means a quotation, inclu</span>
            <span>ding price, delivery time, etc., </span>
            <span class="c1">
              appearing in the System on the basis of the one given by the
              Supplier which relates to a specific{" "}
            </span>
            <span>A</span>
            <span class="c1">
              uction and the production and delivery of a specific{" "}
            </span>
            <span>C</span>
            <span class="c2 c1">ompound(s).</span>
          </p>
          <p class="c0">
            <span class="c1">- </span>
            <span>&ldquo;</span>
            <span class="c4">Customer Order</span>
            <span>&rdquo; is</span>
            <span class="c1">
              &nbsp;a legal relationship, formed on the basis of the{" "}
            </span>
            <span>Bid</span>
            <span class="c1">
              &nbsp;selected by the Customer, between Customer (as Ordering
              Party) and Mcule (as Contractor) for the production and delivery
              of a specific{" "}
            </span>
            <span>C</span>
            <span class="c2 c1">ompound(s).</span>
          </p>
          <p class="c0">
            <span>- &ldquo;</span>
            <span class="c1 c4">M</span>
            <span class="c4">cule Order</span>
            <span>&rdquo;</span>
            <span class="c1">&nbsp;a legal relationship</span>
            <span>, formed on the basis of</span>
            <span class="c1">&nbsp;the </span>
            <span>Bid</span>
            <span class="c1">
              &nbsp;selected by the Customer, between Supplier (as Service
              Provider) and Mcule (as Client) for the production and delivery of
              a specific{" "}
            </span>
            <span>C</span>
            <span class="c2 c1">ompound(s).</span>
          </p>
          <p class="c0">
            <span class="c1">- </span>
            <span>&ldquo;</span>
            <span class="c1 c4">Order</span>
            <span>&rdquo;</span>
            <span class="c1">&nbsp;means </span>
            <span>Customer Order and </span>
            <span class="c1">M</span>
            <span>cule Order together.</span>
          </p>
          <p class="c0">
            <span class="c1">- </span>
            <span>&ldquo;</span>
            <span class="c1 c4">Compound(s)</span>
            <span>&rdquo; is</span>
            <span class="c1">
              &nbsp;a physically available chemical compound or a list of
              compounds the production and delivery of which the Customer
              creates an{" "}
            </span>
            <span>A</span>
            <span class="c2 c1">
              uction for and which are the subject of the Order.
            </span>
          </p>
          <p class="c0">
            <span class="c1">- </span>
            <span>&ldquo;</span>
            <span class="c1 c4">Trade </span>
            <span class="c4">S</span>
            <span class="c1 c4">ecret</span>
            <span>&rdquo; means</span>
            <span class="c1">
              &nbsp;any data, fact, information, or knowledge which is
              considered as{" "}
            </span>
            <span>T</span>
            <span class="c1">rade </span>
            <span>S</span>
            <span class="c1">ecret under the </span>
            <span>Act of LIV 2018</span>
            <span class="c1">&nbsp;on the protection of </span>
            <span>T</span>
            <span class="c1">rade </span>
            <span>S</span>
            <span class="c1">ecrets.</span>
          </p>
          <p class="c0">
            <span>- &ldquo;</span>
            <span class="c4">Representative</span>
            <span>&rdquo; is a person authorized to use the System </span>
            <span class="c1">&ndash;</span>
            <span>&nbsp;to start an Auction or to submit a Bid </span>
            <span class="c1">&ndash;</span>
            <span>
              &nbsp;on behalf of the Customer or Supplier, respectively, and a
              person authorized to make statements regarding the creation,
              possible modification, or termination of an Order on behalf of the
              Customer or Supplier
            </span>
            <span class="c2 c1">.</span>
          </p>
          <p class="c0">
            <span class="c1">- </span>
            <span>&ldquo;</span>
            <span class="c1 c4">Contributor</span>
            <span>&rdquo; is</span>
            <span class="c1">&nbsp;a legal entity &ndash;</span>
            <span>
              &nbsp;for instance a subcontractor, performance assistant, or
              other contributor{" "}
            </span>
            <span class="c1">
              &ndash; who contributes to the fulfillment of an{" "}
            </span>
            <span class="c2 c1">
              Mcule Order on the Supplier&rsquo;s side, regardless of the type
              of legal relationship between the Supplier and this third party.
            </span>
          </p>
          <p class="c0">
            <span>- &ldquo;</span>
            <span class="c4">Knowledge Material</span>
            <span class="c2 c1">
              &rdquo; means the physical and/or intellectual product created
              during the performance of the Order, including all knowledge
              necessary for the production of the Compound(s), or which is
              created, generated during the production of the Compound(s) which
              is the subject of the Order.
            </span>
          </p>
          <p class="c0">
            <span>- &ldquo;</span>
            <span class="c4">Credit Account</span>
            <span class="c2 c1">
              &rdquo; entitles Customers to pay after the fulfillment of the
              Customer Order. It is Mcule&rsquo;s sole decision to grant a
              Customer a Credit Account.
            </span>
          </p>
          <p class="c0">
            <span>- &ldquo;</span>
            <span class="c4">Offer</span>
            <span>&rdquo; is a </span>
            <span>quotation or offer</span>
            <span class="c2 c1">
              &nbsp;automatically generated by the System based on the
              Suppliers&rsquo; Bid. Offer is prepared by Mcule to the Customer.
            </span>
          </p>
          <p class="c0">
            <span>- &ldquo;</span>
            <span class="c4">Quality Control Method</span>
            <span>&rdquo; or &ldquo;</span>
            <span class="c4">QC Method</span>
            <span class="c2 c1">
              &rdquo; means an analytical method that is used to determine the
              identity and/or purity of the Compound(s). The analytical quality
              control method defined as QC Method is used for quality-related
              (identity and/or purity of the ordered Compound(s)) claims, in
              particular, to settle disputes.
            </span>
          </p>
          <p class="c0">
            <span>- &ldquo;</span>
            <span class="c4">Confidential Information</span>
            <span class="c2 c1">
              &rdquo; means any and all information including but not limited to
              know-how, intellectual property, plans, documents, financial
              information, formulas, designs, calculations, facilities, reports,
              models, agreements, methods, inventions, discoveries, research
              concepts, patents, technology, products, chemical compounds and
              compositions, molecules, precursors, concepts, ideas, plans,
              processes, specifications, characteristics, techniques, and
              assays; business information such as development, marketing,
              sales, customer lists, suppliers, consulting relationships,
              performance and cost structures, pricing and commercialization
              plans, forecasts, proposals, and any other non-public information
              or other trade secrets, whether scientific, clinical or financial
              in nature, however recorded or preserved that is provided or
              disclosed by or on behalf of (I) Customer to Supplier or its
              Representatives, or (II) one Party to the other Party or its
              Representatives in the performance or completion of an Order, via
              the System or by any other means, whether in writing, orally,
              pictorially or through other tangible materials. Notwithstanding
              the foregoing, any Knowledge Material shall be considered
              Customer&rsquo;s Confidential Information.
            </span>
          </p>
          <p class="c0">
            <span>- &ldquo;</span>
            <span class="c4">Partial Performance</span>
            <span class="c2 c1">
              &rdquo; can occur if the Compound(s) subject to the Order(s) are
              not produced and delivered in full quantity or not all of the
              Compounds are produced and delivered to Customer or Mcule.
            </span>
          </p>
          <p class="c0">
            <span>- &bdquo;</span>
            <span class="c4">End Date</span>
            <span class="c2 c1">
              &rdquo; means the date and time of the termination of Auction
              specified by Customer during the creation of the Auction.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c5 c1 c4">II. Terms of System Use</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c1">The </span>
            <span>Administrator</span>
            <span class="c1">
              &nbsp;of the online system SynthAgora is Mcule. The{" "}
            </span>
            <span>S</span>
            <span class="c1">
              ystem can be used only after the registration procedure{" "}
            </span>
            <span>set out</span>
            <span class="c1">&nbsp;below, either </span>
            <span>as</span>
            <span class="c1">&nbsp;a Customer or Supplier.</span>
            <span>&nbsp;</span>
            <span class="c1">
              The criteria of the use of the System are the acceptance of and
              compliance with these{" "}
            </span>
            <span>T</span>
            <span class="c1">erms and </span>
            <span>C</span>
            <span class="c1">onditions and demonstrating appropriate </span>
            <span>behavior</span>
            <span class="c1">&nbsp;in all respects.</span>
            <span>
              &nbsp;By registering, the Party accepts these Terms and Conditions
              and declares this by checking the appropriate field during
              registration.{" "}
            </span>
            <span class="c1">Only</span>
            <span>&nbsp;those</span>
            <span class="c2 c1">
              &nbsp;Customers and Suppliers can become Users of the System who
              went through the registration procedure set out below.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">Mcule reserves the right to </span>
            <span>approve or reject</span>
            <span class="c1">
              &nbsp;the registration of the User. Mcule also reserves the right
              to monitor or review the User&rsquo;s{" "}
            </span>
            <span>S</span>
            <span class="c1">ystem usage at any time, and to notify the </span>
            <span>U</span>
            <span class="c1">
              ser in the event of a problem and suspend/delete its Account; in
              this regard, the provisions of
            </span>
            <span>&nbsp;Section</span>
            <span class="c2 c1">&nbsp;IX. shall apply.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1 c4">II. 1. Registration </span>
            <span class="c4">P</span>
            <span class="c5 c1 c4">rocess, Conditions</span>
          </p>
          <p class="c0">
            <span class="c1">
              Only companies or other legal entities are entitled to register
              and use the{" "}
            </span>
            <span>S</span>
            <span class="c1">ystem.</span>
            <span>
              &nbsp;One legal entity (one User) is only authorized to create one
              Account.{" "}
            </span>
            <span class="c1">
              The registration is considered to be completed after{" "}
            </span>
            <span>approval</span>
            <span class="c1">
              &nbsp;by Mcule, after which the services of the System{" "}
            </span>
            <span>become</span>
            <span class="c1">&nbsp;</span>
            <span>available</span>
            <span class="c1">&nbsp;to the </span>
            <span>U</span>
            <span class="c2 c1">ser.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              During the registration, the name and registered address of the
              legal entit
            </span>
            <span>y </span>
            <span class="c1">
              must be provided, and the User must specify the contact details of{" "}
            </span>
            <span>its</span>
            <span class="c1">&nbsp;</span>
            <span>R</span>
            <span class="c1">
              epresentative (by providing name, e-mail address, phone number).
              The Representative is entitled to use the System on behalf of the
              registered legal entity, and thereby to mak
            </span>
            <span>e</span>
            <span class="c1">
              &nbsp;legally valid statements and to carry out legally valid
              activit
            </span>
            <span>ies</span>
            <span class="c1">
              . Such a person shall be considered as an authorized{" "}
            </span>
            <span>R</span>
            <span class="c1">
              epresentative to act on behalf of the User (Customer or Supplier)
              when using the System. It is the responsibility of the{" "}
            </span>
            <span>U</span>
            <span class="c1">ser that the </span>
            <span>R</span>
            <span class="c1">
              epresentative person provided during the registration is entitled
              to make an obligation and to make legal declarations on behalf of
              the{" "}
            </span>
            <span>U</span>
            <span class="c1">ser.</span>
            <span>&nbsp;</span>
            <span class="c1">All </span>
            <span>U</span>
            <span class="c2 c1">
              sers &ndash; both Customer and Supplier &ndash; accept and
              acknowledge that Mcule&rsquo;s liability for any damages or claims
              arising from or in connection with the foregoing is completely
              excluded.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              For the validity of the registration, Mcule shall approve and
              subsequently confirm it via email. This approval and confirmation
              create a legal{" "}
            </span>
            <span>relationship</span>
            <span class="c1">&nbsp;for the use of the System.</span>
            <span>&nbsp;</span>
            <span class="c1">Mcule </span>
            <span>is, in its sole discretion, </span>
            <span class="c1">
              not obliged to approve the registration. If registration is
              rejected,
            </span>
            <span>&nbsp;a notification email is sent by </span>
            <span class="c1">Mcule. Mcule is not required to justify </span>
            <span>rejection</span>
            <span class="c1">
              . Mcule&rsquo;s decision on registration must be accepted and
              acknowledged by all concerned. No claim or demand may be made
              against Mcule in connection with Mcule&rsquo;s decisions regarding
              registration.{" "}
            </span>
            <span>&nbsp;</span>
            <span class="c1">
              Mcule also has the right to suspend the registration if there is
              any doubt regarding &ndash; the validity or reality of &ndash; any
              information provided during the registration. Mcule has the right
              but not obliged to verify the information provided during the
              registration from official records available online.{" "}
            </span>
            <span class="c2 c1">
              Mcule shall not be liable for any damages, claims, or demands
              related to the invalidity or inaccuracy of the information
              provided during the registration.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              By registering in the System and using the services provided by
              the System, the User declares{" "}
            </span>
            <span>that</span>
            <span class="c1">
              &nbsp;it is able to enter into contracts through the System as a
              legal entity and that the{" "}
            </span>
            <span>R</span>
            <span class="c1">
              epresentative is entitled to enter into obligations on behalf of
              the User. The System may only be used as a{" "}
            </span>
            <span>R</span>
            <span class="c1">
              epresentative by persons over eighteen (18) years of age.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0" id="h.gjdgxs">
            <span class="c4">II. </span>
            <span class="c1 c4">2. General Terms of </span>
            <span class="c4">System</span>
            <span class="c5 c1 c4">&nbsp;Use</span>
          </p>
          <p class="c0">
            <span class="c5 c1 c4">
              II. 2.1. Prohibition of Bypassing the System
            </span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Parties involved in an Auction &ndash; the Customer creating an
              Auction and the Supplier or Suppliers submitting Bid(s) in the
              Auction &ndash; undertake not to contact each other directly,
              outside the System in connection with the subject of the Auction
              and not to establish business relationship with each other in
              relation to the subject of the Auction. Breach of the former
              obligations shall constitute a serious breach of contract in which
              case Mcule shall have the right to terminate these Terms and
              Conditions with immediate effect against the Party or Parties
              involved; furthermore, in such case, due to the breach of
              contract, Mcule is entitled to charge Customer 50% of the value of
              the legal transaction realized between the Parties by bypassing
              the System as a penalty against the Parties involved in the
              breach.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              It should be considered a bypassing of the System if a legal
              relationship on the sale and/or production of Compound(s) of a
              given Auction is established outside the Platform between the
              Customer and the Supplier submitting Bid(s) in the Auction.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c1 c8"></span>
          </p>
          <p class="c0">
            <span class="c5 c1 c4">II. 2.2. User-Uploaded Content</span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Users have the opportunity to upload content while using the
              System. To the extent, such content is considered as an
              intellectual creation and is the subject of intellectual property,
              ownership of this content remains with the uploading Party.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span>
              In all cases, the User is solely responsible for the content
              uploaded to the System by the User.{" "}
            </span>
            <span class="c1">
              The content uploaded to the System by the Users may not conflict
              with the law, infringe on the right of others or their legitimate
              interests; the content must not be, in particular: obscene,
              threatening, defamatory, infringing on privacy, infringing on
              intellectual property, offending to third parties
            </span>
            <span>. User&rsquo;s c</span>
            <span class="c1">ontent uploaded to the </span>
            <span>S</span>
            <span class="c2 c1">
              ystem must be free from computer viruses and must not contain
              political campaigns, commercial solicitations, chain, or mass
              mailings.
            </span>
          </p>
          <p class="c10">
            <span class="c1">During registration or </span>
            <span>S</span>
            <span class="c1">
              ystem usage false, unreal, or third party email{" "}
            </span>
            <span>addresses</span>
            <span class="c2 c1">&nbsp;must not be used.</span>
          </p>
          <p class="c10">
            <span class="c1">
              Mcule has the right to review the content uploaded to the System
              by the User and to initiate the modification or removal of content
              that violates these{" "}
            </span>
            <span>T</span>
            <span class="c1">erms and </span>
            <span>C</span>
            <span class="c1">
              onditions or to remove such content with immediate effect without
              notice to the Party concerned
            </span>
            <span>. </span>
            <span class="c2 c1">
              Mcule is not responsible for the content uploaded to the System by
              Users.
            </span>
          </p>
          <p class="c10">
            <span class="c1">
              If the content uploaded to the System by the User is intended to
              be shared with and understood by another Party involved in an{" "}
            </span>
            <span>A</span>
            <span class="c1">uction in accordance with these </span>
            <span>T</span>
            <span class="c1">erms and </span>
            <span>C</span>
            <span class="c1">
              onditions, then by uploading the User gives its consent for such{" "}
            </span>
            <span>sharing</span>
            <span class="c2 c1">
              . For this, the uploading User is not entitled to remuneration.
            </span>
          </p>
          <p class="c0">
            <span class="c5 c1 c4">
              II. 2.3. Account and Password Protection
            </span>
          </p>
          <p class="c0">
            <span>User acknowledges</span>
            <span class="c4">&nbsp;</span>
            <span>
              and agrees that Mcule will access, store, and where appropriate
              use the content associated with the User&rsquo;s Account.
            </span>
          </p>
          <p class="c10">
            <span class="c1">
              User must enter a password during the registration process to use
              and protect its Account.
            </span>
            <span>&nbsp;</span>
            <span class="c1">User is solely responsible for </span>
            <span>its</span>
            <span class="c1">&nbsp;Account and for all activities that </span>
            <span>occur</span>
            <span class="c1">&nbsp;under</span>
            <span>&nbsp;its</span>
            <span class="c1">&nbsp;</span>
            <span>A</span>
            <span class="c1">
              ccount. User must keep its Account&rsquo;s password secure, and is
              responsible for its security, preservation, and also the
              confidential management of{" "}
            </span>
            <span>its</span>
            <span class="c1">&nbsp;Account.</span>
            <span>&nbsp;</span>
            <span class="c1">User shall notify Mcule immediately </span>
            <span>upon</span>
            <span class="c1">
              &nbsp;detecting any problem in connection with the use of its
              Account.
            </span>
            <span class="c2 c1">
              &nbsp;Mcule shall not be liable for any damages, claims, or
              demands related to the use of the User&rsquo;s Account.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c5 c1 c4">II. 2.4. General Prohibitions</span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              User shall, at Mcule&rsquo;s request, provide information on who
              and how has used its Account, including the duration and nature of
              the usage.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              When using the Account, User must refrain from:
            </span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              - Using the System to infringe the rights of either the other
              Party or a third party (in particular: intellectual property,
              privacy, or public rights).
            </span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              - Interfering with the integrity, operation, and performance of
              the System, or obstructing its use.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              The System, its underlying content, all related intellectual
              creation, development, all software, images, text, graphics,
              illustrations, logos, service marks, photographs, and other
              content within the service and all intellectual property rights in
              the foregoing &ndash; not including intellectual property uploaded
              by Users in accordance with Section II. 2.2 &ndash; are
              exclusively owned by Mcule; any application of this property by
              Users for purposes other than described in these Terms and
              Conditions is strictly prohibited.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              The System itself, any Knowledge Material generated during or in
              connection with its use, or any content uploaded by any Party in
              the System which considered to be the intellectual property of the
              uploading Party must not be used in any way beyond those set forth
              in these Terms and Conditions, in particular: must not be copied,
              reproduced, modified, used to create derivative works, distributed
              or published. The System must not be used, either directly or
              indirectly, to advertise or recommend products or services that do
              not fall within the scope defined in the System, in particular, it
              must not be aimed at advertising or referring to other web
              services.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0" id="h.30j0zll">
            <span class="c2 c1">
              If the User causes damage, costs, any inconvenience to a third
              party, another Party during the use of the System, or by violating
              the rules of these Terms and Conditions, User is responsible for
              it and shall indemnify that Party.
            </span>
          </p>
          <p class="c0 c3 c13">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">II. </span>
            <span class="c1 c4">3. Supplier Terms of Use</span>
          </p>
          <p class="c0">
            <span class="c1">
              Supplier must declare whether it has a catalog or catalogs
              containing the compounds it produces or offers for sale during
              registration. If Supplier has such a{" "}
            </span>
            <span>catalog</span>
            <span class="c1">
              , Supplier must also state whether this catalog is updated at
              regular intervals. If Supplier has a catalog, Supplier must make
              it available to Mcule after registration, at the latest by the
              date of the first{" "}
            </span>
            <span>B</span>
            <span class="c1">id that Supplier </span>
            <span>submits</span>
            <span class="c1">.</span>
            <span>&nbsp;</span>
            <span class="c1">The availability of the catalog is </span>
            <span>considered to be</span>
            <span class="c1">
              &nbsp;appropriate if Mcule has unhindered access to its content.
            </span>
            <span>&nbsp;</span>
            <span class="c1">
              If Supplier has a catalog that is updated regularly, Supplier must
              notify Mcule when it gets update
            </span>
            <span>d</span>
            <span class="c2 c1">
              . Mcule is entitled to check compliance at any time. When Supplier
              updates its catalog(s), Supplier must notify Mcule immediately and
              provide Mcule with the updated catalog; failure to do so will
              constitute a breach of contract, in respect of which Mcule may
              terminate the legal relationship for the use of the System.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>To avoid misunderstandings, Suppliers who do not </span>
            <span class="c1">
              have catalogs are entitled to use the System, provided that they
              are not bound by this section, except for their statement
              obligation regarding the availability of the catalog.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c5 c1 c4">III. Request for Bids, Bid, Order</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c1">When using the System, legal </span>
            <span>relationships</span>
            <span class="c2 c1">
              &nbsp;may be established between Customer and Mcule as well as
              between Supplier and Mcule.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">III. </span>
            <span class="c1 c4">1. Request for Bids</span>
          </p>
          <p class="c0">
            <span class="c4">
              III. 1.1. Minimal Content of Request for Bids
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              Customer is entitled to create an Auction which is considered a
              request for{" "}
            </span>
            <span>B</span>
            <span class="c1">ids.</span>
            <span>&nbsp;</span>
            <span class="c1">
              When creating an Auction, Customer must define at least the
              following data as{" "}
            </span>
            <span>Auction</span>
            <span class="c2 c1">
              &nbsp;parameters and record them in the System:
            </span>
          </p>
          <p class="c0">
            <span class="c1">- </span>
            <span>C</span>
            <span class="c2 c1">ompound(s) to be manufactured,</span>
          </p>
          <p class="c0">
            <span class="c1">
              - the quantity and minimum guaranteed purity of the{" "}
            </span>
            <span>C</span>
            <span class="c1">ompound</span>
            <span>(</span>
            <span class="c2 c1">s) to be manufactured,</span>
          </p>
          <p class="c0">
            <span class="c2 c1">- the End Date of the Auction.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              When creating an Auction, Customer must declare whether{" "}
            </span>
            <span>it</span>
            <span class="c1">&nbsp;provides a</span>
            <span>&nbsp;Quality Control Method</span>
            <span class="c1">&nbsp;as a reference.</span>
            <span>&nbsp;</span>
            <span class="c2 c1">
              If Customer has any special needs or special expectations
              regarding its requested Compound(s) or associated service or
              Supplier (in particular: an embargo or restrictions on Suppliers
              and/or third parties registered in particular countries
              potentially involved in the performance or delivery) Customer
              needs to indicate such needs in advance in the System when
              creating the Auction. If Customer requests a report on the
              progress of the fulfillment of the Order, Customer needs to
              specify in the Auction the frequency and the minimum content of
              such reports from the Supplier.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">III. </span>
            <span class="c1 c4">1.</span>
            <span class="c4">2</span>
            <span class="c1 c4">. Criteria for the </span>
            <span class="c4">C</span>
            <span class="c5 c1 c4">ompound(s)</span>
          </p>
          <p class="c0">
            <span class="c1">The </span>
            <span>C</span>
            <span class="c1">
              ompound(s) of the Auction may not be listed as a{" "}
            </span>
            <span>controlled substance</span>
            <span class="c1">
              &nbsp;in the Customer&rsquo;s country of delivery. The{" "}
            </span>
            <span>C</span>
            <span class="c2 c1">
              ompound(s) is considered to be a controlled substance if either of
              its production, supply, domestic transport, possession, usage,
              application for research and development purposes conflicts with
              legislation or official regulations, or is subject to special
              permit(s).
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">When creating an Auction, Customer must </span>
            <span>declare</span>
            <span class="c1">&nbsp;that the </span>
            <span>C</span>
            <span class="c1">ompound(s) specified in </span>
            <span>
              the Auction is not a controlled substance according to the
              relevant laws of Customer&rsquo;s countries. Customer shall be
              liable for the incorrect content of this state
            </span>
            <span class="c1">
              ment, for incorrect or inadequate knowledge of the relevant
              legislation, for non-compliance with it, for violating it, and for
              any damage, claim, demand, or sanction{" "}
            </span>
            <span>arising</span>
            <span class="c1">&nbsp;from the above.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              When creating an Auction, Customer must declare that, to the best
              of its knowledge, the Compound(s) is not covered by a valid patent
              or other industrial property rights that may prohibit its
              manufacture, sale, use for the purpose intended by Customer, or
              exploitation in any other economic activity. If the Compound(s) is
              protected by such property rights, the Customer shall be entitled
              to its use. Customer is responsible for the accuracy and veracity
              of this statement. Customer shall be liable for the incorrect
              content of the statement, for incorrect or inadequate knowledge of
              the relevant intellectual property rights, legislation, for
              non-compliance with it, for violating it, and for any damage,
              claim, demand, or sanction arising from the above.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>
              Supplier shall at each and every Bid declare that the Compound(s)
              specified in the Auction is not on the Prohibited List in the
              manufacturing country of the Supplier.
            </span>
            <span class="c1">&nbsp;Supplier</span>
            <span>&nbsp;</span>
            <span class="c1">
              shall be liable for the incorrect content of the statement, for
              incorrect or inadequate knowledge of the relevant legislation, for
              non-compliance with it, for violating it, and for any damage,
              claim, demand, or sanction{" "}
            </span>
            <span>arising</span>
            <span class="c2 c1">&nbsp;from the above.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>Supplier shall at each and every Bid declare </span>
            <span class="c1">
              that, to the best of its knowledge, the Compound(s) is not covered
              by a valid patent or other industrial property right in the{" "}
            </span>
            <span>country in which the Supplier</span>
            <span class="c1">&nbsp;is operating</span>
            <span>&nbsp;t</span>
            <span class="c2 c1">
              hat may prohibit its manufacture, sale, use for the purpose
              intended by Supplier, or exploitation in any other economic
              activity. If the Compound(s) is protected by such property rights,
              the Supplier shall be entitled to its use. The Supplier is
              responsible for the accuracy and veracity of this statement.
              Supplier shall be liable for the incorrect content of its
              statement, for incorrect or inadequate knowledge of the relevant
              intellectual property rights, legislation, for non-compliance with
              it, for violation thereof, and for any damage, claim, demand, or
              sanction arising from the above.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">III. </span>
            <span class="c1 c4">1.</span>
            <span class="c4">3</span>
            <span class="c1 c4">. Starting an Auction, </span>
            <span class="c4">D</span>
            <span class="c1 c4">etermining the </span>
            <span class="c4">E</span>
            <span class="c5 c1 c4">nd Date of the Auction</span>
          </p>
          <p class="c0">
            <span class="c1">Mcule, as the </span>
            <span>Administrator</span>
            <span class="c1">&nbsp;of the System, will perform </span>
            <span>an analysis </span>
            <span class="c1">o</span>
            <span>n</span>
            <span class="c1">&nbsp;the Auction created </span>
            <span>by the Customer</span>
            <span class="c1">. Mcule will conduct the </span>
            <span>analysis</span>
            <span class="c1">
              &nbsp;within one (1) business day of the creation of the Auction.
              If as a result of the{" "}
            </span>
            <span>analysis</span>
            <span class="c1">
              &nbsp;the Auction is considered eligible, Mcule approves and
              launches the Auction (
            </span>
            <span>Open</span>
            <span class="c1">&nbsp;Auction). If Mcule finds any </span>
            <span>issues</span>
            <span class="c1">&nbsp;during the </span>
            <span>analysis</span>
            <span class="c1">
              , Mcule will contact the Customer who created the{" "}
            </span>
            <span>A</span>
            <span class="c1">uction immediately </span>
            <span>to solve the issue</span>
            <span class="c2 c1">.</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span>
              When creating an Auction, Customer must specify how long the
              Auction will last (End Date). The Auction remains active (i.e.
              Bids may be submitted) for a maximum of fifteen (15) calendar days
              after the Auction is created/submitted. Valid Bids can be
              submitted only after the Auction has been approved and launched by
              Mcule
            </span>
            <span class="c1">.</span>
            <span class="c2 c1">
              &nbsp;When creating an Auction, Customer has the option to select
              which Suppliers can make Bids. A Bid may be made during the
              specified period by the registered Suppliers selected by the
              Customer. Customer may only accept Bids after the Auction has been
              closed.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              An immediate closure of an Auction &ndash; i.e. closing before its
              pre-determined End Date &ndash; is only possible if this option
              was selected by the Customer when creating the Auction. In case
              the Customer did not select this option when creating the Auction,
              the Auction can only be prematurely closed by defining a new End
              Date, which shall not be earlier than three (3) calendar days from
              initiating such a premature closure. In case Customer has
              initiated a premature closure of the Auction, there is no
              possibility to withdraw it and to restore the original Auction End
              Date. An Auction may not be extended.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>
              Customer may cancel the Auction at any time before a corresponding
              Order is created. Cancelation of an Auction means its termination.
              If the Auction is canceled or closed as described above, the
              participating Suppliers may not make any claim or demand against
              either Customer or Mcule.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">III. </span>
            <span class="c1 c4">1.</span>
            <span class="c4">4</span>
            <span class="c1 c4">. Recording of Q</span>
            <span class="c4">C</span>
            <span class="c1 c4">&nbsp;</span>
            <span class="c4">M</span>
            <span class="c5 c1 c4">ethod</span>
          </p>
          <p class="c0">
            <span>
              The Quality Control (QC) Method is an analytical method that is
              used to determine the identity and/or purity of the Compound(s).
              The QC Method{" "}
            </span>
            <span class="c1">
              is used to settle which analytical method is{" "}
            </span>
            <span>
              acceptable for quality-related (identity and/or purity of the
              ordered Compound(s)) claims
            </span>
            <span class="c2 c1">, in particular, to settle disputes.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>
              When creating an Auction Customer must declare whether or not it
              defines a QC Method to determine the identity and/or{" "}
            </span>
            <span class="c1">purity of the Compound(s) </span>
            <span>subject of an A</span>
            <span class="c1">uction.</span>
            <span>&nbsp;</span>
            <span class="c1">In case Customer defines a QC </span>
            <span>
              Method, it must be defined straightforwardly, and by uploading
              appropriate documents to the System if necessary. By accepting
              these Terms, Customer acknowledges that the defined and uploaded
              QC Method shall not be covered by a valid patent or other
              industrial property rights that may prohibit its use or
              exploitation in any other economic activity. The responsibility of
              Mcule and Supplier is excluded in connection with the foregoing.
              If Customer is entitled to use the industrial property right of
              the defined QC Method, then by uploading Customer expressly
              consent to the use of such patent by the other Party to the extent
              necessary under these Terms.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">If Customer does not define </span>
            <span>
              a QC Method but the Supplier contributing in the fulfillment of
              the Order &ndash; the Party in Mcule Order &ndash; provides a QC
              Method when submitting the Bid, this will be the method of choice
              to determine the identity and/or{" "}
            </span>
            <span class="c1">purity of the Compound(s) </span>
            <span>subject of the A</span>
            <span class="c1">
              uction. It has to be defined straightforwardly and by uploading
              the appropriate document to the System if necessary. By accepting
              these Terms, Supplier acknowledges that the defined and uploaded
              QC Method shall not be covered by a valid patent or other
              industrial property rights that may prohibit its use or
              exploitation in any other economic activity. The responsibility of
              Mcule and Customer is excluded in connection with{" "}
            </span>
            <span>the foregoing</span>
            <span class="c2 c1">
              . If Supplier is entitled to use the industrial property right of
              the defined QC Method, then by uploading it Supplier expressly
              consent to the use of such patent by the other Party to the extent
              necessary under these Terms.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              In case neither Customer nor Supplier has provided a QC Method,{" "}
            </span>
            <span class="c1">the LC-MS method specified by Mcule</span>
            <span class="c1">&nbsp;will govern, if necessary, </span>
            <span>to determine the identity and/or </span>
            <span class="c1">purity of the Compound(s) </span>
            <span>subject of the A</span>
            <span class="c1">uction. </span>
            <span>Mcule&rsquo;s LC-MS method can be found </span>
            <span class="c18">
              <a href={pdfFile} target="_blank">
                here
              </a>
            </span>
            <span class="c2 c1">.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">Parties accept the specific </span>
            <span>QC Method</span>
            <span class="c1">
              &nbsp;defined above and its results and conclusions to verify the
              orderly fulfillment of the Customer Order &ndash; and, at the same
              time, that of Mcule Order &ndash; and they{" "}
            </span>
            <span class="c2 c1">
              waive the right of further investigation, contradiction, or
              dispute.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              The handling of the QC Methods specified by the Customer and the
              Supplier, as well as the handling of any related documentation
              provided/uploaded to the System, shall be{" "}
            </span>
            <span>governed</span>
            <span class="c1">
              &nbsp;by the confidentiality provisions of these{" "}
            </span>
            <span>T</span>
            <span class="c1">erms.</span>
            <span>&nbsp;</span>
            <span class="c1">
              Supplier shall not use the QC Method provided by the Customer for
              purposes other than those{" "}
            </span>
            <span class="c1">
              described in this section. Customer shall not use the{" "}
            </span>
            <span>QC Method</span>
            <span class="c2 c1">
              &nbsp;provided by the Supplier for purposes other than those
              described in this section.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">III. </span>
            <span class="c1 c4">1.</span>
            <span class="c4">5</span>
            <span class="c1 c4">. Request for Bids with </span>
            <span class="c4">A</span>
            <span class="c1 c4">dditional </span>
            <span class="c4">C</span>
            <span class="c1 c4">onditions, </span>
            <span class="c4">E</span>
            <span class="c5 c1 c4">xclusivity </span>
          </p>
          <p class="c0">
            <span class="c1">Customer </span>
            <span>can</span>
            <span class="c1">
              &nbsp;define special needs and additional conditions when creating
              the{" "}
            </span>
            <span>A</span>
            <span class="c1">
              uction. Such an additional condition is that Customer may request
              exclusivity for the Compound(s).
            </span>
            <span>&nbsp;</span>
            <span class="c1">
              Exclusivity means, in the application and interpretation of these
              Terms and Conditions and any Order, that Suppliers and any
              Contributor t
            </span>
            <span>o</span>
            <span class="c1">
              &nbsp;Supplier on the basis of Section IV. 4 fulfilling the Mcule
              Order, can only{" "}
            </span>
            <span>produce</span>
            <span class="c1">
              , sell or transfer the Compound(s), subject of the Auction, to
              Mcule and Mcule to Customer for a period specified by Customer.{" "}
            </span>
            <span>
              If Customer requests exclusivity, Customer must indicate this when
              creating the Auction and must also specify the period of
              exclusivity at creation. The start date of the exclusivity period
              is the date of the Customer Order.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">To avoid misunderstandings, i</span>
            <span>
              n case of exclusivity, Mcule may not sell the Compound(s) to
              anyone other than Customer and Supplier may not sell the
              Compound(s) to anyone other than Mcule.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              By accepting these Terms and Conditions, Customer acknowledges and
              agrees that any claim or demand against Mcule for breaching
              exclusivity or any other pre-determined special need, may be made
              only in the event of willful or gross negligence, and
              Mcule&rsquo;s liability for such claim shall not exceed the amount
              of Customer Orders of the Compound(s) subject to exclusivity.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">III. </span>
            <span class="c5 c1 c4">2. Bidding</span>
          </p>
          <p class="c0">
            <span class="c1">Supplier may submit multiple Bids during </span>
            <span>an </span>
            <span class="c1">
              Auction, even with different professional content.
            </span>
            <span>&nbsp;</span>
            <span class="c1">The </span>
            <span>Bid</span>
            <span class="c1">
              &nbsp;shall include all parameters specified in{" "}
            </span>
            <span class="c1">Section III. 1</span>
            <span class="c2 c1">
              .1 and the additional queries requested in the Auction. Bids that
              do not contain all the required parameters below are considered
              invalid:
            </span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              - Compound price, delivery cost and all other costs of Supplier to
              be incurred,
            </span>
          </p>
          <p class="c0">
            <span class="c2 c1">- lead time,</span>
          </p>
          <p class="c0">
            <span class="c2 c1">- quantity,</span>
          </p>
          <p class="c0">
            <span class="c2 c1">- purity,</span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              - exact/minimum number of Compound(s) to Bid.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              The Bid must include the full cost to be incurred on
              Supplier&rsquo;s side, including delivery charges, other
              additional condition related costs (e.g. exclusivity), extra
              services (e.g. reporting), and applicable taxes.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>
              If Customer has requested a report in the Auction, during the
              preparation of the Bid, Supplier has the option to upload a report
              template to inform the Customer on the quality of the expected
              reporting. Such report templates shall not include{" "}
            </span>
            <span>
              company names, trademarks, logos, and any kind of reference that
              enables the identification of the{" "}
            </span>
            <span class="c2 c1">Supplier.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>
              During the Auction, Bids may be withdrawn by the Supplier before
              the Auction&rsquo;s End Date. From the End Date of the Auction,
              Supplier shall be bound by the Bid(s) submitted by Supplier in the
              given Auction, which binding lasts until the 50
            </span>
            <span class="c7">th</span>
            <span class="c2 c1">
              &nbsp;calendar day from the creation of the Auction. All Suppliers
              submitting Bids are bound by the Offer binding.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              The System automatically generates Offers from the Bids made by
              Suppliers, which are considered to be Offers prepared by Mcule for
              Customer. The Offer prepared by Mcule for Customer includes the
              full cost incurred, including delivery costs, charges for any
              additional conditions (e.g. exclusivity), fees for extra services
              (e.g. reporting), applicable taxes, and management costs.{" "}
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">III. </span>
            <span class="c1 c4">3. Creating and </span>
            <span class="c4">E</span>
            <span class="c5 c1 c4">diting Auctions and Bids</span>
          </p>
          <p class="c0">
            <span class="c1">
              The System provides the possibility for the Customer to save and
              edit Auctions in a draft form (&ldquo;Auction Draft&rdquo;) and
              for the Supplier to save and edit Bids in a draft form (&ldquo;Bid
              Draft&rdquo;). The content of the Auction
            </span>
            <span>&nbsp;Drafts</span>
            <span class="c1">&nbsp;and Bid</span>
            <span>&nbsp;Drafts</span>
            <span class="c1">&nbsp;can be modified until the</span>
            <span>&nbsp;final Auctions and Bids</span>
            <span class="c1">
              &nbsp;are submitted, respectively. Such Auction
            </span>
            <span>&nbsp;D</span>
            <span class="c1">rafts and Bid</span>
            <span>&nbsp;D</span>
            <span class="c1">
              rafts shall not be deemed to have been created yet,{" "}
            </span>
            <span>consequently they </span>
            <span class="c1">do not constitute </span>
            <span>valid</span>
            <span class="c1">
              &nbsp;Auctions or Bids. Auctions and Bids will only be created,
              become active, and also not editable at the time of their{" "}
            </span>
            <span>submission</span>
            <span class="c2 c1">.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c4">III. </span>
            <span class="c5 c1 c4">4. Placing an Order</span>
          </p>
          <p class="c0">
            <span class="c4">III. </span>
            <span class="c1 c4">4.1. Auction </span>
            <span class="c4">C</span>
            <span class="c1 c4">los</span>
            <span class="c4">ure</span>
            <span class="c1 c4">, </span>
            <span class="c4">S</span>
            <span class="c1 c4">election of the </span>
            <span class="c4">W</span>
            <span class="c1 c4">inning </span>
            <span class="c4">B</span>
            <span class="c5 c1 c4">id</span>
          </p>
          <p class="c0">
            <span class="c1">The Auction will </span>
            <span>be completed</span>
            <span class="c1">
              &nbsp;successfully if at least one valid Bid is submitted before
              the closure of the Auction.
            </span>
            <span>
              &nbsp;Customer is entitled to decide (choose) which Offer to
              accept on the appropriate interface of the System. Customer has
              the opportunity to do so after the Auction closes, but not later
              than forty-five (45) calendar days after the creation of the
              Auction.{" "}
            </span>
            <span class="c1">The Supplier who submits the Bid corres</span>
            <span>ponding to the </span>
            <span>accepted</span>
            <span class="c1">&nbsp;</span>
            <span>Offer</span>
            <span class="c1">
              &nbsp;shall be deemed the Party of the Mcule Order.
            </span>
            <span class="c2 c1">
              &nbsp;Customer has the right not to accept any Offers.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              If Customer fails to meet the forty-five (45) calendar days
              deadline, Customer may contact and notify Mcule which of the
              Offers received during the Auction Customer intends to accept. In
              such a case, Mcule consults with the Supplier concerned, who may
              decide whether to maintain the terms of its Bid and undertake to
              produce the subject of the Auction under the original term and
              content.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Acceptance of the Offer by the Customer does not yet create a
              legal relationship (Order) between the Customer, Mcule, and the
              selected Supplier.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c5 c1 c4">
              III. 4.2. Customer Order Established between Customer and Mcule
            </span>
          </p>
          <p class="c0">
            <span class="c5 c1 c4">
              III. 4.2.1. Customer Order Confirmation by Customer
            </span>
          </p>
          <p class="c0">
            <span>
              After selecting the winning Offer according to Section III. 4.1,
              Customer (i) must select how to fulfill its obligations to pay the
              purchase price according to Section{" "}
            </span>
            <span>V. 2</span>
            <span class="c2 c1">
              &nbsp;and (ii) must declare whether to establish a legal
              relationship between Customer and Mcule (Customer Order) on the
              basis of these Terms and Conditions or under different conditions.
              If Customer proposes to apply conditions other than those set
              forth in these Terms and Conditions, Customer must send these to
              Mcule either through the System (Customer Purchase Order upload
              option) or by email.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">
              III. 4.2.2. Customer Order Confirmation by Mcule
            </span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              After Customer made its declarations according to Section III. 4.1
              on winning Offer and according to Section III. 4.2.1, Mcule will
              perform checks on the Compound(s) subject of the Customer Order as
              described in Section III. 4.4 of these Terms and Conditions.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Following the above, if Customer proposes to apply conditions
              other than those set forth in these Terms and Conditions, Mcule
              may contact the Supplier who submitted the winning Bid to confirm
              the acceptance of such different conditions. Customer agrees that
              Mcule may share such conditions with the Supplier who submitted
              the winning Bid. The Supplier who submitted the winning Bid must
              declare whether it accepts such conditions different from those
              set forth in these Terms and Conditions.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              If Customer proposes to apply conditions other than those set
              forth in these Terms, Mcule may consult with Supplier on the
              subject before the confirmation of Customer Order by Mcule. In
              case the additional conditions are acceptable, Mcule notifies the
              Customer about the acceptance of the Customer Order via the System
              (confirmation). Mcule&rsquo;s confirmation governs whether the
              legal relationship between Customer and Mcule is subject to these,
              different or additional terms and conditions. In this
              confirmation, Mcule also indicates the payment deadline of the
              purchase price of the Compound(s) subject to the Customer Order by
              Customer to Mcule.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              If the Customer does not have a Credit Account defined in Section
              V. 2, the purchase price of the Compound(s) subject of the
              Customer Order shall be paid for the Customer Order to take
              effect. The Customer Order between Customer and Mcule becomes
              valid and effective by crediting this purchase price to
              Mcule&rsquo;s account.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              If the Customer has a Credit Account as defined in Section V. 2,
              the date of Customer Order as a legal relationship becomes valid
              and enters into force on the date of the receipt of the
              confirmation defined in this section from Mcule to Customer.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c5 c1 c4">
              III. 4.3. Mcule Order Established between Mcule and Supplier
            </span>
          </p>
          <p class="c0">
            <span>After the Customer Order becomes effective,</span>
            <span class="c4">&nbsp;</span>
            <span class="c2 c1">
              Mcule places an order (Mcule Order) with the Supplier who
              submitted the winning Bid via the System and/or by email. This
              Mcule Order contains the statement by Mcule that the legal
              relationship between Mcule and the Supplier is governed solely by
              these Terms and Conditions or other conditions should also be
              applied.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>
              If the legal relationship between Mcule and the Supplier (Mcule
              Order) is governed solely by these Terms and Conditions, the legal
              relationship will be established when the Supplier confirms the
              Mcule Order. In such a case, Mcule Order becomes valid and
              effective on the day the Supplier&rsquo;s confirmation of
              acceptance is indicated on or uploaded to the System or sent to
              Mcule by email. Supplier undertakes to respond and make a
              statement of Mcule Order&rsquo;s purchase order confirmation no
              later than two (2) calendar days after the date of Mcule places an
              Order with the Supplier.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              If the relationship between Mcule and Supplier (Mcule Order) is
              subject to other conditions than those set forth in these Terms
              and Conditions, the relationship between Mcule and Supplier (Mcule
              Order) will be established upon acceptance of the other conditions
              by Supplier. In such a case, Mcule Order becomes valid and
              effective on the day the Supplier&rsquo;s confirmation of
              acceptance is indicated on or uploaded to the System or sent to
              Mcule by email. Supplier undertakes to respond and make a
              substantive statement whether it accepts the conditions other than
              those set forth in these Terms and Conditions for the legal
              relationship between Mcule and the Supplier (Mcule Order) no later
              than two (2) calendar days after the date of Mcule places an Order
              with the Supplier.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              If the Supplier does not accept the conditions different from
              these Terms and Conditions for the legal relationship between
              Mcule and Supplier (Mcule Order), then the legal relationship
              between Mcule and Supplier will not be established (Mcule Order is
              canceled). In this case, Mcule is entitled to cancel the
              corresponding Customer Order according to Section III. 4.2; in the
              event of such a waiver, Customer may not make any claims or
              demands against Mcule.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">III. </span>
            <span class="c1 c4">4.4. Compound </span>
            <span class="c4">B</span>
            <span class="c1 c4">an-</span>
            <span class="c4">L</span>
            <span class="c1 c4">ist and </span>
            <span class="c4">P</span>
            <span class="c1 c4">atent </span>
            <span class="c4">C</span>
            <span class="c5 c1 c4">ontrol</span>
          </p>
          <p class="c0">
            <span class="c1">
              After the Customer Order is established between Customer and Mcule
              according to Section III. 4.2.1, Mcule{" "}
            </span>
            <span>is not obligated but may</span>
            <span class="c1">&nbsp;check whether the </span>
            <span>C</span>
            <span class="c1">
              ompound(s) subject to the Customer Order meets the requirements of
              Section III. 1.
            </span>
            <span>2</span>
            <span class="c1">
              &nbsp;above, i.e. whether it is considered as a controlled
            </span>
            <span>&nbsp;substance</span>
            <span class="c2 c1">&nbsp;or it infringes patent rights.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">If, as a result, Mcule concludes that the </span>
            <span>C</span>
            <span class="c1">ompound(s) is a controlled</span>
            <span>&nbsp;substance</span>
            <span class="c2 c1">
              &nbsp;and/or shall potentially infringe a patent or any other
              intellectual property rights of a third party, Mcule will
              immediately notify the Customer and the winning Supplier and
              consult this case with them. To avoid the occurrence of breach
              Mcule has the right to terminate the Order if the answer is not
              reassuring to the questions raised.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">Customer and</span>
            <span>&nbsp;</span>
            <span class="c1">Supplier</span>
            <span>&nbsp;</span>
            <span class="c1">acknowledge and agree that Mcule&rsquo;s </span>
            <span>check</span>
            <span class="c1">&nbsp;or the lack of </span>
            <span>it</span>
            <span class="c1">
              &nbsp;does not exempt either Customer or Supplier from the
              liability in respect of its declarations under Section III. 1.
            </span>
            <span>2</span>
            <span class="c1">.</span>
            <span>&nbsp;</span>
            <span class="c1">Mcule&rsquo;s </span>
            <span>check</span>
            <span class="c1">
              &nbsp;carried out in accordance with Section III. 4.4 is for
              information purposes only, and failure to do so, or any incomplete
              or incorrect result does not exempt Customer or Supplier from the
              obligations imposed on them under{" "}
            </span>
            <span>these</span>
            <span class="c1">&nbsp;</span>
            <span>T</span>
            <span class="c1">erms and </span>
            <span>C</span>
            <span class="c1">onditions</span>
            <span>&nbsp;and it neither</span>
            <span class="c1">&nbsp;results in shared responsibility.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">III. </span>
            <span class="c5 c1 c4">4.5. Priority of the Order</span>
          </p>
          <p class="c0">
            <span>I</span>
            <span class="c1">
              f there is a discrepancy between an Order&rsquo;s conditions and{" "}
            </span>
            <span>these</span>
            <span class="c2 c1">
              &nbsp;Terms and Conditions, the provisions of the Order shall
              prevail.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c4">III. </span>
            <span class="c5 c1 c4">5. Order Withdrawal and Cancellation</span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              If Customer withdraws or cancels an already placed Customer Order,
              Customer must reimburse Mcule for the costs incurred and justified
              up to the date of withdrawal or termination, as well as the
              working hours and resources spent on fulfilling the Customer
              Order. In such a case Mcule may withdraw from Mcule Order and is
              obliged to reimburse Supplier for the costs incurred and justified
              up to the date of withdrawal as well as for its recorded working
              hours and resources spent on the fulfillment of Mcule Order.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              In the event Supplier is unable to fulfill Mcule Order &ndash; and
              consequently Mcule the Customer Order &ndash; because Supplier is
              unable to synthesize the{" "}
            </span>
            <span>C</span>
            <span class="c1">ompound(s) or fails to</span>
            <span>
              &nbsp;synthesize it for any reason, Customer may declare its need
              for the results so far, to document{" "}
            </span>
            <span>them.</span>
            <span>&nbsp;</span>
            <span>
              In the event of such a claim, Supplier shall provide the results
              and their documentation to Mcule and Mcule to Customer without
              reimbursement.{" "}
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c5 c1 c4">IV. Obligations of Supplier</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">IV. </span>
            <span class="c5 c1 c4">1. Professional Obligations </span>
          </p>
          <p class="c0">
            <span class="c1">
              Supplier is obliged to fulfill Mcule Order to the highest
              professional standard expected. Supplier is obliged to{" "}
            </span>
            <span>submit</span>
            <span class="c1">&nbsp;only such Bids </span>
            <span>for which</span>
            <span class="c1">&nbsp;</span>
            <span>Supplier has</span>
            <span class="c1">
              &nbsp;sufficient capacity, professional conditions, and resources
              to fulfill the contents of the{" "}
            </span>
            <span>A</span>
            <span class="c1">
              uction. To comply with this Supplier has an unlimited warranty and
              liability.
            </span>
            <span>&nbsp;</span>
            <span class="c1">
              It is the Supplier&rsquo;s responsibility to produce the{" "}
            </span>
            <span>C</span>
            <span class="c1">ompound(s) that </span>
            <span>is</span>
            <span class="c1">
              &nbsp;the subject of the Mcule Order. In doing so, Supplier must
              comply with the Mcule Order and{" "}
            </span>
            <span>these</span>
            <span class="c2 c1">&nbsp;Terms and Conditions.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Supplier shall act during the performance in accordance with the
              applicable laws and regulations for preparation of{" "}
            </span>
            <span>C</span>
            <span class="c2 c1">
              ompound(s), including the applicable legal and professional rules
              of the state of production (either the country of the Supplier or
              of a third party).
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Supplier shall promptly notify Mcule in writing of any
              circumstances that prevent or delay the performance of its duties.
              Mcule will promptly provide such information to Customer.
            </span>
            <span>&nbsp;</span>
            <span class="c2 c1">
              Supplier shall, at Mcule&rsquo;s request, provide information on
              the performance of the tasks at any stage.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              If Supplier has undertaken a reporting obligation in its Bid,
              Supplier shall comply with it as specified in the Bid or the
              corresponding Auction. If Supplier has uploaded a report template
              along with its winning Bid, Supplier is obliged to create and
              upload a report in accordance with that template during the
              execution of the Order.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Supplier acknowledges and agrees that Mcule will acquire ownership
              rights of the{" "}
            </span>
            <span>C</span>
            <span class="c1">ompound(s) at the time of </span>
            <span>Supplier fulfilling Mcule Order.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">IV. </span>
            <span class="c1 c4">2. Obligation to </span>
            <span class="c4">C</span>
            <span class="c5 c1 c4">ooperate</span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Supplier shall act in good faith, in cooperation with Mcule and
              Customer, representing each other&rsquo;s interests, protecting
              each other, and informing each other of all relevant circumstances
              during the fulfillment of Mcule Order.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Supplier undertakes to cooperate with Customer upon
              Customer&rsquo;s request &ndash; in particular: to make all
              statements, provide all necessary documentation &ndash; in order
              to Customer being able to initiate proceedings and obtain patent
              protection or any other intellectual property protection for the
              Compound(s) subject to the Order. In case any cost incurs on
              Supplier&rsquo;s side in connection with the fulfillment of the
              above, Customer shall reimburse this to Mcule and Mcule to
              Supplier. Supplier undertakes to execute (including to require its
              employees and former employees to execute) when requested by
              Customer, patent, copyright, trademark, and/or other similar
              applications and assignments as Customer may deem necessary to
              protect Customer&rsquo;s IP rights.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Supplier shall keep and maintain an accurate record of all
              documentation, execution phases, and work processes related to the
              fulfillment of the Order, in particular, to be able to fulfill the
              former obligation, which Supplier shall keep at least for five (5)
              years from the fulfillment of the Mcule Order.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>
              Upon request of Customer or Mcule, Supplier shall provide such
              records to Customer or Mcule. With regard to these records,
              Supplier is bound by an obligation of confidentiality without any
              time limit.{" "}
            </span>
            <span>
              The content of these records shall be considered the intellectual
              property of Customer.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c4">IV. </span>
            <span class="c5 c1 c4">3. Warranty</span>
          </p>
          <p class="c0">
            <span class="c1">Supplier warrants for the </span>
            <span>C</span>
            <span class="c1">ompound(s</span>
            <span>)</span>
            <span class="c1">
              &nbsp;produced and delivered as the fulfillment of Mcule Order
              according to th
            </span>
            <span>ese</span>
            <span class="c2 c1">&nbsp;Terms and Conditions.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">IV. </span>
            <span class="c5 c1 c4">4. Contributors</span>
          </p>
          <p class="c0">
            <span class="c1">Supplier is entitled to involve</span>
            <span>&nbsp;a Contributor </span>
            <span class="c1">&ndash;</span>
            <span>&nbsp;for instance</span>
            <span class="c1">
              &nbsp;a subcontractor, performance assistant, or other
              contributors &ndash;, who contributes to the fulfillment of an
              Mcule Order on the Supplier&rsquo;s side, regardless of the type
              of legal relationship between the Supplier and this third party.
              If Mcule objects to the designated Contributor, Supplier shall
              immediately replace such a Contributor.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Supplier shall be liable for the Contributor used as if the
              Supplier had performed the task. Supplier agrees to enter into a
              contract with any Contributor that complies with th
            </span>
            <span>ese</span>
            <span class="c1">
              &nbsp;Terms and Conditions and the particular Mcule Order in all
              respects. At Mcule&rsquo;s request, Supplier shall make this
              contract available to Mcule.
            </span>
            <span class="c2 c1">
              &nbsp;If Mcule requests information on who has been involved in
              the fulfillment of a particular Mcule Order as Contributor(s),
              Supplier shall provide the relevant information.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">IV. </span>
            <span class="c5 c1 c4">
              5. Requirements on Shipping and Delivery Deadline
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              During the fulfillment of Mcule Order, Supplier shall act
              according to the mode of transport specified in the Auction,
              keeping the fulfillment deadline.
            </span>
            <span>
              &nbsp;If Supplier fails to meet the Mcule Order delivery deadline
              and, as a result, Mcule fails to meet the Customer Order delivery
              deadline towards
            </span>
            <span>&nbsp;Customer</span>
            <span class="c2 c1">
              , Customer may withdraw from the Customer Order with Mcule. In
              such a case Mcule is also entitled to withdraw from Mcule Order.
              In the event of such withdrawal, neither Mcule nor Customer shall
              be required to reimburse the Supplier for its costs and Customer
              shall not be required to reimburse Mcule for its costs.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c1 c4">V. Obligations of Customer</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c4">V. </span>
            <span class="c1 c4">1. Obligation to </span>
            <span class="c4">C</span>
            <span class="c5 c1 c4">ooperate</span>
          </p>
          <p class="c0">
            <span class="c1">Customer shall act in good faith, in</span>
            <span>&nbsp;</span>
            <span class="c2 c1">
              cooperation with Mcule and Supplier, representing each
              other&rsquo;s interests, protecting each other, and informing each
              other of all relevant circumstances during the fulfillment of the
              Order.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">V. </span>
            <span class="c1 c4">2. Payment of </span>
            <span class="c4">P</span>
            <span class="c1 c4">urchase </span>
            <span class="c4">P</span>
            <span class="c5 c1 c4">rice of Customer Order</span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Customer shall pay Mcule the remuneration (purchase price) of the
              Compound(s) subject of the Order. The purchase price must be paid
              in advance to Mcule. Following Customer Order Confirmation by
              Mcule, Mcule will issue a proforma invoice to Customer that
              includes the due date and other required information for making
              the payment. The due date cannot be later than the Offer binding.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              If Customer has a Credit Account, Customer has the option to pay
              the purchase price after fulfillment &ndash; after the Compound(s)
              subject to the Customer Order has been sent to Customer &ndash;
              but within thirty (30) calendar days of the Compound(s) have been
              sent. Upon individual arrangement of the Parties, the due date to
              a given Credit Account may be modified. The Compound(s) subject to
              the Customer Order shall be deemed to have been sent when
              dispatched or handed over (as a consignment) to a postal service
              provider, courier service, conveyer, or carrier.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              At the Customer&rsquo;s request, Mcule will investigate whether to
              provide a Credit Account to Customer and will notify Customer of
              the decision. Mcule&rsquo;s decision in this regard is based
              solely on its own judgment, its own business decision; no claim or
              demand may be made regarding the decision on the Credit Account.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              In case of late payment, the default annual interest rate is 10%
              of the amount affected by the delay.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              If the Compound(s) subject to the Customer Order is not produced
              and delivered in full quantity or not all of the Compounds are
              produced and delivered to the Customer (Partial Performance),
              Customer is still obliged to pay Mcule a duly proportional part of
              the purchase price. By accepting these Terms and Conditions,
              Parties expressly accept the possibility of Partial Performance as
              described above.{" "}
            </span>
          </p>
          <p class="c0 c3">
            <span class="c8 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">V. </span>
            <span class="c1 c4">3. Obligation to Package </span>
            <span class="c4">T</span>
            <span class="c1 c4">ake </span>
            <span class="c4">O</span>
            <span class="c1 c4">ver and </span>
            <span class="c4">I</span>
            <span class="c5 c1 c4">nspection</span>
          </p>
          <p class="c0">
            <span class="c1">
              Customer shall, as soon as possible after receipt of the{" "}
            </span>
            <span>C</span>
            <span class="c1">
              ompound(s) subject to the Customer Order, ensure that the shipment
              (vial or other storage{" "}
            </span>
            <span>containers</span>
            <span class="c1">&nbsp;of the </span>
            <span>C</span>
            <span class="c2 c1">
              ompound(s) and the identifiers therein) is in accordance with the
              Customer Order numerically.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Quantity objection to the fulfillment of an Order may only be
              raised if Customer sends pre-weighed vial(s) or other storage{" "}
            </span>
            <span>container</span>
            <span class="c1">
              (s) to Mcule and Mcule or the Supplier sends the{" "}
            </span>
            <span>C</span>
            <span class="c1">ompound(</span>
            <span>s</span>
            <span class="c1">) to Customer in </span>
            <span>such pre-weighed</span>
            <span class="c1">&nbsp;vial(s) or other storage </span>
            <span>container</span>
            <span class="c1">
              (s). An additional condition for enforcing such quantitative
              objection is that the weight of the pre-weighed vials shall be
              recorded in the System or shall be sent to Mcule prior to the
              beginning of fulfillment.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c5 c1 c4">VI. Obligations of Mcule</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c4">VI. </span>
            <span class="c1 c4">1. Obligation to </span>
            <span class="c4">C</span>
            <span class="c5 c1 c4">ooperate</span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Mcule shall act in good faith, in cooperation with Customer and
              Supplier, representing each other&rsquo;s interests, protecting
              each other, and informing each other of all relevant circumstances
              during the fulfillment of the Customer Order and Mcule Order.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">VI. </span>
            <span class="c1 c4">2. Payment of </span>
            <span class="c4">P</span>
            <span class="c1 c4">urchase </span>
            <span class="c4">P</span>
            <span class="c5 c1 c4">rice of Mcule Order</span>
          </p>
          <p class="c0">
            <span class="c1">
              Mcule is required to pay the Supplier the remuneration (purchase
              price) of the{" "}
            </span>
            <span>C</span>
            <span class="c2 c1">
              ompound(s) that is the subject of the Mcule Order.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              If the Compound(s) subject to the Mcule Order(s) are not produced
              and delivered in full quantity or not all of the Compounds are
              produced and delivered to Mcule (Partial Performance), Mcule is
              still obliged to pay Supplier a duly proportional part of the
              purchase price. By accepting these Terms and Conditions, Parties
              expressly accept the possibility of Partial Performance as
              described above.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">The </span>
            <span class="c2 c1">
              due date of the payment of the Mcule Order is sixty (60) calendar
              days after the Compound(s) have been sent by Supplier (when handed
              over as a consignment to a postal service provider, courier
              service, conveyer, or carrier) or when the corresponding invoice
              has been received by Mcule, the later of the two.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              In case of late payment, the default annual interest rate is 10%
              of the amount affected by the delay.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">VI. 3. Other Obligations of Mcule</span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Mcule shall notify the Customer of any circumstances that prevent
              or delay the performance of its duties.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Mcule acknowledges and agrees that Customer acquires ownership of
              the{" "}
            </span>
            <span>C</span>
            <span class="c1">ompound(s) at the same time when </span>
            <span>Mcule</span>
            <span class="c1">&nbsp;fulfill</span>
            <span>s</span>
            <span class="c2 c1">&nbsp;the Customer Order.</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c1 c4">VII. Mutual </span>
            <span class="c4">O</span>
            <span class="c1 c4">bligations of the </span>
            <span class="c4">P</span>
            <span class="c5 c1 c4">arties</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c4">VII. </span>
            <span class="c1 c4">1. General </span>
            <span class="c4">O</span>
            <span class="c5 c1 c4">bligations</span>
          </p>
          <p class="c0">
            <span class="c1">Parties shall act in good faith, in</span>
            <span>&nbsp;</span>
            <span class="c1">
              cooperation representing each other&rsquo;s interests, protecting
              each other, and informing each other of all relevant circumstances
              during the us
            </span>
            <span>age</span>
            <span class="c1">&nbsp;of the System and executi</span>
            <span>on</span>
            <span class="c1">&nbsp;of any Order.</span>
            <span>&nbsp;</span>
            <span class="c1">
              Parties acknowledge that any intellectual property owned by the
              other{" "}
            </span>
            <span>P</span>
            <span class="c1">
              arty may be used only with the prior and expressed permission of
              the{" "}
            </span>
            <span>P</span>
            <span class="c2 c1">arty concerned to perform the task.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Any documents or files uploaded to the System, &ndash; except the
              Customer Order and Mcule Order &ndash; must be free from company
              names, trademarks, logos, and any kind of reference that enables
              the identification of the Parties.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">VII. </span>
            <span class="c5 c1 c4">2. Vis Maior</span>
          </p>
          <p class="c0">
            <span class="c1">
              Parties shall be released from liability for partial or total
              failure to fulfill their obligations under the Order if such
              failure results from
            </span>
            <span class="c17">&nbsp;</span>
            <span class="c1">
              &bdquo;force majeure&rdquo;; the discharge shall continue until
              the end of this force majeure situation. The term &bdquo;force
              majeure&rdquo; includes all events that occur after the acceptance
              of th
            </span>
            <span>ese</span>
            <span class="c1">&nbsp;Terms and </span>
            <span>C</span>
            <span class="c1">
              onditions or the creation of the Order and which are of such
              exceptional nature that their occurrence could not have been
              foreseen or avoided by the Parties by any reasonable measures.
            </span>
            <span>&nbsp;</span>
            <span class="c1">
              These extraordinary events include flood, fire, earthquake, or
              other natural disasters, as well as war, military acts, acts or
              actions of public authorities, and any other circumstances beyond
              the expected control of the Parties. In the event of such, the{" "}
            </span>
            <span>P</span>
            <span class="c1">
              arty who became aware of it shall promptly notify the other{" "}
            </span>
            <span>P</span>
            <span class="c1 c2">arty in writing.</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c4">VII. 3. Communications</span>
          </p>
          <p class="c0">
            <span class="c1">
              Parties consider properly written communication messages sent
              within the System or by e-mail. Any message, statement suitable
              for inducing legal effect or activity sent in the System shall be
              deemed to have been communicated at the time received and{" "}
            </span>
            <span>set</span>
            <span class="c1">&nbsp;by the System.</span>
            <span>&nbsp;</span>
            <span class="c1">According to the above, an e-mail sent </span>
            <span>to the User&#39;s</span>
            <span class="c1">&nbsp;Represent</span>
            <span>ative&rsquo;s</span>
            <span class="c1">
              &nbsp;email address is considered a legally valid communication.
              For legally valid communication Mcule utilizes the{" "}
            </span>
            <span class="c12">
              <a class="c16" href="mailto:info@mcule.com">
                info@mcule.com
              </a>
            </span>
            <span class="c2 c1">&nbsp;email address.</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c1 c4">VIII. Confidentiality, </span>
            <span class="c4">I</span>
            <span class="c1 c4">ntellectual </span>
            <span class="c4">P</span>
            <span class="c5 c1 c4">roperty</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Protected intellectual property is any intellectual property owned
              by either Party that is: legally protected under the relevant
              legislation or which is the subject of proceedings to that effect
              under applicable law, or such unlicensed intellectual property,
              has not been filed or formally protected (in particular:
              trademarks, patents, industrial designs, copyright, know-how).
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              The fact and content of each Customer Order and Mcule Order, the
              Compound(s) subject of the Orders, the fact and content of the
              Auctions and the{" "}
            </span>
            <span>C</span>
            <span class="c1">ompound(s) </span>
            <span>shared in the Auctions</span>
            <span class="c1">, any chemical or </span>
            <span>other</span>
            <span class="c1">
              &nbsp;knowledge which may be associated with the{" "}
            </span>
            <span>C</span>
            <span class="c1">ompound(s), the </span>
            <span>QC</span>
            <span class="c1">&nbsp;</span>
            <span>M</span>
            <span class="c1">ethod and the </span>
            <span>B</span>
            <span class="c1">ids are considered a </span>
            <span>T</span>
            <span class="c1">rade </span>
            <span>S</span>
            <span class="c1">ecret and </span>
            <span>C</span>
            <span class="c1">onfidential </span>
            <span>I</span>
            <span class="c1">nformation. Trade </span>
            <span>Se</span>
            <span class="c1">
              cret means any data, fact, information, or knowledge which is
              considered as Trade Secret under the Act of LIV 2018 on the
              protection of Trade Secrets
            </span>
            <span>.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              In addition to the above, all data, information, facts, knowledge,
              know-how obtained by any Party in connection with the use of the
              System in relation to another{" "}
            </span>
            <span>P</span>
            <span class="c1">
              arty shall be considered a protected intellectual product,
              protected intellectual property, or a{" "}
            </span>
            <span>T</span>
            <span class="c1">rade </span>
            <span>S</span>
            <span class="c1">ecret, and </span>
            <span>Confidential</span>
            <span class="c1">&nbsp;</span>
            <span>I</span>
            <span class="c1">nformation.</span>
            <span>&nbsp;</span>
            <span class="c1">
              In case of doubt as to whether a data, information, fact, or
              knowledge constitutes a protected intellectual property or{" "}
            </span>
            <span>T</span>
            <span class="c1">rade </span>
            <span>S</span>
            <span class="c2 c1">
              ecret, the provisions of the relevant international conventions
              and Community law shall prevail.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Each Party using the System is responsible for handling all
              information and{" "}
            </span>
            <span>documents</span>
            <span class="c1">
              &nbsp;strictly confidential that is obtained by the Party during{" "}
            </span>
            <span>the usage of </span>
            <span class="c2 c1">
              the System or in connection with an Order and deemed to be
              protected under this section, and shall not disclose these to any
              third party. It shall not be considered a breach of these
              confidentiality rules if Mcule or the Supplier transfers
              (discloses) the information which is the subject of
              confidentiality to the Contributor.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">Parties warrant that their employees, </span>
            <span>A</span>
            <span class="c1">ffiliates, Contributors</span>
            <span>, </span>
            <span class="c2 c1">
              and any other person who, for necessary and justifiable reasons,
              may have access to the protected information or documents comply
              with the confidentiality obligations.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              The breach of confidentiality obligations under this section shall
              be considered as a serious breach of contract with immediate
              termination. In such a case, any effective Customer Order and
              Mcule Order may be terminated with immediate effect. In such a
              case Mcule is entitled to terminate the legal relationship with
              the infringing{" "}
            </span>
            <span>P</span>
            <span class="c2 c1">
              arty for the use of the System with immediate effect. The Party in
              breach of confidentiality obligations shall be liable for any
              damage that would not have occurred without such breach.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>It is t</span>
            <span class="c2 c1">
              he obligation of the Supplier (who contributes in the fulfillment
              of the Customer Order and who is a Party in the Mcule Order) not
              to use the Compound(s) subject of the Order, or any chemical
              production-related information or other information in any way,
              not to share it with a third party or not to make it available to
              a third party. In the event of the breach of this, the Supplier
              shall bear all damages and costs incurred in connection with such
              a breach. The Contributor lawfully used by the Supplier shall not
              be considered as a third party in this respect, provided that the
              Supplier is fully responsible for the activities of this
              Contributor in this regard.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>It is the o</span>
            <span>
              bligation of Mcule not to use the Compound(s) subject of the
              Order, or any chemical production-related information or other
              information in any way, not to share it with a third party or not
              to make it available to a third party. In the event of the breach
              of this, Mcule shall be liable for the damages and costs incurred
              in connection with such a breach, provided that the amount of the
              damages and costs that may be claimed against Mcule may not exceed
              the amount of the corresponding Order.{" "}
            </span>
            <span>
              Supplier who has submitted the winning Bid of the corresponding
              Order shall not be considered as a third party in this respect.{" "}
            </span>
            <span class="c2 c1">
              Customer acknowledges and agrees that if the confidentiality
              obligations have been verifiably breached by the Supplier (who
              contributes in the fulfillment of the Customer Order and who is a
              Party in the Mcule Order), Customer is entitled to initiate
              proceedings exclusively and directly against Supplier to enforce
              its claims.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              To avoid misunderstanding, Parties acknowledge that mutatis
              mutandis the transfer of the Compound(s) and additional
              information which are the subject of the Order to the Customer do
              not constitute a breach of confidentiality.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Each Party shall hold in confidence and not disclose any{" "}
            </span>
            <span>C</span>
            <span class="c1">onfidential </span>
            <span>I</span>
            <span class="c1">
              nformation to any other person, firm, or corporation, nor use{" "}
            </span>
            <span>C</span>
            <span class="c1">onfidential </span>
            <span>I</span>
            <span class="c1">
              nformation for its own benefit, except for fulfilling the Order.
              In particular, the{" "}
            </span>
            <span>r</span>
            <span class="c1">
              eceiving Party of the Confidential Information shall not include
              the{" "}
            </span>
            <span>d</span>
            <span class="c1">
              isclosing Party&#39;s Confidential Information in any application
              for patent, utility model, design, or other proprietary protection
              in any country filed by or on behalf of the{" "}
            </span>
            <span>r</span>
            <span class="c1">eceiving Party.</span>
            <span>&nbsp;</span>
            <span class="c1">Confidential </span>
            <span>I</span>
            <span class="c2 c1">
              nformation also includes the terms and conditions of all
              agreements concluded by the Parties; tangible and oral information
              that is not marked or confirmed as being confidential, but under
              the circumstances, a reasonable person would believe to be
              confidential; and the fact that the Parties are assisting each
              other to fulfill the Orders.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">Notwithstanding the foregoing, any </span>
            <span>P</span>
            <span class="c1">arty receiving </span>
            <span>C</span>
            <span class="c1">onfidential </span>
            <span>I</span>
            <span class="c1">
              nformation warrants that disclosure of such shall be limited to
              those employees, officers, consultants, and agents who have an
              actual need to know the{" "}
            </span>
            <span>C</span>
            <span class="c1">onfidential </span>
            <span>I</span>
            <span class="c1">
              nformation for the successful fulfillment of the Order and which
              are bound by confidentiality obligations corresponding to the
              obligations of th
            </span>
            <span>ese</span>
            <span class="c2 c1">&nbsp;Terms and Conditions.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">Information shall not be deemed </span>
            <span>C</span>
            <span class="c1">onfidential </span>
            <span>I</span>
            <span class="c1">
              nformation, and the Parties shall have no obligation
            </span>
            <span>&nbsp;</span>
            <span class="c1">with respect to any information, which</span>
            <span>&nbsp;</span>
            <span class="c1">(i)</span>
            <span>&nbsp;</span>
            <span class="c1">was known by the </span>
            <span>P</span>
            <span class="c1">
              arties prior to receipt from the Party concerned and can be shown
              through appropriate written documentation; or (
            </span>
            <span>ii) </span>
            <span class="c1">
              is publicly known at the time of disclosure through no act or
              failure to act of the other{" "}
            </span>
            <span>P</span>
            <span class="c1">arties; or</span>
            <span>&nbsp;</span>
            <span class="c1">(iii)</span>
            <span>&nbsp;</span>
            <span class="c1">
              is independently developed by any of the Parties without reference
              to or reliance upon the Confidential Information if the Party
              concerned and such independent development can be shown by written
              documentation; or
            </span>
            <span>&nbsp;</span>
            <span class="c1">(iv)</span>
            <span>&nbsp;</span>
            <span class="c1">
              was rightfully received by the Party in good faith from a third
              party having no duty of confidentiality to the Party concerned; or
            </span>
            <span>&nbsp;</span>
            <span class="c1">(v)</span>
            <span>&nbsp;</span>
            <span class="c1">
              is to be disclosed to public authorities due to official
              regulations, provided that Party concerned is promptly informed of
              such a disclosure and with best efforts the disclosure is limited
              to the minimum necessary level;
            </span>
            <span>&nbsp;or </span>
            <span class="c1">(vi)</span>
            <span>&nbsp;</span>
            <span class="c1">the disclosing </span>
            <span>P</span>
            <span class="c1">arty approve</span>
            <span>d</span>
            <span class="c2 c1">&nbsp;for release in writing.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>Supplier and Mcule </span>
            <span class="c1">acknowledge and agree th</span>
            <span>
              at if, and as long as the provisions of Section III. 5 of these
              Terms and Conditions are met, all ideas, Knowledge Material,{" "}
            </span>
            <span class="c1">
              knowledge, products, things, information, work product resulted
              from the Order &ndash; including idea, things, knowledge,
              information, work products created during the fulfillment of the
              Order &ndash; are the intellectual property of the Customer.{" "}
            </span>
            <span>Supplier and Mcule </span>
            <span class="c1">
              acknowledge and agree that the above described intellectual
              property belongs{" "}
            </span>
            <span>to</span>
            <span class="c1">
              &nbsp;the Customer even if the Order is not fulfilled.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>
              By transferring ownership of the Compound(s), all ownership of the
              knowledge
            </span>
            <span class="c1">
              , products, things, information, work product resulted from Mcule
              Order will be fully transferred directly to Customer (as the
              Client of the Customer Order) by Supplier, regardless of that
              Supplier and Mcule are the contracting{" "}
            </span>
            <span>P</span>
            <span class="c1">arties in the Mcule Order</span>
            <span class="c2 c1">
              . Customer acquires exclusive and unrestricted (unrestricted in
              space, time, and scope) ownership of the intellectual property
              assigned by the Supplier according to the former, so the
              intellectual property transferred may be used by the Customer
              without restriction.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              All Users, Contributors, and others who access SynthAgora
              acknowledges that the designations &ldquo;SynthAgora&rdquo; and
              &rdquo;Mcule&rdquo; constitutes the intellectual property of
              Mcule, and as such, only Mcule is entitled to use them in course
              of business regardless of whether they are protected by trademark
              registration, domain name registration, or used as a trade name
              without any formal registration. Further, all Users, Contributors,
              and any other parties involved in the fulfillment of any Orders
              are obliged to refrain from any kind of use, and in particular
              from filing a trademark application for or registering a domain
              name any designation confusingly similar to &ldquo;Mcule&rdquo; or
              &ldquo;SynthAgora&rdquo;.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              All Users, Contributors, and any others who access SynthAgora
              acknowledge that the SynthAgora website, web service, and
              application are the intellectual property of Mcule. Accordingly,
              Mcule has the exclusive and full right to operate, improve,
              otherwise modify, or obtain any kind of protection for the System,
              and the Users, Contributors, and any others who access SynthAgora
              are entitled to use the System only in the manner and to the
              extent governed by these Terms and Conditions.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1 c4">IX. Rights of Mcule as the System</span>
            <span class="c4">&rsquo;s Administrator</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">Mcule, as the System</span>
            <span>&rsquo;s</span>
            <span class="c1">&nbsp;</span>
            <span>Administrator</span>
            <span class="c2 c1">
              , has the right to make decisions regarding all technical and
              technological issues related to the use of the System and is
              entitled to issue a call or notice to the Users in such matters.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">Mcule, as the System</span>
            <span>&rsquo;s Administrator</span>
            <span class="c1">
              , has the right to exclude Users from the System, who does not
              comply with or breaches the Terms and Conditions of the System,
              who infringes or endangers the System or other{" "}
            </span>
            <span>P</span>
            <span class="c1">
              arties with its activities. Exclusion from the System means the
              termination of the legal relationship regarding the Use of the
              System. Exclusion from the System shall have an immediate effect
            </span>
            <span class="c2 c1">
              . Parties are obliged to fulfill their obligations existing based
              on the Orders in progress at the time of exclusion.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              The System may be modified or improved by the{" "}
            </span>
            <span>Administrator</span>
            <span class="c1">
              &nbsp;at any time. In such cases, the use of the System may be
              hindered or impossible, or some of its functions may not be
              available or may not be fully available. All claims, demands, or
              compensation needs arising from the foregoing are excluded against
              Mcule
            </span>
            <span>. &nbsp;</span>
            <span>
              However, Mcule will send a prior notice or post a message in the
              System in advance about larger scheduled maintenance events
            </span>
            <span class="c1">.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              If the System, whether temporarily or permanently, does not or
              does not fully function due to external circumstances (especially:
              force majeure, hacker attack, inoperability of the electrical
              server network), all claims, demands, or compensation needs
              arising from the foregoing are excluded against Mcule.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span>Parties </span>
            <span class="c1">
              acknowledge and agree that Mcule may, in its sole discretion,
              modify these{" "}
            </span>
            <span>T</span>
            <span class="c1">erms and </span>
            <span>C</span>
            <span class="c2 c1">
              onditions at any time. In case of a modification, Mcule will
              notify the Users, either through a notice posted on the System or
              via an email sent directly to the Users. However, in addition to
              the above, the use of the System means the acceptance of the
              change or modification by the User.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1 c4">X. Order Completion </span>
            <span class="c4">D</span>
            <span class="c5 c1 c4">ate</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">The date of completion is </span>
            <span>when</span>
            <span class="c1">&nbsp;the subject of the Customer Order </span>
            <span>and</span>
            <span class="c1">
              &nbsp;Mcule Order is sent to the relevant (eligible) Party.
              Sending means the dispatch or handover of the consignment to a
              postal service provider, courier service,{" "}
            </span>
            <span>conveyer, or carrier</span>
            <span class="c1">.</span>
            <span>&nbsp;</span>
            <span class="c1">Supplier shall send Mcule the </span>
            <span>C</span>
            <span class="c1">
              ompound(s) subject to the Mcule Order no later than the due date
              as specified in Mcule Order.
            </span>
            <span>&nbsp;</span>
            <span class="c1">
              Mcule shall send the Compound(s) subject of the Customer Order no
              later than the due date as specified in the Customer Order.
            </span>
            <span>&nbsp;</span>
            <span class="c2 c1">
              By using the System, Parties acknowledge and agree that the dates
              specified above shall be deemed as due dates for all Orders.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              If the Compound(s) subject to the Customer Order is not sent to
              the Customer until the due date, for reasons attributable to Mcule
              or the Supplier, it is considered late performance
            </span>
            <span>. </span>
            <span class="c2 c1">
              In such a case, the Customer is entitled, at its own discretion,
              to provide an additional deadline for performance, or cancel the
              Customer Order.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              If the Compound(s) subject to the Mcule Order are not sent to
              Mcule within the time limit, for reasons attributable to the
              Supplier, it is considered late performance. In such a case, Mcule
              is entitled, at its own discretion, to provide an additional
              deadline for performance, or to cancel the Mcule Order.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c1 c4">XI. Defective </span>
            <span class="c4">P</span>
            <span class="c1 c4">erformance, </span>
            <span class="c4">W</span>
            <span class="c1 c4">arranty, </span>
            <span class="c4">C</span>
            <span class="c5 c1 c4">ompensation</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c4">XI. </span>
            <span class="c1 c4">1. Defective </span>
            <span class="c4">P</span>
            <span class="c5 c1 c4">erformance</span>
          </p>
          <p class="c0">
            <span class="c1">
              Defective performance is, when the quality (identity and/or
              purity) or quantity of the Compound(s) subject to the Customer
              Order
            </span>
            <span>&nbsp;and</span>
            <span class="c1">&nbsp;Mcule Order does no</span>
            <span>t</span>
            <span class="c1">
              &nbsp;conform to those specified in the Customer Order
            </span>
            <span>&nbsp;and</span>
            <span class="c1">
              &nbsp;Mcule Order, respectively. It is considered a breach of
              contract
            </span>
            <span>&nbsp;and</span>
            <span class="c1">
              &nbsp;non-fulfillment if the Compound(s) subject to the Customer
              Order
            </span>
            <span>&nbsp;and</span>
            <span class="c1">&nbsp;Mcule Order </span>
            <span>is</span>
            <span class="c1">&nbsp;not sent; by </span>
            <span>P</span>
            <span class="c1">artial </span>
            <span>P</span>
            <span class="c1">
              erformance Section V. 2 and Section VI. 2 shall be applied mutatis
              mutandis
            </span>
            <span>.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">If the Customer considers that the </span>
            <span>C</span>
            <span class="c1">ompound(s) </span>
            <span>has a</span>
            <span class="c1">
              &nbsp;quality (identity and/or purity) defect,
            </span>
            <span>
              &nbsp;Parties shall act based on the QC Method as set out in
              Section III. 1.4.
            </span>
            <span class="c1">
              &nbsp;If the Customer considers that the quantity of the{" "}
            </span>
            <span>C</span>
            <span class="c1">
              ompound(s) does not comply with the Customer Order the procedure
              set out in the last paragraph of{" "}
            </span>
            <span>S</span>
            <span class="c2 c1">
              ection V. 3 shall apply. The same applies mutatis mutandis to the
              Mcule Order between Mcule and the Supplier in terms of both
              qualitative and quantitative problems.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              All Parties acknowledge and agree that quantit
            </span>
            <span>y</span>
            <span class="c1">&nbsp;and/or qualit</span>
            <span>y</span>
            <span class="c1">&nbsp;</span>
            <span>defects</span>
            <span class="c1">
              &nbsp;can only be identified in accordance with the procedures set
              out above. All other methods, procedures, basis of references
              shall be deemed irrelevant and shall not be applied to any
              Customer Order
            </span>
            <span>&nbsp;or</span>
            <span class="c2 c1">
              &nbsp;Mcule Order unless such methods and procedures are accepted
              by all Parties.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              In the event of proven quality or quantity defect, Customer is
              entitled, at its own discretion, to request the correction of the
              defect or cancel the Customer Order. In case of cancellation,
              Customer must return (send back) the corresponding Compound(s) to
              Mcule. In this case, the return shipping cost will be borne by
              Customer.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              In the event of proven quality or quantity defect, Mcule is
              entitled, at its own discretion, to request the correction of the
              defect or cancel the Mcule Order. In case of cancellation, Mcule
              must return (send back) the corresponding Compound(s) to Supplier.
              In this case, the return shipping cost will be borne by Mcule.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              In the event of a minor quality (purity) or quantity defect,
              Customer is not entitled to cancel the Customer Order and is not
              entitled to request the correction of the defect. In the event of
              a minor quality (purity) or quantity defect, Mcule is not entitled
              to cancel Mcule Order and is not entitled to request the
              correction of the defect.{" "}
            </span>
            <span>By</span>
            <span class="c2 c1">
              &nbsp;applying the above provisions, a deviation not exceeding 2%
              in terms of quality (purity) and 10% in terms of quantity shall be
              considered as a minor defect.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">Mcule shall return Customer the </span>
            <span>remuneration </span>
            <span class="c1">
              already paid (purchase price) if Supplier is unable, or unwilling,
              or fails to perform the correction.
            </span>
            <span class="c2 c1">&nbsp;</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              If Supplier is unable or unwilling or fails to perform the
              correction, Supplier is required to return Mcule the{" "}
            </span>
            <span>remuneration </span>
            <span class="c1">already paid</span>
            <span>&nbsp;</span>
            <span class="c2 c1">(purchase price).</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0" id="h.3znysh7">
            <span class="c4">XI. </span>
            <span class="c5 c1 c4">2. Warranty</span>
          </p>
          <p class="c0">
            <span class="c1">
              Supplier provides Mcule a six (6) month warranty on the Compound(
            </span>
            <span>s)</span>
            <span class="c2 c1">
              &nbsp;subject of the Mcule Order from the date of dispatch by
              Supplier.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">Mcule provides Customer a five (5)</span>
            <span>&nbsp;</span>
            <span class="c2 c1">
              month warranty on the Compound(s) subject of the Customer Order
              from the date of dispatch by Mcule.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Beyond this period, no claims, complaints, or warranty claims can
              be accepted, the service is considered to have been contractually
              fulfilled.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c4">XI. </span>
            <span class="c5 c1 c4">3. Indemnity</span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Supplier shall be liable for the damages caused by the above
              breach of contract.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Mcule shall be liable to Customer for the damages caused by the
              above breach of contract if it is the result of willful misconduct
              or gross negligence. The amount of the compensation may not exceed
              the amount of the given Customer Order.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c5 c1 c4">XII. Termination</span>
          </p>
          <p class="c0 c3">
            <span class="c1 c4 c5"></span>
          </p>
          <p class="c0">
            <span>Users</span>
            <span class="c1">&nbsp;</span>
            <span>have</span>
            <span class="c1">&nbsp;the right to terminate the </span>
            <span>A</span>
            <span class="c2 c1">
              ccount authorizing the use of the System at any time without
              giving reasons, which means the termination of these Terms and
              Conditions.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">Mcule, as the System</span>
            <span>&rsquo;s Administrator</span>
            <span class="c1">
              , has the right to terminate the legal relationship with any User
              for the use of the System with a unilateral statement, without{" "}
            </span>
            <span>providing </span>
            <span class="c2 c1">
              reasons, with a notice period of fifteen (15) calendar days.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">Mcule, as the System</span>
            <span>&rsquo;s Administrator</span>
            <span class="c1">
              , has the right to terminate a Party&rsquo;s legal relationship
              for the use of the System with a unilateral statement{" "}
            </span>
            <span>immediately</span>
            <span class="c1">
              &nbsp;if the Party breaches these Terms and Conditions.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              The termination of the legal relationship for the use of the
              System shall not affect the obligation to fulfill the Order(s)
              that are already in force but not yet fulfilled at the time of
              termination, as well as the obligations of confidentiality set out
              in Section{" "}
            </span>
            <span>VIII</span>
            <span class="c1">
              ., furthermore the exclusivity obligations set out in{" "}
            </span>
            <span>S</span>
            <span class="c1">ection III. 1.</span>
            <span>5</span>
            <span class="c1">&nbsp;Parties may have entered into.</span>
            <span>&nbsp;</span>
            <span class="c2 c1">
              Customer Orders, Mcule Orders cannot be terminated with ordinary
              notice.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Either Party concerned shall have the right to terminate a
              Customer Order
            </span>
            <span>&nbsp;or</span>
            <span class="c1">
              &nbsp;Mcule Order if the other Party involved in that Customer
              Order
            </span>
            <span>&nbsp;or</span>
            <span class="c2 c1">
              &nbsp;Mcule Order breaches a substantial obligation (as set forth
              in a Customer Order, Mcule Order, or these Terms and Conditions).
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1 c4">XIII. Notifications</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c13 c19">
            <span class="c1">
              Mcule reserves the right to determine the form and means of
              providing notifications to Users, provided that User may opt-out
              of certain notifications by modifying the notification settings t
            </span>
            <span>hrough the System.</span>
            <span class="c1">
              &nbsp;Users can turn off the application and email notification
              services in the System. Mcule is not responsible for any{" "}
            </span>
            <span class="c1">
              automatic filtering applied by User or by User
            </span>
            <span>&rsquo;s</span>
            <span class="c1">
              &nbsp;email / network provider to email notifications sent by{" "}
            </span>
            <span>Mcule </span>
            <span class="c1">to </span>
            <span class="c1">the email address User provide</span>
            <span>d</span>
            <span class="c1">&nbsp;to Mcule.</span>
            <span class="c1">
              &nbsp;Mcule is not responsible for any damage{" "}
            </span>
            <span>to</span>
            <span class="c1">&nbsp;Users </span>
            <span>caused </span>
            <span class="c1">by turning off notifications.</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c1 c4">XIV. Data </span>
            <span class="c4">P</span>
            <span class="c5 c1 c4">rotection</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c1">
              With regard to the application of these Terms and Conditions, use
              of the System, the fulfillment of Customer Orders
            </span>
            <span>&nbsp;and</span>
            <span class="c1">
              &nbsp;Mcule Orders, only the contact details (name, email{" "}
            </span>
            <span>address</span>
            <span class="c1">&nbsp;and phone number) of the </span>
            <span>R</span>
            <span class="c1">
              epresentatives, contact persons, and possible contacts are
              processed by the Parties to fulfill the legal relationship,
              communication and for sending notices to the other{" "}
            </span>
            <span>P</span>
            <span class="c1">
              arty in relation with the contract, in connection with the
              creation and fulfillment of Customer Orders
            </span>
            <span>&nbsp;and</span>
            <span class="c2 c1">
              &nbsp;Mcule Orders, during the term of the legal relationship or
              until the date of termination of the legal relationship. Parties
              agree that in doing so, each Party acts as a data controller.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Parties undertake to ensure that the data provided by the other
              Party during and in the course of their legal relationship are
              handled in accordance with the General Data Protection Regulation
              (
            </span>
            <span class="c1">Regulation (EU) 2016/679</span>
            <span class="c1">
              &nbsp;of the European Parliament and of the Council) (GDPR) and
              current national legislation, and the provisions on the data
              management and data protection of{" "}
            </span>
            <span class="c1">
              Act CXII of 2011 on information self-determination and freedom of
              information (Info Act)
            </span>
            <span class="c2 c1">.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Parties agree that the personal data of their own contacts,{" "}
            </span>
            <span>R</span>
            <span class="c1">epresentatives, and </span>
            <span>C</span>
            <span class="c1">
              ontributors shall be acquired and processed in accordance with{" "}
            </span>
            <span class="c1">Article 6 (1) (b) of the GDPR </span>
            <span class="c1">and</span>
            <span class="c1">
              &nbsp;Section 10 (1) of Act I of 2012 on the Labor Code
            </span>
            <span class="c1">
              , while the personal data of the other Party&#39;s contacts,{" "}
            </span>
            <span>R</span>
            <span class="c1">epresentatives and </span>
            <span>C</span>
            <span class="c1">
              ontributors are received and processed in the legitimate interest
              of the other Party to the extent and for the period specified
              above in accordance with{" "}
            </span>
            <span class="c1">Article 6 (1) (f) of the GDPR.</span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Parties process only the data of the contacts that are strictly
              necessary for the contact, so the fundamental rights and freedoms
              of the contact person,{" "}
            </span>
            <span>R</span>
            <span class="c1">epresentative, or </span>
            <span>C</span>
            <span class="c1">
              ontributor are not violated by the data processing, and not take
              precedence against the above legitimate interests of the Parties.
              Parties declare that the data of their contacts,{" "}
            </span>
            <span>R</span>
            <span class="c1">epresentatives, and </span>
            <span>C</span>
            <span class="c2 c1">
              ontributors provided to each other will be lawfully processed and
              transmitted to the other Party for the above-mentioned data
              processing purposes. Parties agree that it is the responsibility
              of the respective Party to provide appropriate data management
              information in accordance with GDPR and Info Act to their
              contacts, Representatives, and Contributors, as data subjects in
              connection with the data processing referred to in the preceding
              paragraph. Parties may request the other Party to certify that
              such information has been provided.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1 c4">XV. Miscellaneous </span>
            <span class="c4">P</span>
            <span class="c1 c4">rovisions, </span>
            <span class="c4">A</span>
            <span class="c1 c4">pplicable </span>
            <span class="c4">L</span>
            <span class="c5 c1 c4">egislation</span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <p class="c0">
            <span class="c1">
              By registering in the System and using the System, each Party
              declares and warrants that it is a company, legal entity
              registered and operating in accordance with the applicable law,
              and declares that its{" "}
            </span>
            <span>R</span>
            <span class="c1">
              epresentative is not aware of any fact, matter, or circumstance
              that could reasonably affect its business intentions. Each Party
              and its{" "}
            </span>
            <span>R</span>
            <span class="c1">
              epresentatives declares and warrants that the{" "}
            </span>
            <span>R</span>
            <span class="c1">epresentatives</span>
            <span>&rsquo;</span>
            <span class="c1">
              &nbsp;ability to do business is not limited or excluded.
            </span>
            <span>&nbsp;</span>
            <span class="c1">In addition, the </span>
            <span>R</span>
            <span class="c2 c1">
              epresentative or contact person who registered in the System, and
              using it, declares in full awareness of their criminal liability
              that they are entitled to represent the company, firm, or legal
              entity without restriction.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              These Terms and Conditions are governed by Hungarian law.
            </span>
            <span>&nbsp;</span>
            <span class="c1">
              Parties agree that in case of any disputes between them, they
              shall initially attempt to resolve them out of court, by way of{" "}
            </span>
            <span>negotiations</span>
            <span class="c2 c1">
              . If this does not lead to a result, Parties shall go to a
              Hungarian Court to litigate, in accordance with the rules of the
              Hungarian Code of Civil Procedure.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">If any provision of th</span>
            <span>e</span>
            <span class="c2 c1">
              se Terms and Conditions (in whole or in part) is found to be
              invalid or in fact unlawful, this provision should be separated
              and this does not affect the validity of the other provisions, and
              Parties shall negotiate the consequences in good faith.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c0">
            <span class="c1">Neither </span>
            <span>P</span>
            <span class="c2 c1">
              arty is entitled to assign any of its rights or obligations,
              arising from the legal relationship related to the System, without
              the prior written consent of Mcule.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2 c1"></span>
          </p>
          <p class="c15">
            <span class="c1">I</span>
            <span>n</span>
            <span class="c1">
              &nbsp;matters not regulated in these Terms and Conditions, the
              provisions of Hungarian Civil Code and other relevant Hungarian
              legislation shall prevail.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c5 c1 c4"></span>
          </p>
          <div>
            <p class="c3 c9">
              <span class="c2 c1"></span>
            </p>
            <p class="c3 c11">
              <span class="c2 c1"></span>
            </p>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
