import React from "react";
import { MyInfoCircle } from "./Entities";
import { ToolTip } from "./ToolTip";

export default function SimpleTooltip(props) {
  return (
    <React.Fragment>
      <div id={"info-" + props.name} className="info-circle">
        <MyInfoCircle />
      </div>
      <ToolTip target={"info-" + props.name} autohide={false}>
        {props.children}
      </ToolTip>
    </React.Fragment>
  );
}
