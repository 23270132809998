import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link as OpenLink } from "react-router-dom";

export default function Footer(props) {
  return (
    <footer>
      <Container className="footer-container">
        <Row>
          <Col sm={6}>
            <img
              src="./assets/img/logo_footer.png"
              alt="SynthAgora footer logo"
            />
          </Col>
          <Col sm={6}>
            <div>
              Any questions / problems? Contact{" "}
              <a href="mailto:support@synthagora.com">
                SynthAgora Support Team
              </a>
            </div>
            <div className="mt-2 footer-links">
              <OpenLink to="/privacy-policy">Privacy policy</OpenLink>
              <OpenLink to="/terms">Terms and conditions</OpenLink>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
