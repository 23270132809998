import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "reactstrap";
import { toast } from "react-toastify";
import API from "../api/api";
import * as CONSTANTS from "../common/Constants";
import Loader from "../common/Loader";
import AuctionList from "./AuctionList";
import BidList from "./BidList";
import OrderList from "./OrderList";
import MetaTags from "react-meta-tags";

export default function SupplierHome() {
  const title =
    CONSTANTS.TITLES.DASHBOARD +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;
  const [auctions, setAuctions] = useState([]);
  const [auctionLoading, setAuctionLoading] = useState(false);
  const [bids, setBids] = useState([]);
  const [bidLoading, setBidLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderLoading, setOrderLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setAuctionLoading(true);
      let params = {
        state: CONSTANTS.AUCTION_STATE.APPROVED,
        ordering: "-created_at",
      };
      API.get(`auctions/`, { params })
        .then((auctionResult) => {
          setAuctions(
            auctionResult.data.results.filter(
              (auction) => auction.state === CONSTANTS.AUCTION_STATE.APPROVED
            )
          );
        })
        .catch((err) => {
          toast.error("Error loading auction data", {});
          console.log("ERROR", err);
        })
        .finally(() => {
          setAuctionLoading(false);
        });

      setBidLoading(true);
      params = {
        ordering: "-created_at",
      };
      API.get("bids/", { params })
        .then((bidResult) => {
          setBids(bidResult.data.results);
        })
        .catch((err) => {
          toast.error("Error loading bid data", {});
          console.log("ERROR", err);
        })
        .finally(() => {
          setBidLoading(false);
        });

      setOrderLoading(true);
      API.get("customer-orders-for-supplier/", { params })
        .then((result) => {
          setOrders(result.data.results);
        })
        .catch((err) => {
          toast.error("Error loading auction data", {});
          console.log("ERROR", err);
        })
        .finally(() => {
          setOrderLoading(false);
        });
    };
    fetchData();
  }, []);

  return (
    <div className="grey-bg dashboard">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="pt-4">
        <h2 className="subheadline orange">Open auctions</h2>
        <div className="box-container orange">
          <div className="box">
            {auctionLoading ? (
              <Loader overlay text="Loading auctions" />
            ) : (
              <AuctionList summary auctions={auctions} />
            )}
          </div>
        </div>
        <Row>
          <Col md={6}>
            <h2 className="subheadline orange">My bids</h2>
            <div className="box-container orange">
              <div className="box">
                {bidLoading ? (
                  <Loader overlay text="Loading bids" />
                ) : (
                  <BidList summary bids={bids} />
                )}
              </div>
            </div>
          </Col>
          <Col md={6}>
            <h2 className="subheadline blue">My orders</h2>
            <div className="box-container blue">
              <div className="box">
                {orderLoading ? (
                  <Loader overlay text="Loading orders" />
                ) : (
                  <OrderList summary orders={orders} />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
