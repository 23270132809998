import { useState, useLayoutEffect, useRef, useEffect } from "react"
import { getDefaultServices } from "./webservices"

export function useMarvin(
  id,
  displaySettings,
  textFieldImportCallback,
  marvinImportCallback,
  services = getDefaultServices(),
  structures,
  isMarvinImport
) {
  const [loaded, setLoaded] = useState(false)
  const editor = useRef()
  const marvinPackage = useRef()
  const [textStructures, setTextStructures] = useState([])

  const loadEditor = async () => {
    return await window.MarvinJSUtil.getEditor(id)
  }

  const loadPackage = async () => {
    return await window.MarvinJSUtil.getPackage(id)
  }

  const initEditor = (sketch) => {
    sketch.setDisplaySettings(displaySettings)
    if (services) {
      sketch.setServices(services)
    }
  }

  useLayoutEffect(() => {
    marvinPackage.current = loadPackage()
    marvinPackage.current.then((marvin) => {
      marvin.Sketch.license(`${process.env.REACT_APP_MARVINJS_API_DOMAIN}/rest-v1/license/grant`, true)
    })
  })

  useLayoutEffect(() => {
    // useEffect(() => {
    editor.current = loadEditor()
    editor.current.then((sketch) => {
      initEditor(sketch)
      setLoaded((l) => !l)
    })
  }, [])

  useEffect(() => {
    if (structures) {
      startExport(0)
    }
  }, [structures])

  const startExport = (index) => {
    editor.current.then((sketch) => {
      sketch.importStructure(null, structures[index].structure)
      sketch.clearUndoStack()
    })
  }

  useEffect(() => {
    if (textStructures && structures && textStructures.length === structures.length) {
      textFieldImportCallback(textStructures)
      setTextStructures([])
    }
  }, [textStructures])

  useEffect(() => {
    const exportData = async (sketch) => {
      const exportedSmiles = await sketch.exportStructure("smiles")
      const exportedMol = await sketch.exportStructure("mol")
      if (isMarvinImport) {
        marvinImportCallback(exportedSmiles, exportedMol)
      } else {
        if (exportedSmiles && structures[i]) {
          let tempStructure = { ...structures[i] }
          tempStructure.exportedSmiles = exportedSmiles
          tempStructure.exportedMol = exportedMol
          setTextStructures((oldArray) => [...oldArray, tempStructure])
          i++
          if (!sketch.isEmpty()) {
            sketch.clear()
          }
          if (structures.length > i) {
            startExport(i)
          }
        }
      }
    }

    let i = 0
    editor.current.then((sketch) => {
      sketch.off("molchange", null)
      sketch.on("molchange", () => {
        exportData(sketch)
      })
    })
  }, [isMarvinImport, structures])

  return {
    editor,
    loaded,
  }
}
