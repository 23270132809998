import React, { useEffect } from "react"
import { Container } from "reactstrap"
import { Link } from "react-scroll"

export default function Privacy(props) {
  useEffect(() => {}, [])

  return (
    <React.Fragment>
      <div className="grey-bg">
        <Container className="pt-4">
          <h1 className="mb-4 subheadline text-center">Privacy Policy</h1>
          <div className="mb-2">
            This Privacy Policy describes how <strong>mcule.com Ltd.</strong> (Address: Bartók Béla út 105-113. H-1115,
            Budapest Hungary; Tax ID: 23383540-2-43; Contact: email address:{" "}
            <a href="mailto:info@mcule.com">info@mcule.com</a>, phone: +36 1 781 5983; registered at the Metropolitan
            Court of Budapest, under the company registration number 01-09-963056) vendor of chemicals and chemical
            products and provider of related information technology and consultancy services and its affiliate{" "}
            <strong>Mcule, Inc.</strong> (Address: 535 Everett Ave #510, Palo Alto, CA 94301, USA; Tax ID: 46-2751332;
            Contact: email address: <a href="mailto:info@mcule.com">info@mcule.com</a>,, phone: +1-650-741-1391;
            registered at the California Secretary of State of California under the company registration number 3566521)
            (hereinafter together "Company", “we”, “us”, or “our”) process (handle, collect, store, use and disclose)
            your personal data when you use our services (“Services”), in particular our websites (including all sites
            on the mcule.com, and synthagora.com domains and all their subdomains) (hereinafter together: "Website"), or
            otherwise interact with us, unless a different policy is displayed.
          </div>

          <div>
            <h3>Table of Content</h3>
            <ol>
              <li>
                <Link className="policy-link" to="subject-of-policy" spy={true} smooth={true} duration={500}>
                  Subject of Policy
                </Link>
              </li>
              <li>
                <Link className="policy-link" to="collected-data" spy={true} smooth={true} duration={500}>
                  Collected data
                </Link>
              </li>
              <li>
                <Link className="policy-link" to="use" spy={true} smooth={true} duration={500}>
                  Use
                </Link>
              </li>
              <li>
                <Link className="policy-link" to="disclosure" spy={true} smooth={true} duration={500}>
                  Disclosure of information
                </Link>
              </li>
              <li>
                <Link className="policy-link" to="rights" spy={true} smooth={true} duration={500}>
                  Rights and responsibilities of the Client
                </Link>
              </li>
              <li>
                <Link className="policy-link" to="liability" spy={true} smooth={true} duration={500}>
                  The Company’s liability
                </Link>
              </li>
              <span id="subject-of-policy"></span>
              <li>
                <Link className="policy-link" to="contact" spy={true} smooth={true} duration={500}>
                  How to Contact the Company
                </Link>
              </li>
              <li>
                <Link className="policy-link" to="legal-basis" spy={true} smooth={true} duration={500}>
                  Legal basis
                </Link>
              </li>
            </ol>
          </div>
          <hr className="my-4" />

          <div className="nested_list list_indent">
            <ol>
              <li>
                <strong>1. Subject of Policy</strong>
                <ol>
                  <li>
                    1.1 Acceptance of the Privacy Policy implies that the Client gives its express consent to the
                    processing (handling, collecting, storing, using and disclosing) of its data by the Company, as
                    regulated in this Privacy Policy. Client means those persons and legal entities who registered on
                    the Website, who require help from Company via Website, with whom Company connects, who orders from
                    the Company, who fulfills the orders received from the Company and who uses the Services of Company.
                  </li>
                  <span id="collected-data"></span>
                  <li>
                    1.2 The Company reserves the right to amend this Privacy Policy any time at its sole discretion. The
                    amended version becomes effective immediately upon posting on the Website. It is the Client’s
                    responsibility to review the Privacy Policy prior to each use of the Website or the Services offered
                    by Company. By continuing, the Client agrees to any changes.
                  </li>
                  <li>
                    1.3 The Client acknowledges that the handling of any and all data provided during any payment
                    procedure to the Company’s payment provider, shall be governed by the privacy policy of the latter.
                  </li>
                </ol>
                <br />
              </li>

              <li>
                <strong>2. Collected data</strong>
                <ol>
                  <li>
                    <p>
                      2.1 Data, which may be processed (handled, collected, stored, used and disclosed) by the Company,
                      shall consist of the following: information provided by the Client for the purpose of registration
                      on the Website or for the Services such as username, email address, password, name,
                      institution/company name, name and phone number of contact persons for delivery of goods, company
                      registration number, company VAT number, financial information (including credit card or bank
                      account numbers), shipping information and billing information; information provided by the Client
                      for the purpose of communicating with the Company in matters, such as dispute resolution;
                      information provided by the Client when using interactive features of the Services. Company also
                      process information the Client shares with the Company when receives customer support, or
                      otherwise interacts with the Company; Company will never ask the Client to send the Company or
                      submit to the Services any information related to racial or ethnic origin; political opinions,
                      religion or other beliefs; Client’s health, biometric or genetic information; or information about
                      Client’s criminal background or union membership. Company cautions the Client against giving the
                      Company this information, but Company will not collect and will not process special categories of
                      personal data; information resulting from interaction with the Website, such as computer and
                      connection information, statistics on page views, traffic to and from the Website, ad data, IP
                      address and standard web log information and/or other information obtained from "cookies";
                      information collected from publicly available sources or from service providers lawfully
                      collecting personal data (such as financial service provider, credit bureau, couriers, LinkedIn,
                      etc.), including name, email address, phone number, company name and working title of Clients that
                      based on their professional background could be interested in our Services; information collected
                      from suppliers that they provided in our Supplier Registration Form or made available publicly on
                      their website. Collected data include contact name, email address, phone number, fax number,
                      shipping and billing details; information collected via questionnaires where Client provided its
                      name, company name and email address.
                    </p>
                    <p>
                      2.2 The Company may use different types of cookies to optimize the performance of the Website.
                      <br />
                      Cookies are small text files placed on your computer, phone, or other device when you visit
                      websites and are used to record information about your activity, including the pages you view, ads
                      you click on, your settings, and other actions you take on the Website. Cookies can be
                      "persistent" or "session" cookies. Persistent cookies have an expiry date; therefore, they will
                      remain on the hard drive of the Client until that date is reached, or until they are manually
                      deleted by the Client. Session cookies are deleted once you close your web browser.
                      <br />
                      The cookies we use generally fall into one of the following categories:
                      <br />
                      Operational: We use these cookies to operate our websites. Some cookies are essential for the
                      operation of the Website. For example, they let us authenticate your account. If a user chooses to
                      disable these cookies, the user will not be able to access all of the content and features.
                      <br />
                      Security: We use these cookies support our security features. These cookies enable and support our
                      security features and help us detect malicious or unauthorized activity. For example the csrftoken
                      cookie helps us prevent Cross-Site Request Forgery (CSRF) attacks.
                      <br />
                      Functional/Preference Cookies: We use these cookies to remember your preferences. These cookies
                      let us operate certain functions of the website in line with the choices you make. These cookies
                      enable us to remember you in-between visits. For example, when you continue to use or come back to
                      the Website, we will remember your settings and content preferences. Deletion of these types of
                      cookies will result in limited functionality of the Website.
                      <br />
                      Disabling cookies: Most browsers let you remove or reject cookies. To do this, follow the
                      instructions in your browser settings. Many browsers accept cookies by default until you change
                      your settings. For more information about cookies, including how to see what cookies have been set
                      on your computer or mobile device and how to manage and delete them, visit{" "}
                      <a href="http://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer">
                        www.allaboutcookies.org
                      </a>
                      . If you do not accept our cookies, you may experience some inconvenience on our sites.
                      <br />
                      “Do Not Track Signals”: Your browser settings may allow you to automatically transmit a “Do Not
                      Track” signal. Because there currently isn’t an industry or legal standard for recognizing or
                      honoring DNT signals, we are not configured to respond to them at this time. To learn more about
                      "Do Not Track" please visit{" "}
                      <a href="http://www.allaboutdnt.com" target="_blank" rel="noopener noreferrer">
                        http://www.allaboutdnt.com
                      </a>
                      .
                    </p>
                    <p>
                      2.3 The Client can choose to decline providing information or cookies, although it may interfere
                      with the use of the Website or the services offered thereon.
                    </p>
                    2.4 Cookies used by third parties
                    <br />
                    We allow certain service providers to place cookies and similar technologies on the Website for the
                    same purposes listed above, including collecting information about your online activities over time
                    and across different websites and devices in order to provide you with more relevant advertising.
                    These providers may place their own cookies in your browser. This policy covers use of cookies by us
                    only and not the use of cookies by third parties.
                    <span id="use"></span>
                    <table className="table mt-2">
                      <thead>
                        <tr>
                          <th>Third-party Service</th>
                          <th>More information</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Google Analytics</td>
                          <td>
                            <a
                              href="https://support.google.com/analytics/answer/6004245"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              More info
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>AddThis</td>
                          <td>
                            <a href="http://www.addthis.com/privacy/" target="_blank" rel="noopener noreferrer">
                              More info
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                </ol>
              </li>
              <li>
                <strong>3. Use</strong>
                <ol>
                  <li>
                    3.1 The Company undertakes to use the Client’s information only in accordance with the purposes laid
                    down in the provisions of this Privacy Policy and probable other purposes in the relevant parts of
                    the Website. Accordingly, the Company may use the Client’s information to enable the use of the
                    services offered on the Website;
                    <br />
                    communicate in matters of dispute resolution and troubleshooting, to send transactional
                    communications via email and within the Services, including confirming your purchases, collecting
                    payments, responding to your comments, questions and requests, providing customer support, and
                    sending you technical notices, updates, security alerts, and administrative messages. These
                    communications are part of the Services and in most cases Client cannot opt out of them. If opting
                    out is available, Client will find that option within the communication itself or in Client’s
                    account settings;
                    <br />
                    prevent potentially prohibited or illegal activities, and enforce the Company’s Terms of Use, to
                    protect our rights, privacy, safety or property, or that of yours or others; to investigate and
                    protect against fraudulent, harmful, unauthorized, unethical, or illegal activity; and in connection
                    with legal claims;
                    <br />
                    customize, evaluate and enhance the Services and content;
                    <br />
                    subject to legal restrictions, inform the Client about service updates, service developments by
                    email;
                    <br />
                    we use your personal information as we believe necessary or appropriate to comply with applicable
                    laws, lawful requests and legal processes, such as to respond to subpoenas or requests from
                    government authorities;
                    <br />
                    we may create aggregated and other anonymous data derived from the use of our Services. We make
                    personal information into anonymous data by removing information that makes the data personally
                    identifiable, and we use that anonymous data to operate, understand, and improve our products and
                    services and for other lawful business purposes;
                    <br />
                    compare information for accuracy, and verify it with third parties described in section 4.
                    <span id="disclosure"></span>
                  </li>
                  <li>
                    3.2 The Client’s prior written consent is necessary for the use of its data for purposes, which are
                    not defined in Clause 3.1.
                  </li>
                  <li>
                    3.3 The Company does not sell or lend the Client’s personal information to third parties for
                    marketing purposes, not even with the Client’s explicit consent.
                  </li>
                </ol>
              </li>

              <li>
                <strong>4. Disclosure of information</strong>
                <div className="pl-40">
                  <p>
                    The Company may disclose the Client’s data to comply with legal requirements, enforce the Company’s
                    Terms of Use, prevent imminent physical harm or financial loss, ensure the smooth operation of the
                    Services, collect payments, resolve disputes or to troubleshoot problems. The relevant laws and
                    regulations shall apply to the disclosure of such data. The Company may transfer the Client’s
                    personal data to the following contracted partners:
                  </p>
                  <p>
                    service providers (engaged in performing the Company’s functions such as order processing, customer
                    support, hosting, backup, storage, payment processing, website analytics, and email delivery) in
                    order to fulfil their tasks. If a service provider needs access to your personal information to
                    perform these services, they do so only in accordance with our instructions and safeguards to
                    protect your information;
                  </p>
                  <p>couriers, such as FedEx, UPS, DHL or other third-party couriers to deliver your orders;</p>
                  <p>
                    software provider partners (such as BioSolveIT GmbH and ChemAxon Ltd.) in case of registered users
                    of the Website who subscribed and / or received trial access to tools that are only available in our
                    for-fee subscription packages (
                    <a href="https://mcule.com/pricing" target="_blank" rel="noopener noreferrer">
                      https://mcule.com/pricing
                    </a>
                    );
                  </p>
                  <p>
                    supplier partners in case Client requests direct delivery from the supplier to the premises of the
                    Client or other locations;
                  </p>
                  <p>
                    law enforcement or other governmental officials, provided that the Company is expressly authorised
                    or obliged to do so by statutory regulations or resolutions of the authorities;
                  </p>
                  <p>
                    another third party, provided that the Company is expressly authorised by the Client to do so by
                    prior written consent;
                  </p>
                  <span id="rights"></span>
                  <p>
                    other business entities, should the Company plan to merge with or be acquired by that business
                    entity. (In the event of such merger or acquisition, the Company will require that the new combined
                    entity follow this privacy policy with respect to Client’s information); we, our affiliate and our
                    service providers may use and store your personal information in Hungary or other locations outside
                    of your country of residence, where privacy laws may not be as protective as the laws where you
                    live.
                  </p>
                </div>
              </li>
              <li id="rights">
                <strong>5. Rights and responsibilities of the Client</strong>
                <ol>
                  <li>
                    5.1 Clients who are registered users of the Website may review, update, correct, or delete the
                    personal information in their profiles within their profile settings. Clients who are registered
                    users of the Website may also contact us to request deletion of their accounts. If you decline to
                    provide us with, or request deletion of, or restrict the processing of, information designated in
                    the Services as mandatory or required, we may be unable to provide the Services or parts of the
                    Services to you and may close your account and notify you about such an event by email. Client may
                    also request access to Company’s personal data processing practices and to his or her personal data
                    processed by Company. Client may also request to correct or delete his or her personal data
                    processed by Company. Client may also object to Company’s reliance on Company’s legitimate interests
                    as the legal basis of our processing your personal information, where that processing adversely
                    impacts your legal rights.
                    <p>
                      Client may send Company these requests by emailing to{" "}
                      <a href="mailto:dataprotection@mcule.com">dataprotection@mcule.com</a>. Company may request
                      information from Client to help confirm Client’s identity and process its request. Applicable law
                      may require or permit Company to reject part or all of Client’s request. If Company rejects
                      Client’s request, Company shall provide an explanation, subject to legal restrictions. If Client
                      would like to submit a complaint about Company’s use of Client’s personal information or response
                      to Client’s requests regarding personal information processing, Client may contact Company as
                      described above or submit a complaint to the data protection regulator in Client’s jurisdiction
                      (National Authority for Data Protection and Freedom of Information) or go to the competent
                      Hungarian Courts.
                    </p>
                  </li>
                  <li>
                    5.2 The Client may not select or use a username under which another person has rights without such
                    person’s authorization, neither can the Client register under a username that the Company deems
                    offensive. It is the Client’s responsibility to maintain the confidentiality of its password and to
                    restrict access to it by unauthorized persons. All damages resulting from the loss of the Client’s
                    password, as well as from its use by unauthorized persons are the responsibility of the Client.
                  </li>
                  <li>
                    5.3 The Client is liable for providing real, accurate, reasonably complete and not misleading
                    information during the registration process. The Client undertakes responsibility to maintain and
                    update this information on a continuous basis and to keep it real, accurate and reasonably complete.
                    The Client shall suffer all the consequences of providing false, inaccurate, incomplete, misleading
                    or out-dated information.
                  </li>
                  <li>
                    5.4 The Client has the right to request information about the handling of data provided by the
                    Client at the following address:{" "}
                    <a href="mailto:dataprotection@mcule.com">dataprotection@mcule.com</a>. The Client may also use this
                    address to inform the Company about the necessary changes in its data, and correction or deletion
                    thereof.
                    <span id="liability"></span>
                  </li>
                  <li>
                    5.5 Our Services are not directed to children under eighteen (18). If a parent or guardian becomes
                    aware that his or her child has provided us with information without their consent, he or she should
                    contact us and we will take steps to delete the child's data.
                  </li>
                </ol>
                <br />
              </li>

              <li>
                <strong>6. The Company’s liability</strong>
                <ol>
                  <li>
                    6.1 The Company undertakes to process the Client’s data only in accordance with the provisions of
                    this Privacy Policy and the applicable laws. Restrictions of responsibilities included in this
                    section can only be applied in the aspects of damages where the applicable law allows exclusions and
                    restrictions of responsibility at the time when the damage happened.
                  </li>
                  <li>
                    6.2 The Company makes all reasonable efforts to protect the Client’s data against unauthorized
                    access or alteration, misuse, loss by storing on secure servers. In view of the insecure character
                    of data transmission over the internet, the Company cannot be held responsible for the security of
                    the Client’s data sent over the Internet.
                  </li>
                  <li>
                    6.3 Third parties, including suppliers, may provide links to or is integrated with other websites on
                    the Website for the user’s information. The Company cannot control the contents of such websites;
                    therefore it excludes its liability for them and for any loss or damage arising from their use. Such
                    links and integrations are not endorsements of, or representations that we are affiliated with, any
                    third party. We do not control third-party websites, applications or services and we are not
                    responsible for their actions. Third-party websites and services follow different rules regarding
                    their collection, use, and sharing of your personal information. Please contact us if you wish to
                    know more. In using any links from our Services, Company does not share any personal information
                    with the sites you may link to.
                  </li>
                </ol>
                <br />
              </li>

              <li id="contact">
                <strong>7. How to Contact the Company</strong>
                <p className="pl-40">
                  If you have any questions or concerns please contact us through our{" "}
                  <a href="https://mcule.com/contact/">Contact page</a> or at{" "}
                  <a href="mailto:dataprotection@mcule.com">dataprotection@mcule.com</a>.
                </p>
              </li>

              <li id="legal-basis">
                <strong>8. Legal basis</strong>
                <div class="pl-40">
                  <p>
                    Processing Client’s data is necessary in cases such as to provide the Services or to take steps that
                    Client requests prior to requesting the Services or to comply with law.
                  </p>
                  <p>
                    Processing Client’s data for other purposes, such as to communicate with Client about the Services,
                    to improve Services,are based on Company’s legitimate interest. We do not use your personal
                    information for activities where your data-protection interests override these legitimate interests
                    (unless we have your consent or are otherwise required or permitted to by law).
                  </p>
                  <p>
                    We will only retain your personal information for as long as necessary to fulfil the purposes we
                    collected it for, including for the purposes of satisfying any legal, accounting, or reporting
                    requirements. To determine the appropriate retention period for personal information, we consider
                    the amount, nature, and sensitivity of the personal information, the potential risk of harm from
                    unauthorized use or disclosure of your personal information, the purposes for which we process your
                    personal information and whether we can achieve those purposes through other means, and the
                    applicable legal requirements.
                  </p>
                  <p>
                    You may contact the competent Hungarian Courts or the Hungarian National Authority for Data
                    Protection and Freedom of Information regarding your rights in this Policy or your rights regarding
                    your data.
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
