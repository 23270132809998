import React, { useState } from "react";
import API from "../api/api";
import { Col, Row, Button } from "reactstrap";
import Infobox from "../common/Infobox";
import InputField from "../common/InputField";
import { toast } from "react-toastify";

export default function InvitationBlock(props) {
  const [errors, setErrors] = useState({});

  const [invitation, setInvitation] = useState({
    invite_email: "",
    invite_name: "",
    invite_type: "CS",
  });

  const handleInviteInputChange = (event) => {
    event.persist();
    setInvitation((input) => ({
      ...input,
      [event.target.name]: event.target.value,
    }));
  };

  const sendInvitation = () => {
    API.post("invitations/", invitation)
      .then((result) => {
        toast.success(
          `Invite successfully sent out to ${invitation.invite_email}`,
          {}
        );
        setInvitation({
          invite_email: "",
          invite_name: "",
          invite_type: "CS",
        });
        setErrors({});
      })
      .catch((err) => {
        toast.error("Please correct the form errors.", {});
        if (err.response) {
          setErrors(err.response.data);
        }
      });
  };

  return (
    <div className="invite-block">
      <div className="invite-title">
        <strong>One of your partner is missing from this list?</strong> enter
        their contact details and send them an invitation.
      </div>
      <Infobox>
        <p>
          Please note that sending an invitation to one of your supplier
          partners does not mean that they will automatically have access to
          your auctions. To gain access, first, they must finalize their
          registration, then you have to select them from the preferred supplier
          list in your profile or when creating the upcoming auctions.
        </p>
      </Infobox>
      <Row className="my-2">
        <Col md={6} sm={12}>
          <InputField
            formGroupClass="ml-auto"
            name="invite_name"
            displayName="Organization name"
            type="text"
            value={invitation.invite_name}
            onChangeHandler={handleInviteInputChange}
            invalidCondition={errors.invite_name}
            errorField={errors && errors.invite_name ? errors.invite_name : ""}
          />
        </Col>
        <Col md={6} sm={12}>
          <InputField
            name="invite_email"
            displayName="Contact email"
            type="text"
            value={invitation.invite_email}
            onChangeHandler={handleInviteInputChange}
            invalidCondition={errors.invite_email}
            errorField={
              errors && errors.invite_email ? errors.invite_email : ""
            }
          />
        </Col>
      </Row>
      <div className="text-center">
        <Button className="btn-orange" onClick={() => sendInvitation()}>
          Send invitation
        </Button>
      </div>
    </div>
  );
}
