import { getDefaultServices } from "./webservices";

const params = {
  imageType: "image/png",
  inputFormat: null,
  settings: {
    carbonLabelVisible: false,
    cpkColoring: true,
    chiralFlagVisible: true,
    atomMapsVisible: true,
    lonePairsVisible: true,
    lonepaircalculationenabled: 0,
    atomIndicesVisible: false,
    implicitHydrogen: "TERMINAL_AND_HETERO",
    displayMode: "WIREFRAME",
    // "background-color": "#ffffff",
    zoomMode: "fit",
    width: parseInt(450, 10),
    height: parseInt(450, 10),
  },
  services: {
    molconvertws: getDefaultServices().molconvertws,
    stereoinfows: getDefaultServices().stereoinfows,
  },
};

let namespace;

const loadConverter = (id) => {
  return new Promise((resolve, reject) => {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("src", "/marvinjs/marvinpack.html");
    iframe.setAttribute("id", id);
    iframe.style.visibility = "hidden";
    document.body.appendChild(iframe);

    window.MarvinJSUtil.getPackage(id)
      .then((ns) => {
        namespace = ns;
        if (!namespace) {
          reject();
        }
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const convertStructureToImage = (structure) => {
  return new Promise((resolve, reject) => {
    namespace.onReady(() => {
      resolve(new namespace.ImageExporter(params).render(structure));
    });
  });
};

export { loadConverter, convertStructureToImage };
