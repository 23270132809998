import React, { useState, useEffect } from "react";
import { Table, Button, UncontrolledTooltip as Tooltip } from "reactstrap";
import API from "../api/api";
import LinkButton from "../common/LinkButton";
import * as CONSTANTS from "../common/Constants";
import moment from "moment";
import { toast } from "react-toastify";
import ConfirmModal from "../common/ConfirmModal";
import StructureImage from "../common/StructureImage";
import { formatPrice } from "../common/Utilities";
import LazyLoad from "react-lazyload";
import Td from "../common/ContentTag";

export default function AuctionList(props) {
  const APIDomain = process.env.REACT_APP_API_DOMAIN;
  const APIBase = process.env.REACT_APP_API_BASE_URL;
  const [auctions, setAuctions] = useState([]);
  const [auction, setAuction] = useState({});
  const [confirmCancel, setConfirmCancel] = useState(false);

  const hasDiscountedPrice = (bid) => {
    return bid.discounted_public_total_price !== bid.public_total_price;
  };

  const cancelClicked = (auction) => {
    setAuction(auction);
    toggleModal();
  };

  const cancelAuction = () => {
    if (auction.state === CONSTANTS.AUCTION_STATE.DRAFT) {
      API.delete(`auctions/${auction.id}/`)
        .then((res) => {
          toast.success("Auction deleted successfully!", {});
          getAuctions();
        })
        .catch((err) => {
          toast.error("Please correct the form errors." + err, {});
          console.log("ERROR", err);
        });
    } else {
      API.patch(`auctions/${auction.id}/`, {
        state: CONSTANTS.AUCTION_STATE.CANCELLED,
      })
        .then((res) => {
          toast.success("Auction canceled successfully!", {});
          getAuctions();
        })
        .catch((err) => {
          toast.error("Please correct the form errors." + err, {});
          console.log("ERROR", err);
        });
    }
  };

  const toggleModal = () => {
    setConfirmCancel(!confirmCancel);
  };

  function getDownloadAuctionReportURL(auction) {
    return `${APIDomain}${APIBase}auctions/${auction.id}/auction-summary/`;
  }

  const getAuctions = () => {
    API.get("auctions/")
      .then((result) => {
        setAuctions(result.data.results);
      })
      .catch((err) => {
        toast.error("Error loading auction data", {});
        console.log("ERROR", err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    setAuctions(props.auctions);
  }, [props.auctions]);

  const renderDate = (auction) => {
    switch (auction.state) {
      case CONSTANTS.AUCTION_STATE.APPROVED:
        return (
          <div>
            {`Closes: ${moment(auction.valid_until).format(
              CONSTANTS.DATETIME_FORMAT
            )}`}
          </div>
        );
      case CONSTANTS.AUCTION_STATE.SUBMITTED:
      case CONSTANTS.AUCTION_STATE.ACCEPTED:
      case CONSTANTS.AUCTION_STATE.CANCELLED:
      case CONSTANTS.AUCTION_STATE.EXPIRED:
      case CONSTANTS.AUCTION_STATE.CLOSED:
        return (
          <div>
            {`Valid until: ${moment(auction.expiration_date).format(
              CONSTANTS.DATETIME_FORMAT
            )}`}
          </div>
        );

      default:
        return null;
    }
  };

  const renderResult = () => {
    if (auction.length <= 0) {
      return (
        <div className="text-center subheadline blue">No auction found</div>
      );
    } else {
      return (
        <React.Fragment>
          <Table>
            <thead>
              <tr>
                <th className="column-structure">Structure</th>
                <th>Auction details</th>
                <th>Status</th>
                {!props.summary && <th>Best price bid</th>}
                {!props.summary && <th>Best lead time bid</th>}
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {auctions.length === 0 && (
                <tr>
                  <td colspan={props.summary ? 4 : 6}>No auction found</td>
                </tr>
              )}
              {auctions.map((auction, index) => (
                <tr key={auction.id}>
                  <td data-label="Structure" className={"structure-container "}>
                    {auction.structures.length > 0 && (
                      <LazyLoad height={100}>
                        <StructureImage
                          isBackground={true}
                          src={auction.structures[0].chemical_id}
                          alt={auction.structures[0].unique_id}
                          className="compound-img"
                        />
                      </LazyLoad>
                    )}
                    {auction.structures.length > 1 && (
                      <div className="structure-counter">
                        + {auction.structures.length - 1} other(s)
                      </div>
                    )}
                  </td>
                  <Td
                    to={
                      auction.state === 5
                        ? `/auction/${auction.id}`
                        : `/track-auction/${auction.id}`
                    }
                    datalabel="Auction detail"
                  >
                    <div>
                      <div>{auction.auction_name_full}</div>
                      {renderDate(auction)}
                    </div>
                  </Td>
                  <Td
                    to={
                      auction.state === 5
                        ? `/auction/${auction.id}`
                        : `/track-auction/${auction.id}`
                    }
                    datalabel="Status"
                  >
                    <div
                      className={
                        "status " + auction.state_display.toLowerCase()
                      }
                    >
                      {auction.state_display}
                    </div>
                  </Td>
                  {!props.summary && (
                    <Td
                      to={
                        auction.state === 5
                          ? `/auction/${auction.id}`
                          : `/track-auction/${auction.id}`
                      }
                      datalabel="Best price bid"
                    >
                      <div>
                        {auction.best_price_bid ? (
                          <React.Fragment>
                            <div
                              className={
                                "detail-icons price-icon " +
                                (hasDiscountedPrice(auction.best_price_bid)
                                  ? "old-price"
                                  : "")
                              }
                              id={`auction-best-price-${auction.id}-price`}
                            >
                              {formatPrice(
                                auction.best_price_bid.public_total_price
                              )}
                            </div>
                            <Tooltip
                              placement="left"
                              target={`auction-best-price-${auction.id}-price`}
                            >
                              Total price
                            </Tooltip>
                            {hasDiscountedPrice(auction.best_price_bid) && (
                              <>
                                <div
                                  className="detail-icons price-icon discounted-price"
                                  id={`auction-best-price-${auction.id}-discounted-price`}
                                >
                                  {formatPrice(
                                    auction.best_price_bid
                                      .discounted_public_total_price
                                  )}
                                </div>
                                <Tooltip
                                  placement="left"
                                  target={`auction-best-price-${auction.id}-discounted-price`}
                                >
                                  Discounted total price
                                </Tooltip>
                              </>
                            )}
                            <div
                              className="detail-icons delivery-icon"
                              id={`auction-best-price-${auction.id}-time`}
                            >
                              {auction.best_price_bid.public_delivery_time +
                                " week(s)"}
                            </div>
                            <Tooltip
                              placement="left"
                              target={`auction-best-price-${auction.id}-time`}
                            >
                              Lead time
                            </Tooltip>
                            <div
                              className="detail-icons purity-icon"
                              id={`auction-best-price-${auction.id}-purity`}
                            >
                              {auction.best_price_bid.purity + "%"}
                            </div>
                            <Tooltip
                              placement="left"
                              target={`auction-best-price-${auction.id}-purity`}
                            >
                              Purity
                            </Tooltip>
                            <div
                              className="detail-icons num-compounds-icon"
                              id={`auction-best-price-${auction.id}-num`}
                            >
                              {auction.best_price_bid.num_compounds}
                            </div>
                            <Tooltip
                              placement="left"
                              target={`auction-best-price-${auction.id}-num`}
                            >
                              Number of compounds
                            </Tooltip>
                          </React.Fragment>
                        ) : (
                          "No bid available for this auction"
                        )}
                      </div>
                    </Td>
                  )}
                  {!props.summary && (
                    <Td
                      to={
                        auction.state === 5
                          ? `/auction/${auction.id}`
                          : `/track-auction/${auction.id}`
                      }
                      datalabel="Bid delivery time bid"
                    >
                      <div>
                        {auction.best_delivery_time_bid ? (
                          <React.Fragment>
                            <div
                              className={
                                "detail-icons price-icon " +
                                (hasDiscountedPrice(
                                  auction.best_delivery_time_bid
                                )
                                  ? "old-price"
                                  : "")
                              }
                              id={`auction-best-delivery-${auction.id}-price`}
                            >
                              {formatPrice(
                                auction.best_delivery_time_bid
                                  .public_total_price
                              )}
                            </div>
                            <Tooltip
                              placement="left"
                              target={`auction-best-delivery-${auction.id}-price`}
                            >
                              Total price
                            </Tooltip>
                            {hasDiscountedPrice(
                              auction.best_delivery_time_bid
                            ) && (
                              <>
                                <div
                                  className="detail-icons price-icon discounted-price"
                                  id={`auction-best-delivery-${auction.id}-discounted-price`}
                                >
                                  {formatPrice(
                                    auction.best_delivery_time_bid
                                      .discounted_public_total_price
                                  )}
                                </div>
                                <Tooltip
                                  placement="left"
                                  target={`auction-best-delivery-${auction.id}-discounted-price`}
                                >
                                  Discounted total price
                                </Tooltip>
                              </>
                            )}
                            <div
                              className="detail-icons delivery-icon"
                              id={`auction-best-delivery-${auction.id}-time`}
                            >
                              {auction.best_delivery_time_bid
                                .public_delivery_time + " week(s)"}
                            </div>
                            <Tooltip
                              placement="left"
                              target={`auction-best-delivery-${auction.id}-time`}
                            >
                              Lead time
                            </Tooltip>
                            <div
                              className="detail-icons purity-icon"
                              id={`auction-best-delivery-${auction.id}-purity`}
                            >
                              {auction.best_delivery_time_bid.purity + "%"}
                            </div>
                            <Tooltip
                              placement="left"
                              target={`auction-best-delivery-${auction.id}-purity`}
                            >
                              Purity
                            </Tooltip>
                            <div
                              className="detail-icons num-compounds-icon"
                              id={`auction-best-delivery-${auction.id}-num`}
                            >
                              {auction.best_delivery_time_bid.num_compounds}
                            </div>
                            <Tooltip
                              placement="left"
                              target={`auction-best-delivery-${auction.id}-num`}
                            >
                              Number of compounds
                            </Tooltip>
                          </React.Fragment>
                        ) : (
                          "No bid available for this auction"
                        )}
                      </div>
                    </Td>
                  )}
                  <td className="text-center">
                    <LinkButton
                      to={`/auction/${auction.id}`}
                      className="d-block btn btn-orange mb-1 mx-auto"
                    >
                      Auction details
                    </LinkButton>
                    {[
                      CONSTANTS.AUCTION_STATE.APPROVED,
                      CONSTANTS.AUCTION_STATE.ACCEPTED,
                      CONSTANTS.AUCTION_STATE.CLOSED,
                      CONSTANTS.AUCTION_STATE.EXPIRED,
                      CONSTANTS.AUCTION_STATE.REQUESTED,
                      CONSTANTS.AUCTION_STATE.CANCELLED,
                    ].includes(auction.state) && (
                      <LinkButton
                        to={`/track-auction/${auction.id}`}
                        className="d-block btn btn-orange mb-1 mx-auto"
                      >
                        Track auction
                      </LinkButton>
                    )}
                    {auction.state === CONSTANTS.AUCTION_STATE.ACCEPTED && (
                      <LinkButton
                        to={`/confirm-order/${auction.id}`}
                        className="d-block btn btn-orange mb-1 mx-auto"
                      >
                        Confirm order
                      </LinkButton>
                    )}
                    {(auction.state === CONSTANTS.AUCTION_STATE.DRAFT ||
                      auction.state === CONSTANTS.AUCTION_STATE.SUBMITTED ||
                      auction.state === CONSTANTS.AUCTION_STATE.APPROVED) && (
                      <Button
                        className="d-block btn btn-orange mb-1 mx-auto"
                        onClick={(event) => cancelClicked(auction)}
                      >
                        {auction.state === CONSTANTS.AUCTION_STATE.DRAFT
                          ? "Discard draft"
                          : "Cancel auction"}
                      </Button>
                    )}
                    {(auction.state === CONSTANTS.AUCTION_STATE.ACCEPTED ||
                      CONSTANTS.AUCTION_STATE.CANCELLED ||
                      auction.state === CONSTANTS.AUCTION_STATE.CLOSED ||
                      auction.state === CONSTANTS.AUCTION_STATE.EXPIRED) && (
                      <a
                        className="btn btn-orange mx-auto"
                        href={getDownloadAuctionReportURL(auction)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download report
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <ConfirmModal
            open={confirmCancel}
            toggle={toggleModal}
            confirmed={cancelAuction}
            title="Confirmation needed"
            bodyText="Are you sure you want to cancel this auction?"
            cancelBtn="No"
            okBtn="Yes"
          />
        </React.Fragment>
      );
    }
  };

  return renderResult();
}
