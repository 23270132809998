import React from "react";
import { FaClipboard } from "react-icons/fa";
import { IconContext } from "react-icons";
import { ToolTip } from "./ToolTip";

export default function CopyToClipboard(props) {
  const rand = Math.floor(Math.random() * 6) + 1;
  return (
    <IconContext.Provider value={{ size: "1em" }}>
      <ToolTip placement="bottom" target={`clipboard-${rand}`}>
        Copy to clipboard
      </ToolTip>
      <FaClipboard
        className="clipboard-icon"
        id={`clipboard-${rand}`}
        onClick={() => {
          navigator.clipboard.writeText(props.text);
        }}
      />
    </IconContext.Provider>
  );
}
