import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "./context/auth";

function PrivateRoute({ component: Component, ...rest }) {
  const { userInfo } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        userInfo ? (
          <Component {...props} />
        ) : (
            <Component {...props} />
            //   <Redirect
            //     to={{ pathname: "/login", state: { referer: props.location } }}
            //   />
          )
      }
    />
  );
}

export default PrivateRoute;
