import LRU from 'lru-cache'
const cache = new LRU(10)

function accessResult(fetch, input) {
  const hasValue = input => JSON.stringify(input)
  const key = hasValue(input)
  const found = cache.get(key)

  if (!found) {
    const thenable = fetch(input)
    const result = {
      status: 'Pending',
      value: thenable,
    }

    thenable.then(
      fullfilmentValue => {
        if (result.status === 'Pending') {
          result.status = 'Resolved'
          result.value = fullfilmentValue
        }
      },
      rejectionReason => {
        if (result.status === 'Pending') {
          result.status = 'Rejected'
          result.value = rejectionReason
        }
      },
    )
    cache.set(key, result)
    return result
  } else {
    return found
  }
}

function createResource(fetch) {
  const resource = {
    read(input) {
      const { status, value } = accessResult(fetch, input)
      switch (status) {
        case 'Pending': {
          throw value
        }
        case 'Resolved': {
          return value
        }
        case 'Rejected': {
          throw value
        }
        default:
          return null
      }
    },
  }

  return resource
}
export { createResource }
