import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  CustomInput,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  FormText,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import API from "../api/api";
import { toast } from "react-toastify";
import * as CONSTANTS from "../common/Constants";
import moment from "moment";
import StructureImage from "../common/StructureImage";
import InputField from "../common/InputField";
import ConfirmModal from "../common/ConfirmModal";
import { Link as ScrollTo } from "react-scroll";
import SimpleTooltip from "../common/SimpleTooltip";
import MetaTags from "react-meta-tags";

export default function OrderSupplier(props) {
  const title =
    CONSTANTS.TITLES.ORDER_TRACKING +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;
  const APIDomain = process.env.REACT_APP_API_DOMAIN;
  const APIBase = process.env.REACT_APP_API_BASE_URL;
  const [order, setOrder] = useState(null);
  // const [message, setMessage] = useState("");
  const [updatedState, setUpdatedState] = useState();
  const [confirmStatusModal, setConfirmStatusModal] = useState(false);
  const validChoices = [
    CONSTANTS.ORDER_STATE.REAGENTS_ARRRIVED,
    CONSTANTS.ORDER_STATE.VALIDATION,
    CONSTANTS.ORDER_STATE.PRODUCTION,
    CONSTANTS.ORDER_STATE.PURIFICATION,
    CONSTANTS.ORDER_STATE.READY_FOR_SHIPPING,
    CONSTANTS.ORDER_STATE.SHIPPING_TO_MCULE,
  ];
  const [errors, setErrors] = useState({});
  const [availableStates, setAvailableStates] = useState([]);
  const [logoFreeFile, setLogoFreeFile] = useState(false);
  const [files, setFiles] = useState([]);
  const [confirmDeleteFile, setConfirmDeleteFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const toggleDeleteFileModal = () => {
    setConfirmDeleteFile(!confirmDeleteFile);
  };

  const deleteReportingFile = () => {
    API.delete(`files/reporting/${selectedFile.id}/`)
      .then((res) => {
        toast.success("File deleted!", {});
        if (selectedFile.assigned_to) {
          const newFiles = bid.files.filter((f) => f.id !== selectedFile.id);
          setBid((inputs) => ({
            ...inputs,
            files: newFiles,
          }));
        } else {
          const newFiles = files.filter((f) => f.id !== selectedFile.id);
          setFiles(newFiles);
        }
      })
      .catch((err) => {
        toast.error("Error at deleting a file: " + err, {});
        console.log("ERROR", err);
      });
  };

  const statusUpdateClicked = () => {
    toggleModal();
  };

  const statusUpdate = () => {
    const obj = {
      state: updatedState,
    };

    API.put(`customer-orders-for-supplier/${props.match.params.orderId}/`, obj)
      .then((res) => {
        toast.success("Status updated successfully!", {});
        getOrderInfo(order.id);
      })
      .catch((err) => {
        toast.error("Error at status update." + err, {});
        console.log("ERROR", err);
      });
  };

  const toggleModal = () => {
    setConfirmStatusModal(!confirmStatusModal);
  };

  const handleFileChange = (event) => {
    if (!event.target.files[0]) {
      return
    }
    setIsFileUploading(true);
    const formData = new FormData();
    formData.append("name", event.target.files[0].name);
    formData.append("file", event.target.files[0]);
    API.post(`files/reporting/`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((res) => {
        setFiles((inputs) => [...inputs, res.data]);
      })
      .catch((err) => {
        toast.error("Error at file upload.", {});
        if (err.response) {
          setErrors((errors) => ({
            ...errors,
            file: err.response.data.file[0],
          }));
        }
      })
      .finally(() => {
        setIsFileUploading(false);
      });
  };

  const uploadReport = () => {
    setErrors({});
    if (files.length > 0 && !logoFreeFile) {
      setErrors((errors) => ({
        ...errors,
        logoFreeFile: !logoFreeFile
          ? "Please check this box if You want to proceed"
          : null,
      }));
      toast.error("Please correct the form errors.", {});
    } else {
      const obj = {
        reporting_files: [...order.reporting_files, ...files].map(function (
          file
        ) {
          return file.id;
        }),
      };

      API.put(
        `customer-orders-for-supplier/${props.match.params.orderId}/`,
        obj
      )
        .then((res) => {
          toast.success("Report uploaded successfully!", {});
          getOrderInfo(order.id);
          setFiles([]);
        })
        .catch((err) => {
          toast.error("Error at report upload. " + err, {});
          console.log("ERROR", err);
        });
    }
  };

  const orderState = [
    {
      id: CONSTANTS.ORDER_STATE.CONFIRMED_BY_SUPPLIER,
      title: "Confirmed by supplier",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.REAGENTS_ARRRIVED,
      title: "Reagents have arrived",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.VALIDATION,
      title: "Validation",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.PRODUCTION,
      title: "Production",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.PURIFICATION,
      title: "Purification",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.READY_FOR_SHIPPING,
      title: "Ready for shipping",
      date: null,
    },
    {
      id: CONSTANTS.ORDER_STATE.SHIPPING_TO_MCULE,
      title: "Shipping to Mcule",
      date: null,
    },
  ];

  const handleUpdateStatusChange = (event) => {
    event.persist();
    setUpdatedState(event.target.value);
  };

  const renderStateOptions = (actualState) => {
    const availableState = orderState.filter(
      (state) => state.id >= actualState && validChoices.includes(state.id)
    );
    return availableState.map((item, index) => {
      return (
        <option key={item.id} value={item.id}>
          {item.title}
        </option>
      );
    });
  };

  useEffect(() => {
    if (props.match.params.orderId) {
      getOrderInfo(props.match.params.orderId);
    }
  }, [props.match.params.orderId]);

  const getOrderInfo = (orderId) => {
    API.get(`customer-orders-for-supplier/${orderId}/`)
      .then((res) => {
        setOrder(res.data);
        // setShippingAddress(
        // 	res.data.addresses.find(
        // 		(address) => address.type_display === "shipping"
        // 	)
        // );
        let tempAvailableStates = orderState.filter(
          (state) =>
            state.id >= res.data.state && validChoices.includes(state.id)
        );
        setAvailableStates(tempAvailableStates);
        if (availableStates.length) {
          setUpdatedState(availableStates[0].id);
        }
      })
      .catch((err) => {
        toast.error("Error loading order data", {});
        console.log("ERROR", err);
      })
      .finally(() => {
        // this.setState({loading: false})
      });
  };

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="mt-4">
        {order ? (
          <React.Fragment>
            <Row>
              <Col sm={8}>
                <ul className="breadcrumb">
                  <li>
                    <Link to={`/my-orders`}>My orders</Link>
                  </li>
                  <li>{order.order_cqr_name}</li>
                </ul>
                <h1 className="subheadline">
                  {order.order_cqr_name} Order Tracking
                </h1>
              </Col>
              <Col sm={4} className="text-right header-right">
                <div className="subheader">
                  <span className="subheader-label">Status:</span>
                  <span
                    className={"status " + order.state_display.toLowerCase()}
                  >
                    {order.state_display}
                  </span>
                </div>
                <h3 className="subheadline">{order.auction.quote_name}</h3>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={6}>
                <div className="subheader border-left-blue">
                  {order.supplier_created_at && (
                    <div>
                      <span className="subheader-label">Date of order:</span>
                      <span className="subheader-value">
                        {moment(order.supplier_created_at).format(
                          CONSTANTS.DATETIME_FORMAT
                        )}
                      </span>
                    </div>
                  )}
                  {order.supplier_end_date && (
                    <div>
                      <span className="subheader-label">
                        Expected delivery date:
                      </span>
                      <span className="subheader-value">
                        {moment(order.supplier_end_date).format(
                          CONSTANTS.DATE_FORMAT
                        )}
                      </span>
                    </div>
                  )}
                  <div>
                    <span className="subheader-label pl-2">
                      Time remaining:
                    </span>
                    <span className="subheader-value">
                      {(order.supplier_time_remaining || 0) + " day(s)"}
                    </span>
                  </div>
                </div>
              </Col>
              {availableStates.length > 0 && (
                <Col sm={6} className="text-right">
                  <ScrollTo
                    className="btn btn-blue btn"
                    to="status-update-btn"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Update status
                  </ScrollTo>
                </Col>
              )}
            </Row>

            <h2 className="subheadline blue">Order details</h2>
            <div className="box-container blue">
              <div className="box">
                <Row>
                  <Col sm={6}>
                    <Row className="align-items-center">
                      <Col sm={6}>
                        <Label for="price">Total price</Label>
                      </Col>
                      <Col sm={6}>
                        <InputField
                          name="price"
                          type="price"
                          value={order.bid.price}
                          readOnly
                          append="USD"
                        />
                      </Col>
                    </Row>
                    <Row className="align-items-center mb-3">
                      <Col sm={6}>
                        <Label for="amount">Ordered amount</Label>
                      </Col>
                      <Col sm={6}>
                        <InputGroup>
                          <Input
                            type="number"
                            name="amount"
                            id="amount"
                            value={order.bid.amount}
                            disabled
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>mg</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="align-items-center mb-3">
                      <Col sm={6}>
                        <Label for="purity">Ordered purity</Label>
                      </Col>
                      <Col sm={6}>
                        <InputGroup>
                          <Input
                            type="number"
                            name="purity"
                            id="purity"
                            value={order.bid.purity}
                            disabled
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>%</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="align-items-center mb-3">
                      <Col sm={6}>
                        <Label for="num_compounds">Number of compounds</Label>
                      </Col>
                      <Col sm={6}>
                        <Input
                          type="text"
                          name="num_compounds"
                          id="num_compounds"
                          value={order.bid.num_compounds}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row className="align-items-center mb-3">
                      <Col sm={6}>
                        <Label for="country">Shipping country</Label>
                      </Col>
                      <Col sm={6}>
                        <Input
                          type="text"
                          name="country"
                          id="country"
                          value={"Hungary"}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row className="align-items-top mb-3">
                      <Col sm={6}>
                        <Label for="address">Shipping address</Label>
                      </Col>
                      <Col sm={6}>
                        <Input
                          type="textarea"
                          rows="3"
                          name="address"
                          className="form-control"
                          value={"Bartók Béla út 105-113.\nH-1115, Budapest"}
                          disabled
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={6}>
                    <div>All of these compounds will be shipped.</div>
                    <Table>
                      <thead>
                        <tr>
                          <th className="column-id">#</th>
                          <th className="text-center">Structure</th>
                          <th>Chemical ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.bid.auction_structures_filtered &&
                          order.bid.auction_structures_filtered.map(
                            (item, index) => (
                              <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td className="structure-container">
                                  <StructureImage src={item.chemical_id} />
                                  <div>{item.customer_id}</div>
                                </td>
                                <td>{item.chemical_id}</td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </div>
            <div id="status-update-btn"></div>
            <Row>
              <Col sm={12} md={6}>
                <h2 className="subheadline blue">
                  Order status
                  <SimpleTooltip name="status">
                    Here you can update the status of the order to inform the
                    customer about the project. The dates under the completed
                    steps belong to the status modification. Please try to keep
                    the order status as up-to-date as possible.
                  </SimpleTooltip>
                </h2>
              </Col>
              {availableStates.length > 0 && (
                <Col sm={12} md={6} className="text-right">
                  <span className="subheadline blue">Update status: </span>
                  <Input
                    type="select"
                    name="updated_status"
                    id="updated_status"
                    onChange={handleUpdateStatusChange}
                    value={updatedState}
                    disabled={
                      order.state === CONSTANTS.ORDER_STATE.SHIPPING_TO_MCULE
                    }
                  >
                    {renderStateOptions(order.state)}
                  </Input>
                  <Button
                    className="btn btn-blue"
                    onClick={() => statusUpdateClicked()}
                    disabled={
                      order.state === CONSTANTS.ORDER_STATE.SHIPPING_TO_MCULE ||
                      !validChoices.includes(Number.parseInt(updatedState))
                    }
                  >
                    Save
                  </Button>
                </Col>
              )}
            </Row>
            <div className="box-container blue">
              <div className="box">
                <ul className="timeline" id="timeline">
                  {orderState.map((item, index) => {
                    const foundState = order.state_updates.find(
                      (x) => Number.parseInt(x.id) === item.id
                    );
                    item.date = foundState
                      ? moment(foundState.date).format(
                          CONSTANTS.DATETIME_FORMAT
                        )
                      : null;

                    return (
                      <li
                        key={item.title}
                        className={`li ${item.date ? "complete" : ""}`}
                      >
                        <div className="timestamp text-center">
                          {item.title}
                        </div>
                        <div className="order-status">{item.date}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <h2 className="subheadline blue">Uploaded files</h2>
            <div className="box-container blue">
              <div className="box">
                <div className="label-line-wrapper blue">
                  <div className="label-text">Customer files</div>
                  <div className="label-line"></div>
                </div>
                {order.auction?.files?.length > 1 && (
                  <a
                    href={`${APIDomain}${APIBase}auctions/${order.auction.id}/download-files/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mb-1 d-block"
                  >
                    <span>Download all files</span>
                  </a>
                )}

                <ul className="file-list">
                  {order.auction?.files?.map((file) => (
                    <li key={file.id}>
                      <a
                        href={`${APIDomain}${APIBase}files/auction/${file.id}/download/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="far fa-file"></i>{" "}
                        <span>{file.filename}</span>
                      </a>
                    </li>
                  ))}
                </ul>

                <div className="label-line-wrapper blue">
                  <div className="label-text">Bid files</div>
                  <div className="label-line"></div>
                </div>
                {order.bid?.files?.length > 1 && (
                  <a
                    href={`${APIDomain}${APIBase}bids/${order.bid.id}/download-files/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mb-1 d-block"
                  >
                    <span>Download all files</span>
                  </a>
                )}
                <ul className="file-list">
                  {order.bid?.files?.map((file) => (
                    <li key={file.id}>
                      <a
                        href={`${APIDomain}${APIBase}files/bid/${file.id}/download/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="far fa-file"></i>{" "}
                        <span>{file.filename}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <h2 className="subheadline blue">Reports</h2>
            <div className="box-container blue">
              <div className="box">
                <FormGroup className="mw-50">
                  <Label for="files">File</Label>
                  <div className="position-relative">
                    <CustomInput
                      type="file"
                      id="files"
                      name="files"
                      label="Upload file"
                      onChange={handleFileChange}
                      className={errors.file ? "is-invalid" : ""}
                    />
                    {errors.file && <FormFeedback>{errors.file}</FormFeedback>}
                    <FormText color="muted">
                      Supported file formats: .gz, .zip, .pdf, .jpg, .png
                    </FormText>
                    {isFileUploading && (
                      <div className="file-uploading-overlay">
                        <Spinner color="info" />
                        <span className="ml-2">Uploading file</span>
                      </div>
                    )}
                  </div>
                </FormGroup>
                {order.reporting_files?.length > 1 && (
                  <a
                    href={`${APIDomain}${APIBase}customer-orders/${order.id}/download-reporting-files/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mb-1 d-block"
                  >
                    <span>Download all files</span>
                  </a>
                )}

                <ul className="file-list">
                  {order.reporting_files.map((file) => (
                    <li key={file.id}>
                      <a
                        href={`${APIDomain}${APIBase}files/reporting/${file.id}/download/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="far fa-file"></i>{" "}
                        {`${file.filename} (${moment(file.created_at).format(
                          CONSTANTS.DATETIME_FORMAT
                        )})`}
                        {/* <span>{file.name}</span> */}
                      </a>
                      {/* <span
                        className="del-btn"
                        onClick={() => {
                          setSelectedFile(file);
                          toggleDeleteFileModal();
                        }}
                      >
                        &#10006;
                      </span> */}
                    </li>
                  ))}
                  {files.length > 0 && (
                    <>
                      <div className="mt-2">Newly added files</div>
                      {files.map((file) => (
                        <li key={file.id}>
                          <a
                            href={`${APIDomain}${APIBase}files/reporting/${file.id}/download/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="far fa-file"></i>{" "}
                            <span>{file.name || file.filename}</span>
                          </a>

                          <span
                            className="del-btn"
                            onClick={() => {
                              setSelectedFile(file);
                              toggleDeleteFileModal();
                            }}
                          >
                            &#10006;
                          </span>
                        </li>
                      ))}
                    </>
                  )}
                </ul>
                {files.length > 0 && (
                  <FormGroup check>
                    <Label
                      check
                      className={errors.logoFreeFile ? "text-danger" : ""}
                    >
                      <Input
                        type="checkbox"
                        name="logo-free-file"
                        id="logo-free-file"
                        onChange={() => {
                          setLogoFreeFile(!logoFreeFile);
                        }}
                        checked={logoFreeFile}
                        invalid={errors.logoFreeFile}
                      />{" "}
                      I understand and acknowledge when uploading files, that
                      according to Mcule SynthAgora Terms and Conditions it is
                      not allowed to upload files containing company identifier
                      information. I accept that if such a case would occur the
                      Administrator of SynthAgora can remove these files in its
                      sole decision.
                    </Label>
                    {errors.logoFreeFile && (
                      <FormFeedback>{errors.logoFreeFile}</FormFeedback>
                    )}
                  </FormGroup>
                )}
                <Button
                  disabled={files.length === 0}
                  className="btn btn-blue"
                  onClick={() => uploadReport()}
                >
                  Upload report
                </Button>
              </div>
            </div>
            <ConfirmModal
              open={confirmStatusModal}
              toggle={toggleModal}
              confirmed={statusUpdate}
              title="Confirmation needed"
              bodyText="Are you sure you want to set the status of this order?"
              cancelBtn="No"
              okBtn="Yes"
            />
            <ConfirmModal
              open={confirmDeleteFile}
              toggle={toggleDeleteFileModal}
              confirmed={deleteReportingFile}
              title="Confirmation needed"
              bodyText={
                "Are you sure you want to delete this file? This step cannot be redone."
              }
              cancelBtn="No"
              okBtn="Yes"
            />

            {/* <h2 className="mb-2 subheadline blue">Messages</h2>
            <div className="box-container blue">
              <div className="box">
                <FormGroup>
                  <Label for="message">
                    Do you need further information about
                    the project?
                  </Label>
                  <FormText color="muted">
                    This is a provate communication channel
                    where you can discuss further details of
                    the project.
                  </FormText>
                  <Input
                    type="textarea"
                    name="message"
                    id="message"
                    placeholder="Message to your vendor! (Note: you cannot share information that violate the anonymity of the website users.)"
                    rows="5"
                    onChange={event => {
                      setMessage(event);
                    }}
                    value={message}
                    // disabled={isDisabledField()}
                    // invalid={errors.description}
                  />
                </FormGroup>
                <div className="text-right">
                  <Button
                    className="btn-blue"
                    onClick={() => console.log("send")}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div> */}
          </React.Fragment>
        ) : (
          <div>No order found</div>
        )}
      </Container>
    </div>
  );
}
