import React from "react";
import { FaInfoCircle, FaTimes, FaCheck } from "react-icons/fa";
import { IconContext } from "react-icons";

export function MyCheckMark() {
  return (
    <IconContext.Provider value={{ color: "#4fc67f", size: "1.2em" }}>
      <FaCheck />
    </IconContext.Provider>
  );
}

export function MyCrossMark() {
  return (
    <IconContext.Provider value={{ color: "#e27161", size: "1.2em" }}>
      <FaTimes />
    </IconContext.Provider>
  );
}

export function MyInfoCircle() {
  return (
    <IconContext.Provider value={{ color: "#4f4f4f", size: "1.2em" }}>
      <FaInfoCircle />
    </IconContext.Provider>
  );
}
