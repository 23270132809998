import React, { useState, useEffect } from "react";
import {
  Alert,
  Container,
  Col,
  Row,
  Form,
  FormGroup,
  FormText,
  Button,
  Label,
  Input,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  CustomInput,
  ButtonGroup,
  Spinner,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Datetime from "react-datetime";
import MetaTags from "react-meta-tags";
import { toast } from "react-toastify";
import * as moment from "moment";
import API from "../api/api";
import { cloneDeep, isDate } from "lodash";
import { useAuth } from "../context/auth";
import LinkButton from "../common/LinkButton";
import { useAuctionForm } from "./AuctionHooks";
import * as CONSTANTS from "../common/Constants";
import { EditorProvider, EditorConsumer } from "../Marvin";
import StructureImage from "../common/StructureImage";
import { removeEmpty, isValidFile } from "../common/Utilities";
import InputField from "../common/InputField";

import Loader from "../common/Loader";
import SimpleTooltip from "../common/SimpleTooltip";
import Infobox from "../common/Infobox";
import ConfirmModal from "../common/ConfirmModal";

import InvitationBlock from "../common/InvitationBlock";

export default function Auction(props) {
  const [title, setTitle] = useState(
    CONSTANTS.TITLES.MAKE_AUCTION +
      CONSTANTS.TITLES.DELIMITER +
      CONSTANTS.TITLES.POSTFIX
  );
  const history = useHistory();
  const { userInfo } = useAuth();
  // For init values
  const [suppliers, setSuppliers] = useState([]);
  const [initSuppliers, setInitSuppliers] = useState([]);
  const [supplierSearch, setSupplierSearch] = useState("");
  const [structureInput, setStructureInput] = useState(
    CONSTANTS.STRUCTURE_INPUT.TEXTAREA
  );
  const [exclusivityRequired, setExlusivityRequired] = useState(false);
  const [tac, setTac] = useState(false);
  const [notPatentedCompounds, setNotPatentedCompounds] = useState(false);
  const [compoundText, setCompoundText] = useState("");
  const [compoundLines, setCompoundLines] = useState([]);
  const [errors, setErrors] = useState({});

  /* MARVIN */
  const [marvinInput, setMarvinInput] = useState("");
  const [marvinInputError] = useState(false);
  const [inputStructure, setInputStructure] = useState(null);

  // For edit values
  const [mode, setMode] = useState(CONSTANTS.MODE_CREATE);
  const [auctionId, setAuctionId] = useState();
  const [auctionData, setAuctionData] = useState();
  const [auctionLoading, setAuctionLoading] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [actionMode, setActionMode] = useState("");

  const [logoFreeFile, setLogoFreeFile] = useState(false);

  const [files, setFiles] = useState([]);
  const [confirmDeleteFile, setConfirmDeleteFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const toggleDeleteFileModal = () => {
    setConfirmDeleteFile(!confirmDeleteFile);
  };

  const deleteAuctionFile = () => {
    API.delete(`files/auction/${selectedFile.id}/`)
      .then((res) => {
        toast.success("File deleted!", {});
        if (selectedFile.assigned_to) {
          const newFiles = inputs.files.filter((f) => f.id !== selectedFile.id);
          setInputs((inputs) => ({
            ...inputs,
            files: newFiles,
          }));
        } else {
          const newFiles = files.filter((f) => f.id !== selectedFile.id);
          setFiles(newFiles);
        }
      })
      .catch((err) => {
        toast.error("Error at deleting a file: " + err, {});
        console.log("ERROR", err);
      });
  };

  const APIDomain = process.env.REACT_APP_API_DOMAIN;
  const APIBase = process.env.REACT_APP_API_BASE_URL;

  const formSubmit = () => {
    if (mode === CONSTANTS.MODE_CREATE) {
      // ToDo: confirmation
      addAuction(inputs, CONSTANTS.AUCTION_STATE.SUBMITTED);
    }
    if (mode === CONSTANTS.MODE_EDIT) {
      updateAuction(inputs, CONSTANTS.AUCTION_STATE.SUBMITTED);
    }
  };

  /* Input handling with hook START */
  let {
    inputs,
    setInputs,
    handleInputChange,
    handleDateTimePickerChange,
    handleSupplierChange,
    handleStructureChange,
    handleSubmit,
    handlePartialOfferChange,
    setInitAuctionValues,
  } = useAuctionForm(auctionData, formSubmit);
  /* Input handling with hook END */

  const [waiting, setWaiting] = useState(false);

  /* Supplier handling START */
  function renderAuctionSupplierRows() {
    if (mode === CONSTANTS.MODE_CREATE) {
      return inputs.preferred_suppliers.map((item, index) => {
        const supplier = suppliers.filter((s) => s.id === item)[0];
        return supplier ? (
          <tr key={supplier.name}>
            <td>{supplier.company_name}</td>
            <td className="text-right">
              <Button
                onClick={(event) =>
                  handleSupplierChange(
                    supplier,
                    CONSTANTS.PREFERRED_SUPPLIER_REMOVE
                  )
                }
                disabled={isDisabledField()}
              >
                -
              </Button>
            </td>
          </tr>
        ) : null;
      });
    }
    if (mode === CONSTANTS.MODE_EDIT) {
      if (inputs.preferred_suppliers && suppliers.length > 0) {
        return inputs.preferred_suppliers.map((item, index) => {
          const supplier = suppliers.filter((s) => s.id === item)[0];
          return supplier ? (
            <tr key={index}>
              <td>{supplier.company_name}</td>
              <td className="text-right">
                <Button
                  onClick={(event) =>
                    handleSupplierChange(
                      supplier,
                      CONSTANTS.PREFERRED_SUPPLIER_REMOVE
                    )
                  }
                  disabled={isDisabledField()}
                >
                  -
                </Button>
              </td>
            </tr>
          ) : null;
        });
      }
    }
  }

  const handleSupplierSearch = (event) => {
    setSupplierSearch(event.target.value);
    if (event.target.value.length > 0) {
      const filtered = initSuppliers.filter((str) => {
        return (
          str.company_name
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) >= 0
        );
      });
      setSuppliers(filtered);
    } else {
      setSuppliers(initSuppliers);
    }
  };
  /* Supplier handling END */

  /* Set disable for input fields */
  function isDisabledField() {
    let response = false;
    if (
      props.match.params.auctionId &&
      auctionData &&
      auctionData.state !== CONSTANTS.AUCTION_STATE.DRAFT
    ) {
      response = true;
    }
    return response;
  }

  const toggleModal = () => {
    setConfirmDelete(!confirmDelete);
  };

  const addCompoundToList = async (event) => {
    event.preventDefault();
    let convertableStructures = [];
    compoundLines.map((line) => {
      let temp = line.split(/\s+/);
      if (temp.length && temp[0].length) {
        var tempIndex = inputs.structures.findIndex(
          (x) =>
            x.chemical_id === temp[0] || (temp[1] && x.customer_id === temp[1])
        );
        if (tempIndex === -1) {
          convertableStructures.push({
            structure: temp[0],
            customerId: temp[1],
          });
        } else {
          toast.error(
            `Structure ${temp[0]} ${
              temp[1] ? "- " + temp[1] : ""
            } already on the list`,
            {}
          );
          handleStructureChange(inputs.structures);
          updatePartialOffer(inputs.structures);
        }
      }
      return true;
    });
    setInputStructure(convertableStructures);
    setCompoundText("");
    setCompoundLines([]);
  };

  const handleAddStructure = (smiles, mol, customerId) => {
    var index = inputs.structures.findIndex((x) => x.chemical_id === smiles);
    if (index === -1) {
      setMarvinInput("");
      inputs.structures.push({
        customer_id: marvinInput || customerId,
        chemical_id: smiles,
        molfile: mol,
      });
      handleStructureChange(inputs.structures);
      updatePartialOffer(inputs.structures);
    } else {
      toast.error(`Structure ${smiles} already on the list`, {});
    }
  };

  const removeCompoundFromList = (item) => {
    const newStructures = inputs.structures.filter((e) => e !== item);
    handleStructureChange(newStructures);
    updatePartialOffer(newStructures);
  };

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      setIsFileUploading(true);
      const formData = new FormData();
      formData.append("name", event.target.files[0].name);
      formData.append("file", event.target.files[0]);
      API.post(`files/auction/`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          setFiles((inputs) => [...inputs, res.data]);
        })
        .catch((err) => {
          toast.error("Error at file upload.", {});
          if (err.response) {
            setErrors((errors) => ({
              ...errors,
              file: err.response.data.file[0],
            }));
          }
          console.log("ERROR", err.response);
        })
        .finally(() => {
          setIsFileUploading(false);
        });
    }
  };

  useEffect(() => {
    if (exclusivityRequired) {
      setInputs((inputs) => ({
        ...inputs,
        exclusivity_value: 1,
      }));
    } else {
      setInputs((inputs) => ({
        ...inputs,
        exclusivity_value: 0,
      }));
    }
  }, [exclusivityRequired]);

  /* Adding new auction */
  function addAuction(inputs, state) {
    setErrors({});
    if (files.length > 0 && !logoFreeFile) {
      setErrors((errors) => ({
        ...errors,
        logoFreeFile: !logoFreeFile
          ? "Please check this box if You want to proceed"
          : null,
      }));
      toast.error("Please correct the form errors.", {});
    } else if (
      state !== CONSTANTS.BID_STATE.DRAFT &&
      (!tac || !notPatentedCompounds)
    ) {
      setErrors((errors) => ({
        ...errors,
        tac: !tac ? "Please check this box if You want to proceed" : null,
        notPatentedCompounds: !notPatentedCompounds
          ? "Please check this box if You want to proceed"
          : null,
      }));
    } else {
      let tempInputs = cloneDeep(inputs);
      tempInputs.state = state;
      tempInputs.delivery_deadline = isDate(tempInputs.delivery_deadline)
        ? moment(tempInputs.delivery_deadline).format(
            CONSTANTS.INPUT_DATE_FORMAT
          )
        : null;
      tempInputs.target_price = tempInputs.target_price
        ? parseFloat(tempInputs.target_price.split(",").join(""))
        : null;
      if (!exclusivityRequired) {
        tempInputs.exclusivity_value = 0;
      }
      setWaiting(true);
      tempInputs.files = [...inputs.files, ...files].map(function (file) {
        return file.id;
      });
      API.post("auctions/", removeEmpty(tempInputs))
        .then((res) => {
          const feedback =
            state === CONSTANTS.BID_STATE.DRAFT
              ? "Auction saved as draft"
              : "Auction created successfully!";
          toast.success(feedback, {});
          history.push("/my-auctions");
        })
        .catch((err) => {
          toast.error("Please correct the form errors.", {});
          // console.log("ERROR", err);
          if (err.response) {
            setErrors(err.response.data);
          }
        })
        .finally(() => {
          setWaiting(false);
        });
    }
  }

  /* Updating existing auction */
  function updateAuction(inputs, state) {
    if (files.length > 0 && !logoFreeFile) {
      setErrors((errors) => ({
        ...errors,
        logoFreeFile: !logoFreeFile
          ? "Please check this box if You want to proceed"
          : null,
      }));
      toast.error("Please correct the form errors.", {});
    } else {
      let tempInputs = cloneDeep(inputs);
      tempInputs.state = state;
      tempInputs.delivery_deadline = isDate(tempInputs.delivery_deadline)
        ? moment(tempInputs.delivery_deadline).format(
            CONSTANTS.INPUT_DATE_FORMAT
          )
        : null;
      tempInputs.target_price = isNaN(tempInputs.target_price)
        ? parseFloat(tempInputs.target_price.split(",").join(""))
        : tempInputs.target_price;
      if (!exclusivityRequired) {
        tempInputs.exclusivity_value = 0;
      }
      tempInputs.files = [...inputs.files, ...files].map(function (file) {
        return file.id;
      });
      setWaiting(true);
      API.put(`auctions/${auctionId}/`, removeEmpty(tempInputs))
        .then((res) => {
          const feedback =
            state === CONSTANTS.BID_STATE.DRAFT
              ? "Auction saved as draft"
              : "Auction created successfully!";
          toast.success(feedback, {});
          history.push("/my-auctions");
        })
        .catch((err) => {
          toast.error("Please correct the form errors.", {});
          // console.log("ERROR", err);
          if (err.response) {
            setErrors(err.response.data);
          }
        })
        .finally(() => {
          setWaiting(false);
        });
    }
  }

  /* Delete auction */
  function deleteAuction() {
    if (inputs.state === CONSTANTS.AUCTION_STATE.DRAFT) {
      API.delete(`auctions/${auctionId}/`)
        .then((res) => {
          toast.success("Auction cancelled successfully!", {});
          history.push("/");
        })
        .catch((err) => {
          toast.error("Please correct the form errors." + err, {});
          console.log("ERROR", err);
        });
    } else {
      API.patch(`auctions/${auctionId}/`, {
        state: CONSTANTS.AUCTION_STATE.CANCELLED,
      })
        .then((res) => {
          toast.success("Auction canceled successfully!", {});
          history.push("/");
        })
        .catch((err) => {
          toast.error("Please correct the form errors." + err, {});
          console.log("ERROR", err);
        });
    }
  }

  /* Component init: reading out data if necessary */
  useEffect(() => {
    setMode(CONSTANTS.MODE_CREATE);
    setInitAuctionValues();
    setExlusivityRequired(false);
    setTitle(
      CONSTANTS.TITLES.MAKE_AUCTION +
        CONSTANTS.TITLES.DELIMITER +
        CONSTANTS.TITLES.POSTFIX
    );
    if (props.match.params.auctionId) {
      setAuctionLoading(true);
      setMode(CONSTANTS.MODE_EDIT);
      setAuctionId(props.match.params.auctionId);
      setTitle(
        CONSTANTS.TITLES.AUCTION_DETAILS +
          CONSTANTS.TITLES.DELIMITER +
          CONSTANTS.TITLES.POSTFIX
      );
      API.get(`auctions/${props.match.params.auctionId}/`)
        .then((res) => {
          res.data.valid_until = moment(res.data.valid_until).toDate();
          if (res.data.delivery_deadline) {
            res.data.delivery_deadline = moment(
              res.data.delivery_deadline
            ).toDate();
          }
          setAuctionData(res.data);
          setExlusivityRequired(
            res.data.exclusivity_value && res.data.exclusivity_value !== 0
              ? true
              : false
          );
          if (res.data.state >= CONSTANTS.AUCTION_STATE.APPROVED) {
            setTac(true);
            setNotPatentedCompounds(true);
          }
        })
        .catch((err) => {
          toast.error("Error loading auction data", {});
          console.log("ERROR", err);
        })
        .finally(() => {
          setAuctionLoading(false);
        });
    } else {
      if (userInfo) {
        API.get("customers/" + userInfo.customer + "/")
          .then((result) => {
            if (
              result.data.preferred_suppliers &&
              mode === CONSTANTS.MODE_CREATE
            ) {
              const tempPreferred = result.data.preferred_suppliers.map(
                (value) => {
                  return {
                    id: value,
                  };
                }
              );
              handleSupplierChange(
                tempPreferred,
                CONSTANTS.PREFERRED_SUPPLIER_ADD,
                true
              );
            }
          })
          .catch((err) => {
            toast.error("Error loading user data", {});
            console.log("ERROR", err);
          });
      }
    }

    API.get(`suppliers/?page_size=100`)
      .then((res) => {
        setSuppliers(res.data.results);
        setInitSuppliers(res.data.results);
      })
      .catch((err) => {
        toast.error("Error loading suppliers data", {});
        console.log("ERROR", err);
      })
      .finally(() => {
        // this.setState({loading: false})
      });
  }, [props.match.params.auctionId]);

  const updatePartialOffer = (structures) => {
    if (structures.length > 1) {
      setInputs((inputs) => ({
        ...inputs,
        partial_offer_type: CONSTANTS.PARTIAL_OFFER_TYPE.MINIMUM,
        partial_offer: 1,
      }));
    } else if (inputs.partial_offer !== structures.length) {
      handlePartialOfferChange(structures.length);
    }
  };

  const renderButtons = () => {
    return (
      <React.Fragment>
        {mode === CONSTANTS.MODE_CREATE && (
          <React.Fragment>
            <Col sm={4} className="text-center"></Col>
            <Col sm={4} className="text-center mb-2">
              <Button
                className="btn-orange"
                onClick={() => {
                  addAuction(inputs, CONSTANTS.AUCTION_STATE.DRAFT);
                }}
                disabled={waiting}
              >
                Save as draft
              </Button>
            </Col>
            <Col sm={4} className="text-center">
              <Button className="btn-orange" disabled={waiting}>
                Submit for review
              </Button>
            </Col>
          </React.Fragment>
        )}
        {mode === CONSTANTS.MODE_EDIT && (
          <React.Fragment>
            <Col sm={4} className="text-center">
              {inputs.state === CONSTANTS.AUCTION_STATE.DRAFT && (
                <Button
                  color="danger"
                  onClick={() => {
                    setActionMode(CONSTANTS.ACTION_MODE.DISCARD);
                    toggleModal();
                  }}
                >
                  Discard draft
                </Button>
              )}
              {(inputs.state === CONSTANTS.AUCTION_STATE.SUBMITTED ||
                inputs.state === CONSTANTS.AUCTION_STATE.APPROVED) && (
                <Button
                  color="danger"
                  onClick={() => {
                    setActionMode(CONSTANTS.ACTION_MODE.CANCEL);
                    toggleModal();
                  }}
                >
                  Cancel auction
                </Button>
              )}
            </Col>
            <Col sm={4} className="text-center">
              {inputs.state === CONSTANTS.AUCTION_STATE.DRAFT && (
                <Button
                  className="btn-orange"
                  onClick={() => {
                    updateAuction(inputs, CONSTANTS.AUCTION_STATE.DRAFT);
                  }}
                  disabled={waiting}
                >
                  Save as draft
                </Button>
              )}
            </Col>
            <Col sm={4} className="text-center">
              {inputs.state === CONSTANTS.AUCTION_STATE.DRAFT && (
                <Button disabled={waiting} className="btn-orange">
                  Submit for review
                </Button>
              )}
              {[
                CONSTANTS.AUCTION_STATE.APPROVED,
                CONSTANTS.AUCTION_STATE.ACCEPTED,
                CONSTANTS.AUCTION_STATE.CLOSED,
                CONSTANTS.AUCTION_STATE.EXPIRED,
                CONSTANTS.AUCTION_STATE.REQUESTED,
                CONSTANTS.AUCTION_STATE.CANCELLED,
              ].includes(inputs.state) && (
                <LinkButton
                  to={`/track-auction/${auctionData.id}`}
                  className="btn-orange d-block btn btn-secondary"
                >
                  Track auction
                </LinkButton>
              )}
            </Col>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="pt-4">
        {auctionLoading ? (
          <Loader text="Loading auction" />
        ) : (
          <Form className="mb-4" onSubmit={handleSubmit}>
            {mode === CONSTANTS.MODE_EDIT && (
              <Row>
                <Col sm={8}>
                  <ul className="breadcrumb">
                    <li>
                      <Link to={`/my-auctions`}>My auctions</Link>
                    </li>
                    <li>{inputs.auction_name} auction</li>
                  </ul>
                  <h1 className="mb-4 subheadline">
                    {inputs.state !== CONSTANTS.AUCTION_STATE.DRAFT ? (
                      <div>{inputs.auction_name} auction details</div>
                    ) : (
                      <div>Editing {inputs.auction_name} auction</div>
                    )}
                  </h1>
                </Col>
                <Col sm={4} className="text-right header-right">
                  <div className="subheader">
                    <span className="subheader-label">State:</span>
                    {inputs.state_display && (
                      <span
                        className={
                          "status " + inputs.state_display.toLowerCase()
                        }
                      >
                        {inputs.state_display}
                      </span>
                    )}
                  </div>
                  <h3 className="subheadline">{inputs.auction_name_full}</h3>
                  {[
                    CONSTANTS.AUCTION_STATE.APPROVED,
                    CONSTANTS.AUCTION_STATE.ACCEPTED,
                    CONSTANTS.AUCTION_STATE.CLOSED,
                    CONSTANTS.AUCTION_STATE.EXPIRED,
                    CONSTANTS.AUCTION_STATE.REQUESTED,
                    CONSTANTS.AUCTION_STATE.CANCELLED,
                  ].includes(inputs.state) && (
                    <LinkButton
                      to={`/track-auction/${inputs.id}`}
                      className="btn btn-orange"
                    >
                      Track auction
                    </LinkButton>
                  )}
                </Col>
              </Row>
            )}
            {mode === CONSTANTS.MODE_CREATE && (
              <h1 className="mb-4 subheadline">
                Create an auction by specifying all your requirements
              </h1>
            )}
            {errors.detail ||
              errors.name ||
              (errors.non_field_errors && (
                <Alert color="danger" className="mb-2">
                  <div>{errors.detail}</div>
                  <div>{errors.name}</div>
                  <div>{errors.non_field_errors}</div>
                </Alert>
              ))}
            {inputs.state === CONSTANTS.AUCTION_STATE.SUBMITTED && (
              <Alert color="info">
                <i className="fas fa-exclamation"></i>
                This auction is waiting for approval by Mcule, you will be
                informed when it is completed.
              </Alert>
            )}
            <h2 className="subheadline">
              {!isDisabledField() && <span>Step 1: </span>}
              Auction details
            </h2>
            <div className="box-container orange">
              <div className="box">
                <Row>
                  <Col sm={6}>
                    <InputField
                      name="auction_name"
                      displayName="Auction name"
                      type="text"
                      value={inputs.auction_name}
                      onChangeHandler={handleInputChange}
                      disabled={isDisabledField()}
                      invalidCondition={errors && errors.auction_name}
                      errorField={
                        errors && errors.auction_name ? errors.auction_name : ""
                      }
                      tooltip={
                        "Auction name serves as the primary identifier for your project besides the CSR name, automatically generated by Mcule. Auction name is hidden from the suppliers, only visible to you. Auction name setting is optional."
                      }
                    />

                    <FormGroup>
                      <Label for="description">
                        Your notes about the project
                      </Label>
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        placeholder="Write a description here so you can easily retrieve your project later. Note that this section is only visible to you."
                        rows="5"
                        onChange={handleInputChange}
                        value={inputs.description}
                        disabled={isDisabledField()}
                        invalid={errors.description}
                      />
                      {errors.description && (
                        <FormFeedback>{errors.description}</FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="close_immediately"
                          id="close_immediately"
                          onChange={handleInputChange}
                          checked={inputs.close_immediately}
                          disabled={isDisabledField()}
                          invalid={errors.close_immediately}
                        />{" "}
                        Close the auction anytime immediately{" "}
                        <SimpleTooltip name="close_immediately">
                          If selected, you can close the auction immediately
                          anytime. Otherwise you can still close the auction
                          sooner than the original auction closing date, only if
                          it will remain active for 3 more days.
                        </SimpleTooltip>
                      </Label>
                      {errors.close_immediately && (
                        <FormFeedback>{errors.close_immediately}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="valid_until">Auction closing date*</Label>
                      <SimpleTooltip className="valid_until">
                        {" "}
                        Set a closing date and time for the auction. The auction
                        must be closed in 15 days after submission. Bids can be
                        received within the time period between the approval of
                        the auction and the specified closing date. During the
                        auction, this period can be shortened to a certain
                        scale, but extending the auction is not possible. Please
                        note that the suppliers need time for bidding, so the
                        auction recommended length is from 7 to 15 days.
                      </SimpleTooltip>
                      <Datetime
                        name="valid_until"
                        id="valid_until"
                        className={
                          " " + (errors.valid_until ? "is-invalid" : "")
                        }
                        onChange={(moment) =>
                          handleDateTimePickerChange(moment, "valid_until")
                        }
                        dateFormat={CONSTANTS.DATE_FORMAT}
                        timeFormat={CONSTANTS.TIME_FORMAT}
                        value={inputs.valid_until}
                        inputProps={{
                          disabled: isDisabledField(),
                          className:
                            "form-control " +
                            (errors.valid_until ? "is-invalid" : ""),
                        }}
                      />
                      {errors.valid_until && (
                        <FormFeedback>{errors.valid_until}</FormFeedback>
                      )}
                    </FormGroup>
                    <InputField
                      name="target_price"
                      displayName="Budget for the project"
                      type="price"
                      value={inputs.target_price}
                      onChangeHandler={handleInputChange}
                      disabled={isDisabledField()}
                      invalidCondition={errors.target_price}
                      errorField={
                        errors && errors.target_price ? errors.target_price : ""
                      }
                      append="USD"
                      tooltip={
                        "Indicate the estimated size of the project budget. This can assist the suppliers in making a quote, but the suppliers are not required to consider it when bidding."
                      }
                    />
                    <FormGroup>
                      <Label for="delivery_deadline">
                        Delivery deadline date
                      </Label>
                      <SimpleTooltip name="delivery_deadline">
                        Indicate your preferred delivery deadline date for this
                        project. This serves as a target delivery time for the
                        bidders. This can assist the suppliers in bidding, but
                        their offer may differ from this. Delivery deadline
                        should be greater than the auction closing date by at
                        least 14 days.
                      </SimpleTooltip>
                      <Datetime
                        name="delivery_deadline"
                        id="delivery_deadline"
                        className={
                          " " + (errors.delivery_deadline ? "is-invalid" : "")
                        }
                        onChange={(moment) =>
                          handleDateTimePickerChange(
                            moment,
                            "delivery_deadline"
                          )
                        }
                        dateFormat={CONSTANTS.DATE_FORMAT}
                        timeFormat={CONSTANTS.TIME_FORMAT}
                        value={
                          inputs.delivery_deadline
                            ? inputs.delivery_deadline
                            : ""
                        }
                        inputProps={{
                          disabled: isDisabledField(),
                          className:
                            "form-control " +
                            (errors.delivery_deadline ? "is-invalid" : ""),
                        }}
                      />
                      {errors.delivery_deadline && (
                        <FormFeedback>{errors.delivery_deadline}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={12}>
                    <div className="label-line-wrapper">
                      <div className="label-text">
                        Select suppliers to your auction
                      </div>
                      <div className="label-line"></div>
                    </div>
                    <Infobox>
                      <p>
                        Select your preferred suppliers who can access your
                        auction and make bids. At least three suppliers should
                        be selected. The suppliers who are not selected can
                        neither see the auction nor make bids. In your profile,
                        you can set your default preferred suppliers.
                      </p>
                    </Infobox>
                  </Col>
                  <Col sm={6}>
                    <Row className="align-items-center mb-2">
                      <Col>List of all suppliers</Col>
                      <Col>
                        <Input
                          type="text"
                          name="supplier_search"
                          id="supplier_search"
                          placeholder="Filter"
                          onChange={handleSupplierSearch}
                          value={supplierSearch}
                          disabled={isDisabledField()}
                        />
                      </Col>
                      <Col className="text-right">
                        <Button
                          className="btn-orange"
                          onClick={(event) =>
                            handleSupplierChange(
                              suppliers,
                              CONSTANTS.PREFERRED_SUPPLIER_ADD,
                              true
                            )
                          }
                          disabled={isDisabledField()}
                        >
                          Add all
                        </Button>
                      </Col>
                    </Row>
                    <Table className="supplier-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {suppliers.map((supplier, index) => (
                          <tr key={supplier.name}>
                            <td>{supplier.company_name}</td>
                            <td className="text-right">
                              <Button
                                onClick={(event) =>
                                  handleSupplierChange(
                                    supplier,
                                    CONSTANTS.PREFERRED_SUPPLIER_ADD
                                  )
                                }
                                disabled={isDisabledField()}
                              >
                                +
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                  <Col sm={6}>
                    <Row className="align-items-center mb-2">
                      <Col>Your preferred suppliers</Col>
                      <Col className="text-right">
                        <Button
                          className="btn-orange"
                          onClick={(event) =>
                            handleSupplierChange(
                              suppliers,
                              CONSTANTS.PREFERRED_SUPPLIER_REMOVE,
                              true
                            )
                          }
                          disabled={isDisabledField()}
                        >
                          Remove all
                        </Button>
                      </Col>
                    </Row>
                    {errors.preferred_suppliers && (
                      <Alert color="danger">{errors.preferred_suppliers}</Alert>
                    )}
                    <Table className="supplier-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>{renderAuctionSupplierRows()}</tbody>
                    </Table>
                  </Col>
                </Row>
                <InvitationBlock />
              </div>
            </div>
            <h2 className="subheadline">
              {!isDisabledField() && <span>Step 2: </span>}
              Compound details
            </h2>
            <div className="box-container orange">
              <div className="box">
                <Row>
                  <Col sm={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Add new compound</div>
                      <div className="label-line"></div>
                    </div>

                    <FormGroup>
                      <div>
                        <Label for="structureInput">Structure input mode</Label>
                        <SimpleTooltip name="structure-input">
                          Choose from the available input methods, namely Paste
                          SMILES text or Draw the structures in Marvin editor.
                          Paste text mode is recommended if you want to add more
                          than one compound to the compound list at a time. Draw
                          structure mode is recommended if you want to add one
                          compound to the compound list at a time. Compound ID
                          is an optional identifier of the compounds, it is not
                          shown to suppliers.
                        </SimpleTooltip>
                      </div>
                      <ButtonGroup className="input-mode">
                        <Button
                          onClick={() =>
                            setStructureInput(
                              parseInt(CONSTANTS.STRUCTURE_INPUT.TEXTAREA)
                            )
                          }
                          active={
                            structureInput ===
                            CONSTANTS.STRUCTURE_INPUT.TEXTAREA
                          }
                          disabled={isDisabledField()}
                        >
                          Paste text
                        </Button>
                        <Button
                          onClick={() =>
                            setStructureInput(
                              parseInt(CONSTANTS.STRUCTURE_INPUT.MARVIN)
                            )
                          }
                          active={
                            structureInput === CONSTANTS.STRUCTURE_INPUT.MARVIN
                          }
                          disabled={isDisabledField()}
                        >
                          Draw structure
                        </Button>
                      </ButtonGroup>
                    </FormGroup>
                    <div className="marvin-container mb-2">
                      <EditorProvider
                        exportStructure={handleAddStructure}
                        structure={inputStructure}
                        isMarvinImport={
                          structureInput === CONSTANTS.STRUCTURE_INPUT.MARVIN
                            ? true
                            : false
                        }
                      >
                        <EditorConsumer>
                          {() => {
                            return (
                              <>
                                {structureInput ===
                                  CONSTANTS.STRUCTURE_INPUT.TEXTAREA && (
                                  <React.Fragment>
                                    <FormGroup>
                                      <Label for="input_textarea">
                                        Textarea
                                      </Label>
                                      <Input
                                        type="textarea"
                                        name="input_textarea"
                                        id="input_textarea"
                                        placeholder="One compound per row: SMILES and your own compound ID (optional) separated by space or tab.
                                                        Example: c1ccccc1 COMPANY-123"
                                        onChange={(event) => {
                                          setCompoundText(event.target.value);
                                          setCompoundLines(
                                            event.target.value.split(/\r?\n/)
                                          );
                                        }}
                                        value={compoundText}
                                        rows="8"
                                        disabled={isDisabledField()}
                                      />
                                    </FormGroup>
                                    <div className="text-right">
                                      <Button
                                        className="btn-orange"
                                        onClick={addCompoundToList}
                                      >
                                        Add to list
                                      </Button>
                                    </div>
                                  </React.Fragment>
                                )}
                                {structureInput ===
                                  CONSTANTS.STRUCTURE_INPUT.MARVIN && (
                                  <FormGroup>
                                    <Label for="compound_id">Compound ID</Label>
                                    <Input
                                      type="text"
                                      name="compound_id"
                                      id="compound_id"
                                      placeholder="Compound ID. Example: COMPANY-123"
                                      onChange={(event) =>
                                        setMarvinInput(event.target.value)
                                      }
                                      value={marvinInput}
                                      disabled={isDisabledField()}
                                      invalid={marvinInputError}
                                    />
                                    {marvinInputError && (
                                      <FormFeedback>Missing field</FormFeedback>
                                    )}
                                  </FormGroup>
                                )}
                              </>
                            );
                          }}
                        </EditorConsumer>
                      </EditorProvider>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Compound list*</div>
                      <div className="label-line"></div>
                    </div>
                    {errors.structures && (
                      <Alert color="danger">
                        {errors.structures[0].hasOwnProperty("chemical_id")
                          ? `${errors.structures[0].chemical_id} (chemical ID)`
                          : JSON.stringify(errors.structures)}
                      </Alert>
                    )}
                    {mode === CONSTANTS.MODE_EDIT &&
                      inputs.structures.length > 0 && (
                        <a
                          href={`${APIDomain}${APIBase}auctions/${inputs.id}/structures-sdf/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mb-1 d-block"
                        >
                          <i className="far fa-file"></i>{" "}
                          <span>Download structures SDF</span>
                        </a>
                      )}
                    <Table className="fixed-layout">
                      <thead>
                        <tr>
                          <th className="column-id">#</th>
                          <th className="column-structure">Structure</th>
                          <th>Chemical Identifier (Smiles)</th>
                          <th className="column-x"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {inputs.structures.map((item, index) => (
                          <tr key={`${item.chemical_id}`}>
                            <td className="text-center">{index + 1}</td>
                            <td className="structure-container">
                              <StructureImage src={item.chemical_id} />
                              {item.customer_id}
                            </td>
                            <td>{item.chemical_id}</td>
                            <td className="text-center">
                              <Button
                                disabled={isDisabledField()}
                                onClick={(event) =>
                                  removeCompoundFromList(item)
                                }
                              >
                                X
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </div>

            <h2 className="subheadline">
              {!isDisabledField() && <span>Step 3: </span>}
              General compound requirements
            </h2>
            <div className="box-container orange">
              <div className="box">
                <Row>
                  <Col sm={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Amount</div>
                      <div className="label-line"></div>
                    </div>
                    <FormGroup>
                      <Row>
                        <Col sm={6}>
                          <InputField
                            name="amount"
                            displayName="Requested amount"
                            type="price"
                            min={0}
                            value={inputs.amount}
                            onChangeHandler={handleInputChange}
                            disabled={isDisabledField()}
                            invalidCondition={errors.amount}
                            errorField={
                              errors && errors.amount ? errors.amount : ""
                            }
                            tooltip={
                              "The amount indicated here applies to all compounds in the compound list. Please note the minimum order quantity is 1 mg. Please note that the suppliers’ offer may differ from this."
                            }
                            required
                          />
                        </Col>
                        <Col sm={6}>
                          <Label></Label>
                          <Input
                            type="select"
                            name="unit"
                            id="unit"
                            onChange={handleInputChange}
                            value={inputs.unit}
                            disabled={isDisabledField()}
                          >
                            <option value={CONSTANTS.AMOUNT_UNIT.MG}>mg</option>
                            <option value={CONSTANTS.AMOUNT_UNIT.G}>g</option>
                            <option value={CONSTANTS.AMOUNT_UNIT.KG}>kg</option>
                          </Input>
                        </Col>
                      </Row>
                    </FormGroup>
                    <div className="label-line-wrapper">
                      <div className="label-text">Purity</div>
                      <div className="label-line"></div>
                    </div>
                    <FormGroup>
                      <Label for="target_purity">Required purity*</Label>
                      <SimpleTooltip name="target_purity">
                        The purity indicated here applies to all compounds in
                        the compound list.
                      </SimpleTooltip>
                      <InputGroup>
                        <Input
                          type="number"
                          name="target_purity"
                          id="target_purity"
                          min="0"
                          onChange={handleInputChange}
                          value={inputs.target_purity}
                          disabled={isDisabledField()}
                          invalid={errors.target_purity}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      {errors.target_purity && (
                        <FormFeedback>{errors.target_purity}</FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="purity_own_analysis"
                          id="purity_own_analysis"
                          onChange={handleInputChange}
                          checked={inputs.purity_own_analysis}
                          disabled={isDisabledField()}
                          invalid={errors.purity_own_analysis}
                        />{" "}
                        I provide my quality control (QC) requirements.
                        <SimpleTooltip name="purity_own_analysis">
                          If you provide your QC requirements, it will be the
                          guidance in case of any quality related customer
                          claims. If you do not provide such requirements, the
                          QC standards the supplier provided along with the bid
                          will be the guidance. In the absence of these, Mcule’s
                          QC standards will be applied.
                        </SimpleTooltip>
                      </Label>
                      {errors.purity_own_analysis && (
                        <FormFeedback>
                          {errors.purity_own_analysis}
                        </FormFeedback>
                      )}
                    </FormGroup>
                    {inputs.purity_own_analysis && (
                      <Alert color="info">
                        Please indicate your QC requirements in the General
                        comment field or upload them as a file below.
                      </Alert>
                    )}
                    <div className="label-line-wrapper">
                      <div className="label-text">Synthesis route</div>
                      <div className="label-line"></div>
                    </div>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="known_synthesis_route"
                          id="known_synthesis_route"
                          onChange={handleInputChange}
                          checked={inputs.known_synthesis_route}
                          disabled={isDisabledField()}
                        />{" "}
                        I provide potential synthesis route.
                      </Label>
                      <SimpleTooltip name="known_syntesis_route">
                        Indicate whether you have previous knowledge of the
                        potential synthesis route of the compounds in the
                        compound list.
                      </SimpleTooltip>
                    </FormGroup>
                    {inputs.known_synthesis_route && (
                      <Alert color="info">
                        Please indicate the synthesis route in the General
                        comment field or upload it as a file below.
                      </Alert>
                    )}
                  </Col>
                  <Col sm={6}>
                    {inputs.structures.length > 1 && (
                      <React.Fragment>
                        <div className="label-line-wrapper">
                          <div className="label-text">Partial offer</div>
                          <div className="label-line"></div>
                        </div>
                        <Infobox>
                          As you have more than one compound you can specify
                          whether you allow bids for only a part of your
                          requested structures. “Exactly” means that each bid
                          contains exactly the specified number of compounds.
                          “Minimum” means that each bid contains at least the
                          specified number of compounds.
                        </Infobox>
                        <Row className="form-group">
                          <Col sm={6} md={3}>
                            Offers must include{" "}
                          </Col>
                          <Col sm={6} md={3}>
                            <Input
                              type="select"
                              name="partial_offer_type"
                              id="partial_offer_type"
                              onChange={handleInputChange}
                              value={inputs.partial_offer_type}
                              disabled={isDisabledField()}
                              invalid={errors.partial_offer_type}
                            >
                              <option
                                value={CONSTANTS.PARTIAL_OFFER_TYPE.EXACT}
                              >
                                exactly
                              </option>
                              <option
                                value={CONSTANTS.PARTIAL_OFFER_TYPE.MINIMUM}
                              >
                                minimum
                              </option>
                            </Input>
                          </Col>
                          <Col sm={6} md={3}>
                            <Input
                              type="number"
                              name="partial_offer"
                              id="partial_offer"
                              onChange={handleInputChange}
                              value={inputs.partial_offer}
                              disabled={isDisabledField()}
                              invalid={errors.partial_offer}
                              min={1}
                              max={inputs.structures.length}
                            />
                            {errors.partial_offer && (
                              <FormFeedback>
                                {errors.partial_offer}
                              </FormFeedback>
                            )}
                          </Col>
                          <Col sm={6} md={3}>
                            requested compound(s).
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                    <div className="label-line-wrapper">
                      <div className="label-text">Exclusivity</div>
                      <div className="label-line"></div>
                    </div>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="exclusivity"
                          id="exclusivity"
                          onChange={() => {
                            setExlusivityRequired(!exclusivityRequired);
                          }}
                          checked={exclusivityRequired}
                          disabled={isDisabledField()}
                        />
                        Exclusivity required
                      </Label>
                      <SimpleTooltip name="exclusivity">
                        If selected, you can request the supplier not to sell
                        the ordered product(s) to other customers for the
                        specified time period. Please note, that this can be an
                        extra service of the suppliers thus it may increase the
                        price.
                      </SimpleTooltip>
                    </FormGroup>
                    {exclusivityRequired && (
                      <FormGroup>
                        <Label for="exclusivity_value">
                          Exclusivity required for
                        </Label>
                        <Row>
                          <Col sm={6}>
                            <Input
                              type="number"
                              name="exclusivity_value"
                              id="exclusivity_value"
                              onChange={handleInputChange}
                              value={inputs.exclusivity_value}
                              disabled={isDisabledField()}
                              invalid={errors.exclusivity_value}
                              min={1}
                              step={1}
                            />
                            {errors.exclusivity_value && (
                              <FormFeedback>
                                {errors.exclusivity_value}
                              </FormFeedback>
                            )}
                          </Col>
                          <Col sm={6}>
                            <Input
                              type="select"
                              name="exclusivity_unit"
                              id="exclusivity_unit"
                              onChange={handleInputChange}
                              value={inputs.exclusivity_unit}
                              disabled={isDisabledField()}
                            >
                              <option value={CONSTANTS.EXLUSIVITY_UNIT.MONTH}>
                                months
                              </option>
                              <option value={CONSTANTS.EXLUSIVITY_UNIT.YEAR}>
                                years
                              </option>
                            </Input>
                          </Col>
                        </Row>
                      </FormGroup>
                    )}
                    <div className="label-line-wrapper">
                      <div className="label-text">
                        Reporting about the project
                      </div>
                      <SimpleTooltip name="reporting">
                        Indicate if you wish to receive reports about the
                        project and select its frequency. Please note, that this
                        can be an extra service of the suppliers thus it may
                        increase the price.
                      </SimpleTooltip>
                      <div className="label-line"></div>
                    </div>
                    <FormGroup>
                      <Input
                        type="select"
                        name="reporting"
                        id="reporting"
                        onChange={handleInputChange}
                        value={inputs.reporting}
                        disabled={isDisabledField()}
                        min="0"
                        invalid={errors.reporting}
                      >
                        <option value={CONSTANTS.REPORTING.WEEKLY}>
                          weekly
                        </option>
                        <option value={CONSTANTS.REPORTING.TWO_WEEKS}>
                          every two weeks
                        </option>
                        <option value={CONSTANTS.REPORTING.ONE_MONTH}>
                          monthly
                        </option>
                        <option value={CONSTANTS.REPORTING.END_OF_THE_PROJECT}>
                          at the end of the project
                        </option>
                        <option value={CONSTANTS.REPORTING.NOT_REQUIRED}>
                          not required
                        </option>
                      </Input>
                      {errors.reporting && (
                        <FormFeedback>{errors.reporting}</FormFeedback>
                      )}
                    </FormGroup>
                    <div className="label-line-wrapper">
                      <div className="label-text">Stereo match</div>
                      <SimpleTooltip name="exact_stereo_match">
                        Specify here whether the chemical IDs should be
                        considered exact stereochemical representations of the
                        compounds.
                      </SimpleTooltip>
                      <div className="label-line"></div>
                    </div>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="exact_stereo_match"
                          id="exact_stereo_match"
                          onChange={handleInputChange}
                          checked={inputs.exact_stereo_match}
                          disabled={isDisabledField()}
                        />{" "}
                        Exact stereo match required
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="label-line-wrapper">
                  <div className="label-text">Additional details</div>
                  <div className="label-line"></div>
                </div>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="files">File</Label>
                      <SimpleTooltip name="files">
                        The file(s) must be uploaded in one of .gz, .zip, .pdf,
                        .jpg, .png file formats.
                      </SimpleTooltip>
                      <div
                        className={
                          errors && errors.file
                            ? "is-invalid position-relative"
                            : "position-relative"
                        }
                      >
                        <CustomInput
                          type="file"
                          label="Upload file"
                          name="files"
                          id="files"
                          onChange={handleFileChange}
                          disabled={isDisabledField()}
                          invalid={errors?.file}
                        />
                        {isFileUploading && (
                          <div className="file-uploading-overlay">
                            <Spinner color="info" />
                            <span className="ml-2">Uploading file</span>
                          </div>
                        )}
                      </div>
                      {errors?.file && (
                        <FormFeedback>{errors.file}</FormFeedback>
                      )}
                      <FormText color="muted">
                        Supported file formats: .gz, .zip, .pdf, .jpg, .png
                      </FormText>
                    </FormGroup>

                    {inputs.files?.length > 1 && (
                      <a
                        href={`${APIDomain}${APIBase}auctions/${inputs.id}/download-files/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mb-1 d-block"
                      >
                        <span>Download all files</span>
                      </a>
                    )}
                    <ul className="file-list">
                      {inputs.files?.map((file) => (
                        <li key={file.id}>
                          <a
                            href={`${APIDomain}${APIBase}files/auction/${file.id}/download/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="far fa-file"></i>{" "}
                            <span>{file.name || file.filename}</span>
                          </a>
                          {auctionData?.state ===
                            CONSTANTS.AUCTION_STATE.DRAFT && (
                            <span
                              className="del-btn"
                              onClick={() => {
                                setSelectedFile(file);
                                toggleDeleteFileModal();
                              }}
                            >
                              &#10006;
                            </span>
                          )}
                        </li>
                      ))}
                      {files.length > 0 && (
                        <>
                          <div className="mt-2">Newly added files</div>
                          {files.map((file, index) => (
                            <li key={file.id}>
                              <a
                                href={`${APIDomain}${APIBase}files/auction/${file.id}/download/`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="far fa-file"></i>{" "}
                                <span>{file.name || file.filename}</span>
                              </a>
                              {inputs?.state <=
                                CONSTANTS.AUCTION_STATE.DRAFT && (
                                <span
                                  className="del-btn"
                                  onClick={() => {
                                    setSelectedFile(file);
                                    toggleDeleteFileModal();
                                  }}
                                >
                                  &#10006;
                                </span>
                              )}
                            </li>
                          ))}
                        </>
                      )}
                    </ul>
                    {files.length > 0 && (
                      <FormGroup check>
                        <Label
                          check
                          className={errors.logoFreeFile ? "text-danger" : ""}
                        >
                          <Input
                            type="checkbox"
                            name="logo-free-file"
                            id="logo-free-file"
                            onChange={() => {
                              setLogoFreeFile(!logoFreeFile);
                            }}
                            checked={logoFreeFile}
                            invalid={errors.logoFreeFile}
                          />{" "}
                          I understand and acknowledge when uploading files,
                          that according to Mcule SynthAgora Terms and
                          Conditions it is not allowed to upload files
                          containing company identifier information. I accept
                          that if such a case would occur the Administrator of
                          SynthAgora can remove these files in its sole
                          decision.
                        </Label>
                        {errors.logoFreeFile && (
                          <FormFeedback>{errors.logoFreeFile}</FormFeedback>
                        )}
                      </FormGroup>
                    )}
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="comment">General comments</Label>
                      <Input
                        type="textarea"
                        name="comment"
                        id="comment"
                        placeholder="Add further details about the project here, such as the required or prohibited salt forms of the compounds in the compound list, or your requirement for GLP, GMP etc. conditions. Please note that this section is visible to the suppliers, thus do not share information referring to your company."
                        onChange={handleInputChange}
                        value={inputs.comment}
                        rows="8"
                        disabled={isDisabledField()}
                        invalid={errors.comment}
                      />
                      {errors.comment && (
                        <FormFeedback>{errors.comment}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
            <FormGroup check>
              <Label check className={errors.tac ? "text-danger" : ""}>
                <Input
                  type="checkbox"
                  name="tac"
                  id="tac"
                  onChange={() => {
                    setTac(!tac);
                  }}
                  checked={tac}
                  invalid={errors.tac}
                  required
                  disabled={isDisabledField()}
                />{" "}
                Circumvention of SynthAgora is not allowed. You cannot use
                SynthAgora only to get information and/or quotes for a custom
                synthesis request, so you cannot purchase the compound(s) of the
                submitted auction from the selected suppliers outside of the
                Platform.*
              </Label>
              {errors.tac && <FormFeedback>{errors.tac}</FormFeedback>}
            </FormGroup>

            <FormGroup check>
              <Label
                check
                className={errors.notPatentedCompounds ? "text-danger" : ""}
              >
                <Input
                  type="checkbox"
                  name="notPatentedCompounds"
                  id="notPatentedCompounds"
                  onChange={() => {
                    setNotPatentedCompounds(!notPatentedCompounds);
                  }}
                  checked={notPatentedCompounds}
                  disabled={isDisabledField()}
                  required
                  invalid={errors.notPatentedCompounds}
                />{" "}
                The compounds in the submitted auction are not patented nor
                under any kind of regulation in my country.*
              </Label>
              {errors.notPatentedCompounds && (
                <FormFeedback>{errors.notPatentedCompounds}</FormFeedback>
              )}
            </FormGroup>
            <Row className="mt-4 buttons-container">{renderButtons()}</Row>
          </Form>
        )}
      </Container>
      <ConfirmModal
        open={confirmDelete}
        toggle={toggleModal}
        confirmed={deleteAuction}
        title="Confirmation needed"
        bodyText={
          actionMode === CONSTANTS.ACTION_MODE.DISCARD
            ? "Are you sure you want to discard this draft?"
            : "Are you sure you want to cancel this auction?"
        }
        cancelBtn="No"
        okBtn="Yes"
      />
      <ConfirmModal
        open={confirmDeleteFile}
        toggle={toggleDeleteFileModal}
        confirmed={deleteAuctionFile}
        title="Confirmation needed"
        bodyText={
          "Are you sure you want to delete this file? This step cannot be redone."
        }
        cancelBtn="No"
        okBtn="Yes"
      />
    </div>
  );
}
