import React from "react";
import { Col, Row, Container, Button } from "reactstrap";
import headerImage from "../assets/landing_hero_illustration.png";
import infographic from "../assets/landing_infographic.png";
import customerImage from "../assets/landing_customer.png";
import supplierImage from "../assets/landing_supplier.png";
import { Element } from "react-scroll";
import { Link } from "react-router-dom";

export default function Landing() {
  return (
    <div className="landing-bg">
      <Container className="mt-4">
        <Row className="header-block align-items-center">
          <Col sm={12} md={6} lg={4}>
            <h1 className="landing-h1">A new way to order custom synthesis</h1>
            <div className="h1-summary">
              <div>
                Join to accelerate your custom synthesis projects with
                SynthAgora.
              </div>
              <Link className="btn customer-btn mt-2" to="/registration">
                Register
              </Link>
            </div>
          </Col>
          <Col sm={12} md={6} lg={8}>
            <img
              src={headerImage}
              alt="landing_hero_illustration"
              className="landing-hero-img"
            />
          </Col>
        </Row>

        <Element name="about" className="element" id="about">
          <div className="main-description-block">
            <h2 className="text-center">How does it work?</h2>
            <img
              src={infographic}
              alt="landing_infographic"
              className="landing-infographic-img"
            />
            <Row>
              <Col sm={12} md={6} lg={3}>
                <div className="step-title color-orange">
                  1. Start an auction
                </div>
                <div className="step-description">
                  You can create an auction and specify your requirements
                  including compound specifications, exclusivity and reporting
                  frequency.
                </div>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <div className="step-title color-orange">2. Receive bids</div>
                <div className="step-description">
                  You can follow and compare the bids of your trusted suppliers.
                </div>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <div className="step-title color-blue">
                  3. Choose the winner
                </div>
                <div className="step-description">
                  After the auction is closed you can select the best offer and
                  place an order.
                </div>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <div className="step-title color-blue">
                  4. Track & Receive your order
                </div>
                <div className="step-description">
                  After the order confirmation, you can track your order and
                  check the expected date of arrival of your freshly synthesized
                  compounds.
                </div>
              </Col>
            </Row>
          </div>
        </Element>
        <Element name="customers" className="element" id="customers">
          <div className="customer-block">
            <Row>
              <Col sm={12} md={4}>
                <img src={customerImage} alt="Beneficial for customers" />
              </Col>
              <Col sm={12} md={8}>
                <h2>Why is it beneficial for customers?</h2>
                <ul className="text-left">
                  <li>Track your custom synthesis projects in one place</li>
                  <li>Broaden your supplier base</li>
                  <li>Receive multiple offers</li>
                  <li>Choose by price, delivery time or other criteria</li>
                  <li>Competition guarantees the best price</li>
                  <li>Supplier ratings</li>
                </ul>
                <Link className="btn customer-btn" to="/registration/customer">
                  Register as customer
                </Link>
              </Col>
            </Row>
          </div>
        </Element>
        <Element name="suppliers" className="element" id="suppliers">
          <div className="supplier-block">
            <Row>
              <Col sm={12} md={8} className="order-2 order-md-1">
                <h2>Why is it beneficial for suppliers?</h2>
                <ul className="text-left">
                  <li>
                    Transparent bidding (see price and delivery time of the
                    leading bids)
                  </li>
                  <li>
                    Save time and only bid for auctions where you are
                    competitive
                  </li>
                  <li>Instant feedback on how competitive your offer is</li>
                  <li>Broaden your customer base</li>
                  <li>Customer ratings</li>
                  <li>Orders from reliable customers</li>
                </ul>
                <Link className="btn supplier-btn" to="/registration/supplier">
                  Register as supplier
                </Link>
              </Col>
              <Col sm={12} md={4} className="order-1 order-md-2">
                <img src={supplierImage} alt="Beneficial for suppliers" />
              </Col>
            </Row>
          </div>
        </Element>
        <div className="partners">
          <h2>Some of our partners who are waiting for your request</h2>
          <Row>
            <Col xs={12} md={6} className="text-center">
              <img src="./assets/img/wuxi.jpg" alt="WuXi" />
            </Col>
            <Col xs={6} md={2} className="text-center">
              <img src="./assets/img/specs.jpg" alt="Specs" />
            </Col>
            <Col xs={6} md={2} className="text-center">
              <img src="./assets/img/keyorganics.jpg" alt="KeyOrganics" />
            </Col>
            <Col xs={6} md={2} className="text-center">
              <img src="./assets/img/jubilantbiosys.jpg" alt="JubilantBiosys" />
            </Col>
            <Col xs={6} md={2} className="text-center">
              <img src="./assets/img/chiroblock.jpg" alt="Chiroblock" />
            </Col>
            <Col xs={6} md={2} className="text-center">
              <img src="./assets/img/bld.jpg" alt="Bld" />
            </Col>
            <Col xs={6} md={2} className="text-center">
              <img src="./assets/img/targetmol.jpg" alt="TargetMol" />
            </Col>
            <Col xs={6} md={2} className="text-center">
              <img src="./assets/img/chiralstar.jpg" alt="ChiralStar" />
            </Col>
            <Col xs={6} md={2} className="text-center">
              <img src="./assets/img/carbanio_plus.png" alt="Carbanio Plus" />
            </Col>
            <Col xs={6} md={2} className="text-center">
              <img src="./assets/img/accela.jpg" alt="Accela" />
            </Col>
          </Row>
        </div>
      </Container>
      <div className="footer-bg">
        <Element name="contact" className="element" id="contact">
          <Container>
            <div className="contact-block mb-4">
              <h2 className="text-center">Contact</h2>
              <Row>
                <Col className="text-center" xs="6">
                  <p>Mcule, Inc.</p>
                  <div>535 Everett Ave #510</div>
                  <div>Palo Alto, CA 94301</div>
                  <div>USA</div>
                  <div>info@mcule.com</div>
                  <div>EIN: 46-2751332</div>
                </Col>
                <Col className="text-center" xs="6">
                  <p>mcule.com Kft.</p>
                  <div>Bartók Béla út 105-113.</div>
                  <div>H-1115, Budapest</div>
                  <div>Hungary</div>
                  <div>info@mcule.com</div>
                </Col>
              </Row>
            </div>
          </Container>
        </Element>
        <div>
          <Container className="text-center footer-links">
            <Link to="/terms">Terms and Conditions</Link>
            <span className="link-separator">|</span>
            <Link to="/privacy-policy">Privacy policy</Link>
          </Container>
        </div>
      </div>
    </div>
  );
}
