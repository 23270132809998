import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory, Link as OpenLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useAuth } from "../context/auth";
import * as CONSTANTS from "../common/Constants";
import { Link, Events, animateScroll as scroll, scroller } from "react-scroll";
import { useNotification } from "../context/notification";

function Navigation(props) {
  const history = useHistory();
  const { userInfo } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;
  const { notifications } = useNotification();
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdon = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    Events.scrollEvent.register("begin", function () {});
    Events.scrollEvent.register("end", function () {});

    const handleScroll = () => {
      const show = window.scrollY > 15;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const url = location.pathname.slice(1);
    let elem = document.getElementById(url);
    if (elem) {
      setTimeout(function () {
        scroller.scrollTo(url, {
          duration: 500,
          smooth: true,
          spy: true,
        });
        //elem.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
    setIsOpen(false);
  }, [location]);

  const renderSupplierMenu = () => {
    return (
      <React.Fragment>
        <NavItem>
          <OpenLink to="/open-auctions" className="nav-link">
            Open auctions
          </OpenLink>
        </NavItem>
        <NavItem>
          <OpenLink to="/my-bids" className="nav-link">
            My bids
          </OpenLink>
        </NavItem>
        <NavItem>
          <OpenLink to="/my-orders" className="nav-link">
            My orders
          </OpenLink>
        </NavItem>
      </React.Fragment>
    );
  };

  const renderCustomerMenu = () => {
    return (
      <React.Fragment>
        <NavItem>
          <OpenLink to="/create-auction" className="nav-link">
            Create an auction
          </OpenLink>
        </NavItem>
        <NavItem>
          <OpenLink to="/my-auctions" className="nav-link">
            My Auctions
          </OpenLink>
        </NavItem>
        <NavItem>
          <OpenLink to="/my-orders" className="nav-link">
            My Orders
          </OpenLink>
        </NavItem>
      </React.Fragment>
    );
  };

  const renderGuestMenu = () => {
    return (
      <React.Fragment>
        <NavItem>
          <OpenLink to="/about" className="nav-link" replace>
            About
          </OpenLink>
        </NavItem>
        <NavItem>
          <OpenLink to="/customers" className="nav-link" replace>
            Customers
          </OpenLink>
        </NavItem>
        <NavItem>
          <OpenLink to="/suppliers" className="nav-link" replace>
            Suppliers
          </OpenLink>
        </NavItem>
        <NavItem>
          <OpenLink to="/contact" className="nav-link" replace>
            Contact
          </OpenLink>
        </NavItem>
        <NavItem>
          <OpenLink to="/registration" className="btn" replace>
            Register
          </OpenLink>
        </NavItem>
        <NavItem>
          <OpenLink to="/login" className="btn" replace>
            Login
          </OpenLink>
        </NavItem>
      </React.Fragment>
    );
  };

  const logOut = () => {
    props.onSignOut().then(() => history.push("/"));
  };

  const isLandingPage = () => {
    return !userInfo && location.pathname === "/";
  };

  const getUnseenNotifications = () => {
    return notifications && notifications.length > 0
      ? notifications.results.filter((notification) => notification.unseen)
          .length || 0
      : null;
  };

  const renderMenu = () => {
    const background = navBackground ? "white" : "";
    return (
      <header
        className={`navbar-container sticky-top navbar-light ${background}`}
      >
        <Navbar className="container" expand="md">
          <OpenLink
            to="/"
            className="navbar-brand"
            replace
            onClick={(e) => {
              scroll.scrollToTop();
            }}
          >
            <img src="./assets/img/logo.png" alt="SynthAgora logo" />
          </OpenLink>
          <NavbarToggler onClick={toggle} />
          <div className="nav-right">
            {userInfo && (
              <React.Fragment>
                <Dropdown
                  className="d-inline-block"
                  isOpen={dropdownOpen}
                  toggle={toggleDropdon}
                >
                  <DropdownToggle tag="a" caret>
                    <i className="fas fa-user-circle"></i>
                    {userInfo.name || userInfo.email}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      tag="span"
                      onClick={() => history.push("/profile")}
                    >
                      Profile
                    </DropdownItem>
                    <DropdownItem
                      tag="span"
                      onClick={() => history.push("/password-change")}
                    >
                      Password change
                    </DropdownItem>
                    <DropdownItem
                      tag="span"
                      onClick={() => history.push("/notification-settings")}
                    >
                      Notification settings
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <OpenLink to="/profile"></OpenLink>
                <OpenLink to="/notifications">
                  <div className="notification-badge">
                    <i className="far fa-bell"></i>
                    {getUnseenNotifications() !== 0 && (
                      <span className="notification-counter">
                        {getUnseenNotifications()}
                      </span>
                    )}
                  </div>
                </OpenLink>
                <Button className="logout-btn" onClick={logOut}>
                  Log out
                </Button>
              </React.Fragment>
            )}
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {userInfo
                  ? userInfo.user_type === CONSTANTS.USER_TYPE.CUSTOMER
                    ? renderCustomerMenu()
                    : renderSupplierMenu()
                  : renderGuestMenu()}
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </header>
    );
  };

  return renderMenu();
}

export default Navigation;
