import React, { useState, useEffect } from "react";
import API from "../api/api";
import * as CONSTANTS from "../common/Constants";
import { useAuth } from "../context/auth";
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Table,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap";
import { toast } from "react-toastify";
import * as countryFile from "../assets/countries.json";
import InputField from "../common/InputField";
import * as publicDomains from "../assets/popularDomains.json";
import SimpleTooltip from "../common/SimpleTooltip";
import MetaTags from "react-meta-tags";
import Infobox from "../common/Infobox";
import InvitationBlock from "../common/InvitationBlock";

export default function Profile(props) {
  const title =
    CONSTANTS.TITLES.PROFILE +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;
  const { userInfo } = useAuth();
  const [countries, setCountries] = useState([]);
  const [errors, setErrors] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [initSuppliers, setInitSuppliers] = useState([]);
  const [supplierSearch, setSupplierSearch] = useState("");
  const [popularDomains, setPopularDomains] = useState([]);

  const [profile, setProfile] = useState({
    user_type: CONSTANTS.USER_TYPE.CUSTOMER,
    field: CONSTANTS.COMPANY_TYPE.PHARMA_COMPANY,
    addresses: [],
    contacts: [],
    preferred_suppliers: [],
  });

  const [shippingAddress, setShippingAddress] = useState({
    type: CONSTANTS.ADDRESS_TYPE.SHIPPING,
  });

  const [billingAddress, setBillingAddress] = useState({
    type: CONSTANTS.ADDRESS_TYPE.BILLING,
  });

  const [manufacturingAddress, setManufacturingAddress] = useState({
    type: CONSTANTS.ADDRESS_TYPE.MANUFACTURING,
  });

  const [poAddress, setPoAddress] = useState({
    type: CONSTANTS.ADDRESS_TYPE.PO,
  });

  const [generalContact, setGeneralContact] = useState({
    type: CONSTANTS.CONTACT_TYPE.GENERAL,
  });

  const [shippingContact, setShippingContact] = useState({
    type: CONSTANTS.CONTACT_TYPE.SHIPPING,
  });

  const [billingContact, setBillingContact] = useState({
    type: CONSTANTS.CONTACT_TYPE.BILLING,
  });

  const isCustomer = () => {
    return userInfo.user_type === CONSTANTS.USER_TYPE.CUSTOMER;
  };

  const isAllowedEmail = (email) => {
    let response = true;
    if (email && email.length) {
      var parts = email.split("@");
      if (parts.length === 2) {
        if (popularDomains.some((v) => parts[1].includes(v))) {
          response = false;
        }
      }
    }
    return response;
  };

  const requestPaymentAccount = () => {
    API.patch(`customers/${userInfo.customer}/`, {
      payment_account_state: CONSTANTS.PAYMENT_ACCOUNT_STATE.REQUESTED,
    })
      .then((res) => {
        toast.success("Credit Account request sent!", {
          autoClose: 5000,
        });
      })
      .catch((err) => {
        toast.error("Error at request.", {});
        // console.log("ERROR", err);
        if (err.response) {
          // console.log(err.response.data);
          setErrors(err.response.data);
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setPopularDomains(publicDomains.default);
      if (userInfo) {
        let apiPath = "";
        if (isCustomer()) {
          apiPath = "customers/" + userInfo.customer + "/";
        } else {
          apiPath = "suppliers/" + userInfo.supplier + "/";
        }
        API.get(apiPath)
          .then((result) => {
            setProfile(result.data);

            // setting contacts and addresses
            const generalCont = result.data.contacts.filter(
              (a) => a.type === CONSTANTS.CONTACT_TYPE.GENERAL
            );
            if (generalCont.length > 0) {
              setGeneralContact(generalCont[0]);
            }

            const shippingAddr = result.data.addresses.filter(
              (a) => a.type === CONSTANTS.ADDRESS_TYPE.SHIPPING
            );
            if (shippingAddr.length > 0) {
              setShippingAddress(shippingAddr[0]);
            }

            if (isCustomer()) {
              const billingCont = result.data.contacts.filter(
                (a) => a.type === CONSTANTS.CONTACT_TYPE.BILLING
              );
              if (billingCont.length > 0) {
                setBillingContact(billingCont[0]);
              }

              const billingAddr = result.data.addresses.filter(
                (a) => a.type === CONSTANTS.ADDRESS_TYPE.BILLING
              );
              if (billingAddr.length > 0) {
                setBillingAddress(billingAddr[0]);
              }

              const shippingCont = result.data.contacts.filter(
                (a) => a.type === CONSTANTS.CONTACT_TYPE.SHIPPING
              );
              if (shippingCont.length > 0) {
                setShippingContact(shippingCont[0]);
              }

              API.get(`suppliers/?page_size=100`)
                .then((res) => {
                  setSuppliers(res.data.results);
                  setInitSuppliers(res.data.results);
                })
                .catch((err) => {
                  toast.error("Error loading suppliers data", {});
                  console.log("ERROR", err);
                })
                .finally(() => {
                  // this.setState({loading: false})
                });
            }

            if (!isCustomer()) {
              const manufacturingAddr = result.data.addresses.filter(
                (a) => a.type === CONSTANTS.ADDRESS_TYPE.MANUFACTURING
              );
              if (manufacturingAddr.length > 0) {
                setManufacturingAddress(manufacturingAddr[0]);
              }

              const poAddr = result.data.addresses.filter(
                (a) => a.type === CONSTANTS.ADDRESS_TYPE.PO
              );
              if (poAddr.length > 0) {
                setPoAddress(poAddr[0]);
              }
            }
          })
          .catch((error) => {
            toast.error("Error loading user data", {});
            console.log("ERROR", error);
          })
          .finally(() => {
            // this.setState({loading: false})
          });
        setCountries(countryFile.default);
      }
    };
    fetchData();
  }, [userInfo]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const apiPath = isCustomer()
      ? "customers/" + userInfo.customer + "/"
      : "suppliers/" + userInfo.supplier + "/";

    const generalCont = profile.contacts.filter(
      (a) => a.type === CONSTANTS.CONTACT_TYPE.GENERAL
    );
    if (generalCont.length > 0) {
      profile.contacts = profile.contacts.map((a) =>
        a.type === CONSTANTS.CONTACT_TYPE.GENERAL ? generalContact : a
      );
    } else {
      profile.contacts.push(generalContact);
    }

    const shippingAddr = profile.addresses.filter(
      (a) => a.type === CONSTANTS.ADDRESS_TYPE.SHIPPING
    );
    if (shippingAddr.length > 0) {
      profile.addresses = profile.addresses.map((a) =>
        a.type === CONSTANTS.ADDRESS_TYPE.SHIPPING ? shippingAddress : a
      );
    } else {
      profile.addresses.push(shippingAddress);
    }

    if (isCustomer()) {
      const billingAddr = profile.addresses.filter(
        (a) => a.type === CONSTANTS.ADDRESS_TYPE.BILLING
      );
      if (billingAddr.length > 0) {
        profile.addresses = profile.addresses.map((a) =>
          a.type === CONSTANTS.ADDRESS_TYPE.BILLING ? billingAddress : a
        );
      } else {
        profile.addresses.push(billingAddress);
      }

      const billingCont = profile.contacts.filter(
        (a) => a.type === CONSTANTS.CONTACT_TYPE.BILLING
      );
      if (billingCont.length > 0) {
        profile.contacts = profile.contacts.map((a) =>
          a.type === CONSTANTS.CONTACT_TYPE.BILLING ? billingContact : a
        );
      } else {
        profile.contacts.push(billingContact);
      }

      const shippingCont = profile.contacts.filter(
        (a) => a.type === CONSTANTS.CONTACT_TYPE.SHIPPING
      );

      if (shippingCont.length > 0) {
        profile.contacts = profile.contacts.map((a) =>
          a.type === CONSTANTS.CONTACT_TYPE.SHIPPING ? shippingContact : a
        );
      } else {
        profile.contacts.push(shippingContact);
      }
    }

    if (!isCustomer()) {
      const manufacturingAddr = profile.addresses.filter(
        (a) => a.type === CONSTANTS.ADDRESS_TYPE.MANUFACTURING
      );
      if (manufacturingAddr.length > 0) {
        profile.addresses = profile.addresses.map((a) =>
          a.type === CONSTANTS.ADDRESS_TYPE.MANUFACTURING
            ? manufacturingAddress
            : a
        );
      } else {
        profile.addresses.push(manufacturingAddress);
      }

      const poAddr = profile.addresses.filter(
        (a) => a.type === CONSTANTS.ADDRESS_TYPE.PO
      );
      if (poAddr.length > 0) {
        profile.addresses = profile.addresses.map((a) =>
          a.type === CONSTANTS.ADDRESS_TYPE.PO ? poAddress : a
        );
      } else {
        profile.addresses.push(poAddress);
      }
    }

    setErrors({});
    let canProceed =
      isAllowedEmail(billingContact.email) &&
      isAllowedEmail(shippingContact.email)
        ? true
        : false;
    if (!isCustomer()) {
      canProceed = true;
    }

    if (canProceed) {
      API.put(apiPath, profile)
        .then((res) => {
          toast.success("Profile saved!", {
            autoClose: 5000,
          });
        })
        .catch((err) => {
          toast.error("Error at saving the profile.", {});
          // console.log("ERROR", err);
          if (err.response) {
            // console.log(err.response.data);
            setErrors(err.response.data);
          }
        });
    } else {
      if (!isAllowedEmail(billingContact.email)) {
        setErrors((error) => ({
          ...error,
          billingContact: {
            email: "Only company or university domains are allowed",
          },
        }));
      }
      if (!isAllowedEmail(shippingContact.email)) {
        setErrors((error) => ({
          ...error,
          shippingContact: {
            email: "Only company or university domains are allowed",
          },
        }));
      }
    }
  };

  const onChangeHandler = (event) => {
    event.persist();
    setProfile((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const onShippingAddressChangeHandler = (event) => {
    event.persist();
    setShippingAddress((address) => ({
      ...address,
      [event.target.name]: event.target.value,
    }));
  };

  const onBillingAddressChangeHandler = (event) => {
    event.persist();
    setBillingAddress((address) => ({
      ...address,
      [event.target.name]: event.target.value,
    }));
  };

  const onManufacturingAddressChangeHandler = (event) => {
    event.persist();
    setManufacturingAddress((address) => ({
      ...address,
      [event.target.name]: event.target.value,
    }));
  };

  const onPoAddressChangeHandler = (event) => {
    event.persist();
    setPoAddress((address) => ({
      ...address,
      [event.target.name]: event.target.value,
    }));
  };

  const onGeneralContactChangeHandler = (event) => {
    event.persist();
    setGeneralContact((contact) => ({
      ...contact,
      [event.target.name]: event.target.value,
    }));
  };

  const onBillingContactChangeHandler = (event) => {
    event.persist();
    setBillingContact((contact) => ({
      ...contact,
      [event.target.name]: event.target.value,
    }));
  };

  const onShippingContactChangeHandler = (event) => {
    event.persist();
    setShippingContact((contact) => ({
      ...contact,
      [event.target.name]: event.target.value,
    }));
  };

  /* Supplier handling START */
  function renderSavedSupplierRows() {
    if (profile.preferred_suppliers && suppliers.length > 0) {
      return profile.preferred_suppliers.map((item, index) => {
        const supplier = suppliers.filter((s) => s.id === item)[0];
        return supplier ? (
          <tr key={index}>
            <td>{supplier.company_name}</td>
            <td className="text-right">
              <Button
                onClick={(event) =>
                  handleSupplierChange(
                    supplier,
                    CONSTANTS.PREFERRED_SUPPLIER_REMOVE
                  )
                }
              >
                -
              </Button>
            </td>
          </tr>
        ) : null;
      });
    }
  }

  const handleSupplierSearch = (event) => {
    setSupplierSearch(event.target.value);
    if (event.target.value.length > 0) {
      const filtered = initSuppliers.filter((str) => {
        return (
          str.company_name
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) >= 0
        );
      });
      setSuppliers(filtered);
    } else {
      setSuppliers(initSuppliers);
    }
  };

  const handleSupplierChange = (supplier, action, multiSupplier = false) => {
    if (action === CONSTANTS.PREFERRED_SUPPLIER_REMOVE) {
      let filteredArray = profile.preferred_suppliers.filter(
        (e) => e !== supplier.id
      );
      if (multiSupplier) {
        filteredArray = [];
      }
      setProfile((inputs) => ({
        ...inputs,
        preferred_suppliers: filteredArray,
      }));
    } else if (action === CONSTANTS.PREFERRED_SUPPLIER_ADD) {
      if (multiSupplier) {
        for (let sup of supplier) {
          if (!profile.preferred_suppliers.includes(sup.id)) {
            profile.preferred_suppliers.push(sup.id);
          }
        }
        setProfile((inputs) => ({
          ...inputs,
          preferred_suppliers: inputs.preferred_suppliers,
        }));
      } else {
        if (!profile.preferred_suppliers.includes(supplier.id)) {
          profile.preferred_suppliers.push(supplier.id);
          setProfile((inputs) => ({
            ...inputs,
            preferred_suppliers: inputs.preferred_suppliers,
          }));
        }
      }
    }
  };
  /* Supplier handling END */

  const renderProfile = () => {
    if (userInfo) {
      return (
        <React.Fragment>
          <div
            className={"box-container " + (isCustomer() ? "orange" : "blue")}
          >
            <Form className="box" autoComplete="off" onSubmit={handleSubmit}>
              <Row>
                <Col sm={12} md={6}>
                  <div className="label-line-wrapper">
                    <div className="label-text">User/contact information</div>
                    <SimpleTooltip name="user_info">
                      These details were provided at registration. If you would
                      like to make a change in User/Contact, or Organization
                      information, please contact us at{" "}
                      <a href="mailto:support@synthagora.com">
                        support@synthagora.com
                      </a>
                      .
                    </SimpleTooltip>
                    <div className="label-line"></div>
                  </div>
                  <InputField
                    name="contact_person"
                    displayName="Contact name"
                    type="text"
                    value={generalContact.contact_person}
                    onChangeHandler={onGeneralContactChangeHandler}
                    invalidCondition={
                      errors &&
                      errors.generalContact &&
                      errors.generalContact.contact_person
                    }
                    errorField={
                      errors && errors.generalContact
                        ? errors.generalContact.contact_person
                        : ""
                    }
                  />
                  <InputField
                    formGroupClass="flex-fill"
                    name="email"
                    displayName="Contact email"
                    type="text"
                    value={generalContact.email}
                    onChangeHandler={onGeneralContactChangeHandler}
                    disabled
                    autoComplete="off"
                  />
                  <InputField
                    name="phone"
                    displayName="Contact phone"
                    type="text"
                    value={generalContact.phone}
                    disabled
                    autoComplete="off"
                  />
                  {isCustomer() ? (
                    <React.Fragment>
                      {profile.payment_account_state &&
                      profile.payment_account_state !==
                        CONSTANTS.PAYMENT_ACCOUNT_STATE.NOT_REQUESTED ? (
                        <React.Fragment>
                          <Row>
                            <Col sm={6}>
                              <InputField
                                name="payment_term"
                                displayName="Payment term"
                                type="text"
                                value={profile.payment_term || 0}
                                disabled
                              />
                            </Col>
                            <Col sm={6}>
                              <InputField
                                name="payment_account_state"
                                displayName="Credit Account status"
                                type="text"
                                value={profile.payment_account_state_display}
                                disabled
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Infobox>
                            Request Credit Account to pay after fulfillment and
                            we will contact you soon to discuss details.
                          </Infobox>
                          <div
                            className="btn btn-orange btn-sm mb-4"
                            onClick={() => {
                              requestPaymentAccount();
                            }}
                          >
                            Request Credit Account
                          </div>
                        </React.Fragment>
                      )}
                      {profile.discount > 0 && (
                        <InputField
                          name="discount"
                          displayName="Discount"
                          type="text"
                          value={`${profile.discount} %`}
                          disabled
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <FormGroup>
                      <Label for="catalog_type">Catalog type</Label>
                      <Input
                        type="select"
                        name="catalog_type"
                        id="catalog_type"
                        onChange={onChangeHandler}
                        value={profile.catalog_type}
                      >
                        <option
                          value={CONSTANTS.SUPPLIER_CATALOG_TYPE.ONLINE_UPDATE}
                        >
                          {CONSTANTS.SUPPLIER_CATALOG_TYPES.ONLINE_UPDATE}
                        </option>
                        <option
                          value={
                            CONSTANTS.SUPPLIER_CATALOG_TYPE.ONLINE_NO_UPDATE
                          }
                        >
                          {CONSTANTS.SUPPLIER_CATALOG_TYPES.ONLINE_NO_UPDATE}
                        </option>
                        <option
                          value={CONSTANTS.SUPPLIER_CATALOG_TYPE.OFFLINE_UPDATE}
                        >
                          {CONSTANTS.SUPPLIER_CATALOG_TYPES.OFFLINE_UPDATE}
                        </option>
                        <option
                          value={
                            CONSTANTS.SUPPLIER_CATALOG_TYPE.OFFLINE_NO_UPDATE
                          }
                        >
                          {CONSTANTS.SUPPLIER_CATALOG_TYPES.OFFLINE_NO_UPDATE}
                        </option>
                        <option
                          value={CONSTANTS.SUPPLIER_CATALOG_TYPE.NOT_EXIST}
                        >
                          {CONSTANTS.SUPPLIER_CATALOG_TYPES.NOT_EXIST}
                        </option>
                      </Input>
                    </FormGroup>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <div className="label-line-wrapper">
                    <div className="label-text">Organization information</div>
                    <SimpleTooltip name="organization_info">
                      These details were provided at registration. If you would
                      like to make a change in User/Contact, or Organization
                      information, please contact us at{" "}
                      <a href="mailto:support@synthagora.com">
                        support@synthagora.com
                      </a>
                    </SimpleTooltip>
                    <div className="label-line"></div>
                  </div>
                  <InputField
                    name="company_name"
                    displayName="Organization name"
                    type="text"
                    value={profile.company_name}
                    onChangeHandler={onChangeHandler}
                    disabled
                  />
                  <FormGroup>
                    <Label for="field">Organization type</Label>
                    <Input
                      type="select"
                      name="field"
                      id="field"
                      onChange={onChangeHandler}
                      disabled={true}
                      value={profile.field}
                    >
                      <option value={CONSTANTS.COMPANY_TYPE.INDUSTRIAL}>
                        {CONSTANTS.COMPANY_TYPES.INDUSTRIAL}
                      </option>
                      <option value={CONSTANTS.COMPANY_TYPE.UNIVERSITY}>
                        {CONSTANTS.COMPANY_TYPES.UNIVERSITY}
                      </option>
                      {profile.field === CONSTANTS.COMPANY_TYPE.SUPPLIER && (
                        <option value={CONSTANTS.COMPANY_TYPE.SUPPLIER}>
                          {CONSTANTS.COMPANY_TYPES.SUPPLIER}
                        </option>
                      )}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="description">Organization description</Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      onChange={onChangeHandler}
                      value={profile.description || ""}
                      invalid={errors.description}
                    />
                    {errors && errors.description && (
                      <FormFeedback>{errors.description}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {isCustomer() && (
                  <Col sm={12} md={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Billing information</div>
                      <SimpleTooltip name="billing_info">
                        Billing address is the same as the address you provided
                        at registration. This address will be used for invoicing
                        purposes. If you would like to change this information,
                        please contact us at{" "}
                        <a href="mailto:support@synthagora.com">
                          support@synthagora.com
                        </a>
                      </SimpleTooltip>
                      <div className="label-line"></div>
                    </div>
                    <InputField
                      name="name"
                      displayName="Address line 1"
                      type="text"
                      value={billingAddress.name}
                      onChangeHandler={onBillingAddressChangeHandler}
                      invalidCondition={
                        errors &&
                        errors.billingAddress &&
                        errors.billingAddress.name
                      }
                      errorField={
                        errors && errors.billingAddress
                          ? errors.billingAddress.name
                          : ""
                      }
                      disabled
                    />
                    <InputField
                      name="address_line"
                      displayName="Address line 2"
                      type="text"
                      value={billingAddress.address_line}
                      onChangeHandler={onBillingAddressChangeHandler}
                      invalidCondition={
                        errors &&
                        errors.billingAddress &&
                        errors.billingAddress.address_line
                      }
                      errorField={
                        errors && errors.billingAddress
                          ? errors.billingAddress.address_line
                          : ""
                      }
                      disabled
                    />
                    <InputField
                      name="city"
                      displayName="City/Region"
                      type="text"
                      value={billingAddress.city}
                      onChangeHandler={onBillingAddressChangeHandler}
                      invalidCondition={
                        errors &&
                        errors.billingAddress &&
                        errors.billingAddress.city
                      }
                      errorField={
                        errors && errors.billingAddress
                          ? errors.billingAddress.city
                          : ""
                      }
                      disabled
                    />
                    <Row>
                      <Col sm={6}>
                        <InputField
                          name="state"
                          displayName="State"
                          type="text"
                          value={billingAddress.state}
                          onChangeHandler={onBillingAddressChangeHandler}
                          invalidCondition={
                            errors &&
                            errors.billingAddress &&
                            errors.billingAddress.state
                          }
                          errorField={
                            errors && errors.billingAddress
                              ? errors.billingAddress.state
                              : ""
                          }
                          disabled
                        />
                      </Col>
                      <Col sm={6}>
                        <InputField
                          name="zip_code"
                          displayName="Zip code"
                          type="text"
                          value={billingAddress.zip_code}
                          onChangeHandler={onBillingAddressChangeHandler}
                          invalidCondition={
                            errors &&
                            errors.billingAddress &&
                            errors.billingAddress.zip_code
                          }
                          errorField={
                            errors && errors.billingAddress
                              ? errors.billingAddress.zip_code
                              : ""
                          }
                          disabled
                        />
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="billing_country">Country</Label>
                      <Input
                        type="select"
                        name="country"
                        id="billing_country"
                        onChange={onBillingAddressChangeHandler}
                        value={billingAddress.country}
                        invalid={
                          errors &&
                          errors.billingAddress &&
                          errors.billingAddress.country
                        }
                        disabled
                      >
                        {countries.map((country, index) => (
                          <option key={country.iso} value={country.iso}>
                            {country.name}
                          </option>
                        ))}
                      </Input>
                      {errors &&
                        errors.billingAddress &&
                        errors.billingAddress.country && (
                          <FormFeedback>
                            {errors.billingAddress.country}
                          </FormFeedback>
                        )}
                    </FormGroup>
                    <InputField
                      name="tax_number"
                      displayName="Tax number"
                      type="text"
                      value={profile.tax_number}
                      onChangeHandler={onChangeHandler}
                      invalidCondition={errors && errors.tax_number}
                      errorField={errors.tax_number}
                      tooltip={
                        "Please, provide your Tax number for invoicing purposes."
                      }
                    />
                    <div className="label-line-wrapper">
                      <div className="label-text">Billing contact</div>
                      <SimpleTooltip name="billing_contact">
                        Please provide a general billing contact here. The
                        billing contact can be modified later for each order if
                        it's necessary.
                      </SimpleTooltip>
                      <div className="label-line"></div>
                    </div>
                    <InputField
                      name="contact_person"
                      displayName="Contact person"
                      type="text"
                      value={billingContact.contact_person}
                      onChangeHandler={onBillingContactChangeHandler}
                      invalidCondition={
                        errors &&
                        errors.billingContact &&
                        errors.billingContact.contact_person
                      }
                      errorField={
                        errors && errors.billingContact
                          ? errors.billingContact.contact_person
                          : ""
                      }
                    />
                    <InputField
                      name="email"
                      displayName="Email"
                      type="text"
                      value={billingContact.email}
                      onChangeHandler={onBillingContactChangeHandler}
                      invalidCondition={
                        errors &&
                        errors.billingContact &&
                        errors.billingContact.email
                      }
                      errorField={
                        errors && errors.billingContact
                          ? errors.billingContact.email
                          : ""
                      }
                      required
                      tooltip={
                        "Please provide a business email address here. Personal email addresses (gmail.com, yahoo.com, hotmail.com, etc. domains) are not allowed."
                      }
                    />
                    <InputField
                      name="phone"
                      displayName="Phone"
                      type="text"
                      value={billingContact.phone}
                      onChangeHandler={onBillingContactChangeHandler}
                      invalidCondition={
                        errors &&
                        errors.billingContact &&
                        errors.billingContact.phone
                      }
                      errorField={
                        errors && errors.billingContact
                          ? errors.billingContact.phone
                          : ""
                      }
                    />
                  </Col>
                )}
                <Col sm={12} md={6}>
                  <React.Fragment>
                    <div className="label-line-wrapper">
                      <div className="label-text">Shipping information</div>
                      <SimpleTooltip name="shipping_info">
                        Please provide a general shipping address here. The
                        shipping information can be modified later for each
                        order if it's necessary.
                      </SimpleTooltip>
                      <div className="label-line"></div>
                      {isCustomer() && (
                        <span
                          className="label-line-button"
                          onClick={() =>
                            setShippingAddress((address) => ({
                              ...address,
                              ...billingAddress,
                              type: CONSTANTS.ADDRESS_TYPE.SHIPPING,
                            }))
                          }
                        >
                          Copy address from billing
                        </span>
                      )}
                    </div>
                    <InputField
                      name="name"
                      displayName="Address line 1"
                      type="text"
                      value={shippingAddress.name}
                      onChangeHandler={onShippingAddressChangeHandler}
                      invalidCondition={
                        errors &&
                        errors.shippingAddress &&
                        errors.shippingAddress.name
                      }
                      errorField={
                        errors && errors.shippingAddress
                          ? errors.shippingAddress.name
                          : ""
                      }
                    />
                    <InputField
                      name="address_line"
                      displayName="Address line 2"
                      type="text"
                      value={shippingAddress.address_line}
                      onChangeHandler={onShippingAddressChangeHandler}
                      invalidCondition={
                        errors &&
                        errors.shippingAddress &&
                        errors.shippingAddress.address_line
                      }
                      errorField={
                        errors && errors.shippingAddress
                          ? errors.shippingAddress.address_line
                          : ""
                      }
                    />
                    <InputField
                      name="city"
                      displayName="City/Region"
                      type="text"
                      value={shippingAddress.city}
                      onChangeHandler={onShippingAddressChangeHandler}
                      invalidCondition={
                        errors &&
                        errors.shippingAddress &&
                        errors.shippingAddress.city
                      }
                      errorField={
                        errors && errors.shippingAddress
                          ? errors.shippingAddress.city
                          : ""
                      }
                    />
                    <Row>
                      <Col sm={6}>
                        <InputField
                          name="state"
                          displayName="State"
                          type="text"
                          value={shippingAddress.state}
                          onChangeHandler={onShippingAddressChangeHandler}
                          invalidCondition={
                            errors &&
                            errors.shippingAddress &&
                            errors.shippingAddress.state
                          }
                          errorField={
                            errors && errors.shippingAddress
                              ? errors.shippingAddress.state
                              : ""
                          }
                        />
                      </Col>
                      <Col sm={6}>
                        <InputField
                          name="zip_code"
                          displayName="Zip code"
                          type="text"
                          value={shippingAddress.zip_code}
                          onChangeHandler={onShippingAddressChangeHandler}
                          invalidCondition={
                            errors &&
                            errors.shippingAddress &&
                            errors.shippingAddress.zip_code
                          }
                          errorField={
                            errors && errors.shippingAddress
                              ? errors.shippingAddress.zip_code
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="shipping_country">Country</Label>
                      <Input
                        type="select"
                        name="country"
                        id="shipping_country"
                        onChange={onShippingAddressChangeHandler}
                        value={shippingAddress.country || ""}
                        invalid={
                          errors &&
                          errors.shippingAddress &&
                          errors.shippingAddress.country
                        }
                      >
                        {countries.map((country, index) => (
                          <option key={country.iso} value={country.iso}>
                            {country.name}
                          </option>
                        ))}
                      </Input>
                      {errors &&
                        errors.shippingAddress &&
                        errors.shippingAddress.country && (
                          <FormFeedback>
                            {errors.shippingAddress.country}
                          </FormFeedback>
                        )}
                    </FormGroup>
                    {isCustomer() && (
                      <React.Fragment>
                        <InputField
                          name="fedex_account_number"
                          displayName="Fedex account number"
                          type="text"
                          value={profile.fedex_account_number}
                          onChangeHandler={onChangeHandler}
                          invalidCondition={
                            errors && errors.fedex_account_number
                          }
                          errorField={errors.fedex_account_number}
                        />
                        <div className="label-line-wrapper">
                          <div className="label-text">Shipping contact</div>
                          <SimpleTooltip name="shipping_contact">
                            Please provide a general shipping contact here. The
                            shipping contact can be modified later for each
                            order if it's necessary.
                          </SimpleTooltip>
                          <div className="label-line"></div>
                          <span
                            className="label-line-button"
                            onClick={() =>
                              setShippingContact((contact) => ({
                                ...contact,
                                ...billingContact,
                                type: CONSTANTS.CONTACT_TYPE.SHIPPING,
                              }))
                            }
                          >
                            Copy contact from billing
                          </span>
                        </div>
                        <InputField
                          name="contact_person"
                          displayName="Contact person"
                          type="text"
                          value={shippingContact.contact_person}
                          onChangeHandler={onShippingContactChangeHandler}
                          invalidCondition={
                            errors &&
                            errors.shippingContact &&
                            errors.shippingContact.contact_person
                          }
                          errorField={
                            errors && errors.shippingContact
                              ? errors.shippingContact.contact_person
                              : ""
                          }
                        />
                        <InputField
                          name="email"
                          displayName="Email"
                          type="text"
                          value={shippingContact.email}
                          onChangeHandler={onShippingContactChangeHandler}
                          invalidCondition={
                            errors &&
                            errors.shippingContact &&
                            errors.shippingContact.email
                          }
                          errorField={
                            errors && errors.shippingContact
                              ? errors.shippingContact.email
                              : ""
                          }
                        />
                        <InputField
                          name="phone"
                          displayName="Phone"
                          type="text"
                          value={shippingContact.phone}
                          onChangeHandler={onShippingContactChangeHandler}
                          invalidCondition={
                            errors &&
                            errors.shippingContact &&
                            errors.shippingContact.phone
                          }
                          errorField={
                            errors && errors.shippingContact
                              ? errors.shippingContact.phone
                              : ""
                          }
                        />
                      </React.Fragment>
                    )}
                    {!isCustomer() && (
                      <InputField
                        name="delivery_cost"
                        displayName="Shipping and handling fee [to Mcule, Hungary]"
                        type="number"
                        min="0"
                        value={profile.delivery_cost}
                        onChangeHandler={onChangeHandler}
                        invalidCondition={errors && errors.delivery_cost}
                        errorField={errors && errors.delivery_cost}
                        append="USD"
                      />
                    )}
                  </React.Fragment>
                </Col>
                {!isCustomer() && (
                  <Col sm={12} md={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">Manufacturing address</div>
                      <div className="label-line"></div>
                    </div>
                    <InputField
                      name="name"
                      displayName="Address line 1"
                      type="text"
                      value={manufacturingAddress.name}
                      onChangeHandler={onManufacturingAddressChangeHandler}
                      invalidCondition={
                        errors &&
                        errors.manufacturingAddress &&
                        errors.manufacturingAddress.name
                      }
                      errorField={
                        errors && errors.manufacturingAddress
                          ? errors.manufacturingAddress.name
                          : ""
                      }
                    />
                    <InputField
                      name="address_line"
                      displayName="Address line 2"
                      type="text"
                      value={manufacturingAddress.address_line}
                      onChangeHandler={onManufacturingAddressChangeHandler}
                      invalidCondition={
                        errors &&
                        errors.manufacturingAddress &&
                        errors.manufacturingAddress.address_line
                      }
                      errorField={
                        errors && errors.manufacturingAddress
                          ? errors.manufacturingAddress.address_line
                          : ""
                      }
                    />
                    <InputField
                      name="city"
                      displayName="City/Region"
                      type="text"
                      value={manufacturingAddress.city}
                      onChangeHandler={onManufacturingAddressChangeHandler}
                      invalidCondition={
                        errors &&
                        errors.manufacturingAddress &&
                        errors.manufacturingAddress.city
                      }
                      errorField={
                        errors && errors.manufacturingAddress
                          ? errors.manufacturingAddress.city
                          : ""
                      }
                    />
                    <Row>
                      <Col sm={6}>
                        <InputField
                          name="state"
                          displayName="State"
                          type="text"
                          value={manufacturingAddress.state}
                          onChangeHandler={onManufacturingAddressChangeHandler}
                          invalidCondition={
                            errors &&
                            errors.manufacturingAddress &&
                            errors.manufacturingAddress.state
                          }
                          errorField={
                            errors && errors.manufacturingAddress
                              ? errors.manufacturingAddress.state
                              : ""
                          }
                        />
                      </Col>
                      <Col sm={6}>
                        <InputField
                          name="zip_code"
                          displayName="Zip code"
                          type="text"
                          value={manufacturingAddress.zip_code}
                          onChangeHandler={onManufacturingAddressChangeHandler}
                          invalidCondition={
                            errors &&
                            errors.manufacturingAddress &&
                            errors.manufacturingAddress.zip_code
                          }
                          errorField={
                            errors && errors.manufacturingAddress
                              ? errors.manufacturingAddress.zip_code
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="manufacturing_country">Country</Label>
                      <Input
                        type="select"
                        name="country"
                        id="manufacturing_country"
                        onChange={onManufacturingAddressChangeHandler}
                        value={manufacturingAddress.country}
                        invalid={
                          errors &&
                          errors.manufacturingAddress &&
                          errors.manufacturingAddress.country
                        }
                      >
                        {countries.map((country, index) => (
                          <option key={country.iso} value={country.iso}>
                            {country.name}
                          </option>
                        ))}
                      </Input>
                      {errors &&
                        errors.manufacturingAddress &&
                        errors.manufacturingAddress.country && (
                          <FormFeedback>
                            {errors.manufacturingAddress.country}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </Col>
                )}
                {!isCustomer() && (
                  <Col sm={12} md={6}>
                    <div className="label-line-wrapper">
                      <div className="label-text">PO address</div>
                      <div className="label-line"></div>
                    </div>
                    <InputField
                      name="name"
                      displayName="Address line 1"
                      type="text"
                      value={poAddress.name}
                      onChangeHandler={onPoAddressChangeHandler}
                      invalidCondition={
                        errors && errors.poAddress && errors.poAddress.name
                      }
                      errorField={
                        errors && errors.poAddress ? errors.poAddress.name : ""
                      }
                    />
                    <InputField
                      name="address_line"
                      displayName="Address line 2"
                      type="text"
                      value={poAddress.address_line}
                      onChangeHandler={onPoAddressChangeHandler}
                      invalidCondition={
                        errors &&
                        errors.poAddress &&
                        errors.poAddress.address_line
                      }
                      errorField={
                        errors && errors.poAddress
                          ? errors.poAddress.address_line
                          : ""
                      }
                    />
                    <InputField
                      name="city"
                      displayName="City/Region"
                      type="text"
                      value={poAddress.city}
                      onChangeHandler={onPoAddressChangeHandler}
                      invalidCondition={
                        errors && errors.poAddress && errors.poAddress.city
                      }
                      errorField={
                        errors && errors.poAddress ? errors.poAddress.city : ""
                      }
                    />
                    <Row>
                      <Col sm={6}>
                        <InputField
                          name="state"
                          displayName="State"
                          type="text"
                          value={poAddress.state}
                          onChangeHandler={onPoAddressChangeHandler}
                          invalidCondition={
                            errors && errors.poAddress && errors.poAddress.state
                          }
                          errorField={
                            errors && errors.poAddress
                              ? errors.poAddress.state
                              : ""
                          }
                        />
                      </Col>
                      <Col sm={6}>
                        <InputField
                          name="zip_code"
                          displayName="Zip code"
                          type="text"
                          value={poAddress.zip_code}
                          onChangeHandler={onPoAddressChangeHandler}
                          invalidCondition={
                            errors &&
                            errors.poAddress &&
                            errors.poAddress.zip_code
                          }
                          errorField={
                            errors && errors.poAddress
                              ? errors.poAddress.zip_code
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="po_country">Country</Label>
                      <Input
                        type="select"
                        name="country"
                        id="po_country"
                        onChange={onPoAddressChangeHandler}
                        value={poAddress.country}
                        invalid={
                          errors && errors.poAddress && errors.poAddress.country
                        }
                      >
                        {countries.map((country, index) => (
                          <option key={country.iso} value={country.iso}>
                            {country.name}
                          </option>
                        ))}
                      </Input>
                      {errors &&
                        errors.poAddress &&
                        errors.poAddress.country && (
                          <FormFeedback>
                            {errors.poAddress.country}
                          </FormFeedback>
                        )}
                    </FormGroup>
                  </Col>
                )}
              </Row>

              {isCustomer() && (
                <Row className="mt-4">
                  <Col sm={12}>
                    <div className="label-line-wrapper">
                      <div className="label-text">
                        Select the suppliers you want to work with
                      </div>
                      <SimpleTooltip name="supplier_list">
                        This list can be modified for each auction
                      </SimpleTooltip>
                      <div className="label-line"></div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Row className="align-items-center mb-2">
                      <Col>List of suppliers</Col>
                      <Col>
                        <Input
                          type="text"
                          name="supplier_search"
                          id="supplier_search"
                          placeholder="Filter"
                          onChange={handleSupplierSearch}
                          value={supplierSearch}
                        />
                      </Col>
                      <Col className="text-right">
                        <Button
                          onClick={(event) =>
                            handleSupplierChange(
                              suppliers,
                              CONSTANTS.PREFERRED_SUPPLIER_ADD,
                              true
                            )
                          }
                        >
                          Add all
                        </Button>
                      </Col>
                    </Row>
                    <Table className="supplier-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {suppliers.map((supplier, index) => (
                          <tr key={index}>
                            <td>{supplier.company_name}</td>
                            <td className="text-right">
                              <Button
                                onClick={(event) =>
                                  handleSupplierChange(
                                    supplier,
                                    CONSTANTS.PREFERRED_SUPPLIER_ADD
                                  )
                                }
                              >
                                +
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                  <Col sm={6}>
                    <Row className="align-items-center mb-2">
                      <Col>Your preferred suppliers</Col>
                      <Col className="text-right">
                        <Button
                          onClick={(event) =>
                            handleSupplierChange(
                              suppliers,
                              CONSTANTS.PREFERRED_SUPPLIER_REMOVE,
                              true
                            )
                          }
                        >
                          Remove all
                        </Button>
                      </Col>
                    </Row>
                    <Table className="supplier-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>{renderSavedSupplierRows()}</tbody>
                    </Table>
                  </Col>
                </Row>
              )}
              <div className="text-center mb-4">
                <Button
                  className={
                    "" + (isCustomer() ? "customer-btn" : "supplier-btn")
                  }
                  type="submit"
                >
                  Save changes
                </Button>
              </div>
              {isCustomer() && <InvitationBlock />}
            </Form>
          </div>
        </React.Fragment>
      );
    } else {
      return <div>Loading</div>;
    }
  };

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="pt-4">
        <h1 className="mb-4 subheadline text-center">Profile page</h1>
        {renderProfile()}
      </Container>
    </div>
  );
}
