import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Container,
  Alert,
} from "reactstrap";
import API from "../api/api";
import { toast } from "react-toastify";
import * as CONSTANTS from "../common/Constants";
import MetaTags from "react-meta-tags";
import { useHistory } from "react-router-dom";

export default function ResetPasswordConfirmPage(props) {
  const title =
    CONSTANTS.TITLES.RESET_PASS_CONFIRM +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;
  const [inputs, setInputs] = useState({
    password: "",
    token: "",
    uid: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();

  useEffect(() => {
    setInputs((inputs) => ({
      ...inputs,
      uid: props.match.params.uid,
      token: props.match.params.token,
    }));
  }, [props.match.params.uid, props.match.params.token]);

  const handleSubmit = (event) => {
    event.preventDefault();
    API.post("/users/password-reset/confirm/", {
      password: inputs.password,
      uid: inputs.uid,
      token: inputs.token,
    })
      .then((res) => {
        toast.success(`Password successfully saved.`, {});
        history.push("/login");
      })
      .catch((err) => {
        toast.error("Please correct the form errors.", {});

        if (err.response) {
          setErrors(err.response.data);
        }
      });
  };

  const confirmPassword = () => {
    let result = false;
    if (
      ((inputs.password && inputs.password.length > 0) ||
        (inputs.confirm_password && inputs.confirm_password.length > 0)) &&
      inputs.password !== inputs.confirm_password
    ) {
      result = true;
    }

    return result;
  };

  const onChangeHandler = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="pt-4">
        <h1 className="mb-4 subheadline text-center">Setting new password</h1>
        <div className="box-container mw-500">
          <Form className="box" autoComplete="off" onSubmit={handleSubmit}>
            {(errors.uid || errors.token || errors.non_field_errors) && (
              <Alert color="danger" className="mb-2">
                <div>{errors.uid}</div>
                <div>{errors.token}</div>
                <div>{errors.non_field_errors}</div>
              </Alert>
            )}
            <FormGroup>
              <Label for="userPassword">Password</Label>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Your password"
                autoComplete="new-password"
                onChange={onChangeHandler}
                value={inputs.password || ""}
                invalid={
                  confirmPassword() ||
                  (errors && errors.password && errors.password.length > 0)
                }
                required
              />
              <small>
                (Minimum 8 characters - can't be a commonly used password -
                can't be entirely numeric)
              </small>
              {confirmPassword() && (
                <FormFeedback>
                  The password fields are not the same!
                </FormFeedback>
              )}
              {errors.password && (
                <FormFeedback>{errors.password}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="userPassword">Confirm password</Label>
              <Input
                type="password"
                name="confirm_password"
                id="confirm_password"
                placeholder="Confirm password"
                autoComplete="new-password"
                onChange={onChangeHandler}
                value={inputs.confirm_password || ""}
                invalid={confirmPassword()}
              />
            </FormGroup>
            <div className="text-center">
              <Button color="primary" type="submit">
                Save new password
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
}
