import React from "react";

export default function Loader(props) {
  return (
    <div
      className={
        "loader-container d-flex align-items-center justify-content-center" +
        (props.overlay ? " overlay" : "")
      }
    >
      <div className="loader triangle">
        <svg viewBox="0 0 86 80">
          <polygon points="43 8 79 72 7 72"></polygon>
        </svg>
      </div>
      {props.text && <div className="loader-text">{props.text}</div>}
    </div>
  );
}
