import React from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
import Countdown, { zeroPad } from "react-countdown";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (!completed) {
    return (
      <span>
        {days > 0 && <span>{days}D</span>} {zeroPad(hours, 2)}:
        {zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}
      </span>
    );
  }
};

export const getTimeLeft = (
  input,
  expiredText = "Expired",
  isDetailedCountdown = false,
  boolAsResponse = false
) => {
  const now = moment();
  if (input) {
    const deadline = moment(input);
    if (now.isAfter(deadline)) {
      if (boolAsResponse) {
        return false;
      }
      return <span>{expiredText}</span>;
    } else {
      if (boolAsResponse) {
        return true;
      }
      const diff = deadline.diff(now, "days");
      const diffSeconds = deadline.diff(now, "seconds");
      if (isDetailedCountdown && diff <= 1 && diffSeconds > 5) {
        return (
          <Countdown date={input} renderer={renderer}>
            <span>{expiredText}</span>
          </Countdown>
        );
      } else {
        return deadline.from(now);
      }
    }
  }
};

export const round = (number, precision) => {
  const factor = Math.pow(10, precision);
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
};

export const removeEmpty = (obj) => {
  Object.entries(obj).forEach(
    ([key, val]) =>
      (val && typeof val === "object" && removeEmpty(val)) ||
      ((val === null || val === "") && delete obj[key])
  );
  return obj;
};

export function stringToSlug(str, separator = "_") {
  str = str.trim();
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaaaeeeeiiiioooouuuunc------";

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  return str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes
    .replace(/^-+/, "") // trim - from start of text
    .replace(/-+$/, "") // trim - from end of text
    .replace(/-/g, separator);
}

export function isUndefined(param) {
  return typeof param === "undefined";
}

export function formatPrice(
  value,
  prefix = true,
  extraClass = null,
  disabled = false
) {
  if (!isNaN(parseFloat(value))) {
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        thousandSeparator={true}
        prefix={prefix ? "$" : ""}
        className={extraClass ? extraClass : ""}
      />
    );
  } else {
    return <span className="form-control disabled">N/A</span>;
  }
}

export function isValidFile(file) {
  if (file) {
    const availableTypes = ["application/zip", "application/x-zip-compressed"];
    return (
      availableTypes.includes(file.type) &&
      file.name.split(".").pop().toUpperCase() === "ZIP"
    );
  } else {
    return true;
  }
}
