import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  Button,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import API from "../api/api";
import { toast } from "react-toastify";
import AuctionList from "./AuctionList";
import Loader from "../common/Loader";
import MetaTags from "react-meta-tags";
import * as CONSTANTS from "../common/Constants";

export default function AuctionListPage() {
  const title =
    CONSTANTS.TITLES.MY_AUCTIONS +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;
  const [auctions, setAuctions] = useState([]);
  const [auctionLoading, setAuctionLoading] = useState(true);
  const [paginationData, setPaginationData] = useState();
  const PER_PAGE_LIMIT = 10;
  const [auctionStatusFilter, setAuctionStatusFilter] = useState(null);

  useEffect(() => {
    setAuctionLoading(true);
    getAuctions(1);
  }, []);

  const getAuctions = (page) => {
    let params = {
      page,
      ordering: "-created_at",
    };

    if (auctionStatusFilter && auctionStatusFilter !== "none") {
      params = { ...params, state: auctionStatusFilter };
    }

    API.get(`auctions/`, { params })
      .then((result) => {
        setAuctions(result.data.results);
        setPaginationData((data) => ({
          ...data,
          count: result.data.count,
          next: result.data.next,
          previous: result.data.previous,
          activePage: page,
        }));
      })
      .catch((err) => {
        toast.error("Error loading auction data", {});
        console.log("ERROR", err);
      })
      .finally(() => {
        setAuctionLoading(false);
      });
  };

  const renderPagination = () => {
    const pages = Array.from(
      Array(Math.ceil(paginationData.count / 10)),
      (_, i) => i + 1
    );
    return (
      <Pagination aria-label="Synthagora pagination">
        {/* <PaginationItem>
          <PaginationLink first href="#" />
        </PaginationItem> */}
        <PaginationItem disabled={paginationData.activePage === 1}>
          <PaginationLink
            previous
            onClick={() => {
              getAuctions(paginationData.activePage - 1);
            }}
          />
        </PaginationItem>
        {pages.map((item, index) => (
          <PaginationItem
            key={item}
            active={item === paginationData.activePage}
          >
            <PaginationLink
              onClick={() => {
                getAuctions(item);
              }}
            >
              {item}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={paginationData.activePage === pages.length}>
          <PaginationLink
            next
            onClick={() => {
              getAuctions(paginationData.activePage + 1);
            }}
          />
        </PaginationItem>
        {/* <PaginationItem>
          <PaginationLink last href="#" />
        </PaginationItem> */}
      </Pagination>
    );
  };

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="pt-4">
        <Row>
          <Col sm={12} md={6}>
            <h1 className="mb-2 subheadline orange-text">My auctions</h1>
            <div className="mb-4 filter-row">
              <Label for="auction-filter">Status of auction</Label>
              <Input
                type="select"
                name="auction-filter"
                id="auction-filter"
                onChange={(event) => setAuctionStatusFilter(event.target.value)}
              >
                <option value={"none"}>All</option>
                <option value={CONSTANTS.AUCTION_STATE.DRAFT}>
                  {CONSTANTS.REQUEST_STATE_LABEL.DRAFT}
                </option>
                <option value={CONSTANTS.AUCTION_STATE.SUBMITTED}>
                  {CONSTANTS.REQUEST_STATE_LABEL.SUBMITTED}
                </option>
                <option value={CONSTANTS.AUCTION_STATE.APPROVED}>
                  {CONSTANTS.REQUEST_STATE_LABEL.APPROVED}
                </option>
                <option value={CONSTANTS.AUCTION_STATE.CLOSED}>
                  {CONSTANTS.REQUEST_STATE_LABEL.CLOSED}
                </option>
                <option value={CONSTANTS.AUCTION_STATE.ACCEPTED}>
                  {CONSTANTS.REQUEST_STATE_LABEL.ACCEPTED}
                </option>
                <option value={CONSTANTS.AUCTION_STATE.REQUESTED}>
                  {CONSTANTS.REQUEST_STATE_LABEL.REQUESTED}
                </option>
                <option value={CONSTANTS.AUCTION_STATE.EXPIRED}>
                  {CONSTANTS.REQUEST_STATE_LABEL.EXPIRED}
                </option>
                <option value={CONSTANTS.AUCTION_STATE.CANCELLED}>
                  {CONSTANTS.REQUEST_STATE_LABEL.CANCELLED}
                </option>
              </Input>
              <Button
                className="btn btn-orange ml-2"
                onClick={() => getAuctions(1)}
              >
                Filter
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="text-right header-right mb-2">
              <Link to="create-auction" className="btn btn-orange">
                Create an auction
              </Link>
            </div>
          </Col>
        </Row>
        <div className="box-container orange">
          <div className="box">
            {auctionLoading ? (
              <Loader overlay text="Loading auctions" />
            ) : (
              <React.Fragment>
                <AuctionList auctions={auctions} />
                {paginationData?.count > PER_PAGE_LIMIT && renderPagination()}
              </React.Fragment>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
