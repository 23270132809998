import React, { useEffect, useState } from "react";
import { useMarvin } from "./useMarvin";
import { Button } from "reactstrap";

let EditorContext;
const { Provider, Consumer } = (EditorContext = React.createContext());

function EditorProvider({
  id = "sketch",
  displaySettings = { displayMode: "WIREFRAME", toolbars: "markush" },
  services,
  structure,
  url = "/marvinjs/editor.html",
  exportStructure = () => {},
  isMarvinImport = false,
  children,
}) {
  const [editorStructure, setEditorStructure] = useState(false);

  const textFieldImportCallback = (exportedStructures) => {
    exportedStructures.forEach((item) => {
      exportStructure(item.exportedSmiles, item.exportedMol, item.customerId);
    });
  };

  const marvinImportCallback = (exportedSmiles, exportedMol) => {
    if (exportedSmiles && exportedMol) {
      setEditorStructure({ smiles: exportedSmiles, mol: exportedMol });
    }
  };

  useMarvin(
    id,
    displaySettings,
    textFieldImportCallback,
    marvinImportCallback,
    services,
    structure,
    isMarvinImport
  );

  const styleTextFieldInput = {
    visibility: "hidden",
    height: "0px",
    minHeight: "0px",
  };
  const styleMarvinInput = {
    visibility: "visible",
    height: "auto",
    minHeight: "350px",
  };

  return (
    <Provider value={{}}>
      {children}
      <iframe
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        id={id}
        src={url}
        title={id}
        className="marvin-editor"
        style={isMarvinImport ? styleMarvinInput : styleTextFieldInput}
      />
      {isMarvinImport && (
        <div className="text-right">
          <Button
            className="btn-orange"
            onClick={() => {
              if (editorStructure) {
                exportStructure(editorStructure.smiles, editorStructure.mol);
              }
            }}
          >
            Add to list
          </Button>
        </div>
      )}
    </Provider>
  );
}
export { EditorProvider, Consumer as EditorConsumer, EditorContext };
