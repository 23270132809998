import React from "react";
import * as CONSTANTS from "../common/Constants";
import {
  Container,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import NotificationSettings from "./NotificationSettings";

export default function NotificationSettingsPage() {
  const title =
    CONSTANTS.TITLES.NOTIFICATION_SETTINGS +
    CONSTANTS.TITLES.DELIMITER +
    CONSTANTS.TITLES.POSTFIX;

  return (
    <div className="grey-bg">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <Container className="pt-4">
        <h1 className="mb-4 subheadline text-center">Notification settings</h1>
        <NotificationSettings />
      </Container>
    </div>
  );
}
